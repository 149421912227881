@import "../../../../variables.scss";
.online-timeline-ul {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0;
  overflow-x: scroll;
  scroll-behavior: smooth;
  box-sizing: border-box;

  &::before {
    content: "";
    height: 0.5px;
    background: $tableBorderColor;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    z-index: 0;
    // transform: translateX(-50%);
  }

  &::-webkit-scrollbar {
    background: transparent !important;
    /* make scrollbar transparent */
    -webkit-appearance: none !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.online-timeline-ul li {
  text-align: center;
  list-style: none;
  margin: 0 20px;
  position: relative;
  width: fit-content !important;
}
.online-timeline-ul .year_span {
  font-size: 0.937vw;
  color: $primaryColor;
  font-weight: 300;
}

.online-timeline-ul li:hover {
  box-shadow: none !important;
}

.online-timeline-ul li::after {
  content: " ";
  width: 0.781vw;
  height: 0.781vw;
  border: solid 2px $primaryColor;
  border-radius: 50%;
  display: block;
  margin: 0 auto 5px;
  background: white;
}

.online-timeline-ul li.active::after {
  content: " ";
  width: 0.833vw;
  height: 0.833vw;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  display: block;
  background: $primaryColor;
}
