@import "../../../../../variables.scss";

.mtr-i-container {
    padding: 1rem;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    display: flex;
    flex-direction: column;

    .mtr-i-header {
        display: flex;
        justify-content: flex-start;

        span {
            font-size: 1.2vw;
            color: $primaryColor;
            font-weight: 800;
            text-transform: none;
        }
    }

    .mtr-i-content {
        display: flex;
        flex-direction: column;
        // padding-top: 1.8rem;
        width: 100%;

        .mtr-i-row {
            display: flex;
            flex-direction: row;
            width: 100%;

            .ip-input {
                width: 30%;
                margin-right: 2rem;

            }
        }
    }

    .mtr-i-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2rem;
        padding-bottom: 1rem;
        padding-right: 3rem;
        align-items: center;
        .mtr-i-span{
            
            .success{
                font-size:0.95vw;
                color:green;
            }
        }

        .mtr-ia-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .btn-cancel {
                margin-right: 1rem;
            }
        }
    }
}

.mtr-rd-container{
    padding: 1rem;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    display: flex;
    flex-direction: column;
    .mtr-rd-header{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:1rem;
        align-items: center;
        .mtr-rd-hl,
        .mtr-rd-hr{
            display: flex;
            align-items: center;
            .cmfs-container,
            .total-count{
                margin-right:1rem;
            }
            .total-count{
                color: $primaryColor;
            }

        }
    }
    .mtr-rd-table{}
}