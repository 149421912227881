@import '../../../../variables.scss';

.delete-batch-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .delete-batch-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .delete-batch-content {
        padding: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        text-align: center;

        span {
            color: $primaryColor;
            font-size: 1.184375vw;
            font-weight: 700;
            text-transform: none;
        }

        .error-holder {

            .error-title {
                color: $statusInActiveColor;
                font-size: 1.2843vw;
                margin-bottom: 0.5rem;
            }

            span {
                color: $statusWarningColor;
                font-weight: 500 !important;
                font-size: 1vw;
                text-transform: capitalize;
            }
        }

    }



    .delete-batch-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;

        .delete-batch-actions-row {
            display: flex;
            flex-direction: row;
        }
    }
}