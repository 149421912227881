@import '../../variables.scss';


.table-empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    padding: 1em;

    .empty-msg {
        margin-bottom: 1em;

        span {
            font-size: 1.25vw;
            color: $tableBorderColor;
        }
    }

    .refresh-action {
        width: fit-content;
    }
}

.table-error-handler-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    min-height: 250px;

    .error-msg {
        margin-bottom: 1em;

        span {
            font-size: 1.25vw;
            color: $tableBorderColor;
        }
    }

    .error-action {
        width: fit-content;
    }
}

.table-empty-add-icon{
    width: 1.25vw;
    height: 1.25vw;
    // font-size: 1.25vw;
    color: $white;
}

.table-loading{
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}