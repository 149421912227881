@import '../../../variables.scss';

.ac-sc-jobs-dropdown {
  display: flex;
  flex-direction: row !important;

  div:nth-child(1) {
    margin-right: 10px;
  }

  #testing, #testing2 {
    width: 250px !important;
  }

}

.ac-sc-jobs-dialog-container {
  width: 500px;
  height: 360px;
  color: $defaultHighLightColor;

  .dialog-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    img {
      color: black;
      cursor: pointer;
    }

  }

  .ac-sc-jobs-dialog-content {
    margin: 15px;
    padding-bottom: 40px;
    border-bottom: 1px solid $tableBorderColor;

    p {
      margin-bottom: 5px;
    }

    input {
      width: -webkit-fill-available;
      margin-bottom: 10px;
    }

    .message {
      margin: 0;
      margin-top: 5px;
      font-size: 0.625vw;
    }

  }

  .dialog-footer {

    padding-right: 15px;
    float: right;

  }

}

.ac-sc-jobs-dropdown-titles {
  display: flex;

  p:first-child {
    margin-right: 148px;
  }

}