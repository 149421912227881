@import '../../../../variables.scss';


.add-wcc-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding:1rem;
    .add-wcc-actions{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top:1rem;
        margin-top:1rem;
        border-top: 1px solid hsla(0,0%,65%,.4);
        .btn-cancel{
            margin-right:1rem;
        }
    }
    .add-wcc-content{padding:1rem 0rem;
        .search-wcc-holder{
            .srch-wcc-table-search-bar, .search-table-holder{
                width: -webkit-fill-available;
            }
            .search-table-holder{
                display: flex;
                flex-direction: column;
                height: 240px;
                overflow: auto;
                margin-top:1rem;
            }
        }
    }
}