@import '../../../../../variables.scss';

.profile-section-container {
    padding: 0.5em 0.5em 0.38em 0.5em;
    
    .obd-status-options {
        width: -webkit-fill-available;
        flex: 3;
        display: flex;
        flex-direction: row;
        padding: 1em 0;
        .option-column {
          display: flex;
          flex-direction: column;
          margin-left: 1em;
          .doc-card-container{
            .doc-name {
              span:nth-child(2) {
                color: #00a4dc;
              }
            }
          }
        }
      }
}