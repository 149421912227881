@import '../../../../variables.scss';

.add-team-member-container{
    width: 100%;
    .add-role-container{
        margin-left: 0.7rem;
        padding: 0.5em 0 0 0 !important;
    }
    .add-role-content{
        width: -webkit-fill-available;
        margin: 0 0 0 2rem;
        padding: 0 1rem 1rem;
    }
    .add-role-action-holder{
        border-top:1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        .add-role-action {
            padding: 1em 1em 0 1em;
        }
    }
}