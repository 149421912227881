@import '../../../../variables.scss';

/// [sales calls]

.service-calls-list-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    padding-bottom: 1em;
    overflow: auto;
    padding: 0.5rem 0.2rem 1rem 0.2rem;;

    .accordion-container {
        margin-bottom: 1em !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;

        .accordion-details {
            padding: 0 1em 1em 1em !important;
        }

        .accordion-title {
            span {
                font-size: 0.781vw !important;
                color: #79649E !important;
                font-weight: 500 !important;
            }
        }

        .MuiAccordionDetails-root {
            display: block;
        }

        .MuiIconButton-label {
            svg path {
                fill: #79649E !important;
            }
        }

        .holder {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;
            width: -webkit-fill-available;

            .info {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-start;
                justify-content: space-between;

                .date-info {
                    display: flex;
                    flex-direction: column;
                    padding-right: 2em;

                }

                .user-info {
                    display: flex;
                    flex-direction: column;
                    padding-left: 1em;

                    .talent-info {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: nowrap;

                        .talent-icon {
                            width: 0.625vw;
                            height: 1.093vw;
                            margin-right: 0.5em;
                        }

                        .talent-name {
                            span {
                                font-size: 0.572vw;
                            }
                        }
                    }
                }
            }

            .actions {

                .action-contact-icon {
                    width: 0.937vw;
                    height: 0.937vw;
                }
            }
        }
    }

    .accordion-container.Mui-expanded {
        margin: 0 0 1rem 0 !important;

    }

}

.service-card-holder {
    padding-left: 1em;
    padding-right: 1em;
}

.expnd-icon {
    color: $defaultHighLightColor;
    font-size: 0.9375vw;
}