@import '../../variables.scss';

.sortable-table {
    width: -webkit-fill-available;

    thead {
        background-color: $tableHeaderBackgroundColor;
        position: sticky;
        top:0;
        z-index:1000;
        tr {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .tb-header-cc {
                display: flex;
                flex: 1;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding: 1em;
                min-width: 40px;
                transition: 0.3s all ease-in-out;
                cursor: pointer;
                span{
                    text-transform: none;
                }
               
                &:hover {
                    background-color: rgba($color: $defaultHighLightColor, $alpha: 0.05);
                    .table-header-sort-icon {
                        display: block;
                    }
                }

                span {
                    text-overflow: ellipsis;
                    color: $primaryColor;
                    text-decoration: unset;
                }

                .table-header-sort-icon{
                    display: none;
                    font-size: 0.729vw;
                    transition: 0.3s all ease-in-out;
                    transform: rotate(0deg);
                    // color: $primaryColor;
                }

                .table-header-sort-icon-rotate {
                    transform: rotate(-180deg);
                    display: block;
                    transition: 0.3s all ease-in-out;
                }
                
            }
        }
       
    }
    tbody {
        tr {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            border-top: 1px solid rgba($color: $tableRowBorderColor, $alpha: 0.3);
            // transition: 0.3s all ease-in-out;
            // cursor: pointer;

            // &:hover {
            //     background-color: rgba($color: $defaultHighLightColor, $alpha: 0.05);
            // }
            
            td {
                display: flex;
                flex: 1;
                padding: 1em;
                text-decoration: unset;
                color: $tableRowTextColor;
                justify-content: flex-start;
                flex-direction: row;
                flex-wrap: nowrap;
                min-width: 40px;
               
                
                

                span {
                   text-align: left;
                   width: fit-content;
                   text-overflow: ellipsis; 
                   text-transform: none;
                }
            }
        }
    }

}


.table-data-container{
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        text-decoration: none;
        color: #00A4DC;
        cursor: pointer;
        font-weight: normal;
        transition: all 0.3s ease-in-out;


        &:hover {
            text-decoration: underline;
            font-weight: bold;
        }
    }

    .table-data-btn{
        display: flex;
        flex-direction: row;
        // padding: 0.3em 1em;
        // border: 0.5px solid #008FD1;
        // box-shadow: 0px 1px 2px #EAEAEA;
        // border-radius: 5px;
        color: #00A4DC;
    }
}
