@import '../../../../../variables.scss';


.cps-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);

    .cps-ud-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-bottom: 1em;
    }

    .cps-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .cps-ud-add-user-btn {
            width: fit-content;
        }

        .cps-ud-add-icon {
            color: $white;
        }
    }
}

.table-loading {
    width: 100%;
    height: 200px;
}

.dshb-cmp-eng-ud-table-header-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .cps-ud-table-search-bar {
        margin-right: 1em;
    }

    .select-user {
        margin-right: 1em;

        .user-form-control {
            min-width: 190px;
            width: fit-content;
        }
    }

    .deparment-toggle {
        margin-right: 1em;
    }

    .total-count {
        span {
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.86;
            letter-spacing: normal;
            color: $defaultHighLightColor;

            .total-count-number {
                font-weight: bold;
            }
        }
    }
}

.website-icon {
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
    text-decoration: none;

}

.low-level {
    background-color: #ED4857;
}

.medium-level {
    background-color: #EEAC17;
}

.high-level {
    background-color: #53C53F;
}

.cmp-eng-rating-low {
    display: inline-block;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: #ED4857;
    border-radius: 50px;
    text-align: center;
    padding-top:2px;
}

.cmp-eng-rating-high {
    display: inline-block;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: #53C53F;
    border-radius: 50px;
    text-align: center;
    padding-top:2px;
}

.cmp-eng-rating-medium {
    display: inline-block;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: #EEAC17;
    text-align: center;
    border-radius: 50px;
    padding-top: 2px;
}

.cmp-eng-rating-text {
    color: $white;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    span{
        font-size: 40px;
    }
}
.tlnt-ud-table-header-options{
    .total-count{
        margin-left: 16px;
    }
}