@import '../../../../variables.scss';


.dsh-eng-card-holder {
    display: flex;
    flex-direction: column;

    .eng-header-holder {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .title-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 1em;

            .title {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-start;

                .title-text {
                    display: flex;
                    flex-direction: column;

                    .title {
                        span {
                            font-size: 1.14vw;
                            color: $defaultHighLightColor;
                            font-weight: bold;
                        }
                    }


                    .active-assignments-holder {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        margin-top: 0.5em;

                        .assignment-number {
                            margin-right: 1em;
                        }

                        .assignment-text {
                            span {
                                color: #53C53F;
                                // font-size: 0.885vw;
                                font-weight: 800;
                            }
                        }
                    }
                }

                .icon {
                    width: 1.562vw;
                    height: 1.562vw;
                    margin-right: 1em;
                }
            }
        }


    }

    .dsh-eng-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        padding: 1em;
        // padding: 2em;

        .traffic-light {
            width: 5.937vw;
            height: 8.281vw;
        }

        .level {
            // padding: 1em 2em;
            padding: 0.5em;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            height: fit-content;
            min-width: 5.208vw;

            .trf-ins-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .trf-ins-title {
                    span {
                        font-size: 0.937vw;
                        color: $white;
                        font-weight: bold;
                    }
                }

                .trf-ins-interactions {
                    span {
                        font-size: 0.677vw;
                        color: $white;
                        font-weight: bold;
                    }
                }

                .trf-ins-result-value {
                    span {
                        font-size: 1.822vw;
                        color: $white;
                        font-weight: bold;
                    }
                }
            }
        }

        .low-level {
            background-color: #ED4857;
        }

        .medium-level {
            background-color: #EEAC17;
        }

        .high-level {
            background-color: #53C53F;
        }
    }
}