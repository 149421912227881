@import "../../../../variables.scss";

.ic-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .ic-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
    }

    .ic-content {
        padding: 2em 0 2em 3em;
        display: flex;
        flex-direction: column;
        position: relative;

        .ic-c-h {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 1rem;

            .total-count {
                margin-right: 1rem;
                color: $primaryColor;
            }
        }

        .step1 {
            .ic-t-table {
                max-height: 20vw;
                border: 1px solid $tableBorderColor;
                overflow: auto;

                .form-input-holder {
                    .form-input-tag {
                        min-width: 1.5vw !important;
                    }

                    .form-input {
                        min-width: 3vw !important;
                    }
                }

                .sortable-table {
                    tbody tr.tr-odd {
                        background-color: #eeeeee;
                    }
                }
            }

        }

        .step2 {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .switch-holder-col-top {
                margin-right: 2rem;

                .switch {
                    .slider:after {
                        content: "NO" !important;
                    }

                    input:checked+.slider:after {
                        content: "YES" !important;
                    }
                }
            }

        }


    }

    .ic-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;
        align-items: center;

        .er-span {
            .error-section {
                margin-bottom: 0 !important;
            }
        }

        .ic-actions-row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;

            .btn-cancel {
                margin-right: 1em;
            }
        }

    }
}