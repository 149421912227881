@import "../../../../variables.scss";

.applications-sent-ud {
  padding: 1rem;

  .apps-ud-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .apps-ud-search {
      margin-left: 1rem;
      display: flex;
      align-self: center;

      .sb-container {
        width: 15vw !important;
      }
    }

    .total-count {
      span {
        color: $defaultHighLightColor;
      }
    }
  }
}