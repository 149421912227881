@import '../../../variables.scss';

.dir-upl-status-container {
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    width: -webkit-fill-available;
    max-height: 70vh;
    overflow: auto;

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em;

        .header-title {
            span {
                font-size: 0.937vw;
                color: $primaryColor;
                font-weight: 800;
                margin-bottom: 0.2em;
            }

        }

        .right-side-header {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .total-count {
                margin-bottom: 0 !important;

                span {
                    font-weight: 300;
                    color: $defaultHighLightColor;
                    margin-right: 1em;

                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .status-table {
        .dwn-btn {
            margin-right: 1em;

            .custom-button {
                padding: 0.2em;
                width: 1.25vw;
                height: 1.25vw;
            }

            .btn-text {
                span {
                    margin: 0 !important;
                }
            }
        }
    }
}

.hover-underline:hover {
    text-decoration: underline;
}