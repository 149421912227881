@import '../../variables.scss';

/// [Large Input]
.drad-input-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    .drad-label-holder {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;

        .label-txt {
            font-size: 0.729vw; //Helvatica
            margin-bottom: 5px;
            margin-top: 10px;
            font-weight: 900;
            color: $defaultHighLightColor;
        }
    }

    .drad-input-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: -webkit-fill-available;
        border: 1px solid rgba($color: #A8A8A8, $alpha: 0.4);
        padding: 1em;
        transition: all 0.3s ease-in-out;

        .file-icon-info {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            align-items: center;

            .file-icon {
                width: 1.6vw;
                height: 1.6vw;
                margin-right: 1em;
            }

            .drag-info-txt {
                span {
                    font-size: 0.8vw;
                    font-weight: normal;
                    color: $defaultHighLightColor;
                    text-transform: none;
                }
            }
        }
    }

    .drg-active {
        border: 1px solid $defaultHighLightColor !important;
    }
}

.file-preview-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0.5em;

    .file-type-icon {
        width: 3.663003663003663vw;
        height: 3.663003663003663vw;
        margin-bottom: 1em;
        position: relative;

        .close-action {
            width: 1.25vw;
            height: 1.25vw;
            cursor: pointer;
            position: absolute;
            top: -20%;
            right: -20%;
            // top: 1em;
            // right: 1em;
        }
    }

    .file-name {
        span {
            color: $defaultHighLightColor;
        }
    }
}

/// [small Input]
.drad-input-container-small {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    .drad-label-holder {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;

        .label-txt {
            font-size: 0.729vw; //Helvatica
            margin-bottom: 5px;
            margin-top: 10px;
            font-weight: 900;
            color: $defaultHighLightColor;
        }
    }

    .drad-input-holder {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        align-items: center;
        width: -webkit-fill-available;
        border: 2px dotted $defaultHighLightColor;
        padding: 0.5em 1em;
        transition: all 0.3s ease-in-out;

        .file-icon-info {
            display: flex;
            flex-direction: row;
            width: 100%;
            // justify-content: center;
            align-items: center;

            .file-icon {
                width: 0.9895833333333334vw;
                height: 0.9375vw;
                margin-right: 1em;
            }

            .drag-info-txt {
                display: flex;
                width: calc(100% - 45px);

                span {
                    font-weight: normal;
                    color: $defaultHighLightColor;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        // &:hover {
        //     border: 2px dotted $statusActiveColor  !important;
        // }
    }



    .drg-active {
        border: 2px dotted $defaultHighLightColor !important;
    }
}

.file-preview-holder-small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0.25em 0.5em;
    width: -webkit-fill-available;

    .file-type-icon {
        display: flex;
        width: 1.663003663003663vw;
        height: 1.663003663003663vw;
        position: relative;

        .close-action {
            width: 0.85vw;
            height: 0.85vw;
            cursor: pointer;
            position: absolute;
            top: -20%;
            right: -20%;
            // top: 1em;
            // right: 1em;
        }
    }

    .file-name {
        display: flex;
        margin-left: 1em;
        width: calc(100% - 45px);

        span {
            color: $defaultHighLightColor;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.row-list-files {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.multiple-files-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    justify-content: flex-start;
    max-height: 30vh;
    overflow: auto;

    .file-preview-holder {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width:48%;
        align-items: center;
        .file-type-icon {
            width: 1.7vw;
            height: 1.7vw;
            margin-bottom: 0;
            .close-action{
                width:0.9vw;
                height: 0.9vw;
                top:-35%;
                right:-25%;
            }
        }
        .file-name{
            width:85%;
            margin-left:1em;
        }
    }
}