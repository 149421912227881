@import '../../../../variables.scss';


.add-new-po-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 2em;
    overflow: hidden;

    .add-new-po-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
        .title-name span{
            text-transform: none;
        }
    }

    .add-new-po-content {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        margin-top:0.5rem;
        .saved-doc-holder{
            padding:2rem;
        }
        .add-new-po-input-holder-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0.5em;
            width: 100%;
            span {
                text-transform: none;
            }

            .add-new-po-input-holder {
                width: 100%;
                margin: 0 1rem 0.5rem 0rem;
            }
        }

        // .toggle-row {
        //     margin-top:0.5rem !important;
        // }
    }

    .add-new-po-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-top:1rem;
        .btn-cancel {
            margin-right: 1em;
        }
    }
}