@import '../../../variables.scss';

.ac-sc-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;

  .ac-sc-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 20px 0 20px;
    width: -webkit-fill-available;
    position: relative;
    border: 0 !important;

    .ac-sc-header-close {
      display: flex;
      align-items: center;
    }

    p {
      color: $defaultActionColor;
      font-size: 0.781vw;
      cursor: pointer;
      margin: 0;
    }

  }

  .ac-sc-dropdown {
    margin: 0 20px 20px 20px;

    p {
      color: $defaultHighLightColor;
      font-size: 0.729vw;
      margin-bottom: 5px;
    }
  }

  .ac-sc-dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: -webkit-fill-available;

    #testing {
      width: 350px;
    }

  }


  .ac-sc-content {
    padding: 2em;
    margin: 2em;
    position: relative;
    color: $defaultHighLightColor;
    border: 1px solid $tableBorderColor;

    .ac-sc-sp-content-pe {
      margin-bottom: 20px;

      h6 {
        margin-bottom: 15px;
      }

      #testing {
        width: 200px;
      }

    }
    .save-button{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .message {
        font-size: 0.929vw;
        color: green;
        margin-left: 1em;
      }
    }

  }

}

