@import "../../../../../../variables.scss";

.ib-lead-info-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    padding: 1.5rem 1rem;

    .business-goals-div {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #0350901a;
        padding-bottom: 1em;
        .bg-h-title {
            color: #035090;
            font-size: .833vw;
            text-transform: uppercase;
        }

        .bg-content {
            .bg-ul {
                list-style: none;
                padding-left: 2rem !important;

                li {
                    color: $primaryColor;
                    font-size: .833vw;
                    font-weight: 700;
                    margin-top: 2px;
                    position: relative;
                    cursor: auto;
                }

                li:before {
                    content: "";
                    position: absolute;
                    left: -15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0;
                    height: 0;
                    border-color: #0000 #0000 #0000 #074783;
                    border-style: solid;
                    border-width: .4em 0 .4em .6em;
                }
            }
        }
    }
}