@import "../../../../../variables.scss";


.payroll-dedcution-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .key-table {
        min-height: 15vw;
        height: 15vw;
        overflow: auto
    }

    .table-container {
        border-radius: 0.2em;
        border: solid 1px #c0c2c4;
        margin-top: 2em;

        .header-container {
            margin-bottom: 1em;
            margin-top: 1em;
            display: flex;
            justify-content: space-between;
            color: "#0B3E78";
            padding: 0 1em 0 1em;

            .table-header {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-end;

                .table-header-title {
                    span {
                        font-size: 0.937vw;
                        color: $primaryColor;
                        font-weight: 800;
                        margin-bottom: 0.2em;
                    }

                }
            }

            .total-count-action {
                margin-left: 1.5em;
                margin-right: 1em;

                span {
                    width: max-content;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.2;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;

                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .payroll-journal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .main-header {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .dwn-btn {
                margin-left: 1em;
            }
            
            .error-holder {
                width: 15vw;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-left: 1em;
                margin-bottom: 0;
              }
        }
    }

    .total-counts-card {
        .total-count-main {
            width: 12.85%;
        }
    }

    .total-counts-second-card {
        background: #f7fbfd 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        border-left: 4px solid $defaultHighLightColor;
        border-radius: 4px;
        opacity: 1;
        width: 20%;
        margin-top: 2em;
        padding: 1em;

        .total-title {
            color: #074783;
            font-size: 0.792vw;
            margin: 0 0.5em 1em 0.5em;
            font-weight: 800;
        }

        .total-count {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            color: #074783;
            font-size: 0.729vw;
            line-height: 2;
            margin: 0 2em 0em 0.5em;
            font-weight: 600;

            span {
                text-transform: none;
            }
        }
    }
}