@import '../../../../../variables.scss';

.deduction-authority-dashboard-container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .deduction-authority-dashboard-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .right-col{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .ml{
                margin-left: 1rem;
            }

        }
    }
    .deduction-authority-dashboard-content {
        margin-top:1rem;
        height: 59vh;
        position:  relative;
        .deduction-authority-dashboard-table{

        }
    }
}