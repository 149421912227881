.ep-pd-container {
    margin: 20px;

    .ep-db-pd-table-header {
        display: flex;
        justify-content: flex-end;
        padding: 1em;

    }

    .ep-ud-table-header-options {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
}