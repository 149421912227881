@import '../../../../variables.scss';

.pr-tlnt-container {
    display: flex;
    flex-direction: column;
    // margin-top: 1rem;
  
    .pr-tlnt-section {
        border: 1px solid $tableBorderColor;
        margin-bottom: 1.5rem;
        // padding: 0.5rem 0.5rem 1rem 0.5rem;  

        .title-header {
            color: $primaryColor;
            font-size: 0.937vw;
            font-weight: 700;
            padding: 0.5rem;
        }

        .pr-tlnt-header {
            padding: 0.5rem 0;
            background-color: $reportCenterOptionBackgroundColor;

            // border-bottom: 1px solid $tableBorderColor;
            .table-div {
                width: 100%;

                tbody td,
                thead th {
                    padding: 0.3rem 2rem 0.3rem 1rem;
                    color: $primaryColor;
                    width: 20%;
                }

                thead th {
                    text-transform: uppercase;
                    font-weight: 500;

                }

                tbody td {
                    font-weight: 700;
                    font-size: 0.9vw;;
                }
            }

        }

        .pr-tlnt-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0.2rem;
            padding: 0.5rem 0.8rem 1rem 0.8rem;

            .title-span {
                color: $primaryColor;
                font-size: 0.9vw;
                font-weight: 700;
                margin-bottom: 0.5rem;
            }

            .col-div {
                width: 32%;

                // .col-div-table {
                //     height: 12vw;
                //     max-height: 12vw;
                //     min-height: 12vw;
                //     overflow: auto;
                //     position: relative;

                //     table {
                //         height: 100%;

                //         td {
                //             padding: 0.5em;
                //         }
                //     }


                //     .total-row {
                //         margin-top: 1rem;
                //         border-left: 4px solid $primaryColor;
                //         background-color: $tableHeaderBackgroundColor;
                //         position: sticky;
                //         bottom: 0;
                //         z-index: 1;

                //         span {
                //             font-weight: 700;
                //             font-size: 0.9vw;
                //             color: $primaryColor;
                //         }
                //     }
                // }
                .col-div-table {
                    height: 18vw;
                    max-height: 18vw;
                    min-height: 15vw;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    position: relative;
                }

                .table-container {
                    overflow: auto;
                    flex: 1 1 auto;
                }

                .table-content {
                    display: flex;
                    flex-direction: column;
                }

                .table-content tr {
                    flex: 1;
                }

                .total-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-left: 4px solid $primaryColor;
                    background-color: $tableHeaderBackgroundColor;
                    position: sticky;
                    bottom: 0;
                    z-index: 1;
                    flex-shrink: 0;
                    /* Prevent the row from shrinking */
                    padding: 0.3rem 0.5rem;
                    margin-top: 0.5rem;

                    span {
                        font-weight: 700;
                        font-size: 0.9vw;
                        color: $primaryColor;
                    }
                }

                .na-row .na_div {
                    text-align: center;
                }



            }
        }
    }
}