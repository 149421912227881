@import '../../../../variables.scss';

.ac-bo-dialog-header {
    padding: 1em 1em;

    .dialog-header {
        span {
            font-size: 1.04vw;
            font-weight: bold;
            color: $defaultHighLightColor;
        }
    }

    .dialog-content {
        padding-top: 0.5rem;
        padding-left: 2rem;

        span {
            text-transform: none;
        }
        .toggle-switch {
            margin-top: 1em;
            .switch {
                .slider:after {
                    content: 'NO';
                }
                input:checked+.slider:after {
                    content: 'YES';
                }
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: space-between;
        padding-right: 0px !important;
        padding-bottom: 0px !important;

        .btns {
            display: flex;
            justify-content: flex-start;
        }

        .btn-cancel {
            margin-right: 1em;
        }
    }
}

.delete-action-container {
    .dialog-footer {
        display: flex;
        justify-content: flex-end;
        .yes-btn {
            margin-right: 1em;
        }
    }

    .dialog-content {
        padding-top: 1rem;

        span {
            font-size: 0.92vw;
        }
    }
}