@import '../../../variables.scss';

.oah-holder-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    height: -webkit-fill-available;

    background-image: url('../../../../assets/business-man-background.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: -40%;
    position: relative;


    .oah-banner-view {
        display: flex;
        flex-direction: column;
        height: -webkit-fill-available;
        background-color: transparent;
        flex: 2;
        position: relative;
    }

    .oah-content-view {
        display: flex;
        flex-direction: column;
        height: -webkit-fill-available;
        flex: 4;
        background-color: #fff;
        padding: 3em 4em;
        position: relative;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: -webkit-fill-available;

            .page-info {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;

                .icon-holder {
                    width: 1.9791666666666667vw;
                    height: 2.5vw;
                    margin-right: 2em;
                }

                .title-holder {
                    display: flex;
                    flex-direction: column;

                    .title{
                        span{
                            font-size: 1.4583333333333333vw;
                            font-weight: bold;
                            color: $defaultHighLightColor;
                        }
                    }
                    .sub-title{
                        span{
                            font-size: 0.9375vw;
                            font-weight: normal;
                            color: $defaultHighLightColor;
                            text-transform: unset;
                        }
                    }

                }
            }

            .agency-branding {
                width: fit-content;
            }
        }

        .content{
            display: flex;
            flex-direction: column;
            width: -webkit-fill-available;
            height: -webkit-fill-available;
            position: relative;
            padding: 3em 0 0 4em;
        }

    }
}