@import '../../variables.scss';

.tab-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;

    .tab-options-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .tab-option{
            margin: 4px;
            padding-bottom: 4px;
            border-bottom: 2px solid $defaultHighLightColor;

            span {
                font-size: 0.729vw;
                font-family: $defaultFontFamily;
                font-weight: 800;
                color: $defaultHighLightColor;
            }
        }
    }

    .tab-close-action{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        text-decoration: unset;
        cursor: pointer;
        padding: 4px 8px;
        transition: 0.3s all ease-in-out;

        &:hover {
            background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1);
        }

        .tab-close-icon{
            margin-right: 0.5em;
            color: $defaultActionColor;
        }

        span {
            font-size: 0.729vw;
            font-family: $defaultFontFamily;
            color: $defaultActionColor;
        }
        
    }
}

