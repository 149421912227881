.pb-container {
  margin-top: 15px;
  box-shadow: 0px 0px 2px 0px #c3cfd6;
  padding: 1em;

  .pb-header {
    color: #0b3e79;
    font-size: 0.833vw;
  }

  .row2 {
    display: flex !important;
    justify-content: space-between;

    .cmfs-margin-top {
      margin-left: 4.4rem;
    }
  }

  .pb-content {
    display: flex;
    flex-wrap: wrap;

    .pb-item-container {
      margin-top: 10px;

      .pb-item-header {
        color: #0b3e79;
        font-size: 0.729vw;
        font-weight: 800;
      }

      .pb-item {
        display: flex;
        justify-content: space-between;
        span {
          font-size: 0.479vw !important;
          text-transform: uppercase !important;
        }
        .form-input-holder {
          width: 5vw;

          .form-input-tag {
            min-width: 3vw !important;
          }
        }
      }

      .form-input {
        // width: 120px;
        margin-right: 10px;
      }
    }
  }

  .section-header {
    font-weight: 900;
    color: rgb(11, 62, 121);
    font-family: Lato;
  }
}

@media only screen and (max-width: 1440px) {
  .pb-container {
    .row2 {
      .cmfs-margin-top {
        margin-left: 3.7rem !important;
      }
    }
  }
}