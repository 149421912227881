@import '../../variables.scss';


.scrd-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    border-radius: 2.8px;
    background: $white;
    border: 0.1px solid #C1CDD440;
    border-radius: 5px;
    // box-shadow: 0px 1px 2px 0px rgba($color: $pageBoderColor, $alpha: 1.0);

    .scrd-header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 1em;
        background: $adminCenterOptionBackgroundColor;
        // box-shadow: 0px 0.5px 1px 0px rgba($color: $pageHeaderBoderColor, $alpha: 1.0);
        // border: 0.1px solid $pageHeaderBoderColor;
        .header-left{
            .scrd-icon {
                width: 1.25vw;
                height: 1.25vw;
                margin-right: 1em;
            }
    
            .scrd-path-txt-0{
                font-size: 0.937vw;
                font-family: $defaultFontFamily;
                font-weight: 900;
            }
    
            
    
            .scrd-path-name{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                text-transform: capitalize;
               
    
                .scrd-active{
                    cursor: pointer;
                    padding: 4px 8px;
                    transition: 0.3s all ease-in-out;
                    border-radius: 5px;
                    text-decoration: none;
    
                    &:hover {
                        background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1);
                    }
                }
               
                .scrd-arrow-icon{
                    margin-left: 1em;
                    margin-right: 0.5em;
                }
                .scrd-path-txt{
                    font-size: 0.937vw;
                    font-family: $defaultFontFamily;
                    text-transform: capitalize;
                    font-weight: 900;
                }
            }
        }
    }

    .scrd-content {
        display: flex;
        height: -webkit-fill-available;
        background-color: $white;
    }
}