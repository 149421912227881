@import '../../../../variables.scss';

.add-team-container {
    width: 100%;
    .add-role-container {
        margin-left: 0.7rem;
        padding: 0.5em 0 0 0 !important;
    }

    .add-role-content {
        width: -webkit-fill-available;
        margin: 0 1.5rem 0 2rem;
        padding: 0 1em 1em;

        .form-input {
            margin-bottom: 1rem;
        }

        .drad-input-container .drad-input-holder {
            margin-bottom: 1rem;
            .file-preview-holder .file-type-icon {
                width: 1.663003663vw;
                height: 1.663003663vw;
            }
            .file-icon-info {
                .file-icon{
                    width: 1.3vw;
                    height: 1.3vw;
                    margin-bottom: 0.5em;
                }
                .drag-info-txt span {
                    margin-left: 1rem;
                    font-size: 1.3em;
                }

                flex-direction: row;
            }

            display: flex;
            justify-content: center;
            border: 1px dashed $secondaryColor1;
            border-radius: 0.7em;
            background-color: $backgroundColor;
        }
    }

    .add-role-action-holder {
        // margin-right: 1em;
        // margin-top: 1rem;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

        .add-role-action {
            padding: 1em 1em 0 1em;
        }
    }
}