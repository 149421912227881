@import '../../../../variables.scss';

.cnt-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);

    .circle-download-svg {
        svg {
            path {
                fill: white;
            }
        }
    }

    .cursor-p {
        cursor: pointer;
    }

    .as-table-footer {
        position: relative;
    }

    .cnt-ud-table thead {
        top: -1.6vh !important;
    }

    .cnt-ud-table {
        .table-data-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            align-self: center;
        }
        .sortable-table {
            tbody {
              display: block;
              overflow-y: auto;
              max-height: 49vh;
            }
        }
        @media screen and (max-width:1650px) {
            .sortable-table {
                tbody {
                  display: block;
                  overflow-y: auto;
                  max-height: 45vh;
                }
            }
        }

        @media screen and (max-width:1500px) {
            .sortable-table {
                tbody {
                  display: block;
                  overflow-y: auto;
                  max-height: 45vh !important;
                }
            }
        }

        @media screen and (max-width:1280px) {
            .sortable-table {
                tbody {
                  display: block;
                  overflow-y: auto;
                  max-height: 49vh !important;
                }
            }
        }
    }

    .cnt-ud-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0.5em;
    }

    .cnt-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        
        .upl-files{
            margin-right: 0.5em;
            svg {
                .cls-1 {
                    fill: #ffffff !important;
                }
            }
        }
        
        .cnt-ud-add-user-btn {
            width: fit-content;
        }

        .cnt-ud-add-icon {
            color: $white;
        }
    }




}

.table-loading {
    width: 100%;
    height: 200px;
}

.cnt-ud-table-header-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;

    .account-manager-selector,
    .company-status-selector {
        margin-right: 1em;
        width: 8.873983vw;
    }

    .css-t7ry41-control {
        max-width: 9vw;
    }

    .css-c6bn20-control {
        max-width: 9vw;
    }

    .cnt-ud-table-search-bar {
        margin-right: 1em;
    }

    .select-user {
        margin-right: 1em;

        .user-form-control {
            min-width: 190px;
            width: fit-content;
        }
    }

    .deparment-toggle {
        margin-right: 1em;
    }

    .total-count {
        span {
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            // line-height: 2.2;
            letter-spacing: normal;
            color: $defaultHighLightColor;

            .total-count-number {
                font-weight: bold;
            }
        }
    }
}

.website-icon {
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
    text-decoration: none;

}

.open-orders-empty {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .ord-icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 1em;
    }

    .ord-text-empty {
        span {
            color: #b1b1b1;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }

    .ord-text {
        span {
            color: $openOrdersTextColor;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }
}

@media screen and (max-width:1650px) {
    .cnt-ud-table {
        .sortable-table {
            .tbody {
                height: 50vh !important;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:1500px) {
    .cnt-ud-table {
        .sortable-table {
            .tbody {
                height: 45vh !important;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .cnt-ud-table {
        .sortable-table {
            .tbody {
                height: 50vh !important;
                width: 100%;
            }
        }
    }
}