@import '../../variables.scss';

.notification-circle-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 1.302vw;
    min-height: 1.302vw;

    border-radius: 50%;
    border: 1px solid #623F07;

    .notification-txt{

        span {
            font-size: 0.729vw;
            font-weight: bold;
        }
    }
}

.number-badge-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 1.302vw;
    min-height: 1.302vw;

    border-radius: 5px;
    // border: 1px solid $defaultHighLightColor;

    .number-badge-txt{

        span {
            font-size: 0.729vw;
            font-weight: bold;
        }
    }
}