@import "../../../../../variables.scss";

.pr-c-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 2em;

    .header-container {
        display: flex;
        justify-content: space-between;

        .table-header {
            display: flex;
            align-items: center;
            padding: 1em 0 0.2em 0;

            span {
                font-size: 0.937vw;
                color: $primaryColor;
                font-weight: 800;
                margin-bottom: 0.2em;
            }
        }
    }

    .pr-c-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .pr-c-header-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .date-type {
                margin-left: 1em;
                margin-right: 1em;
            }

            .error-holder {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                margin-left: 1em;
                margin-bottom: 0;
            }
        }
    }

    .pr-c-content {
        .pr-c-div-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .pr-c-div {
                width: 30.5%;
                background-color: $tableHeaderBackgroundColor;
                padding: 0.5rem 1.2rem;
                border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                border-left: 4.5px solid $primaryColor;

                .pr-c-div-title {
                    font-size: 1vw;
                    color: $primaryColor;
                    font-weight: 700;
                }

                table {
                    width: 100%;
                    margin: 1rem 0 1.5rem 0;
                    border-collapse: collapse;

                    thead th:last-child,
                    tbody td:last-child {
                        text-align: right;
                        padding-right: 0.8rem;
                    }

                    th {
                        text-transform: uppercase;
                        color: $primaryColor;
                        font-weight: 500;
                        padding-bottom: 0.4rem;
                    }

                    td {
                        color: $primaryColor;
                        padding: 0.2rem 0;
                        font-weight: 700;
                    }

                    .total-row {
                        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                        border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                        margin-top: 1rem;

                        td {
                            padding: 0.4rem 0;
                            font-size: 0.78vw;
                            text-transform: uppercase;
                            font-weight: 750;
                        }

                    }

                    tr:nth-last-child(2) {
                        td {
                            padding-bottom: 0.6rem;
                        }

                    }
                }


            }
        }
    }
}