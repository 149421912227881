@import '../../../../../variables.scss';

.cps-ud-table-header{
    .tlnt-ud-brdcast-btn {
        button {
          background-color: #ff4d00;
          box-shadow: none;
          margin-right:1.5em;
          svg{
            g path:first-child{
              fill:white !important;
            }
            g path:nth-child(2){
              fill:#ff4d00 !important;
            }
            g path:nth-child(3){
              fill:#ff4d00 !important;
            }
          }
        }
      }
      .select_talent_msg{
          text-transform: initial;
          color:#ff4d00;
          margin-top:0.5em;
      }
}
