@import '../../../../../../variables.scss';

.my-profile-info-container {
    .contact-table{
        width: 100%;
        border: 0px;
        td{
            border:none;
            color:$defaultHighLightColor;
            width:20%;
            vertical-align: top;
            padding:0.2em 0;
        }
        td:nth-child(2){
            font-weight: 900;
            width:80%;

        }
        .email-link{
            span{
                text-transform: none;
                font-weight: 900;
            }
        }
    }
    .password-container {

        display: flex;
        justify-content: flex-start;

        .my-profile-icon-parent {
            position: relative;

            .my-profile-icon-child {
                width: 0.833vw;
                height: 0.833vw;
                position: absolute;
                bottom: 0.6rem;
                right: 1rem;
            }
        }

        .my-profile-edit-icon {
            margin-left: 1rem;
            margin-top: 0.5em;
        }
    }

    .my-profile-info-value {
        font-weight: 800;
        color: $userProfileNameColor;
        font-family: $defaultFontFamily;
    }

    .mp-user-last-activity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .last-engagement-txt {
            margin-bottom: 0.25em;
            span {
                display: flex;
                justify-content: center;
                font-weight: 800;
                font-size: 0.677vw;
                color: $tableRowTextColor;
            }
            .mp-last-login-txt{
                font-weight: 800;
                font-size: 0.677vw;
                color: $tableRowTextColor; 
            }
        }
    }
}