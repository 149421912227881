@import '../../../variables.scss';

.send-email-container {
    padding: 1em;
    width: -webkit-fill-available;

    .send-email-content {
        padding: 0 0vw 2vw 2.5vw;
    }

    .send-email-actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

        .btn-cancel {
            margin-top: 1em;
            margin-right: 1em;
        }
    }
}