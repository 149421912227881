@import '../../../../../../variables.scss';

.po-main-container {
    .markup-po-content-table {
        height: 15vw;
        max-height: 15vw;
        position: relative;
        .sortable-table {
            position: sticky;
            top: 0;
        }
    }
}