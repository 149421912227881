.sm-heading {
    color: #074783;
    transition: 0.2s all;
    width: 147px;
    margin: 0px 15px;
    cursor: pointer;
    font-weight: bold;
    border-bottom: solid 3px #074783;
}

.move-top-button{
    position: fixed; 
    width: 100%;
    left: 97.5%;
    bottom: 50px;
    height: 20px;
   
   font-size: 3rem;
   z-index: 1;
   cursor: pointer;
}

.infinite-scroll-component {
    overflow: hidden !important;
}

.modal-dialog {
    max-width: 50%;
}
.modal-body .form-select {
    color: #074783;
    font-style: italic !important;
}
.modal-body label {
    color: #074783 !important;
    font-weight: 700;
}
.link-icon {
    width: 1.302vw;
    position: absolute;
    padding: 10px 10px 0px 0px;
}
.table-head th {
    font-size: 0.781vw;
    font-weight: 700;
    color: #074783;
}
.modal-title p {
    font-size: 0.729vw;
    font-weight: 500;
}
.close {
    border: none;
    border-radius: 50%;
    padding: 0px 8px 2px;
}
.tableList img {
    width: 23px;
}
.tableList td, .tableList td a {
    color: #009ad7 !important;
    font-weight: 600 !important;
}
.tab-border {
    border: none;
    border-color: none;
    background-color: white !important;
    color: #074783;
}
.card-icon-height {
    height: 1.5em;
}
.alignment {
    align-items: left;
}

.icon-height {
    height: 0.8em !important;
}
.row-height {
    height: 2px !important;
}
.tab_design {
    background-color: "#074783";
    border-top-right-radius: '21px';
}
/* .nav-tabs .nav-link.active {
    color: #074783 !important;
    background-color: #074783;
} */

/* Staffing Representative*/

.card-staffing {
    /* background-color: #f2f6fa;
    height: 2.7rem;
    border-left: 4px #074783 solid;
    margin: 10px;
    margin-top: auto; */
    background-color: #f7fbfd;
    /* min-height: 5.5rem; */
    margin: 10px;
    padding: 3px 5px;
    border-radius: 5px;
    border: solid 1px #dee8ee;
    border-left: 6px #035090 solid;
}
.cardtext-color {
    color: #074783 !important;
}

.text-color {
    color: #074783 !important;
    /* font-size: 0.781vw; */
}
.nav-link {
    color: #074783 !important;
    font-weight: 600 !important;
    cursor: pointer;
    /* font-size: 0.781vw; */
}
/*Engagement Details*/

.card-engage {
    background-color: #f7fbfd;
    min-height: 5rem;
    margin: 10px;
    border-radius: 6px;
    border: solid 1px #dee8ee;
    border-left: 6px #035090 solid;
}
.cardtext-color {
    color: #035090;
}
.font-weight-normal {
    font-size: 0.677vw;
}
.summary_tab_border .nav-item .active {
    color: white !important;
    transition: 0.5 all;
}
.nav-item {
    margin-right: 0px;
}
.nav-item a:hover {
    color: #0756a0 !important;
}
.modal-dialog {
    max-width: 70% !important;
}
.badge-items {
    display: contents;
}
.badge-type {
    background-color: #f7fbfd;
    border: solid 2px #0f4d84;
    padding: 3px 10px;
    border-radius: 20px;
    margin: 5px;
}
.badge-type .MuiSvgIcon-root {
    color: #023e84;
}
.badge-txt {
    font-size: 0.729vw;
    font-weight: 600;
    color: #0f4d84;
}

  
.nav-bar-style{
    border-top:none !important;
    border-left: none !important;
    border-right:none !important;
    text-decoration-color :#074783 !important;
    font-size:revert;
    padding:0px;
    margin-right: 30px;
    font-weight: 500 !important;
    
}

.nav-tabs .nav-link.active {
    /* font-size: small; */
    color: #074783 !important;
    font-weight: bold !important;
    border-bottom: 3px solid #074783 !important;
    padding:0px;
}

.table-bordered > :not(caption) > * > * {
    border-width: 0px;
}

.table-text-color{
 color:#0366d6
}

.cardleft-padding{
    padding-left: 30px;
}

#combo-box-demo{
    height: 1px !important; 
    width:20px !important;
}
.MuiAutocomplete-root{
    width:400px !important;
}
.searchWrapper{
    background-color:white;
    /* width:fit-content; */
}
