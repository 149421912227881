@import '../../variables.scss';


.tab-navigation-container {
    display: flex;
    flex-direction: column;
    position: relative;

    width: -webkit-fill-available;

    .header {
        display: flex;
        flex-direction: row;
        position: relative;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        width: -webkit-fill-available;
        padding-right: 2em;

        .tab-options-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 1em 2em;

            .tab-option {
                margin: 2px;
                padding-bottom: 4px;
                border-bottom: 2px solid $transparent;
                text-decoration: unset;
                cursor: pointer;
                margin-right: 3em;

                span {
                    font-size: 0.78vw;
                    font-family: $defaultFontFamily;
                    color: $defaultHighLightColor;
                }
            }

            .active {
                border-bottom: 2px solid $defaultHighLightColor;
                font-weight: 800;
            }
        }

        .tab-close-action {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            text-decoration: unset;
            cursor: pointer;
            padding: 2px 2px;
            transition: 0.3s all ease-in-out;

            &:hover {
                background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1);
            }

            .tab-close-icon {
                font-size: 0.7vw;
                color: $defaultActionColor;
            }

            span {
                font-size: 0.7vw;
                font-family: $defaultFontFamily;
                color: $defaultActionColor;
            }

        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding-left: 2em;
        padding-right: 2em;
       // padding-bottom: 2em;
        position: relative;
    }
}