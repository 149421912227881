@import "../../../../variables.scss";

.ob-cnt-inf-form-container {
  display: flex;
  flex-direction: column;
  //   padding: 0em 4em 2em 4em;
  background-color: #f2f6fa;
  .ob-cnt-inf-form-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.ob-cnt-inf-f-input-holder {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 2em;
}

.ob-cnt-link-icon {
  width: 0.833vw;
  height: 0.833vw;
}
.status-span {
  input {
    border: none !important;
    color: white !important;
    font-weight: bold;
    width: fit-content !important;
    min-width: fit-content !important;
    border-radius: 5px;
    cursor: default;
    // padding-left:0.5rem;
  }
  input:focus {
    outline: none !important;
  }
}
.reviewed {
  input {
    background-color: #00c655 !important;
  }
}
.not-reviewed {
  input {
    background-color: rgb(255, 118, 0) !important;
  }
}
