@import '../../../../variables.scss';


.jbs-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);



    .jbs-ud-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-bottom: 1em;
    }

    .jbs-ud-table {
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        overflow-y: hidden;

        thead {
            top: -1.6vh !important;
        }

    }

    .jbs-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .jbs-ud-add-user-btn {
            width: fit-content;
        }

        .jbs-ud-add-icon {
            color: $white;
        }
    }




}

.jbs-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);



    .jbs-ud-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-bottom: 1em;
    }

    .jbs-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .jbs-ud-add-user-btn {
            width: fit-content;
        }

        .jbs-ud-add-icon {
            color: $white;
        }
    }




}

.table-loading {
    width: 100%;
    height: 200px;
}

.jbs-ud-table-header-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;

    .account-manager-selector,
    .company-status-selector,
    .recruiter-selector {
        margin-right: 1em;
        width: 11.873983vw;

    }

    .jbs-ud-table-search-bar {
        margin-right: 1em;
    }

    .select-user {
        margin-right: 1em;

        .user-form-control {
            min-width: 190px;
            width: fit-content;
        }
    }

    .deparment-toggle {
        margin-right: 1em;
    }

    .total-count {
        span {
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.86;
            letter-spacing: normal;
            color: $defaultHighLightColor;

            .total-count-number {
                font-weight: bold;
            }
        }
    }
}

.website-icon {
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
    text-decoration: none;

}

.open-orders-empty {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .ord-icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 1em;
    }

    .ord-text-empty {
        span {
            color: #b1b1b1;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }

    .ord-text {
        span {
            color: $openOrdersTextColor;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }
}


/// Name
.jbs-ud-table-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    .status-icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 1em;
    }

    .name-txt {
        span {
            font-size: 0.729vw;
            font-weight: bold;
        }
    }
}

.pref-contact-icon {
    width: 1.25vw;
    height: 1.25vw;


}

.access-job-button {
    background-color: $buttonColor17;
    color: $white;
    min-width: 4.166666666666667vw;
    width: fit-content;
    height: 1.5625vw;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.20833333333333334vw 0.4166666666666667vw;
    text-align: center;
    text-overflow: ellipsis;

    span {
        font-weight: 800;
    }
}

.jbs-ud-table{
    .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 44vh;
        }
    }
    @media screen and (max-width:1650px) {
        .sortable-table {
            tbody {
              display: block;
              overflow-y: auto;
              max-height: 40vh;
            }
        }
    }

    @media screen and (max-width:1500px) {
        .sortable-table {
            tbody {
              display: block;
              overflow-y: auto;
              max-height: 45vh !important;
            }
        }
    }

    @media screen and (max-width:1280px) {
        .sortable-table {
            tbody {
              display: block;
              overflow-y: auto;
              max-height: 45vh !important;
            }
        }
    }
  }

.table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 1em;
    padding-right: 3.2rem;
    padding-bottom: 2em;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    .as-table-footer{
        width: 100%;    
        .tb-footer-common-pagination {
            position: relative;
            top: 0;
            margin-top: 0;
            width: 100%;
          }
    }
    .mlt-usrs-content {
        display: flex;
        flex-direction: column;
        margin-left: 1em;
    
        .custom-pab {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0.5em 2em 0.5em 1.5em;
            border-radius: 4px;
            width: max-content;
            margin-top: 0;
            background-color: $tableHeaderBackgroundColor;
            border-left: 4px solid $defaultHighLightColor;
            box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
            align-items:center;
            .pab-title {
                margin: 1em;
                span {
                    font-size: 0.729vw;
                    color: $defaultHighLightColor;
                    font-weight: 800;
                }
            }
    
            .pab-requested-value,
            .pab-placed-value,
            .pab-over-value {
                display: flex;
                flex-direction: column;
                margin: 1em 1.6em;
                text-align: center;
    
                span {
                    font-size: 0.729vw;
                    font-weight: 800;
                }
            }
    
            .pab-requested-value,
            .pab-over-value {
                color: $red;
            }
    
            .pab-placed-value {
                color: $openOrdersTextColor;
            }
    
        }
    }
}

.open-orders-empty {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .ord-icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 1em;
    }


}

.filled-ord-text {
    color: $statusActiveColor;
    font-stretch: normal;
    font-style: normal;
}

.closed-ord-text {
    color: #b1b1b1;
    font-stretch: normal;
    font-style: normal;
}

.open-ord-text {
    color: red;
    font-stretch: normal;
    font-style: normal;
}