@import '../../variables.scss';

.switch-holder-col-top{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1em;
    .switch-label {

        margin-bottom: 5px;
  
        span {
           font-size: 0.729vw;
           color: $defaultHighLightColor;
           font-weight: 900;
           text-transform: none;
        }
     }
}
.switch-holder-col-left{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 1em;
    .switch-label {
        margin-bottom: 0.2em;
        margin-right: 0.5em;
        span {
           font-size: 0.729vw;
           color: $defaultHighLightColor;
           font-weight: 900;
           text-transform: none;
        }
     }
}
.switch-holder {
    display: flex;
    flex-direction: row;
    align-items: center; 
}

.switch {
    position: relative;
    width: 2.34375vw;
    height: 1.1979166666666667vw;
    padding: 0.15625vw;

    input[type=checkbox] {
        height: 1.1979166666666667vw;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: -webkit-fill-available;
        cursor: pointer;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #9e9a9a;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 20px;
    
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 0.937vw;
        width: 0.937vw;
        left: 0.10416666666666667vw;
        bottom: 0.13416666666666667vw;
        background-color: white;
        -webkit-transition: .2s;
        transition: .2s;
        border-radius: 0.937vw;
    }
    
    input:checked+.slider {
        background-color: #2ab934;
    }
    
    input:focus+.slider {
        box-shadow: #2196F3;
    }
    
    input:checked+.slider:before {
        -webkit-transform: translateX(0.9375vw);
        -ms-transform: translateX(0.9375vw);
        transform: translateX(1.25vw);
    }
    
    .slider:after {
        content: 'OFF';
        color: white;
        display: block;
        position: absolute;
        transform: translate(-18%, -18%);
        top: 0.3645833333333333vw;
        right: 0.10416666666666667vw;
        top: 0.3645833333333333vw;
        font-size: 0.4166666666666667vw;
        font-family: Verdana, sans-serif;
    
    }
    
    input:checked+.slider:after {
        content: 'ON';
        left: 0.625vw;
        top: 0.3645833333333333vw;
        font-size: 0.4166666666666667vw;
    }

}



.toggle-label-text {
    font-size: 0.7291666666666666vw;
    font-weight: bold;
    color: #0b3e79;
    margin-left: 0.5208333333333334vw;
    word-wrap: break-word;
}