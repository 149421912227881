@import '../../variables.scss';


// .mps-team-member{
//     width:17vw;
//     height: max-content;
// }
.mps-user-del-upd {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 1.1em 0.6em 0 0;
    cursor: pointer;
}
.mps-user-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0.5rem;
    height: max-content;
    .cursor-p {
        cursor: pointer;
    }

    // border-radius: 0.5em;
    // border: 1px solid $statusDisabledColor;
    // background-color: $dasboardOptionBackgroundColor;
    // position: relative;
    // padding:0.5rem;
    // margin:0 1.5rem 1.5rem 0;
    

    .mps-card-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .mps-card-row-left {
            width: 40%;
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        .mps-contact-info {
            width: 60%;
        }
    }

    .mps-avatar-holder {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0.5rem 0;

        .cursor-p {
            cursor: pointer;
        }

        .mps-avatar {
            width: 5vw;
            height: 5vw;
            border-radius: 3rem;
            padding: 0.3rem;
            border: 1px solid $primaryColor;

            .mps-avatar-img {
                background-image: url('../../../assets/icons/user.svg');
                border-radius: 100%;

            }
        }
    }

    .mps-name-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin: 0 0 1rem 0;
        font-size: 0.8vw;
        color: $userProfileNameColor;
        font-weight: 700;
        position: relative;

        .mps-user-name {
            width: 100%;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }

        .parent-position {
            height: 0;
            position: absolute;
            width: 0;
            z-index: 1000;
            top: 110px;
            left: 100%;
            /* Position it to the right of the .doc-pill-card */
            transform: translateX(-10px);
            /* Add some spacing between the card and tooltip */
            opacity: 0;
            /* Initially hidden */
            pointer-events: none;
            /* Hide it from interactions */
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

            .child-position {
                display: flex;
                position: absolute;
                background-color: white;
                padding: 0.5rem;
                border-radius: 4px;
                border: 1px solid rgba($color: $pageHeaderBoderColor, $alpha: 1.0);


                .action-list-title {
                    color: $defaultHighLightColor;
                    font-size: 0.7463541666666666vw;
                    font-weight: 700;
                    min-width: max-content;
                    text-transform: none;
                }
            }

            .child-position::after {
                content: '';
                height: 10px;
                width: 10px;
                position: absolute;
                background-color: white;
                top: 20%;
                left: -6px;
                border-top: rgba($color: $pageHeaderBoderColor, $alpha: 1.0) solid 1px;
                border-left: rgba($color: $pageHeaderBoderColor, $alpha: 1.0) solid 1px;
                transform: rotate(-45deg);
            }
        }
    }

    .mps-name-title:hover .parent-position {
        opacity: 1;
        /* Show the tooltip when hovered */
        pointer-events: auto;
        /* Enable interactions with the tooltip */
    }

    .mps-contact-info {
        margin-left: 0.3rem;

        .mps-info-row {
            margin-bottom: 0.5rem;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            color: $primaryColor;

            .mps-user-icon {
                margin-right: 1em;

                svg {
                    height: 1vw;
                    width: 1vw;
                }
            }

            .social-media-row {
                display: flex;
                width: 100%;
                justify-content: center;
            }
        }
    }
}

.mps-team {
    .mps-card-row {
        display: block;

        .mps-card-row-left {
            width: 100%;
        }
    }
}