@import '../../../../variables.scss';

.error-sec {
    margin-top: 1rem
}

.dshb-soa-f-content {
    padding: 0 3em 3em 3em !important;
    .error-holder {
        .error-title {
            color: $statusInActiveColor;
            font-size: 1.2843vw;
            margin-bottom: 0.2rem;
        }

        span {
            color: $statusWarningColor;
            font-weight: 500 !important;
            font-size: 0.97vw;
            // text-transform: capitalize;
        }

        .error-table-container {
            max-height: 20vh;
            overflow: auto;
            margin-top:0.5rem;
        }

        .error-table {
            width: 100%;
            border: 1px solid hsla(220, 3%, 77%, .3);

            th {
                color: $primaryColor;
                font-size: 0.87vw;
                font-weight: 700;
                padding: 0.5rem;
            }

            tr {
                border-top: 1px solid hsla(220, 3%, 77%, .3)
            }

            td {
                padding: 0.5rem;
                color: $primaryColor;
                font-size: 0.77vw;
                width:33%;

            }

        }
    }
    fieldset {
        label span {
            color: $defaultHighLightColor;
            font-size: 0.729vw;
            font-family: Lato;
            font-weight:550;

        }

        legend {
            font-size: 0.729vw;
            color: #0b3e79;
            font-weight: 900;
            text-transform: none;
            font-family: Lato;
        }
    }
}