@import '../../../../../variables.scss';


.jb-f-stp-one {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    // border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

    margin-bottom: 2em;
    padding: 2em 2em 2em 0;
    // align-items:flex-end;

    .jb-f-row {
        display: flex;
        flex-direction: row;
    }

    .jb-f-input-holder {
        margin-bottom: 1em;
    }

    .jb-f-dh-holder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 1em;
        .label-span {
            font-size: 0.729vw;
            color: #0b3e79;
            font-weight: 900;
            text-transform: none;
        }

        .jb-f-dh-input {
            display: flex;
            flex-direction: column;
            .radio-btn{
                display: flex;
                flex-direction: row;
                
            }
            .fc{
                margin-right:2rem;
            }
            .MuiSvgIcon-root {
                height: 0.8vw;
                width: 0.8vw;
            }
            
            .MuiFormControlLabel-label{
                font-size: 0.629vw;
                color: #0b3e79;
                font-weight: 600;
                text-transform: uppercase;
            }
            .MuiRadio-root{
                padding:3px !important;
            }
            .radio-i{
                display: flex;
                align-items: center;
                .form-input,.form-input-holder .form-input-tag{
                    min-width:5vw !important;
                    width:5vw !important;
                }
            }
            .MuiRadio-root.Mui-disabled {
                color: $statusDisabledColor; /* Change this to the color you want for disabled state */
                opacity: 0.3; /* Adjust the opacity for a faded look */
            }
            
            .MuiFormControlLabel-root.Mui-disabled {
                color:$statusDisabledColor; /* Change label color when disabled */
                .MuiFormControlLabel-label{
                    color:$statusDisabledColor;
                }
            }
        }
    }
}