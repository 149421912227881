@import '../../../../../variables.scss';

.ac-service-profile-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    border-radius: 3px;
    background-color: #f7fbfd;

    .ac-service-profile-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-top: 1em;
        padding-left: 2em;
        padding-right: 2em;
        width: -webkit-fill-available;
        position: relative;

    }

    .ac-service-profile-content {
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 2em;
        position: relative;
    }
}

.ac-service-profile-headerText {
    font-family: Lato;
    font-size: 1.197vw;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b3e78;
}

.Line-68 {
    height: 0;
    border: solid 0.3px #035090;
    margin-left: 2em;
    margin-right: 2em;
}

.ac-sc-fields {
    flex-wrap: nowrap;
    width: 30%;
    margin-right: 1em;
}

.ac-sc-fields-time {
    flex-wrap: nowrap;
    width: 32%;
    margin-right: 1em;
    margin-top: 1.45em;

    .time-picker-container {
        .MuiInputBase-root input {
            min-width: 24.6vw;
        }

        .MuiInputBase-input {
            margin-left: 0 !important;
        }
    }
}

.ac-sc-chip-field {
    flex-wrap: nowrap;
    width: 40%;
}

.ac-sp-field-row {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    width: 100%;
}

.ac-service-profile-label-text {
    font-size: 0.729vw;
    color: $primaryColor;
    display: block;
    padding-bottom: 10px;
    font-weight: bold;
}

.add-role-action-holder {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
    
    .jsp-error-holder {
        .aie-error {
            margin-bottom: 0.5em;
            .aie-error-txt{
                margin-left: 1.5rem;
            }
        }
    }

    .add-role-action {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 1em;


        .add-ac-btn {
            color: $white;
            padding: 0.5em 3em;
            border-radius: 5px;
            box-shadow: 0px 1px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 0.1);
        }

        .btn-yes {
            width: fit-content;

        }

        .btn-no {
            width: fit-content;
            margin-right: 1em;
        }
    }
}

.table-loading {
    width: 100%;
    height: 200px;
}

.chip {
    border-radius: 2.8px;
    border: solid 0.3px #b8b8b8;
    background-color: var(--white);
    color: #074780;
    display: inline;
}

.datePickerStyle {
    margin-top: 8;
    width: 100%;
}

.ac-service-field-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.ac-service-field {
    flex-wrap: nowrap;
    width: 20%;
}

.service-calls {
    .switch-holder {
        margin-bottom: 1em !important;
    }
}

.dialog-footer {
    margin-top: 20px;
    padding-right: 15px;
    float: right;
    width: 100%;
}

.ac-service-text {
    color: #0b3e79;
    font-size: 0.729vw;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}

.ac-service-text1 {
    color: #0b3e79;
    font-size: 0.833vw;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}

.dialog-footer {
    padding-right: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.field-label-text {
    max-width: 100%;
    box-sizing: border-box;
    word-break: unset;
    font-size: 0.677vw;
    font-family: Lato;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 900;
    color: rgb(11, 62, 121);
}

.formtext {
    color: #074783;
    width: 280px;
    height: 38px;

}

.form {
    background-color: #edf4fa;
    margin: 4px;
    margin-top: 1rem;
}

.form-groups {
    margin-top: 1rem;
    font-size: 80%;
}

.head-color {
    color: #074783;
    font-weight: bold;
    font-size: 1.197vw;
}

.icon-height {
    height: 0.8em !important;
}

/* .toggle-switch{
    margin-top: 2rem; 
    font-size: 80%; 
   } */

.toggle-text {
    margin-top: 1rem;
    color: #074783;
    font-size: medium;
}

.text-align {
    text-align: end;
    padding-top: 8px;
}

.searchWrapper {
    width: fit-content;
    min-width: 266px;
}


.ac-sytm-cns-content {
    .ac-sytm-cns-table {
        table {
            width: -webkit-fill-available;
            border: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);

            thead {
                background-color: $tableHeaderBackgroundColor;

                tr {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    .tb-header-cc {
                        display: flex;
                        flex: 1;
                        flex-shrink: 1;
                        flex-grow: 1;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        padding: 1em;
                        transition: 0.3s all ease-in-out;
                        cursor: pointer;
                        border-right: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);

                        &:hover {
                            background-color: rgba($color: $defaultHighLightColor, $alpha: 0.05);

                            .table-header-sort-icon {
                                display: block;
                            }
                        }

                        span {
                            text-overflow: ellipsis;
                            color: $primaryColor;
                            text-decoration: unset;
                        }

                        .table-header-sort-icon {
                            display: none;
                            font-size: 0.729vw;
                            transition: 0.3s all ease-in-out;
                            transform: rotate(0deg);
                            // color: $primaryColor;
                        }

                        .table-header-sort-icon-rotate {
                            transform: rotate(-180deg);
                            display: block;
                            transition: 0.3s all ease-in-out;
                        }

                    }
                }
            }

            tbody {
                tr {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    border-top: 1px solid rgba($color: $tableRowBorderColor, $alpha: 0.3);
                    cursor: unset;
                    transition: unset;
                    background-color: #ffffff;

                    &:hover {
                        background-color: unset;
                    }

                    td {
                        flex: 1;
                        padding: 1em;
                        text-decoration: unset;
                        color: $tableRowTextColor;
                        border-right: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }
            }

        }
    }
}