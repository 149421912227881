@import '../../variables.scss';


.cclndr-icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 1.979vw;
    cursor: pointer;
}


/* VARIABLES */

:root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
}


/* GENERAL */

* {
    box-sizing: border-box;
}

.cclndr-calendar{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    position: relative;
}

.cclndr-body {
    font-family: $defaultFontFamily;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: rgba($color: $defaultHighLightColor, $alpha: 1.0);
    background: transparent;
    position: relative;
}

.cclndr-header {
    display: block;
    width: 100%;
    padding: 1em 0;
    position: relative;
    // background: var(--neutral-color);
}

.cclndr-header #logo {
    font-size: 175%;
    text-align: center;
    // color: var(--main-color);
    line-height: 1;
}

.cclndr-header #logo .cclndr-icon {
    padding-right: .25em;
}

.cclndr-main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
}



/// Availability banner
/// 
.cclndr-available-banner{
    position: absolute;
    top: 3px;
    left: -14px;
    transform: rotate(-45deg);
    .cclndr-status-banner-container{
        padding: 0;
        margin: 0;
        border-bottom: 10px solid #00a4dc;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        height: 0;
        width: 45px;
        position: relative;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    
        &::before {
            content: '';
            position: absolute;
            background: #00a4dc;
            width: 10px;
            height: 4px;
            bottom: -10px;
            left: -10px;
            transform: rotate(-45deg);
            z-index: 0;
        }
    
        &::after {
            content: '';
            position: absolute;
            background: #00a4dc;
            width: 10px;
            height: 4px;
            bottom: -10px;
            right: -10px;
            transform: rotate(45deg);
            z-index: 0;
        }
    
        .title-text {
            position: absolute;
            top: -9px;
            padding-top: 0;
            // margin-top: -8px;
            span {
                color: #fff;
                // font-weight: 800;
                font-size: 5px;
            }
        }
    }

    // .cclndr-status-txt{
    //     span {
    //         font-size: 8px;
    //     }
    // }
}

/// Month header

.cclndr-month-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1em;
    .cclndr-month-year-txt{
        width: fit-content;
        span {
            font-size: 0.885vw;
            color: rgba($color: $defaultHighLightColor, $alpha: 1.0);
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

/* GRID */

.cclndr-row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.cclndr-row-middle {
    align-items: center;
}

.cclndr-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    position: relative;
}

.cclndr-col-left {
    justify-content: center;
    align-items: center;
    text-align: left;
}

.cclndr-col-right {
    justify-content: center;
    align-items: center;
    text-align: left;
}

.cclndr-col-start {
    justify-content: flex-start;
    text-align: left;
}

.cclndr-col-center {
    justify-content: center;
    text-align: center;
}

.cclndr-col-end {
    justify-content: flex-end;
}

.col-end {
    justify-content: flex-end;
    text-align: right;
}


/* Calendar */

.cclndr-calendar-content {
    display: block;
    position: relative;
    width: 100%;
}

.cclndr-calendar-content .cclndr-header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    // border-bottom: 1px solid var(--border-color);
}

.cclndr-calendar-content .cclndr-header .cclndr-icon {
    cursor: pointer;
    transition: .15s ease-out;
}

.cclndr-calendar-content .cclndr-header .cclndr-icon:hover {
    transform: scale(1.75);
    transition: .25s ease-out;
    // color: var(--main-color);
}

.cclndr-calendar-content .cclndr-header .cclndr-icon:first-of-type {
    margin-left: 1em;
}

.cclndr-calendar-content .cclndr-header .cclndr-icon:last-of-type {
    margin-right: 1em;
}

.cclndr-calendar-content .cclndr-days {
    // text-transform: uppercase;
    font-weight: 400;
    color: $defaultHighLightColor;
    font-size: 70%;
    padding: .75em 0;
    position: relative;
    // border-bottom: 1px solid var(--border-color);
}

.cclndr-calendar-content .cclndr-body .cclndr-cell {
    position: relative;
    height: 39px;
    // border-right: 1px solid var(--border-color);
    // overflow: hidden;
    cursor: pointer;
    background: #f7fbfd;
    transition: 0.25s ease-out;
    box-shadow: 0px 0px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 1.0);
    margin: 0.5em auto;
    border-radius: 2px;
}

.cclndr-calendar-content .cclndr-body .cclndr-cell:hover {
    // background: var(--bg-color);
    transition: 0.5s ease-out;
}

.cclndr-calendar-content .cclndr-body .cclndr-selected {
    border-bottom: 5px solid transparent;
    // border-top-right-radius: 5px;
    // border-bottom-right-radius: 5px;
    border-image: linear-gradient(45deg, $tableRowTextColor 0%, $tableRowTextColor 40%);
    border-image-slice: 1;
}

.cclndr-calendar-content .cclndr-body .cclndr-today {
    border-bottom: 5px solid transparent;
    // border-top-right-radius: 5px;
    // border-bottom-right-radius: 5px;
    border-image: linear-gradient(45deg, $statusActiveColor 0%, $statusActiveColor 40%);
    border-image-slice: 1;
}


// .cclndr-calendar-content .cclndr-body .cclndr-row {
//     border-bottom: 1px solid var(--border-color);
// }

.cclndr-calendar-content .cclndr-body .cclndr-row:last-child {
    border-bottom: none;
}

.cclndr-calendar-content .cclndr-body .cclndr-cell:last-child {
    border-right: none;
}

.cclndr-calendar-content .cclndr-body .cclndr-cell .cclndr-number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;
}

.cclndr-calendar-content .cclndr-body .cclndr-disabled {
    color: $statusDisabledColor;
    pointer-events: none;
}

.cclndr-calendar-content .cclndr-body .cclndr-cell .cclndr-bg {
    font-weight: 700;
    line-height: 1;
    color: rgba($color: $defaultHighLightColor, $alpha: 1);
    opacity: 0;
    font-size: 3.5em;
    position: absolute;
    top: -.1em;
    right: 0.1em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
}

.cclndr-calendar-content .cclndr-body .cclndr-cell:hover .cclndr-bg,
.cclndr-calendar-content .cclndr-body .cclndr-selected .cclndr-bg {
    opacity: 0.05;
    transition: .5s ease-in;
}

.cclndr-calendar-content .cclndr-body .cclndr-cell:hover .cclndr-bg,
.cclndr-calendar-content .cclndr-body .cclndr-today .cclndr-bg {
    opacity: 0.05;
    transition: .5s ease-in;
}

.cclndr-calendar-content .cclndr-body .cclndr-col {
    flex-grow: 0;
    flex-basis: calc(100%/8);
    width: calc(100%/8);
}

