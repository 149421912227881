

.company-details-page-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    padding: 1.0416666666666667vw;
    .cmpny-info-container{
        display: flex;
        justify-content: center;
    }
}