@import '../../../../variables.scss';

.board-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    overflow: hidden;

    .header {
        position: relative;
        display: flex;
        width: -webkit-fill-available;
        height: fit-content;
    }

    .content {
        display: flex;
        flex-direction: column;
        position: relative;
        max-height: -webkit-fill-available;
        overflow: hidden;
        // padding: 0 1em 0 1em;
    }
}
