@import "../../../../../../variables.scss";

.cd-cs-covv-container {
  display: flex;
  flex-direction: column;
  .company_about_us {
    border-top: 1px solid rgba(3, 80, 144, 0.1);
    padding: 1em;

    color: #0b3e79;

    .title {
      font-size: 0.833vw !important;
    }
    .about_us_content {
      margin-top: 1em;
      padding-left: 0em;
      font-size: 0.8vw !important;
      white-space: pre-line;
    }
  }
}
