@import '../../../../variables.scss';

.upload-doc-container {
    display: flex;
    flex-direction: column;
    // width: -webkit-fill-available;
    width: 40vw;
    height: auto;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .upload-doc-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .upload-doc-table-content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        .custom-button {
            padding: 0.2em;
            font-size: 0.433vw;
        }
        .sortable-table tbody tr td span {
            font-size: 0.733vw !important;
        }
    }

    .upload-doc-content {
        padding: 1em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .create-form-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .error-holder {

            .error-title {
                color: $statusInActiveColor;
                font-size: 1.2843vw;
                margin-bottom: 0.5rem;
            }

            span {
                color: $statusWarningColor;
                font-weight: 500 !important;
                font-size: 1vw;
                text-transform: capitalize;
            }
        }
    }
}

.bo-tct-upload-doc-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;

}