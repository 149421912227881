@import '../../../variables.scss';

.oa-edk-form-container{
    display: flex;
    flex-direction: column;

    .oa-edk-form-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.oa-edk-f-input-holder{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 1em;
}