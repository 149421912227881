@import "../../../../variables.scss";

.create-payroll-batch-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;

  .pptp-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .pptp-toggle {
      margin-left: 2em;
      margin-top: 3em;

      span {
        text-transform: none;
      }
    }
  }

  .pc-directory {
    .payroll-paycheck-container {
      padding: 0 !important;
    }

  }

  .create-payroll-batch-header {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
  }

  .create-payroll-batch-content {
    padding-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    margin-left: 3em;

    // span {
    //     color: $primaryColor;
    //     font-size: 1vw;
    //     font-weight: 700;
    //     text-transform: none;
    // }
    .sub-span {
      margin-top: 1rem;
      font-weight: 400;
      font-size: 1vw;
    }

    .open-msg-div {
      display: flex;
      flex-direction: column;

      div {
        margin-bottom: 1rem;
        color: $primaryColor;
        font-size: 1vw;
        font-weight: 600;
        text-transform: none;
        display: inline-block;
      }
    }

    .oc-error-holder {
      margin-top: 2rem;

      span {
        color: red;
        font-weight: 500 !important;
        font-size: 0.927vw;
      }
    }
  }

  .create-payroll-batch-actions {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 2em;
    padding-left: 3em;
    width: 100%;
    justify-content: space-between;

    .actions-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    .alert-message {
      color: $primaryColor;
      font-weight: 500 !important;
      font-size: 0.727vw;
      text-transform: none;
    }
  }
}

.create-payroll-batch-step-one {
  display: flex;
  flex-direction: row;
  width: 100%;

  .s1-col2 {
    margin-left: 2.5rem;
    width: -webkit-fill-available;

    .cpb1-form-input {
      width: 100%;
      ;
    }
  }

}

.cpb-step-two {
  .cpb-step-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1em;

    .total-count {
      margin-right: 1rem;

      span {
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $defaultHighLightColor;

        .total-count-number {
          font-weight: bold;
        }
      }
    }

    .cpb-step-right {
      display: flex;
      align-items: center;
    }
  }

  .cpb-step-two-table {
    max-height: 20vw;
    overflow: auto;

    .nda-div {
      .table-data-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .total-row {
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
      position: sticky;
      bottom: 0;
      z-index: 1;

      span {
        padding: 1em;
      }
    }
  }

  .sortable-table tbody tr:last-child {
    margin-top: 1rem
  }
}

.rev-check-page {
  padding: 1rem;

  .title-sp {
    color: rgb(255, 140, 0);
    font-size: 0.9vw;
    font-weight: 600;
    text-transform: none;
  }

  .ded-table {
    margin-top:1rem;
    max-height: 20vw;
    overflow: auto;
    th {
      font-weight: 800;
    }

    th,
    td {
      padding: 0.5rem;
      color: $primaryColor;
    }
  }
}