@import "../../../../../../variables.scss";

.cs-container {
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    border: 1px solid $tableBorderColor;

    .cs-header {
        padding:1em 0;
        .note-span {
            color: $employeePortalEDCBgColor;
            font-size: 1vw;
            margin: 1rem 0rem;
            text-transform: none;
        }
        .title-header{
            color: $primaryColor;
            font-size: 1.1vw;
            margin: 0.5rem 0rem;
            font-weight: 700;
        }
    }
    .cs-tax-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cs-right{
            display: flex;
            flex-direction: row;
            align-items: center;
            .total-count{
                margin-right:1rem;
                span{
                    color:$primaryColor;
                }
            }
        }
    }
    .cs-content {
        border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-bottom: 1.5rem;
        .cs-warn-title {
            color: $textWarningColor;
            font-size: 1vw;
            margin-bottom: 1rem;
            text-transform: none;
        }
        .cmpny-details {
            padding-bottom: 1em;
        }

        .contact-details {
            padding: 1em 0;
            border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        }

        .cs-title {
            font-size: 1vw;
            color: $primaryColor;
            font-weight: 700;
            margin: 0.5em 0;
        }

        .cs-input-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 0.8rem;

            .cs-input-holder {
                width: 20%;
                margin-right: 1.5rem;

                .date-input-holder-div {
                    width: 100%;
                }
            }

            .ct-l-h {
                width: 30%;
            }

            .sd {
                width: 42%;
            }
        }
    }
    .no-border{
        border:none !important
    }

    .cs-actions-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding-top: 1rem;
        margin-top: 0.5rem;

        .cs-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .btn-cancel {
                margin-right: 1rem;
            }
        }

    }
}