@import '../../variables.scss';

.status-mark-container {
    padding: 0;
    margin: 0;
    border-bottom: 35px solid #00a4dc;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    height: 0;
    width: fit-content;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    &::before {
        content: '';
        position: absolute;
        background: #00a4dc;
        width: 35px;
        height: 10px;
        bottom: -33px;
        left: -38px;
        transform: rotate(-45deg);
        z-index: 0;
    }

    &::after {
        content: '';
        position: absolute;
        background: #00a4dc;
        width: 35px;
        height: 10px;
        bottom: -33px;
        right: -38px;
        transform: rotate(45deg);
        z-index: 0;
    }

    .title-text {
        // position: absolute;
        // top: 0;
        padding-top: 5px;
        span {
            color: #fff;
            font-weight: 800;
        }
    }
}