@import '../../variables.scss';


.custom-pab{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1em;
    border-radius: 4px;
    width: -webkit-fill-available;
    margin-top: 1em;
    background-color: $tableHeaderBackgroundColor;
    border-left: 4px solid $defaultHighLightColor;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    transition: 0.3s all ease-in-out;
    
    &:hover{
        background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1) !important;
    }
}