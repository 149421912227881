@import '../../../../variables.scss';

.ac-wcc-awc-popup-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .ac-wcc-awc-popup-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .ac-wcc-awc-popup-content {
        padding-left: 3em;
        padding-top: 0.5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .ac-wcc-awc-popup-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .ac-wcc-awc-popup-date-row {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .ac-wcc-awc-popup-input-holder {
            width: 50%;
            margin-right: 1em;
            margin-bottom: 1em;

            .cmfs-margin-top {
                margin-top: 0.7em !important;
            }

        }
        .ac-wcc-awc-popup-date-input-holder{
            width: 30%;
            margin-right: 1em;
        }
    }

    .ac-wcc-awc-popup-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid $inputoutlineColor;
        margin-top: 1rem;

        .btn-cancel {
            margin-right: 1em;
            margin-top: 1em;
        }

        .btn-save {
            margin-top: 1em;
        }
    }

}