@import '../../../../../variables.scss';


.if-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;

    .if-header {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .if-content {
        margin: 0rem 0 2rem 0;

        .if-input-holder-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            margin-bottom: 0.6rem;

            .if-content-input-holder {
                width: 100%;

                // margin-right:2rem;
                span {
                    text-transform: none;
                }
            }
        }

        .row-3 {
            .if-content-input-holder {
                width: 32%;

                .cmfs-margin-top {
                    margin-top: 0.6rem !important;
                }
            } 
        }

        .if-input-holder-row:nth-child(3) {
            .if-content-input-holder {
                width: 48%;

                .cmfs-margin-top {
                    margin-top: 0.6rem !important;
                }
            }
        }

        .toggle-row {
            margin-top: 2rem;

            .if-content-input-holder {
                width: 50%;
            }

        }

        .section-header {
            max-width: 100%;
            width: 100%;
            font-size: 0.833vw;
            margin-top: 1rem;
            color: #0b3e79;
            text-transform: uppercase;
        }
    }

    .if-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;


        .if-actions-btn {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .btn-cancel {
            margin-right: 1rem;
        }
    }
}