@import "../../../../../variables.scss";

.hr-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .hr-header {
        display: flex;
        justify-content: space-between;

        .hr-r-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .dateRange {
                width: min-content;
                margin-top: 0.3em;
                margin-right: 1rem;

                input {
                    width: 10vw !important;
                    min-width: 10.5vw !important;
                    color: $defaultHighLightColor !important;
                }
                .error-span{
                    color: red;
                    font-size: 10px;
                }
                .error-date{
                    .dateRange .date-input-icon .calendar-icon-div,
                    .dateRange .date-input-icon input{
                        border: 1px solid red;
                    }
                }
            }

            .hr-input {
                width: fit-content;
                margin-right: 1rem;
            }

            .hr-save-input {
                margin-left: 3rem;
                margin-top:2rem;
            }
      

        }
        .vc-r-right{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        .hr-r-right {
            display: flex;
            justify-content: flex-start;
            color: "#0B3E78";
            padding: 0 1em 0 1em;

            .total-count-action {
                margin-left: 1.5em;
                margin-right: 1em;

                span {
                    width: max-content;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.2;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;

                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .hr-content {
        margin-top:2em;
        .hr-table-c {
            border-radius: 0.2em;
            border: solid 1px #c0c2c4;
            padding-bottom:2em;
            .hr-table {
                max-height: 58vh;
                overflow: auto;
                position: relative;
        
                .sortable-table thead {
                    z-index: 0;
                    position: sticky;
                }
            }
            .hr-table-title {
                margin-top: 1em;
                display: flex;
                justify-content: space-between;
                color: $primaryColor;
                padding: 0 1em 1em 1em;

                .hr-t-left {
                    font-size: 0.937vw;
                    color: $primaryColor;
                    font-weight: 800;
                    display: flex;
                    align-items: center;
                }

                .hr-t-right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .total-span {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}