@import '../../../../../variables.scss';


.code-ro-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0rem;
    border: 0.1px solid rgba(193, 205, 212, 0.2509803922) !important;

    .code-ro-work-comp-codes-container {
        border-bottom: 0.1px solid rgba(193, 205, 212, 0.2509803922);
        padding: 0rem 1rem;
        height: 44.8vh;

    }

    .code-ro-rates-job-container {
        margin-top: 2rem;
        padding: 0rem 1rem;
    }

    .code-ro-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .code-ro-header-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .sb-container {
                width: 11vw;
                margin-right: 0.5rem;
            }
        }

        .section-header {
            // max-width: 100%;
            // width: 100%;
            font-size: 0.833vw;
            color: #0b3e79;
            text-transform: uppercase;
        }
    }

    .code-ro-content {
        margin: 1rem 0;
        overflow: auto;
        max-height: 25vw;
        height: 100%;

        .code-ro-input-holder-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            margin-bottom: 0.6rem;

            .code-ro-content-input-holder {
                width: 100%;
                margin-right: 2rem;
            }
        }

        .empty-data-msg {
            text-align: center;

            span {
                font-size: 1vw;
                color: $tableBorderColor;
            }
        }

        .toggle-row {
            margin-top: 1.5rem;
        }
    }

    .code-ro-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .code-ro-actions-btn {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .btn-cancel {
            margin-right: 1rem;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .code-ro-container {
        .code-ro-work-comp-codes-container {
            height: 47vh !important;
        }
    }
}