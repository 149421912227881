@import "../../../../variables.scss";

.cb-batch-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .cb-batch-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }
    .cb-batch-content{
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding:1rem;
        overflow: auto;
        .pi-content{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .pi-toggle{
              margin-left: 2em;
              margin-top: 3em;
              span{
                text-transform: none;
              }
            }
          }
        .cb-step-one-container{
            display: flex;
            flex-direction: column;
    
            .so-form-input-row{
                display: flex;
                flex-direction:row ;
                justify-content: space-between;
                width:100%;
                .so-form-input{
                    width:30%;
                }
            }
        }

        .pi-toggle{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }

        .cpb-step-two{
            .cpb-step-header {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 1em;
        
                .total-count {
                    span {
                        font-weight: 300;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        color: $defaultHighLightColor;
        
                        .total-count-number {
                            font-weight: bold;
                        }
                    }
                }
            }
            .cpb-step-two-table{
                max-height: 20vw;
                overflow: auto;

                .nda-div {
                    .table-data-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .total-row {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 1rem;
                    position: sticky;
                    bottom: 0;
                    z-index: 1;
                    
                    span {
                        padding: 1em;
                    }
                }
            }
        }
    }

    .cb-batch-actions {
        display: flex;
        flex-direction: row;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 2em;
        padding-left: 3em;
        width:100%;
        justify-content: space-between;
        .actions-row{
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
        }
        .alert-message{
            color: $primaryColor;
            font-weight: 500 !important;
            font-size: 0.727vw;
            text-transform: none;
        }
    }
    

}