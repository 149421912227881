@import '../../../variables.scss';

.dialog-container-tlem{
  padding: 1em 1em 0 0.5em;
  color: $defaultHighLightColor;

  .dialog-header-tlem{
    display: flex;
    justify-content: space-between;

    span {
      font-size: 1.041vw;
      font-weight: 900;
      color: #0b3e79;
    }
    img{
      height: 1.3vw;
      width: 1.3vw;
      color: black;
      cursor: pointer;
    }

  }

  .dialog-content-tlem{
    padding: 0 2em 2em 2em;
    margin-left: 1.4em;
    border-bottom: 1px solid $tableBorderColor;

    .dialog-content-input{
      display: flex;
      flex-direction: row;
      width: -webkit-fill-available;
    }

    input{
      width: -webkit-fill-available;
      margin-right: 1vw;
    }

    .message{
      margin: 0;
      margin-top: 0.2em;
      font-size: 0.625vw;
      height: 1.5vh;
    }

  }

  .dialog-footer-tlem{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .btn-cancel {
      margin-right: 1em;
      margin-top: 1em;
    }
  }

}

.ac-sc-text-em-all-input{
  display: flex;
  margin: 2rem;
}
