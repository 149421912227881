@import '../../../../../variables.scss';


.wcc-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: -webkit-fill-available;
}

.wcc-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.2em;

    .wcc-avatar-holder {

        width: 5.7291666667vw;
        height: 5.7291666667vw;
        border-radius: 5.7291666667vw;
        padding: 4px;
        border: 1px solid #074783;
        position: relative;
    }

    .wcc-avatar {
        background-image: url('../../../../../../assets/icons/user.svg');
        border-radius: 100%;
    }

}

.wcc-user-name {
    margin-top: 0.5em;

    span,
    a {
        font-size: 1.25vw !important;
        font-family: $defaultFontFamily !important;
        color: $userProfileNameColor !important;
        font-weight: 900 !important;

    }
}

.wcc-user-tag {
    span {
        font-size: 0.729vw;
        font-family: $defaultFontFamily;
        color: $tableRowTextColor;
        font-weight: 900;
    }
}



.wcc-activity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .last-engagement-txt {
        margin-bottom: 0.25em;

        span {
            font-weight: 800;
            font-size: 0.677vw;
            color: $tableRowTextColor;
        }
    }

    .assignment-status {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .icon {
            width: 0.833vw;
            height: 0.833vw;
        }

        .status {
            margin-left: 0.2em;

            span {
                font-size: 0.625vw;
                font-weight: 800;
            }
        }
    }
}

.wcc-action-container {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    // justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;

    .wcc-action {
        width: -webkit-fill-available;
        margin-bottom: 1em;

        .wcc-action-icon {
            width: 1.25vw;
            height: 1.25vw;
        }
    }
}

.wcc-branch-selector {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;

    .wcc-status {
        margin-top: 1em;

        span {
            font-weight: 900;
            text-transform: capitalize;
            color: $defaultHighLightColor;

            .status-txt {
                color: $secondaryColor;
                margin-left: 0.5em;
            }

        }
    }

}

.wcc-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em;


    .wcc-info-icon {
        width: 0.833vw;
        height: 0.885vw;
        margin-right: 1em;

        svg path {
            fill: $userProfileNameColor;
        }
    }

    .resume-holder-container .file-name-txt span {
        width: 5.5vw;
    }

    .wcc-info-content {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;

        .wcc-info-holder {
            display: flex;
            flex-direction: column;

            .wcc-into-title {
                span {
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    font-size: 0.833vw;
                }
            }

            .wcc-info-value {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;

                span {
                    font-weight: 800;
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    text-transform: none;
                }

                .wcc-info-icon {
                    width: 1.666vw;
                    height: 1.666vw;
                    cursor: pointer;
                    padding: 0.5em;
                    transition: 0.3s all ease-in-out;

                    &:hover {
                        background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }

            }

        }
    }
}


.wcc-info-address-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1em;
    padding-left: 1em;


    .wcc-info-icon {
        width: 0.833vw;
        height: 0.885vw;
        margin-right: 1em;

        svg path {
            fill: $userProfileNameColor;
        }
    }

    .wcc-info-value {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        span {
            font-weight: 800;
            color: $userProfileNameColor;
            font-family: $defaultFontFamily;
            text-transform: none;
        }

    }
}



.available-status {
    position: absolute;
    top: -8px;
    left: -55px;
    transform: rotate(-45deg);
}