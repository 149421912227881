@import '../../../../variables.scss';

.octw-archd-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);

    .octw-archd-table {
        overflow: auto;
        max-height: 65vh;  
    }

    .octw-archd-table-header-options {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        padding: 1em;

        .apps-ud-search {
            margin-right: 1em;
        }

        .recruiter-selector,
        .company-status-selector {
            margin-right: 1em;
            width: 11.873983vw;
        }

        .octw-archd-table-search-bar {
            margin-right: 1em;
        }

        .select-user {
            margin-right: 1em;

            .user-form-control {
                min-width: 190px;
                width: fit-content;
            }
        }

        .deparment-toggle {
            margin-right: 1em;
        }

        .total-count {
            margin-right:1rem;
            span {
                color: $defaultHighLightColor;
            }
        }
    }
}

.table-loading {
    width: 100%;
    height: 200px;
}


.website-icon {
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
    text-decoration: none;

}

.open-orders-empty {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .ord-icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 1em;
    }

    .ord-text-empty {
        span {
            color: #b1b1b1;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }

    .ord-text {
        span {
            color: $openOrdersTextColor;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }
}

.status-icon {
    width: 1.25vw;
    height: 1.25vw;
    margin-right: 1em;
    flex-shrink: 0;
}

/// Name
// .octw-archd-table-name {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     flex-wrap: nowrap;

//     .status-icon {
//         width: 1.25vw;
//         height: 1.25vw;
//         margin-right: 1em;
//     }

//     .name-txt {
//         span {
//             font-size: 0.729vw;
//             font-weight: bold;
//         }
//     }
// }

.pref-contact-icon {
    // width: 1.25vw;
    height: 1.25vw;
}

.ssn-holder {
    display: flex;
    flex-direction: row;
    // align-items: center;
    flex-wrap: nowrap;

    .ssn-number {
        margin-right: 0.5em;

        span {
            margin-top: 2px;
        }
    }

    .loc-open-icon {
        width: 14px;
        height: 14px;
    }
}