@import '../../../../variables.scss';

.dsh-section-header-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    cursor: pointer;

    .title {
        span {
            font-size: 0.781vw;
            color: #858A95;
        }
    }
}