@import '../../../../variables.scss';


.add-credit-profile-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 1em 1em 3em;
    overflow: hidden;

    .add-credit-profile-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .add-credit-profile-content {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        .saved-doc-holder{
            padding:2rem;
        }
        .add-credit-profile-input-holder-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0.5em;
            width: 100%;

            .add-credit-profile-input-holder {
                width: 100%;
                margin-right: 2rem;
            }
        }

        .holder-2 {
            display: flex;
            flex-direction: row;
            width: 55% !important;

            .holder-2-input {
                width: 48%;
                margin-right: 1rem;

                input {
                    min-width: 3vw !important;
                }
                
            }
            .percent-input {
                margin-top: 2rem;
            }
            .span-input{
               display: flex;
               justify-content: center;
               align-items: center;
               padding-top:1.5rem;
               color:$primaryColor;
            }
        }
        .toggle-row {
            margin:2rem 0rem 0rem 2rem !important;
        }
    }

    .add-credit-profile-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-top:1rem;
        .btn-cancel {
            margin-right: 1em;
        }
    }
}