@import '../../../../../../variables.scss';

.interview-question-card {
    padding: 1em;
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin-bottom: 2em;

    .question-title {
        span {
            font-weight: 800;
            color: $defaultHighLightColor;
            text-transform: none;
        }
    }

    .interview-question-content {
        padding: 1em 1em 1em 1em;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        .qestion-answ {
            display: flex;
            flex-direction: column;
            flex: 1;

            .title-txt {
                margin-bottom: 1em;
                span {
                    font-weight: normal;
                    color: $defaultHighLightColor;
                }
            }

            .answ-txt {
                span {
                    font-weight: 800;
                    color: $defaultHighLightColor;
                    text-transform: none;
                }
            }
        }
        .candidate-answer{
            margin-right:1.5rem;
        }
    }

}