
.rt-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    span {
        font-size: 40px
      }
}

.rating_css{
    display: inline-flex;
    text-align: center;
    font-size: 25px !important;
}

.add-role-action-holder{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 20px;

    .add-role-action{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 1em;

    

        .btn-yes{
            width: fit-content;
            
        }

        .btn-no {
            width: fit-content;
           margin-right: 1em;
        }
    }
}