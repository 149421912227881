@import '../../../../variables.scss';


.add-tax-info-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 1em 1em 3em;
    overflow: hidden;

    .add-tax-info-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
        .title-name span{
            text-transform: none;
        }
    }

    .add-tax-info-content {
        display: flex;
        flex-direction: column;
        // position: relative;
        // overflow: auto;
        .saved-doc-holder{
            padding:2rem;
        }
        .add-tax-info-input-holder-row {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            margin-bottom: 0.5em;
            width: 100%;
            align-items: flex-end;

            .add-tax-info-input-holder {
                width: calc(100%/3);
                margin-right: 1.5rem;
                span{
                    text-transform: none;
                }
            }
            .toggle-row{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .switch {
                    .slider:after {
                      content: 'NO' !important;
                    }
                    input:checked+.slider:after {
                      content: 'YES' !important;
                    }
                  }
            }
        }
    }

    .add-tax-info-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-top:1rem;
        .btn-cancel {
            margin-right: 1em;
        }
    }
}