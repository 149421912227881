

.spl-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;
    .spl-logo-size{
        width: 11.979166666666666vw;
        height: 9.183673469387756vh;
        object-fit: contain;
    }
    .spl-app-logo{
        // background-image: url('../../../../assets/icons/kiniso.svg');
        background-size: contain;
    }
}