@import '../../../../variables.scss';

.tc-container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: -webkit-fill-available;
    overflow: hidden;

    .tc-header {
        display: flex;
        align-items: center;
        border-bottom: 0.5px solid $primaryColor;
        padding-bottom: 0.5rem;

        .title-icon {
            width: 1.2vw;
            height: 1.2vw;
            margin-right: 1em;
            color: $defaultHighLightColor;

            svg path {
                fill: $defaultHighLightColor;
            }
        }

        .title-name {
            
            span {
                text-transform: uppercase;
                font-size: 0.9vw;
                font-weight: 900;
                color: $defaultHighLightColor;
            }
        }
    }

    .tc-content {

        .title-list-div {
            position: relative;
            overflow: auto;
            height: 40vh;
            overflow: auto;
        }

        .cd-title-name {
            span {
                text-transform: uppercase;
                font-size: 0.729vw;
                font-weight: 900;
                color: $defaultHighLightColor;
                margin-top: 1.5em;
                margin-bottom: 0.5em;
            }  
        }

        .title-pill {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // align-items: center;
            padding: 0.5em 1em;
            border-radius: 20px;
            margin-right: 1em;
            margin-top: 1em;
            background-color: #F7FBFD;
            border: 1px solid $defaultHighLightColor;

            .title-name {
                color: $primaryColor;
                font-weight: 600;
                cursor: pointer;
                width:100%;
                span {
                    text-transform: none;
                }
            }

            .title-icon {
                width: 0.9vw;
                height: 0.9vw;
                cursor: pointer;
            }
        }
    }
}