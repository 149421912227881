@import '../../../app/variables.scss';

.profile-dialog-container {
    margin: 10px;
    color: $primaryColor;
    height: 500px;

    .profile-dialog-header {
        display: flex;
        justify-content: space-between;
    }

    .profile-dialog-content {
        width: 800px;
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;

        .agency-logo-container {
            img {
                transform: none !important;
            }
        }

        .options {
            margin-top: 330px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .zoom-range {
                width: 400px;
            }

            .message {
                color: red;
                height: 10px;
            }

        }

    }

    .profile-dialog-footer {
        display: flex;
        justify-content: center;
    }

}