@import '../../../../variables.scss';


.mp-password-update-dialog-container {
    padding: 1vw;
    color: $primaryColor;

    .mp-password-update-dialog-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .mp-password-update-dialog-content {
        display: flex;
        flex-direction: column;

        .mp-password-valid-message {
            margin-left: 5px;
            height: 2vh;
            color: red;
        }
        .form-input-holder .suffix-holder{
            cursor: pointer;
        }


    }

    .mp-password-update-dialog-footer {
        margin-top: 4vh;

        .update-button {
            float: right;
        }

    }

    .mp-password-update-success {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3vh 0 3vh 0;

        h6 {
            margin-left: 10px;
            margin-bottom: 0;
        }
    }

}