@import "../../../variables.scss";

.drdp-f-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (min-width: 1024px) {
  .ob-dd-f-container {
    min-height: 280px;
    height: 280px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 720px) {
  .ob-dd-f-container {
    min-height: 255px;
    height: 255px;
  }
}

@media screen and (max-width: 800px) and (min-width: 600px) {
  .ob-dd-f-container {
    min-height: 210px;
    height: 210px;
  }
}

.drd-title-txt {
  color: $defaultHighLightColor;
 
  margin-bottom: 1rem;
  font-size: 1.6rem;

  span {
    font-size: 1rem;
    font-weight: 750;
  }
}

.direct-deposit-sortable-table {
  margin-bottom: 1rem;
  tbody {
    display: block;
    overflow-y: auto;
    max-height: 27vh;
    td {
      border-right: 1px solid rgba(195, 196, 198, 0.3);
      .switch {
        .slider:after {
          content: "OFF" !important;
        }

        input:checked + .slider:after {
          content: "ON" !important;
        }
      }
    }
  }
}
.cursor-p {
  cursor: pointer;
}
.drd-actions {
  .error-msg {
    color: orangered;
    margin-top:0.3rem;
  }
}