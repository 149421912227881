@import "../../../../../variables.scss";

.tint-isocc-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  box-sizing: border-box;
  padding: 2em;

  .tint-isocc-left-panel {
    flex: 1;
    min-width: 19.03367496339678vw;
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    box-shadow: 0px 0px 2px 0px rgba($color: #c2ced5, $alpha: 1);
    margin-right: 2em;
    position: relative;
    box-sizing: border-box;
    background-color: $tableHeaderBackgroundColor;
    // min-height: -webkit-fill-available;

    .tint-left-panel-shape-pyramid {
      border-top: 0.78125vw solid transparent;
      border-left: 1.0416vw solid $tableHeaderBackgroundColor;
      border-bottom: 0.78125vw solid transparent;
      width: 0;
      height: 0;
      position: absolute;
      top: 5%;
      right: -1.0416vw;
    }
  }

  .tint-isocc-right-panel {
    flex: 4;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    background-color: $tableHeaderBackgroundColor;
    padding: 2em;
  }
}

.tint-isocc-form-container {
  display: flex;
  flex-direction: column;

  .tint-isocc-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1em;
    margin-bottom: 1em;

    .tint-isocc-header-txt {
      span {
        font-size: 0.781vw;
        color: $defaultHighLightColor;
      }
    }
  }

  .tint-isocc-questions-section {
    display: flex;
    flex-direction: column;
    margin-left: 1em;

    .tint-isocc-question-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 2em;

      .tint-isocc-question-title {
        margin-bottom: 1em;

        span {
          font-size: 0.833vw;
          color: $defaultHighLightColor;
          font-weight: bold;
        }
      }

      .tint-isocc-input-form {
        display: flex;
        flex-direction: column;
        margin-left: 1em;
      }
    }
  }
}

.tlnt-f-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .tlnt-f-input-holder {
    width: -webkit-fill-available;
    margin-right: 1em;
  }
}

.error-section {
  margin-bottom: 2em;
}

.tlnt-f-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .btn-cancel {
    margin-right: 1em;
  }
}

/// New design styles

.tint-isocc-details-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;

  .tint-isocc-icon {
    width: 1.7569546120058566vw;
    height: 1.7569546120058566vw;
    margin-right: 1em;
  }

  .tint-isocc-content {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    .tint-isocc-title {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 1.171303074670571vw;
        font-weight: 800;
        color: $defaultHighLightColor;
      }
    }

    .tint-isocc-info-header {
      padding-top: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid $defaultHighLightColor;
    }

    .tint-isocc-info-content {
      display: flex;
      flex-direction: column;
      margin-top: 1em;
    }
  }
}
