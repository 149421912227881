@import "../../../variables.scss";

.wrk-comp-f-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;

  .wrk-comp-f-header {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
  }

  .wrk-comp-f-content {
    padding-left: 3em;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;

    .saved-doc-holder {
      margin: 2em 2em;
    }

    .wrk-comp-f-section-divider {
      padding-bottom: 2em;
      margin-bottom: 2em;
      border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

      .wrk-comp-f-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0.5em;

        .section-title-txt {
          span {
            color: $defaultHighLightColor;
          }
        }

        .wrk-comp-f-input-holder {
          width: 11.895833333333334vw;
          margin-right: 2.5em;
 
          .cmfs-margin-top {
            margin-top: 0.54em !important;
          }
          .css-kiuwwn-control, .css-7nhqpr-control{
            min-height: 0em;
          }
        }
        .css-c0tn0x-control {
          border: 1px solid $inputoutlineColor !important;
          .css-tlfecz-indicatorContainer {
            color: $inputBorderColor;
          }
        }
      }
      .flx-start {
        margin-bottom: 0;
      }
    }
    .wrk-comp-f-section-divider:last-child {
      border-bottom: none;
      padding-bottom: 1em;
      margin-bottom: 1em;
    }
    .error-section {
      margin-bottom: 2em;
    }
  }

  .wrk-comp-f-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 3em;
    .btn-cancel {
      margin-right: 1em;
    }
  }
}

.wrk-comp-f-link-icon {
  width: 0.833vw;
  height: 0.833vw;
}

.info-txt {
  font-size: 1.0416666666666667vw !important;
}

.name {
  font-weight: normal !important;
  font-size: 1.0416666666666667vw !important;
}

.wrk-lock-icon {
    width:  0.78125vw;
    height: 0.8854166666666666vw;
    margin-bottom: 0.4em;
    margin-right: 0.5em;
}
