.pr-errors-report-container {
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .pr-er-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right-col {
            display: flex;
            align-items: center;

            .ml {
                margin-left: 1rem !important;
            }
        }

    }

    .pr-er-content {
        .pr-er-table {
            margin-top: 1rem;
        }

        .na_div {
            width: 100%;
            font-size: 1vw;
        }
    }
}