@import '../../../../../variables.scss';


.fc-container{
    display: flex;
    flex-direction:column;
    width:100%;
    padding:1rem;
    .fc-header{
        display: flex;
        justify-content: flex-end;
        width:100%;
    }
    .fc-content{
        margin:0rem 0 2rem 0;
        .fc-input-holder-row{
            display:flex;
            justify-content: space-between;
            flex-direction: row;
            width:100%;
            margin-bottom:0.6rem;
            .fc-content-input-holder{
                width:100%;
                span{
                    text-transform: none;
                }
            }
        }
        .toggle-row{
            margin-top:1.5rem;
        }
        .fc-input-holder-row:first-child{
            .fc-content-input-holder{
                width:32%;
            }
        }
    }
    .fc-actions{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding-top:1.5rem;
        .fc-actions-btn {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
        .btn-cancel{
            margin-right: 1rem;
        }
    }
}