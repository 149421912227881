@import "../../../../../variables.scss";

.et-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .header-container {
        display: flex;
        justify-content: space-between;

        .table-header {
            display: flex;
            align-items: center;
            padding: 1em 0 0.2em 0;

            span {
                font-size: 0.937vw;
                color: $primaryColor;
                font-weight: 800;
                margin-bottom: 0.2em;
                text-transform: none;
            }
        }
    }

    .et-table {
        border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
        border-bottom: none;
        margin-bottom: 1.5rem;
    }

    .et-left {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .dt-drdn {
            margin-left: 1em;
        }

        .error-holder {
            width: 15vw;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-left: 1em;
            margin-bottom: 0;
        }
    }

    .et-right {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .total-count {
            margin-left: 1.5em;

            span {
                width: max-content;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                color: $defaultHighLightColor;

                .total-count-number {
                    font-weight: bold;
                }
            }
        }

        .dt-drdn {
            margin-left: 1em;
        }
    }

    .total-count {
        margin-left: 1.5rem;
        color: $primaryColor;
    }

    table {
        width: 100%;

        thead {
            th {
                background-color: $tableHeaderBackgroundColor;
                padding: 0.5rem;
                color: $primaryColor;
                padding: 1em;
                min-width: 40px;
                transition: 0.3s all ease-in-out;
                cursor: pointer;

                &:hover {
                    background-color: rgba($color: $defaultHighLightColor, $alpha: 0.05);

                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($color: $tableRowBorderColor, $alpha: 0.25);
            }

            td {
                padding: 1em;
                text-decoration: unset;
                color: $tableRowTextColor;
                justify-content: flex-start;
                flex-direction: row;
                flex-wrap: nowrap;
                min-width: 40px;
                width: 25%;

                span {
                    text-align: left;
                    width: fit-content;
                    text-overflow: ellipsis;
                    text-transform: none;
                }
            }

            .total-row {
                background-color: $tableHeaderBackgroundColor;
                border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                border-left: 4px solid $primaryColor;

                td {
                    color: $primaryColor;
                    font-size: 0.85vw;
                }

                font-weight: 750;

            }
        }
    }
}