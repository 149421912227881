@import "../../../../variables.scss";

.plan-campaign {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2em;
  //box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);

  .header {
    font-size: 1.302vw;
    color: #0b3e78;
    font-weight: 800;
  }

  .call-goal {
    span {
      text-transform: none;
    }

    // &.budget {
    //   max-width: 200px;
    // }
  }

  .form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 3.2em;
    margin-bottom: 1em;

    .cmfs-container {
      margin-top: 25px;

      .cmfs-label span {
        font-size: 0.85vw;
        text-transform: none;
        font-weight: 700;
      }

      .form-select {
        height: 50px;
        width: 600px;
      }
    }

    .label {
      margin-top: 25px;
      font-size: 0.85vw;
      color: #0b3e78;
      font-weight: 700;
    }

    .sub-label {
      font-size: 0.833vw;
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 10px;
      color: #0b3e78;
    }

    .calendar {
      margin-top: 10px;
      height: 45px;
      text-transform: none;
    }

    .schedule-container {
      display: flex;
      justify-content: space-between;

      .time-picker-container {
        .MuiInputBase-root input {
          min-width: 10vw;
        }

        .MuiInputBase-input {
          margin-left: 0 !important;
          margin-top: -0.45em;
        }
      }
    }

    .contact-methods {
      margin-top: 30px;

      .contact-icon {
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100px;
        width: 200px;
        margin-right: 25px;
        cursor: pointer;

        .social-media-options svg {
          margin-bottom: 5px;
        }

        &.selected {
          box-shadow: 0px 0px 2px 0px rgba($color: $buttonColor17, $alpha: 1);
          position: relative;

          .check-circle-outline {
            position: absolute;
            right: 10px;
            top: 10px;

            &.small {
              .MuiSvgIcon-root {
                height: 0.833vw;
                width: 0.833vw;
              }
            }
          }
        }

        &.small {
          height: 80px;
          width: 80px;
        }

        &:hover {
          box-shadow: 0px 0px 2px 0px rgba($color: $buttonColor17, $alpha: 1);
        }

        .contact-label {
          font-size: 0.729vw;
          font-weight: bold;
          color: #0b3e78;
          margin-top: 0.5em;
        }
      }

      .error-message {
        font-size: 0.75rem;
        text-align: left;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        letter-spacing: 0.03333em;
        margin: 0.4em 1em 0 1em;
        color: red;
      }

      .contact-icon-small {
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 80px;
        width: 90px;
        margin-right: 25px;

        &:hover {
          box-shadow: 0px 0px 2px 0px rgba($color: $buttonColor17, $alpha: 1);
        }
      }
    }

    .flex-row {
      display: flex;
    }
  }

  .footer {
    display: flex;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 3em;
    // margin-top: 15px;

    .btn-cancel {
      margin-left: auto;
      margin-right: 15px;
    }

    .btn-text {
      span {
        text-transform: none;
      }
    }
  }
}