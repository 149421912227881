@import '../../variables.scss';


.mlt-usrs-section{
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    padding-top: 1em;
    padding-bottom: 1em;

    .mlt-usrs-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .mlt-usrs-header-title-txt{
            span {
                color: $defaultHighLightColor;
                font-size: 0.833vw;
            }
        }
    }

    .mlt-usrs-content {
        display: flex;
        flex-direction: column;

        .custom-pab{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0.5em 2em 0.5em 1.5em;
            border-radius: 4px;
            width: fit-content;
            margin-top: 1em;
            background-color: $tableHeaderBackgroundColor;
            border-left: 4px solid $defaultHighLightColor;
            box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
            .pab-title{
                margin-right: 1em;
                span{
                    font-size: 0.729vw;
                    color: $defaultHighLightColor;
                    font-weight: 800;
                }
            }
            .delete-icon{
                width: 15px;
                height: 15px;
                // margin-top: 15px;
                float: right;
                path {
                    fill: $buttonColor14;
                }
            }


        }
    }

}