@import '../../../../../../variables.scss';

.cd-cs-dept-container {
    display: flex;
    flex-direction: column;
    position: relative;
}



.cd-cs-covv-dept-section {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    padding: 1em;
    position: relative;

    .cd-cs-covv-dept-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .cd-cs-covv-dept-header-title-txt {
            position: relative;
            span {
                color: $defaultHighLightColor;
                font-size: 0.833vw;
            }
        }
    }

    .cd-cs-covv-dept-content {
        display: flex;
        flex-direction: column;
        position: relative;

        .dept-section {
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            // align-items: center;
            border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
            padding: 1em;
            position: relative;

            .main-dept {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                position: relative;

                .dept-add-icon {
                    margin-right: 1em;
                    cursor: pointer;
                }
            }


            .child-dept-section {
                display: flex;
                flex-direction: column;
                position: relative;


                .inner-child-dept-section {
                    display: flex;
                    flex-direction: column;
                    margin-left: 3em;
                    position: relative;
                }

                .child-dept {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    margin: 0em 0.5em 0em 3em;
                    position: relative;
                }
            }


        }


    }

}

.dept-add-icon {
    margin-right: 1em;
}


.dept-custom-chip {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0.5em 1em;
    border-radius: 20px;
    width: fit-content;
    margin-right: 1em;
    border: 1px solid $defaultHighLightColor;
    min-width: 120px;
    justify-content: center;

    .dept-chip-title {
        margin-right: 1em;

        span {
            font-size: 0.729vw;
            color: $defaultHighLightColor;
            font-weight: 800;
        }
    }

    .dept-chip-action {
        width: 0.625vw;
        height: 0.625vw;
    }

}

.child-dept-connector-line{
    // position: absolute;
    width: 4px;
    height: 14px;
    background-color: $defaultHighLightColor;
    margin-left: 7.5em;
    // top: 0;
    // bottom: 0;
    // z-index: 0;
    // left: 8em;
}