@import "../../variables.scss";

/// Form inputs
.pdf-form-input {
    // color: $inputBorderColor;
    // border-radius: 3px;
    padding: 0.5em;
    border-bottom: 0.3px solid $black;
    font-size: 0.729vw;
    min-width: 9.895833333333334vw;

    &:focus-visible {
        outline: none;
    }
}

.pdf-form-input-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 1.8301610541727673vw;
    // color: $inputBorderColor;
    border-radius: 3px;
    border: 0.3px solid $inputoutlineColor;
    font-size: 0.729vw;
    background: #fff;

    .prefix-holder,
    .suffix-holder {
        font-size: 0.729vw !important;
    }

    .prefix-holder {
        margin-right: 0.5em;
        margin-left: 0.5em;
    }

    .suffix-holder {
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .form-input-tag {
        // min-height: 1.8301610541727673vw;
        // color: $inputBorderColor;
        border-radius: 0;
        padding: 0;
        border: none;
        font-size: 0.729vw;
        width: -webkit-fill-available;
        min-width: 9.895833333333334vw;

        &:focus-visible {
            outline: unset;
        }
    }
}

.input-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
