@import '../../variables.scss';

.lead-container {
    /*.tds-info-container {
        margin-bottom: 0;
    }*/
    .tds-ds-branch-selector {
        display: flex;
        flex-direction: column;
    
        .tds-ds-status {
            margin-top: 1em;
            // margin-bottom: 1em;
            span {
                font-weight: 900;
                text-transform: capitalize;
                color: $defaultHighLightColor; 
            }
            .status-txt {
                color: $secondaryColor;
                margin-left: 0.5em;
            }
        }
    }
}
