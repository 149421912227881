@import "../../../../variables.scss";

.ac-sales-profile-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  border-radius: 3px;
  background-color: #f7fbfd;

  .ac-sales-profile-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
    width: -webkit-fill-available;
    position: relative;
  }

  .ac-sales-profile-content {
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;
    position: relative;
    display: flex;
    justify-content: space-between;
    justify-items: stretch;
    align-items: stretch;
    width: 100%;
    margin-top: 2em;

    .ac-sc-row1 {
      width: 100%;
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .ac-sc-field-two {
        .MuiInputBase-input {
          min-width: 10vw;
        }
      }
    }

    .ac-sc-row-fields{
      .ac-sc-fields {
        // margin-bottom: 2.5em;
      }
    }

    .switch {
      .slider:after {
        content: 'NO' !important;
      }
  
      input:checked+.slider:after {
        content: 'YES' !important;
      }
    }
    .present-div{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top:0.8rem;
      .MuiInputBase-input{
        min-width:7vw !important;
      }
      .switch-holder-col-top{
        margin-right:1rem;
        width:50%;
      }
    }
  }
}

.ac-sales-profile-headerText {
  font-family: Lato;
  font-size: 1.197vw;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b3e78;
}

.Line-68 {
  height: 0;
  border: solid 0.3px #035090;
  margin-left: 2em;
  margin-right: 2em;
}

.ac-sp-field-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
}

.ac-sc-fields,
.ac-sc-chip-field {
  flex-wrap: nowrap;
  width: 100% !important;
  flex: 0 32%;
  margin-right: 1em;

  span {
    text-transform: none;
    word-break: break-word !important;
  }

  .switch {
    .slider:after {
      content: 'NO' !important;
    }

    input:checked+.slider:after {
      content: 'YES' !important;
    }
  }

  // margin-bottom: 2.5em;
}

.ac-sc-fields-row2 {
  display: flex;
  justify-content: flex-start;
  margin-left: 3rem;

  .ac-sc-field-one {
    margin-right: 2rem;
  }

  .ac-sc-field-two {
    margin-left: 3rem
  }

  span {
    text-transform: none;
    word-break: break-word !important;
  }

  .switch {
    .slider:after {
      content: 'NO' !important;
    }

    input:checked+.slider:after {
      content: 'YES' !important;
    }
  }

  margin-bottom: 2em;
}

.row3,
.row1 {
  align-items: end;
}

.row2 {
  align-items: baseline;
}

.ac-sales-profile-label-text {
  font-size: 0.729vw;
  color: $primaryColor;
  display: block;
  padding-bottom: 10px;
  font-weight: bold;
}

.add-role-action-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .add-role-action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1em;

    .add-ac-btn {
      color: $white;
      padding: 0.5em 3em;
      border-radius: 5px;
      box-shadow: 0px 1px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 0.1);
    }

    .btn-yes {
      width: fit-content;
    }

    .btn-no {
      width: fit-content;
      margin-right: 1em;
    }
  }
}

.table-loading {
  width: 100%;
  height: 200px;
}

.chip {
  border-radius: 2.8px;
  border: solid 0.3px #b8b8b8;
  background-color: var(--white);
  color: #074780;
  display: inline;
}

@media (max-width: 1440px) {
  .ac-sc-row1 {
    .ac-sc-field-two {
      .MuiInputBase-input {
        min-width: 9vw !important;
      }
    }
  }
  .ac-sc-row-fields{
    .ac-sc-fields {
      // margin-bottom: 2.5em !important;
    }
  }
}