@import '../../../../variables.scss';

.input-flx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
    margin-left: 10px;
    margin-top: 20px;

    .input-margin {
        margin-left: 30px;
    }
}


.btn-cancel {
    margin-left: 15px;
}