@import "../../../../variables.scss";

.applications-received-ud {
  .appli-received-header {
    display: flex;
    justify-content: center;
  }

  .total-count {
    span {
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.86;
      letter-spacing: normal;
      color: $defaultHighLightColor;

      .total-count-number {
        font-weight: bold;
      }
    }
  }

  .apps-ud-actions {
    margin-left: auto;
    display: flex;
    align-items: center;

    .apps-ud-search {
      margin-right: 1em;
      display: flex;
      align-self: center;

      .sb-container {
        width: 15vw !important;
      }
    }
  }
}

.import-appl-received {
  .import-appl-received-content {
    font-size: 1.041vw;
    color: #0B3E78;
    text-transform: none;
    margin-left: 1.5em;

    .import-appl-received-success-message {
      padding: 0 1em 1em 1rem;

      span:first-child {
        font-size: 1vw;
        text-transform: none;
      }

      span:nth-child(2) {
        font-size: 1vw;
        font-weight: normal;
      }
    }

    .radio-group-div {
      margin-left: 2rem;

      .MuiSvgIcon-root {
        height: 1vw;
        width: 1vw;
      }

      .MuiFormControlLabel-label {
        font-size: 0.9vw;
      }

    }

    .error-holder {
      margin-left: 1.7rem;
    }
  }
}

.archive-appl-received {
  .archive-appl-received-content {
    margin-left: 3rem;
    margin-bottom: 1em;

    span {
      font-size: 1.041vw;
      color: #0B3E78;
      text-transform: none;
    }

    .doc-saved-holder .doc-saved-icon {
      width: 6.0078125vw;
      height: 6.3203125vw;
    }
  }
}