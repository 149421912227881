@import '../../../../variables.scss';


.tb-footer-pagination {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    span {
        color: $defaultHighLightColor;
        font-size: 1em;
        font-weight: 600;
        text-transform: none;
    }

    select {
        min-width: max-content;
        margin: 0 0.5rem;
        border: 0.3px solid #9AB8D1;
        color: #005A9A;
        border-radius: 3px;
        padding: 0.2em;
        border: 0.3px solid #9AB8D1;
        font-size: 0.729vw;
        // min-width: 9.8958333333vw;
    }
    .prev-next{
        margin-right: 1rem;
        .curPage{
            border: 0.3px solid #9AB8D1;
            padding:0rem 0.6rem;
            border-radius: 3px;
        }
        button{
            padding:0 0.4rem;
            text-decoration: underline;
            color: $defaultHighLightColor;
            font-weight: 800;
            margin:0 0.6rem;
        }
        button:disabled{
            color:grey

        }
    }
}