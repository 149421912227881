@import "../../variables.scss";

.tds-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: -webkit-fill-available;
}

.tds-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.2em;

  .tds-avatar-holder {
    width: 110px;
    height: 110px;

    border-radius: 110px;
    padding: 4px;
    border: 1px solid $primaryColor;

    .tds-avatar {
      background-image: url("../../../assets/icons/user.svg");
      border-radius: 100%;
    }
  }

  .tds-user-name {
    margin-top: 0.5em;

    span {
      font-size: 1.25vw;
      font-family: $defaultFontFamily;
      color: $userProfileNameColor;
      font-weight: 900;
    }
  }

  .tds-user-tag {
    span {
      font-size: 0.729vw;
      font-family: $defaultFontFamily;
      color: $tableRowTextColor;
      font-weight: 900;
    }
  }
}

.tds-activity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .last-engagement-txt {
    margin-bottom: 0.25em;
    span {
      font-weight: 800;
      font-size: 0.677vw;
      color: $tableRowTextColor;
    }
  }

  .assignment-status {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .icon {
      width: 0.833vw;
      height: 0.833vw;
    }

    .status {
      margin-left: 0.2em;

      span {
        font-size: 0.625vw;
        font-weight: 800;
      }
    }
  }
}

.tds-action-container {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // justify-content: flex-start;
  border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
  margin-bottom: 1em;
  padding-top: 1em;

  .tds-action {
    width: -webkit-fill-available;
    margin-bottom: 1em;

    .tds-action-icon {
      width: 1.25vw;
      height: 1.25vw;
    }
  }
  .send_message_to_talent {
    svg {
      g path:first-child {
        fill: white !important;
      }
      g path:nth-child(2) {
        fill: #ff4d00 !important;
      }
      g path:nth-child(3) {
        fill: #ff4d00 !important;
      }
    }
  }
}

.tds-branch-selector {
  display: flex;
  margin-bottom: 1em;
  padding-left: 3em;

  .tds-branch-left,
  .tds-branch-right {
    width: 50%;

    .tds-status {
      margin-top: 1em;
      display: flex;
      flex-direction: column;
      align-items: start;

      span {
        color: $userProfileNameColor;
        font-family: $defaultFontFamily;
        font-size: 0.833vw;
      }

      .tds-status-txt,
      .tds-id-txt {
        font-weight: 800;
        color: $userProfileNameColor;
        font-family: $defaultFontFamily;
        font-size: 0.729vw;
      }
      .tds-status-txt {

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .refresh_talent {
    padding: 3px !important;

    span {
      font-size: 0.7vw !important;
    }
    .err-h-add-icon {
      width: 1vw !important;
      height: 1vw !important;
    }

    svg {
      height: 1vw;
      width: 1vw;
    }
  }
  .no-creds-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .no-creds {
    color: $statusWarningColor;
    margin: 0.2rem 0rem 0.4rem 0;
    font-size: 0.7vw;
  }
  .add-creds-div {
    color: $defaultHighLightColor;
    font-weight: 800;
    text-decoration: underline;
    cursor: pointer;
  }
}
.tds-info-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
  margin-bottom: 1em;
  padding-top: 1em;
  padding-left: 1em;

  .tds-info-icon {
    width: 0.885vw;
    height: 0.885vw;
    margin-right: 1em;
    svg path {
      fill: $userProfileNameColor;
    }
  }

  .tds-info-content {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    .tds-info-holder {
      display: flex;
      flex-direction: column;

      .tds-into-title {
        span {
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          font-size: 0.833vw;
        }
      }

      .tds-info-value {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        span {
          font-weight: 800;
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          text-transform: none;
        }

        .email-info-value {
          font-weight: 800;
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          text-transform: none;
          &:hover {
            span {
              text-decoration: underline;
              cursor: pointer;
            }
            .na-span {
              text-decoration: none;
              cursor: auto;
            }
          }
        }

        .tds-info-icon {
          width: 1.666vw;
          height: 1.666vw;
          cursor: pointer;
          padding: 0.5em;
          transition: 0.3s all ease-in-out;

          &:hover {
            background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
          }
        }
      }
    }
  }
}

.tds-info-address-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1em;
  padding-left: 1em;

  .tds-info-icon {
    width: 0.833vw;
    height: 0.885vw;
    margin-right: 1em;

    svg path {
      fill: $userProfileNameColor;
    }
  }

  .tds-info-value {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    span {
      font-weight: 800;
      color: $userProfileNameColor;
      font-family: $defaultFontFamily;
      text-transform: none;
    }
  }
}

.available-status {
  position: absolute;
  top: -8px;
  left: -55px;
  transform: rotate(-45deg);
}

.phone-info-value {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;

  .mobile-text{
    font-weight: 400 !important;
  }
}