@import "../../../../../../variables.scss";

.db-container {
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    border: 1px solid $tableBorderColor;

    .note-span {
        color: $employeePortalEDCBgColor;
        font-size: 1vw;
        margin: 1rem 0rem;
        text-transform: none;
    }

    .db-content {
        border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-bottom: 1.5rem;
        .db-header-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 1rem;

            .title {
                width: fit-content;
            }

            .selection-div {
                width: fit-content;
                margin-left: 2rem;
            }
        }
        .db-title {
            font-size: 1vw;
            color: $primaryColor;
            font-weight: 700;
        }

        .db-input-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 0.8rem;

            .db-input-holder {
                width: 20%;
                margin-right: 1.5rem;

                .date-input-holder-div {
                    width: 100%;
                }
            }

            .ih-l {
                width: fit-content;
            }

            .sd {
                width: 42%;
            }
        }

        .col-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top:2rem;
            .payroll-tb-row,
            .wage-tb-row {
                width: 48%;
            }

            .db-input-holder {
                width: 48% !important;
            }
        }
    }

    .db-actions-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        padding-top: 1rem;
        margin-top: 0.5rem;

        .db-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .btn-cancel {
                margin-right: 1rem;
            }
        }

    }
}