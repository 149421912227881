@import '../../../../variables.scss';


.ac-sales-profile-container.job-details-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    border-radius: 3px;
    background-color: #f7fbfd;

    .ac-sales-profile-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-top: 1em;
        padding-left: 2em;
        padding-right: 2em;
        width: -webkit-fill-available;
        position: relative;

    }

    .ac-sales-profile-content {
        // overflow: auto;
        padding-left: 1em;
        padding-right: 0em;
        padding-bottom: 1em;
        margin-top: 1em;
        position: relative;

    }

    .job-details-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .col1 {
            width: 40% !important;

            p {
                margin-bottom: 0rem !important;
            }

            .job-ord-sp-field-row {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 1rem;
                margin-top: 0.53rem;

                .ac-sc-fields-job-row1 {
                    flex-wrap: nowrap;
                    width: 30%;
                    margin-right: 0.7em;

                    .input-form-control {
                        width: 7vw !important;
                    }
                }

                .ac-sc-fields-job-row2 {
                    flex-wrap: nowrap;
                    width: 30%;
                    margin-right: 0.7em;
                    margin-top: 0.5em;

                    .input-form-control {
                        width: 7vw !important;

                    }
                }

                .ac-sc-fields-job-row3 {
                    flex-wrap: nowrap;
                    width: 30%;
                    margin-right: 0.7em;
                    margin-top: 0.4em;

                    .input-form-control {
                        width: 7vw !important;

                        .form-input {
                            margin-top: 0.1em;
                        }
                    }

                    .css-t7ry41-control {
                        width: 7.35vw;
                        min-height: 1.627485vw;
                    }

                    .css-c6bn20-control {
                        max-width: 7.35vw;
                    }
                }
            }
        }

        .col2 {
            width: 20% !important;

            p {
                margin-bottom: 0.3rem !important;
            }
            .jod-section-holder{
                margin-bottom: 1rem;;
            }
            .job-ord-sp-field-row {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 0.6rem;

                .ac-sc-fields-job {
                    flex-wrap: nowrap;
                    width: 30%;
                    margin-right: 0.7em;

                    .input-form-control {
                        width: 7vw !important;
                    }
                }
            }

            .fee_structure {
                display: flex;
                flex-direction: column;
                .MuiFormGroup-root{
                    flex-direction: row;
                }

                // .radio-btn {
                //     // display: flex;
                //     // flex-direction: row;

                // }

                // .fc {
                //     margin-right: 2rem;
                // }

                .MuiSvgIcon-root {
                    height: 0.8vw;
                    width: 0.8vw;
                }

                .MuiFormControlLabel-label {
                    font-size: 0.629vw;
                    color: #0b3e79;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                .MuiRadio-root {
                    padding: 3px !important;
                }
                .radio-btn{
                    margin-right:0.5rem;
                }

                .radio-i {
                    display: flex;
                    align-items: center;


                    .form-input,
                    .form-input-holder .form-input-tag {
                        min-width: 3vw !important;
                        width: 3vw !important;
                    }
                }
                .fee_amnt{
                    width:86%;
                }

                .MuiRadio-root.Mui-disabled {
                    color: $statusDisabledColor;
                    /* Change this to the color you want for disabled state */
                    opacity: 0.3;
                    /* Adjust the opacity for a faded look */
                }

                .MuiFormControlLabel-root.Mui-disabled {
                    color: $statusDisabledColor;

                    /* Change label color when disabled */
                    .MuiFormControlLabel-label {
                        color: $statusDisabledColor;
                    }
                }
            }
        }

        .col3 {
            width: 40% !important;

            .assignment-summary {
                display: inline-block;
                padding: 0 0 0 1em;

                .schdule-sections-holder {
                    .work-schedule-table {
                        td {
                            border: 1px solid $tableBorderColor;
                            padding: 0.2rem;
                            vertical-align: middle;

                            .label {
                                margin-top: 0.5em;
                                color: $primaryColor;
                            }

                            svg {
                                margin-right: 1em;
                                margin-top: 1em;
                            }

                            .MuiFormControl-root {
                                max-width: 5vw;
                            }
                        }

                        .section-header {
                            font-weight: normal;
                            color: rgb(11, 62, 121);
                            font-family: Lato;
                            margin-bottom: 1em;
                        }

                        .switch-holder {
                            padding: 0 0 0 1em;
                        }

                        .MuiInputBase-input {
                            padding: 0 0 0 1em !important;
                            border: none !important;
                            margin-left: 0 !important;
                            cursor: pointer;
                        }

                        // .MuiInput-underline:before {
                        //     border-bottom: none;
                        // }

                        // .MuiInput-underline:after {
                        //     border-bottom: none;
                        // }
                    }
                }
            }
        }

        .job-ord-sp-field-row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 0.6rem;

            .ac-sc-fields-job {
                flex-wrap: nowrap;
                width: 30%;
                margin-right: 0.7em;

                .input-form-control {
                    width: 7vw !important;

                }

                .css-t7ry41-control {
                    width: 7vw;
                }

                .css-c6bn20-control {
                    max-width: 7vw;
                }
            }
        }

        .input-form-control {
            margin-top: -5px;
            width: 95%;

            .form-input {
                min-width: 7.35vw !important;
            }

            .ac-sc-fields-payroll {
                span {
                    margin-top: 0 !important;
                }
            }
        }
    }
}

.ac-sales-profile-headerText {
    font-family: Lato;
    font-size: 1.197vw;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b3e78;
}

.Line-68 {
    height: 0;
    border: solid 0.3px #035090;
    margin-left: 2em;
    margin-right: 2em;
}

.ac-sc-job-fields {
    flex-wrap: nowrap;
    width: 40%;
    margin-right: 0.7em;
}

.ac-sc-payroll-fields {
    flex-wrap: nowrap;
    // width: 45%;
    // margin-right: 1em;
}

.ac-sc-people-fields {
    flex-wrap: nowrap;
    width: 20%;
    margin-right: 1em;
}

.ac-sc-chip-field {
    flex-wrap: nowrap;
    width: 40%;
}



.MuiMenuItem-root {
    font-size: 0.8rem !important;
    color: $secondaryColor2 !important;
}

.MuiPaper-root {
    min-width: 8vw !important;

    .MuiList-padding {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.ac-sc-fields-job {
    flex-wrap: nowrap;
    width: 47%;
    margin-right: 0.7em;

    .date-input-holder-div {
        width: 7vw;

        .date-input .MuiSvgIcon-root {
            width: 1vw !important;
            height: 1vw !important;
        }

        .MuiIconButton-root {
            padding: 6px !important;
        }
    }
}

.form-input-control {
    .prefix-holder {
        span {
            font-size: 0.729vw !important;
            padding-top: 0em !important;
        }
    }

    span {
        margin-top: 0 !important;
        padding-top: 0.7em !important;
        font-size: 0.479vw !important;
        text-transform: uppercase !important;
    }
}

.ac-sc-fields-payroll {
    flex-wrap: nowrap;
    width: 5.22vw;
    margin-right: 1em;
}

.ac-sc-fields-payroll-bta-wcc {
    flex-wrap: nowrap;
    margin-right: 1em;
}

.ac-sc-fields-payroll-toggle {
    margin-top: 0.1rem;

    .toggle-label-text {
        text-transform: none;
    }
}

.datePickerStyle {
    margin-top: 0px;
    width: 100%;
}

.ac-sales-profile-label-text {
    font-size: 0.729vw;
    color: $primaryColor;
    display: block;
    padding-bottom: 10px;
    font-weight: bold;
}

.add-role-action-holder {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .add-role-action {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 1em;

        .add-ac-btn {
            color: $white;
            padding: 0.5em 3em;
            border-radius: 5px;
            box-shadow: 0px 1px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 0.1);
        }

        .btn-yes {
            width: fit-content;
        }

        .btn-no {
            width: fit-content;
            margin-right: 1em;
        }
    }
}

.table-loading {
    width: 100%;
    height: 200px;
}

.chip {
    border-radius: 2.8px;
    border: solid 0.3px #b8b8b8;
    background-color: var(--white);
    color: #074780;
    display: inline;
}

.datePickerStyle {
    margin-top: 8;
    width: 100%;
}

.ac-service-field-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.ac-service-field {
    flex-wrap: nowrap;
    width: 20%;
}

.dialog-footer {
    margin-top: 20px;
    padding-right: 15px;
    float: right;
}

.ac-service-text {
    color: #0b3e79;
    font-size: 0.729vw;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}

.ac-Field-text {
    font-size: 14;
    margin-bottom: 5;
    margin-top: 10;
    font-weight: 900;
    color: #0b3e79;
}


.ac-Field-text-header {
    font-weight: 900;
    color: #0b3e79;
    margin-bottom: -5px;
}

.dialog-footer {
    padding-right: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.field-label-text {
    max-width: 100%;
    box-sizing: border-box;
    word-break: unset;
    font-size: 0.677vw;
    font-family: Lato;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 900;
    color: rgb(11, 62, 121);
}

.formtext {
    color: #074783;
    width: 280px;
    height: 38px;

}

.form {
    background-color: #edf4fa;
    margin: 4px;
    margin-top: 1rem;
}

.form-groups {
    margin-top: 1rem;
    font-size: 80%;
}

.head-color {
    color: #074783;
    font-weight: bold;
    font-size: 1.197vw;
}

.icon-height {
    height: 0.8em !important;
}

/* .toggle-switch{
    margin-top: 2rem; 
    font-size: 80%; 
   } */

.toggle-text {
    margin-top: 1rem;
    color: #074783;
    font-size: medium;
}

.text-align {
    text-align: end;
    padding-top: 8px;
}

.searchWrapper {
    width: fit-content;
    min-width: 266px;
}


.ac-sytm-cns-content {
    .ac-sytm-cns-table {
        table {
            width: -webkit-fill-available;
            border: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);

            thead {
                background-color: $tableHeaderBackgroundColor;

                tr {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    .tb-header-cc {
                        display: flex;
                        flex: 1;
                        flex-shrink: 1;
                        flex-grow: 1;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        padding: 1em;
                        transition: 0.3s all ease-in-out;
                        cursor: pointer;
                        border-right: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);

                        &:hover {
                            background-color: rgba($color: $defaultHighLightColor, $alpha: 0.05);

                            .table-header-sort-icon {
                                display: block;
                            }
                        }

                        span {
                            text-overflow: ellipsis;
                            color: $primaryColor;
                            text-decoration: unset;
                        }

                        .table-header-sort-icon {
                            display: none;
                            font-size: 0.729vw;
                            transition: 0.3s all ease-in-out;
                            transform: rotate(0deg);
                            // color: $primaryColor;
                        }

                        .table-header-sort-icon-rotate {
                            transform: rotate(-180deg);
                            display: block;
                            transition: 0.3s all ease-in-out;
                        }

                    }
                }
            }

            tbody {
                tr {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    border-top: 1px solid rgba($color: $tableRowBorderColor, $alpha: 0.3);
                    cursor: unset;
                    transition: unset;
                    background-color: #ffffff;

                    &:hover {
                        background-color: unset;
                    }

                    td {
                        flex: 1;
                        padding: 1em;
                        text-decoration: unset;
                        color: $tableRowTextColor;
                        border-right: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }
            }

        }
    }
}


.jod-section-holder {
    display: flex;
    flex-direction: column;

    .jod-section-title {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.2rem;

        span {
            font-size: 0.7vw;
            font-weight: 900;
            color: $defaultHighLightColor,
        }
    }

    .jod-section-content {
        display: flex;
        flex-direction: column;
    }

}



@media only screen and (max-width: 1440px) {
    .ac-sc-fields-job-row3 {
        .input-form-control {
            .form-input {
                margin-top: -0.2em !important;
            }
        }
    }

    .job-details-content {
        .col1 {
            .job-ord-sp-field-row {
                margin-bottom: 1em !important;

                .ac-sc-fields-job-row1 {
                    margin-top: -0.2em !important;
                }

                .ac-sc-fields-job-row2 {
                    margin-top: 0.7em !important;
                }

                .ac-sc-fields-job-row3 {
                    margin-top: 1em !important;
                }
            }
        }
    }
}