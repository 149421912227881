@import '../../variables.scss';

.common-dialog-delete-container {
    width: 100%;
    height: 100% !important;
    color: $defaultHighLightColor;
    padding: 0.5em;

    .delete-dialog-header {
        display: flex;
        justify-content: space-between;
        padding: 1em 0.5em 0 1em;

        .header-content {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .del-icon {
                margin-right: 0.5em;
    
                .cls-1 {
                    fill: $red;
                }
            }
    
            .header-title {
                margin-bottom: 0.2em;
            }
    
            span {
                font-size: 1.041vw;
                font-weight: 900;
                color: $red;
            }
        }
    }

    .delete-dialog-content {

        padding: 0.5em 1em 1em 1rem;
        margin-left: 1.2vw;

        span {
            font-size: 0.8vw;
            font-weight: 400;
            color: $primaryColor;
            text-transform: none;
        }
    }

    .delete-dialog-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 1em 1em 0.5em 1em;
        border-top: 1px solid rgba(3, 80, 144, 0.1);
        .del-actions{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
        .btn-cancel {
            margin-right: 1em;
        }
    }
}