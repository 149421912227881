@import "../../../variables.scss";



.job-order-details-container {
    display: flex;
    flex-direction: column;
    position: relative;
   

    .job-order-details-content {
        display: flex;
        flex-direction: column;
        padding:0rem 2rem 2rem 2rem;
        border:1px solid #c1cdd4;
        margin:1rem 2rem;
        .job-order-desc-content {
            width: 100%;
            height: 100%;

            border: none;
            // padding: 0em 2em 2em 2em;
            color: $defaultHighLightColor;
            overflow: auto;

            .job-order-title-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .label {
                font-size: 16px;
                font-weight: 700;
                margin: 1.2rem 0 0.2rem 0;
                text-transform: uppercase;
            }

            .job-title {
                font-size: 22px;
                font-weight: 900;

            }

            .label-title {
                span {
                    font-size: 14px;
                }
                font-weight: 700;
                margin-right:20px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: baseline;
                svg {
                    width:14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }

            .comp-loc-div {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 12px;
                // justify-content: ;
            }

            .label-btn {
                // margin-top: 0.9rem;

                .custom-btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    button{
                        padding:0.8rem 1rem;
                        border-radius: 0;;
                    }
                    span{
                        font-size:14px !important;

                    }
                }
            }

            .description-span {
                white-space: pre-wrap;
                color: #333333;
                font-family: Lato, Helvetica, Arial, sans-serif;
                span {
                    font-size: 14px !important;
                }
            }

            .back-div {
                padding: 1rem 0rem;
            }
            .back_btn {
                text-transform: capitalize;
                box-shadow: none;
                color: $defaultHighLightColor;
                padding:3px 0px;
                span {
                    color: $defaultHighLightColor !important;
                    font-size: 14px !important;
                }
                .back-icon{
                    font-size: 16px;
                }
            }
        }
    }
}