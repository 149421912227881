@import '../../../variables.scss';

.job-orderlist-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    border:1px solid #c1cdd4;
    margin:1rem 2rem;
    .job-orderlist-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:0rem 2rem 2rem 2rem;
        width:100%;
        align-items: center;
        .total-count{
            span{
                color:$primaryColor;
                font-size:0.85rem;
                padding: 0.4rem;;
            }
        }
        // .jobs-at-title{
        //     font-size:22px;
        //     color:$defaultHighLightColor;
        //     font-weight:700;
        //     margin-bottom: 10px;
        // }
        .sb-container {
            // padding:8px 12px !important;
            width:100% !important;
            .form-input-div{
                padding:0.3rem 0.5rem !important;
            }
            input{
                font-size:0.85rem;
                min-width:20vw !important;
            }
            button{
                min-height:2.3vw !important;
            }

        }
    }

    .job-orderlist-content {
        display: flex;
        flex-direction: column;
        .sortable-table{
            span{
                font-size:0.85rem;
                padding:0.3rem;
            }
        }
        .no-match{
            span{
                font-size:16px;
                font-weight:600;
                // color:#00a651;
            }
        }
        .job-card {
            background-color: white;
            box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .2);
            margin-bottom: 1rem;
            padding: 10px 20px;
            border-bottom: 1px solid #DDDDDD;
            font-family: Helvetica, Arial, sans-serif;
        }

        .job-card-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom:0.5rem;

            .job-title {
                color: $defaultHighLightColor;
                font-size: 16px;
                font-weight: 800;
                cursor:pointer;
            }

            .posted {
                font-size: 12px;
                color: #aaa;
                // font-style: italic;
            }

            .short-desc {
                text-overflow: ellipsis;
                font-size: 14px;

            }

            .label-title {
                font-weight: 700;
                margin-right: 1vw;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                font-size:14px;
                align-items: baseline;
                svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 0.3em;
                    // color: #00a651;
                    // fill:#00a651;
                    // .cls-1{
                    //     color: #00a651;
                    //     fill:#00a651;
                    // }
                }
                span{
                font-size:13px;
                color:$defaultHighLightColor;

                }
            }
            .comp-loc-div {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                // margin-top: 0.4rem;
                // justify-content: ;
            }
        }
    }

}