@import "../../../../variables.scss";
.my-call-deck {
  box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
  .filters-container {
    // display: flex;
    padding: 15px;
    // justify-content: space-between;
    .actions {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      color: "#0B3E78";
      .cs-search-btn {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .cs-search {
          margin-top: 1.8rem;
          margin-right: 1rem;
        }
      }
      .table-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        .total-count-action {
          margin-left: 10px;
          span {
            width: max-content;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.2;
            letter-spacing: normal;
            color: $defaultHighLightColor;
            .total-count-number {
              font-weight: bold;
            }
          }
        }
      }
    }
    .custom-button {
      width: auto !important;
    }

    .cmfs-container {
      // width: 180px;
      margin-right: 20px;
     
    }
    .active-checkbox {
      display: flex;
      align-items: center;
      // margin-left: 20px;

      span {
        margin-left: 5px;
        font-weight: bold;
        color:$defaultHighLightColor;
      }

    }
  }
  .cs-table {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 48vh;
        }
      }
  
      @media screen and (max-width:1650px) {
        .sortable-table {
          tbody {
            display: block;
            overflow-y: auto;
            max-height: 43vh;
          }
        }
      }
  
      @media screen and (max-width:1500px) {
        .sortable-table {
          tbody {
            display: block;
            overflow-y: auto;
            max-height: 43vh !important;
          }
        }
      }
  
      @media screen and (max-width:1280px) {
        .sortable-table {
          tbody {
            display: block;
            overflow-y: auto;
            max-height: 46vh !important;
          }
        }
      }

      .parent-position {
        position: relative;

        .child-position {
          position: absolute;
          left: -3rem;
          top: 1rem;

          .action-icon {
            text-transform: capitalize;
            font-size: 0.700vw;
            color: $primaryColor;
            padding: 0.5em;
            border-radius: 0.5em;
            border: 1px solid #1198AB;
            max-width: 20vw;
            width: max-content;
            background-color: #ffffff;
            font-weight: 600;
          }
        }
      }
  }

  .cs-table-footer {
    position: relative;
  }
}
