@import '../../../../variables.scss';

.board-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    width: -webkit-fill-available;

    .header-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;

        .board-icon {
            width: 1.562vw;
            height: 1.25vw;
            margin-right: 1em;
        }

        .borad-title {
            span {
                font-size: 1.041vw;
                font-weight: bold;
            }
        }
    }
}