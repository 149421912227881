@import '../../variables.scss';

.outline-container {
    position: relative;
    margin-bottom: 20px;
    color: $primaryColor;

    .outline-border {
        border-radius: 13px;
        border: solid 1px #ccd3e1;
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
        padding: 20px;

        input {
            border: 0;
            outline: 0;
            // width: -webkit-fill-available;
            width: 20vw;
            color: $primaryColor;
            font-size: 0.8333333333333334vw
        }
    }

    .outline-label {
        margin: 0 0 0 15px;
        background-color: #ffffff;
        position: absolute;
        top: 0;
        transform: translateY(-50%);

        label {
            margin: 0 5px 0 5px;
            font-size: 0.7291666666666666vw;
        }

    }
}