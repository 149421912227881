@import '../../../../variables.scss';

.dshb-mrk-tlnt-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;
    // max-width: 50%;

    .dshb-mrk-tlnt-f-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .dshb-mrk-tlnt-f-content {
        padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .dshb-mrk-tlnt-f-holder{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .title-txt{
                margin-bottom: 0.5em;
                span{
                    font-size: 0.8721611721611722vw;
                    font-weight: normal;
                    color: $defaultHighLightColor;
                }
            }

            .table-holder{
                display: flex;
                flex-direction: column;
                width: -webkit-fill-available;
                margin-bottom: 1.5em;
            }
        }

        .dshb-mrk-tlnt-f-section-divider {
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

            .dshb-mrk-tlnt-f-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .dshb-mrk-tlnt-f-input-holder {
                    min-width: 9.895833333333334vw;
                    margin-right: 1em;
                }
            }
        }
        .tint-ihi-questions-section {
            display: flex;
            flex-direction: column;
        
            .tint-ihi-question-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2em;
        
                .tint-ihi-question-title {
                    // margin-bottom: 0.5em;
        
                    span {
                        font-size: 0.833vw;
                        color: $defaultHighLightColor;
                        font-weight: bold;
                    }
                }
        
                .tint-ihi-input-form {
                    display: flex;
                    flex-direction: column;
                    // margin-left: 1em;
                }
            }
        }

        .error-section{
            margin-bottom: 2em;
        }
    }

    .dshb-mrk-tlnt-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .btn-cancel {
            margin-right: 1em;
        }
    }

}

.dshb-mrk-tlnt-f-link-icon{
    width: 0.833vw;
    height: 0.833vw;
}




