/* Provide sufficient contrast against white background */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;900&display=swap');
@import './variables.scss';


body {
  font-size: 0.729vw;
  /* font-size: 0.677vw; */
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table,
thead,
tbody,
tr,
td,
div,
p,
label,
span,
a,
select,
input {
  font-size: 0.729vw;
  font-family: $defaultFontFamily;
  /* font-size: 0.677vw; */
}

.bg-light-blue {
  background-color: #f7fbfd;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #074783;
  /* border-color: #1861ac; */
  border: none;
  padding: 5px 8px;
  border-radius: 4px;
}

.btn-secondary {
  color: #fff;
  background-color: #015EAA;
  /* border-color: #1861ac; */
  border: none;
  padding: 5px 8px;
  border-radius: 4px;
}

.btn-third {
  color: #fff;
  background-color: #0079C1;
  border: none;
  padding: 5px 8px;
  border-radius: 4px;
}

.btn-grey {
  color: #fff;
  border: none;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: #bdc2cc
}

.text-success {
  color: #00c655 !important;
}

.font-weight-bold {
  font-weight: 800;
}

.fwb-1 {
  font-weight: 600 !important;
}

.fwb-2 {
  font-weight: 700 !important;
}

.fwb-2 {
  font-weight: 800 !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 5px !important;
}

.ml-2 {
  margin-left: 10px !important;
}

.ml-3 {
  margin-left: 15px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 5px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.mr-3 {
  margin-right: 15px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 5px !important;
}

.mt-2 {
  margin-top: 10px !important;
}

.mt-3 {
  margin-top: 15px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 5px !important;
}

.pl-2 {
  padding-left: 10px !important;
}

.pl-3 {
  padding-left: 15px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 5px !important;
}

.pr-2 {
  padding-right: 10px !important;
}

.pr-3 {
  padding-right: 15px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 5px !important;
}

.pt-2 {
  padding-top: 10px !important;
}

.pt-3 {
  padding-top: 15px !important;
}

.tr {
  text-align: right !important;
}

.tl {
  text-align: left !important;
}

.tc {
  text-align: center !important;
}

.fs-xs {
  font-size: 8px;
}

.fs-sm {
  font-size: 10px;
}

.fs-md {
  font-size: 0.625vw;
}

.fs-lg {
  font-size: 0.833vw;
}

.fs-xl {
  font-size: 0.937vw;
}

.fs-xxl {
  font-size: 1.041vw;
}

.subheading {
  font-size: 1em;
  font-weight: 800;
  color: #074783;
}

.font-light {
  font-weight: 400 !important;
}

.subhead-bg {
  background-color: #f2f6fa;
  border-bottom: solid 1px #2779c7;
}

.table-head {
  /* background-color: #f2f6fa ; */
  background-color: #f0f7fa;
}

label {
  font-weight: 600;
  margin: 5px 0;
}

.text-color {
  color: #074783;
}

.bold-font {
  color: #074783;
  font-weight: bold
}

.button-width {
  width: -webkit-fill-available;
}

.active-assignment-button {
  background-color: #0ba590eb;
}

.job-orders-color {
  background-color: #997ea7;
}

.sales-call-color {
  background-color: #59b2e7;
}

.left-align {
  text-align: left;
}

.css-1r4vtzz,
.css-48ayfv {
  padding: 4px 8px;
  box-shadow: none !important;
  border-radius: 5px !important;
  border: solid 1px lightgray !important;
}

.css-1r4vtzz:focus,
.css-1r4vtzz:visited {
  padding: 4px 8px;
  border-color: #86b7fe;
  outline: 0;
  border: solid 1px lightgray !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.left-nav-border {
  border-right: 1px solid #dddee1;
}

.MuiBadge-colorSecondary {
  color: #fff !important;
  background-color: #47b533eb !important;
  border: solid 1px #1d7f0b !important;
}

.searchbox-style {
  max-width: 1000 !important;
  box-shadow: none !important;
  border: solid 1px lightgray !important;
  height: 32px !important;
  margin: 10px !important;
  font-style: italic;
}

.form-control,
.form-select {
  border: 1px solid #cfdbeb;
  text-transform: capitalize;
  min-height: 1.827485380116959vw;
  padding: 0.5em;
  min-width: 9.895833333333334vw;
  color: #005a9a !important;
  font-size: 0.729vw;
  border-radius: 3px;
}

.form-check-input:checked {
  background-color: #074783;
  border-color: #074783;
}

.modal-content {
  border-radius: 15px !important;
  border: none !important;
}



.card_background_color {
  /* background-color:#edf6fb; */
  background-color: #f8fcfd;
}

.right_alignment {
  text-align: end !important;
}

.card-icon-height {
  height: 1.5em;
}

.icon-height {
  height: 0.8em !important;
}

.big-icon-height {
  height: 2.0em;
}

.link-btn {
  color: #009ad7 !important;
  font-weight: 600 !important;
}

.tableList img {
  width: 23px;
}

.tableList td,
.tableList td a {
  color: #009ad7 !important;
  font-weight: 600 !important;
}

.dashboard_icon_width {
  width: 100px;
}

.background-img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

span {
  white-space: pre-line;
  text-overflow: ellipsis;
  font-family: $defaultFontFamily;
  word-break: break-word;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: capitalize;
  display: inline-block;
  overflow-wrap: break-word;
}

/// Icon buttons
.icon-btn {
  background: none;
  border: none;
}

.MuiOutlinedInput-notchedOutline {
  border: 0.3px solid $inputoutlineColor !important;
}

.required {
  .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
  }
}

/// Form inputs
.form-input {
  color: $inputBorderColor;
  border-radius: 3px;
  padding: 0.5em;
  border: 0.3px solid $inputoutlineColor;
  font-size: 0.729vw;
  min-width: 9.895833333333334vw;
}

.form-input-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 1.8301610541727673vw;
  color: $inputBorderColor;
  border-radius: 3px;
  border: 0.3px solid $inputoutlineColor;
  font-size: 0.729vw;
  background: #fff;

  .prefix-holder,
  .suffix-holder {
    font-size: 0.729vw !important;
  }

  .prefix-holder {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  .suffix-holder {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }



  .form-input-tag {
    // min-height: 1.8301610541727673vw;
    color: $inputBorderColor;
    border-radius: 0;
    padding: 0;
    border: none;
    font-size: 0.729vw;
    width: -webkit-fill-available;
    min-width: 9.895833333333334vw;

    &:focus-visible {
      outline: unset;
    }
  }

}



select {
  min-width: 9.895833333333334vw;
  font-size: 0.729vw;
}

input,
textarea {
  border: none;
  font-size: 0.729vw;
  min-height: 1.827485380116959vw;

  &:focus-visible {
    outline: $secondaryColor1 solid 1px;
  }
}

input[type="checkbox"] {
  min-height: auto !important;
  margin-top: 0.2em !important;
  width: 1.15em;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $defaultHighLightColor;
  opacity: 0.5;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $defaultHighLightColor;
  opacity: 0.5;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $defaultHighLightColor;
  opacity: 0.5;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $defaultHighLightColor;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $defaultHighLightColor;
  opacity: 0.5;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: $defaultHighLightColor;
  opacity: 0.5;
}

.custom-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px 3px 12px;
  border: 2px solid transparent;
  border-radius: 3px;
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);
  flex-wrap: nowrap;
  box-sizing: border-box;

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.custom-button-disabled {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 20%);
}

.custom-button-enabled {
  background-color: $primaryColor;
}


.menu-option-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  padding: 2em;
  box-sizing: border-box;
  width: -webkit-fill-available;
  height: 79vh;
  overflow-x: hidden;

  .note-card-container {
    position: absolute;
    bottom: 0;
    right: -80px;

    .card {
      .icon {
        margin-top: 1em;
      }
    }

    .icon-btn {
      span {
        text-transform: none;
      }
    }
  }

}

/// Button

button {
  all: unset;
}

.btn-icon {
  width: 1.145vw;
  height: 1.145vw;
  margin-bottom: 1.5px;
  margin-right: 0.3em;
}


.btn-cancel {
  .custom-button {
    background-color: rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 20%);
  }
}

.btn-new {
  margin-right: 1em;
}

.btn-no {
  .custom-button {
    background-color: rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 20%);
  }

  margin: 0;
  padding: 0;
}


/// [Date Picker]
/// 
.MuiIconButton-label,
.MuiInputBase-input {
  color: #005a9a !important;
  font-size: 0.729vw !important;
}

.MuiSvgIcon-fontSizeInherit {
  font-size: inherit;
}

// .MuiSvgIcon-root {
//   font-size: 1.25vw !important;
// }

.MuiOutlinedInput-root {
  border-radius: 3px;
}

.MuiOutlinedInput-input {
  // padding: 0.9635416666666666vw 0.7291666666666666vw !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0.7291666666666666vw !important;
}

.MuiOutlinedInput-inputMarginDense {
  // padding-top: 0.246875vw !important;
  padding-bottom: 0.346875vw !important;
  min-height: 1.002923976608187vw !important;
}

.MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 0px !important;
}

.MuiInputAdornment-positionEnd {
  margin-left: 0px !important;
}

/// [Table access icon]

.table-access-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: -webkit-fill-available;

  .table-access-icon {
    width: 1.7vw;
    height: 1.7vw;
    cursor: pointer;
  }
}

.logo-access {
  width: 2vw;
  height: 2vw;
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  .logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures no empty space */
    border-radius: 50%;
    /* Optional: Makes it circular */
  }
}




// .col-logo-container {
//   width: 2vw;
//   height: 2vw;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50%;
//   overflow: hidden;
//   cursor: pointer;

//   img{
//     width:100%;
//     height: 100%;
//     object-fit: cover;
//   }
// }
.flx-start {
  justify-content: flex-start;
}


/// [Adress parser scss]

.address-parser {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .first-line {
    display: flex;
  }

  .second-line {
    display: flex;
    flex-direction: row;

  }
}

.na-span {
  color: $defaultHighLightColor;
  font-weight: 800;
}

.react-datepicker-popper {
  z-index: 1001 !important;
}

.react-datepicker__header {
  background-color: #3f51b5 !important;

  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: white !important;
  }
}

.react-datepicker__month,
.react-datepicker__day,
.react-datepicker__day-name {
  margin: 0.2rem !important;
}

.react-datepicker__header .react-datepicker__day-name {
  font-weight: 800 !important;
}

.react-datepicker__day {
  color: $primaryColor !important;
}

.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range {
  border-radius: 2rem !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: transparent !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range {
  background-color: #91939e !important;
  color: white !important;
}

.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 0px !important;
}

.react-datepicker__navigation-icon {
  top: 6px !important;
}


.dateRange {
  width: min-content;
  margin-top: 0.3em;


  label {
    font-size: 0.729vw;
    color: #0b3e79;
    font-weight: 900;
    text-transform: none
  }

  .date-input-icon {
    display: flex;
    flex-direction: row;

    input {
      border: 0.3px solid #9AB8D1;
      border-radius: 0px 4px 4px 0px;
      max-height: 1.9692307692307693vw;
      padding: 0 0.28572916666666667vw;
      min-height: 1.9692307692307693vw;
      width: 10.5vw !important;
      min-width: 10.5vw !important;
      color: $primaryColor;
    }

    .calendar-icon-div {
      display: flex;
      align-items: center;
      background: white;
      border: 0.3px solid #9AB8D1;
      border-right: none;
      border-radius: 4px 0px 0px 4px;
      padding: 0 0.3857291667vw;

      svg {
        width: 1vw;
        height: 1vw;

        path {
          fill: #0b3e79;
        }
      }

    }

    .react-datepicker__header {
      select {
        min-width: fit-content;
        border: none;
        color: #3f51b5;
        outline: none;

        option {
          background-color: #ffffff;
          color: black;
        }

        font-size: 0.8vw;
        font-weight: bold;
      }

    }

    .react-datepicker__current-month {
      display: none;
    }

    .react-datepicker__month-dropdown-container--select {
      margin: 0;
    }

    .react-datepicker__year-dropdown-container--select {
      margin: 0 0 0 1em;
      min-width: fit-content;
    }

    .react-datepicker__month-dropdown-container--select {
      margin: 0;
      background-color: #3f51b5;
    }

    .react-datepicker__close-icon::after {
      color: $defaultHighLightColor !important;
      background-color: transparent !important;
      font-size: 1.8em !important;
    }
  }



}

.accordion-container {
  box-shadow: none !important;
  border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
  margin-bottom: 2em;
  border-radius: 0px !important;

  .accordion-title {
    min-height: 3vw !important;

    .MuiAccordionSummary-content {
      margin: 0 !important;
    }

    span {
      font-size: 0.776vw;
      font-weight: 400;
      color: $primaryColor !important;
      font-stretch: normal;
      font-style: normal;
      margin-top: 0.5em;
    }
  }

  .accordion-details {
    width: 100%;
    padding: 1em !important;
  }

  ::before {
    background-color: white !important;

  }

}

.accordion-container::before {
  content: none !important;
  background-color: white !important;

}

.website-icon-hover-parent-position {
  position: relative;

  .child-position {
    position: absolute;
    left: -3rem;
    top: 1rem;

    .action-icon {
      text-transform: capitalize;
      font-size: 0.700vw;
      color: $primaryColor;
      padding: 0.5em;
      border-radius: 0.5em;
      border: 1px solid #1198AB;
      max-width: 20vw;
      width: max-content;
      background-color: #ffffff;
      font-weight: 600;
    }
  }
}

.col-hover-container {
  .parent-position {
    position: relative;
    z-index: 10001;

    .child-position {
      position: absolute;
      right: 102%;
      //top: -10rem;
      bottom: 0;
      text-transform: capitalize;
      font-size: 0.7vw;
      color: $primaryColor;
      padding: 1.2em;
      border-radius: 0.5em;
      border: 1px solid #1198ab;
      max-width: 20vw;
      width: max-content;
      background-color: #ffffff;
      font-weight: normal;
      z-index: 100001;
    }
  }
}

.cls-edit-button {
  fill: $primaryColor !important;
  color: $primaryColor !important;
}