@import "../../../../variables.scss";

.complete-sales-call {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;

  .sales-call-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edit-sales-call {
      height: 1vw;
      width: 1vw;
      margin-right: 2em;
    }
  }

  .form-content {
    padding-left: 3em;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

  }

  .section-divider {
    border-bottom: 1px solid rgba(165, 165, 165, 0.4);
    padding-bottom: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
    align-items: stretch;
  }

  .form-row1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-item {
      width: 47%;
      // margin-bottom: 0.7em;
    }
  }

  .form-row2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-item {
      width: 23%;
      margin-bottom: 0.7em;

    }
  }

  .section3 {
    // padding-bottom: 1em;
    // margin-bottom: 1em;
  }

  .form-actions {
    display: flex;

    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 3em;

    .btn-cancel {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  .label {
    margin-top: 15px;
    font-size: 0.729vw;
    color: #0b3e78;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .switch-holder {
    .switch {

      .slider:after {
        content: "NO";
      }

      input:checked+.slider:after {
        content: "YES";
      }
    }
  }

  .call-cplan-assign {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 1rem;
    margin-top: 0.5em;

    .css-t7ry41-control {
      min-height: 0;
    }

    .psc-assign-call {
      margin-left: 1em;
    }
  }
}