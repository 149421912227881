@import "../../../../../../variables.scss";


.mtc-container{
    display: flex;
    flex-direction: column;
    padding:2em;
    border:1px solid $tableBorderColor;
  .mtc-header{
    margin-bottom: 0.5rem;;
    .mtc-title{
        font-size: 1.1vw;
        color:$primaryColor;
        font-weight: 700;
    }
  }
    .mtc-content{
        display: flex;
        flex-direction: column;
        .input-row{
            display: flex;
            flex-direction: row;
        
            .input-holder{
                width:38%;
                margin:0 2rem 1rem 0;
            }
        }
        .filename{
            margin-top:0.8rem;
            font-size:0.8vw;
            color:$primaryColor;
        }
    }
    .mtc-actions{
        padding-top:2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .btn-cancel{
            margin-right:1rem;
        }
    }
}