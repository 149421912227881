.competitor-summary {
  .ad-actions {
    margin: 10px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
