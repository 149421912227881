
@import "../../../../variables.scss";
.add-credits-container{
    padding:1.5em;

    .add-credits-title-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title-holder .title-icon {
            display: none;
        }
    }
    .add-credits-content{
        padding:1.5em 0em;
        .radio-group-div{
            label span{
                text-transform: none;
                font-size:0.8vw;
            }
        }
        .imp-radio-label{
            font-size:0.729vw;
            font-weight:900;
            color:$defaultHighLightColor;
        }
    }
    .add-credit-actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .btn-cancel{
            margin-right:1em;
        }
    
        }   
}