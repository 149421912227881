@import "../../../../../variables.scss";

.cc-sales-profile-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  border-radius: 3px;
  background-color: #f7fbfd;

  // .cc-sales-profile-header {
  //     display: flex;
  //     flex-direction: row;
  //     flex-wrap: nowrap;
  //     padding-top: 1em;
  //     padding-left: 2em;
  //     padding-right: 2em;
  //     width: -webkit-fill-available;
  //     position: relative;

  // }

  .cc-sales-profile-content {
    padding-left: 2em;
    padding-right: 2em;
    // padding-bottom: 2em;
    position: relative;
  }
}
.cc-sp-field-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
}
.cc-sc-fields,
.cc-sc-chip-field {
  flex-wrap: nowrap;
  width: 32%;
  margin-right: 1em;
  span {
    text-transform: none;
    word-break: break-word !important;
  }
  flex: 0 32%;
  margin-bottom: 0.2em;
  textarea{
    min-height:65px !important;
  }
}

.row3,
.row1 {
  align-items: end;
}
.row2 {
  align-items: baseline;
}
.cmfs-container .cmfs-label {
  margin-bottom: 5px;
}

.contact-sales-call-activity {
  .sales-call-activity {
    margin-top: 1rem;
    .dateRange {
      margin-left: 1em;
    }

  }
}