@import '../../../../variables.scss';


.dsh-metric-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    // overflow: hidden;

    .dsb-sb-bg-color {
        padding: 0 0.5em 0.5em 0.5em;
        background-color: #E8F5F7;
    }

    .dsb-srv-bg-color {
        padding: 0 0.5em 0.5em 0.5em;
        background-color: #EBE8F1;
    }

    .dsb-rtg-bg-color {
        padding: 0 0.5em 0.5em 0.5em;
        height: 5vw;
        min-height: 4.2vw;

        background-color: #D9EEF8;

        .dsh-board-cmfs {
            justify-content: flex-end;


        }
    }

    .dsb-second-section-pd {
        padding: 0 1em 0 1em;
    }




    .dsh-metric-boards,
    .dsh-metric-traffic-lights {
        display: flex;
        flex-direction: row;
        .dsh-board {
            display: flex;
            flex: 1;
            min-height: 20.408vh;
            min-width: 16.66vw;
            flex-direction: column;
            background: $white 0% 0% no-repeat padding-box;
            background: $white 0% 0% no-repeat padding-box;
            border: 0.28px solid rgba($color: $tableBorderColor, $alpha: 0.6);
            margin: 0 0.5em 1em 0.5em;
            overflow: hidden;
        }
    }

    .dsh-metric-boards {
        min-height: calc(100% - 33.163vh);
        height: inherit;

        .dsh-sales-board,
        .dsh-service-board,
        .dsh-recruiting-board {
            display: flex;
            flex-direction: column;
        }
    }

    .dsh-metric-traffic-lights {
        max-height: 33.163vh;
        height: fit-content;

        .dsh-eng-board {
            display: flex;
            flex: 1;
            min-width: 16.666vw;
            flex-direction: column;
            background: $white 0% 0% no-repeat padding-box;
            background: $white 0% 0% no-repeat padding-box;
            border: 0.28px solid rgba($color: $tableBorderColor, $alpha: 0.6);
            margin: 0 0.5em 1em 0.5em;
            overflow: hidden;
        }

        // .dsh-cmpny-eng,
        // .dsh-tlnt-eng {
        //     min-height: 240px;
        // }
    }

    .dsh-board-cmfs {
        display: flex;
        justify-content: space-between;

        .dt-input {
            display: flex;
            justify-content: space-between;
            margin-left: 0.5em;

            .assnt-form-input {
                margin-right: 0.5em;
            }
        }

        .d-inline-block {
            min-width: 7vw;
        }

        .tl-count {
            text-transform: capitalize;
            display: flex;
            align-items: flex-end;
            height:100%;
            span {
                font-style: italic;
                font-size: 0.625vw;
                margin-left: 0.1em;

            }

        }


    }
}


.actions-list-count,
.dsb-rtg-bg-color {
    align-items: flex-end;
    // justify-content: center;
    display: flex;
    flex-direction: column;

    // margin-top: 2.5rem;
    .actions-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        // justify-content: space-between;
        justify-content: flex-end;
        padding: 1em 0 0 0;


        // border-bottom: 1px solid rgba($color: #C2CFD6, $alpha: 0.6);
        .parent-position {
            position: relative;
            width: 0;
            height: 0;

            .child-position {
                position: absolute;
                // left: 0;
                right: -3em;
                // bottom: -1.7em;

                .action-list-title {
                    text-transform: capitalize;
                    font-size: 0.700vw;
                    color: #1198AB;
                    padding: 0.5em;
                    border-radius: 0.5em;
                    // margin-bottom: 2rem;
                    border: 1px solid #1198AB;
                    max-width:max-content;
                    width: max-content;
                    background-color: #ffffff;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }
    }

    .action-icon {

        cursor: pointer;
        margin-left: 1em;
        margin-bottom: 0.3rem;
        // background:white ;
        padding: 0.2em;

        svg {
            width: 1vw;
            height: 1vw;
        }
    }

    .on-hover {
        transition: 0.3s all ease-in-out;

        &:hover {
            background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1) !important;
        }
    }

    .avatar-container {
        position: relative;
        .total-leads {
            width: max-content;
            height: max-content;
            cursor: initial;
            position: absolute;
            right:-50%;
            bottom: 1.1em;
            border-radius: 3px;
            background-color: #FF0200;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                word-break: normal;
                color: $white;
                font-size: 0.629vw;
                padding: 0 0.3em;
            }
        }
    }

}
.dsh-board-cmfs{
    .actions-list-count{
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .dateRange {
        width: min-content;
        margin-top: 0.4em;
        margin-left: 0.4em;
    
    
        label {
            font-size: 0.729vw;
            // color: #0b3e79;
            font-weight: 900;
            text-transform: none
        }
    
        .date-input-icon {
            display: flex;
            flex-direction: row;
    
            input {
                border: 0.3px solid #9AB8D1;
                border-radius: 0px 4px 4px 0px;
                max-height: 1.9692307692307693vw;
                padding: 0 1vw 0 0.28572916666666667vw;
                min-height: 1.9692307692307693vw;
                width: 9vw !important;
                min-width: 9vw !important;
            }

            .react-datepicker__close-icon {
                right: -0.2em;
            }
    
            .calendar-icon-div {
                display: flex;
                align-items: center;
                background: white;
                border: 0.3px solid #9AB8D1;
                border-right: none;
                border-radius: 4px 0px 0px 4px;
                padding: 0 0.3857291667vw;
    
                svg {
                    width: 1vw;
                    height: 1vw;
    
                }
            }
        }
    
    }
}


.dsh-sales-board .cmfs-label span,
.dateRange.salesDate label,
.dateRange.salesDate input,
.dsh-sales-board .tl-count {
    color: #1198AB;

}

.dsh-service-board .cmfs-label span,
.dateRange.serviceDate label,
.dateRange.serviceDate input,
.dsh-service-board .tl-count {
    color: #79649e;
}

.dateRange.salesDate .date-input-icon svg path {
    fill: #1198AB !important;
}

.dateRange.serviceDate .date-input-icon svg path {
    fill: #79649e !important;
}

.dateRange.serviceDate .date-input-icon .react-datepicker__close-icon::after {
    color: #79649e !important;
    background-color: transparent !important;
    font-size: 16px;
}

.dateRange.salesDate .date-input-icon .react-datepicker__close-icon::after {
    color: #1198AB !important;
    background-color: transparent !important;
    font-size: 16px;
}

.sales-user-role-select-class__option#react-select-2-option-0 {
    border-bottom: 1px solid rgba($color: $defaultHighLightColor, $alpha: 0.6);

    .mult-option-holder .label-txt span {
        font-weight: 900 !important;
    }
}
.sales-user-role-select-class__option {
    padding:8px 6px !important;
}

.service-user-role-select-class__option {
    padding:8px 6px !important;
    .mult-option-holder .label-txt span {
        color: #79649e;
    }
}

.service-user-role-select-class__option#react-select-3-option-0 {
    border-bottom: 1px solid rgba($color: #79649e, $alpha: 0.6);

    .mult-option-holder .label-txt span {
        font-weight: 900 !important;
    }
}