.ep-ud-db-edc-section {
    border-top: 1px solid rgba(3, 80, 144, 0.1);
    padding: 1em;
    color: #0b3e79;

    .title {
        font-size: 0.833vw;
    }
    .ep-w2-content{
        margin-top:1rem;
    }
}