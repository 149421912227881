@import "../../../../variables.scss";

.sales-call-activity {
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .main-header {
    background: #f3f3f9;
    color: #0b3e79;
    height: 45px;
    border-bottom: 1px solid #0353a1;
    font-size: 0.937vw;
    font-weight: 900;

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
    .selected {
      background-color: #0b3e79;
      color: white;
    }
    .main-header-item {
      padding: 10px;
    }
  }

  .tabs {
    color: #0b3e79;
    font-size: 0.833vw;
    margin: 15px 0px;

    .tab {
      margin-left: 20px;
      margin-right: 25px;
      cursor: pointer;

      &.selected {
        font-weight: 800;
        border-bottom: 2px solid #0b3e79;
      }
    }
  }
  .tab-options-container {
    color: #0b3e79;
    font-size: 0.833vw;
    padding:0 !important;
    // margin: 15px 0px;

    .tab-option {
      margin-left: 20px;
      margin-right: 25px;
      cursor: pointer;
      span{
        text-transform: capitalize;
        font-size: 0.729vw !important;
      }
      &.selected {
        font-weight: 800;
        border-bottom: 2px solid #0b3e79;
      }
    }
  }
  .complete-call {
    background-color: $buttonColor17;
    color: $white;
    width: fit-content;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.6em;
    text-align: center;
    text-overflow: ellipsis;

    span {
      font-weight: 800;
    }
  }

  .table-container {
    padding: 10px;
    border-radius: 2.8px;
    border: solid 0.25px #c0c2c4;

    .sortable-table thead {
      z-index: 0;
    }

    .actions {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      color: "#0B3E78";
      .total-count-action {
        margin-left: 1em;
        span {
          width: max-content;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.6;
          letter-spacing: normal;
          color: $defaultHighLightColor;
        }
        .total-count-number {
          font-weight: bold;
        }
      }

      .sm-sca-search {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .sb-container {
          margin-left:1rem;
        }
      }

      .cmfs-container {
        width: auto;
        .cmfs-label{
          margin-left: 1em;
        }
      }

      .add-campaign {
        margin-left: auto;
        // margin-top: 20px;
        .btn-text span {
          text-transform: none;
        }
      }
      .table-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;

      }
      .serviceCall-toggle{
        margin: 0 0 0 1em;
        line-height: 2.6;
      }

    }

    tr td:last-child,
    tr th:last-child {
      flex: 0.5 !important;
      display: flex;
      justify-content: center;
    }
  }

  .sortable-table {
    tbody {
      display: block;
      overflow-y: auto;
      max-height: 27vh;
      td{
        .gmeet{
          path{
            fill:#035090 !important;
          }
        }
      }
    }
  }

  .total-count {
    margin-top: 5px;
    margin-left: auto;
    color: #074783;
    font-weight: 700;
    font-size: 0.729vw;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
    span{
      margin-left: 0.8em;
    }

    .total-span {
      font-size: 0.9vw;
    }

    &.action {
      margin-left: 10px;
      margin-top: auto;
    }
  }

  .table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 1rem;
    padding-right: 0 !important;
    position: relative;
    .call-counts {
      background: #f7fbfd 0% 0% no-repeat padding-box;
      border: 0.25px solid #035090;
      border-left-width: 4px;
      opacity: 1;
      width: 40%;
      margin-left: 1rem;
      padding: 15px;
    }
  }

  .sales-count {
    font-size: 0.9vw;
    margin-top: 10px;
  }
  .dateRange {
    width: min-content;
    margin-top: 0.3em;
    margin-left: 1em;
    input {
        width: 10vw !important;
        min-width: 10.5vw !important;
        color: $defaultHighLightColor !important;
    }
}
}


