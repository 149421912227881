@import '../../../../variables.scss';

.md-vu-container {
    padding: 2em;
    color: $primaryColor;

    .md-vu-dialog-header {
        display: flex;
        justify-content: space-between;

        img {
            width: 1.25vw;
            height: 1.25vw;
        }

        span {
            font-size: 1.041vw;
            font-weight: 900;
            color: #0b3e79;
        }
    }

    .md-vu-dialog-content {
        margin-bottom: 2em;
    }

    .md-vu-dialog-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

        .md-vu-message {
            color: green;
            margin-bottom: 0.5em;
        }

        .md-footer-actions {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .btn-cancel {
                margin-right: 1em;
                margin-top: 1em;
            }
        }
    }

}