@import '../../variables.scss';


.doc-saved-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .doc-saved-icon {
        width: 8.0078125vw;
        height: 8.3203125vw;
        margin-right: 2em;
    }

    .doc-saved-content{
        display: flex;
        flex-direction: column;
        span {
            font-size: 1.284375vw;
            font-weight: bold;
            color: $defaultHighLightColor;
            text-transform: none;
        }
    }
}