@import '../../../../variables.scss';

.close-batch-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .close-batch-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .close-batch-content {
        padding: 2em 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        span {
            color: $primaryColor;
            font-size: 1.284375vw;
            font-weight: 700;
            text-transform: none;
        }
        // .doc-saved-holder .doc-saved-icon {
        //     width: 14.0078125vw;
        //     height: 14.3203125vw;
        //     margin-right: 2em;
        // }
        .open-msg-div{
            display: flex;
            flex-direction: column;
            div{
                margin-bottom:1rem;
                color: $primaryColor;
                font-size: 1vw;
                font-weight: 600;
                text-transform: none;
                display: inline-block;
            }
        }

        .error-holder {

            .error-title{
                color:$statusInActiveColor;
                font-size:1.2843vw;
                margin-bottom:0.5rem;
            }
            span {
                color: $statusWarningColor;
                font-weight: 500 !important;
                font-size: 1vw;
                text-transform: none;
            }
        }

    }



    .close-batch-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;
    }
}