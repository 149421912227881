@import '../../../variables.scss';

.user-avatar {
  display: flex;
  margin-bottom: 1.5em;
}
.ob-team-user-name {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-left: 1.5em;
}
.ob-team-user-name span {
  color: #035090;
  font-family: Lato;
  font-size: 1.25vw;
  font-weight: 900;
}
.team-title-holder {
  margin-bottom: 1em;

  span {
    color: #0b3e79;
    font-size: 1.0416666667vw;
    font-weight: 800;
    text-transform: inherit;
  }
}
.team-details-holder {
  background: #F7FBFD;
  box-shadow: 0px 0px 2px #77777733;
padding:2em 3em;
}
.ob-team-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em;

    .ob-team-info-icon {
        width: 0.833vw;
        height: 0.885vw;
        margin-right: 1em;
        flex: none;
        color: $companiesOptionTextColor;
        .cls-1{
            fill:$companiesOptionTextColor;
        }
    }

    .ob-team-info-content {
        display: flex;
        flex-direction: column;

        .ob-team-info-holder {
            display: flex;
            flex-direction: column;

            .ob-team-into-title {
                span {
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    font-size: 0.833vw;
                    margin-bottom: 0.5em;
                }
            }

            

            .ob-team-info-value {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                color: $userProfileNameColor;
                font-weight: 800;
                line-break: anywhere;
                // margin-bottom: 0.5em;
                //text-transform: capitalize;
                a{
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    font-weight: 800;
                    text-decoration: none;
                    line-break: anywhere;
                }

                span {
                    font-weight: 800;
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                }

                .ob-team-info-icon {
                    width: 1.666vw;
                    height: 1.666vw;
                    cursor: pointer;
                    padding: 0.5em;
                    transition: 0.3s all ease-in-out;

                    &:hover {
                        background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }

            }

        }
    }
}
