@import '../../../../../variables.scss';


.cp-container{
    display: flex;
    flex-direction:column;
    width:100%;
    padding:1rem;
    .cp-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width:100%;
        .section-header {
            // max-width: 100%;
            // width: 100%;
            font-size: 0.833vw;
            margin-bottom: 10px;
            color: #0b3e79;
            text-transform: uppercase;
        }
    }
    .cp-content{
        margin:1rem 0 ;
        overflow: auto;
        max-height:25vw;
        padding-bottom:2rem;
        // height:100%;
        .cp-input-holder-row{
            display:flex;
            justify-content: space-between;
            flex-direction: row;
            width:100%;
            margin-bottom:0.6rem;
            .cp-content-input-holder{
                width:100%;
                margin-right:2rem;
            }
        }
        .empty-data-msg{
            text-align: center;
            span {
                font-size: 1vw;
                color: $tableBorderColor;
            }
        }
        .toggle-row{
            margin-top:1.5rem;
        }
        .sortable-table {
            thead {
                z-index: 100;
            }
            .tb-header-cc {
                span {
                    overflow: visible !important;
                    padding-right: 1.1em;
                }
            }
        }

        .sortable-table thead tr .tb-header-cc {
            padding-right: 1.1em;
        }
    }
    .cp-actions{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .cp-actions-btn {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
        .btn-cancel{
            margin-right: 1rem;
        }
    }
}