@import "../../../../../variables.scss";

.vr-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 2rem 2em;

    .vr-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .vr-r-left {
            display: flex;
            align-items: center;
            .vr-title {
                font-size: 0.937vw;
                color: $primaryColor;
                font-weight: 800;
          
            }
        }
        .vr-r-right {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 0 1em 0 1em;
            align-items: center;
            .total-span {
                margin-right: 1rem;
                color: $primaryColor;
            }
        }
    }

    .vr-content {
        margin-top:2em;
        .vr-table-c {
            border-radius: 0.2em;
            border: solid 1px #c0c2c4;
            max-height: 68vh;
            overflow: auto;
            position: relative;
            .sortable-table thead {
                z-index: 0;
                position: sticky;
            }

        }
    }
}