@import '../../variables.scss';


.form-select {
   border: 0.3px solid $inputoutlineColor;
   color: $inputBorderColor;
   background-image: url('../../../assets/icons/arrow_new.svg') !important;
   background-size: 0.6784773060029283vw;
   font-size: 0.729vw;
   min-height: 25px;
   padding: 0.5em;
   border-radius: 3px;
   text-transform: none;
}

.cmfs-wrapper {
   display: flex;
   flex-direction: column;
   position: relative;
   width: fit-content;
}

.cmfs-margin-top {
   margin-top: 0.795em !important;
}

.cmfs-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   width: -webkit-fill-available;
   // margin-top: 3.7px;

   .cmfs-label {
      
      margin-bottom: 5px;

      span {
         font-size: 0.729vw;
         color: $defaultHighLightColor;
         font-weight: 900;
         text-transform: none;
      }
   }

   .cmfs-input-holder {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      min-width: 9.895833333333334vw;
      width: fit-content;
      position: relative;
      padding: 0.5em;
      border-radius: 3px;
      border: 1px solid $defaultHighLightColor;
      transition: 0.3s all ease-in-out;
      cursor: pointer;

      &:hover {
         background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1);
      }

      .place-holder {
         span {
            color: $inputBorderColor;
            font-size: 0.729vw;
         }
      }

      .expand-icon {
         font-size: 0.937vw;
         color: $defaultHighLightColor;
      }

   }

   /// holder
   .cmfs-options-container {
      padding: 1em;
      background: $white;
      // position: absolute;
      // left: 0;
      // right: 0;
      // top: 80px;
      height: fit-content;
      box-shadow: 0px 1px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 0.1);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      justify-items: flex-start;
   }
}

.d-inline-block {
   min-width: 9.895833333333334vw;
   width: -webkit-fill-available;

   .css-c0tn0x-control {
      border: 1px solid $inputoutlineColor !important;
      min-height: 25px !important;
      border-radius: 3px;
   }

   .css-14el2xx-placeholder {
      color: $inputBorderColor;
   }

   .css-1msacmo-placeholder {
      color: $inputBorderColor;
   }

   .css-tlfecz-indicatorContainer {
      color: $inputBorderColor;
   }
}


.mult-option-holder {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;


   .multi-select-input-check {
      margin-right: 0.5em;
   }

   .label-txt {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
         color: $defaultHighLightColor;
         overflow: hidden;
         white-space: nowrap;
         text-transform: none;
      }
      .option-icon{
         margin-right:1rem;
         padding:0.5rem 0rem;
         svg{
            width:1vw;
            height:1vw;
            color:$primaryColor;
         }
      }
   }
}

.more-selected-badge {
   margin-left: 40px;
   background: #d4eefa;
   border-radius: 4px;
   font-family: Open Sans;
   font-size: 11px;
   padding: 3px;
}

.mult-down-chevron {
   width: 0.6784773060029283vw;
   height: 0.6784773060029283vw;
}

.cross-icon {
   width: 15px;
   height: 15px;
}

.ellipse-label {
   color: $defaultHighLightColor;
   overflow: hidden;
   white-space: nowrap;
}
