@import './app/variables.scss';


.app-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  min-width: $minWindowWidth;
  background-color: $white;
  z-index: 0;
}


/*--------- scroll bar styles---------*/

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: $tableBorderColor;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $tableBorderColor;
}


@media only screen and (min-width: 1024px) {
  .app-container{
    overflow: auto;
  }
}


