@import "../../../../variables.scss";

.oah-mobile-holder-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: -webkit-fill-available;
  height: -webkit-fill-available;

  // background-image: url('../../../../assets/business-man-background.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -40%;
  position: relative;

  .oah-mobile-banner-view {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    background-color: transparent;
    flex: 2;
    position: relative;
  }
  
  .oah-mobile-content-view {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    flex: 4;
    background-color: #fff;
    padding: 3em 4em;
    position: relative;
    
    .header-nav{
      box-shadow: 0px 3px 6px #00000029;
      height:60px;
      margin:-3em -4em;
      display: flex;
      align-items: center;
      padding-left: 10px;
      .prev svg{
        color:#0b3e79;
      }
    }
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: -webkit-fill-available;
      padding-bottom:2em;
      border-bottom: 1px solid $tableBorderColor;

      .page-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        .icon-holder {
          width: 25px;
          height: 34px;
          margin-right: 20px;
        }

        .title-holder {
          display: flex;
          flex-direction: column;

          .title {
            span {
              font-size: 20px;
              font-weight: bold;
              color: $defaultHighLightColor;
            }
          }
          .sub-title {
            span {
              font-size:13px;
              font-weight: normal;
              color: $defaultHighLightColor;
              text-transform: unset;
            }
          }
        }
      }

      .agency-branding {
        width: fit-content;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
      position: relative;
      padding: 3em 0 0 4em;
      overflow: auto;
    }
  }
}
