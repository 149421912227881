@import '../../../../variables.scss';


.add-new-markup-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 2em;
    overflow: hidden;

    .add-new-markup-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
        .title-name span{
            text-transform: none;
        }
    }

    .add-new-markup-content {
        display: flex;
        flex-direction: column;
        // position: relative;
        // overflow: auto;
        .saved-doc-holder{
            padding:2rem;
        }
        .add-new-markup-input-holder-row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 0.5em;
            width: 100%;

            .add-new-markup-input-holder {
                width: 100%;
                margin-bottom: 0.6rem;
                span {
                    text-transform: none;
                }
            }
        }

        .switch {
            .slider:after {
              content: "NO" !important;
            }
        
            input:checked+.slider:after {
              content: "YES" !important;
            }
          }
    }

    .add-new-markup-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-top:1rem;
        .btn-cancel {
            margin-right: 1em;
        }
    }
}