.contacts {
  .sb-container {
    width: auto;
  }

  .cmfs-label {
    margin-bottom: 5px;
    margin-top: 8px;

    span {
      font-size: 0.677vw;
    }
  }
}

.contact-form {
  .cmfs-label {
    margin-bottom: 5px;
    margin-top: 8px;

    span {
      font-size: 0.677vw;
    }
  }
  .uf-content{
    overflow-x: hidden !important;
  }
}

.contact-form-footer {
  .custom-btn {
    width: 150px;
  }
  &:first-child {
    margin-left: auto;
  }
}

.contacts-ud {
  .sb-container {
    width: auto;
  }
}
