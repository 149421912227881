@import "../../../../variables.scss";

.assignments-ud {
  .access-btn {
    background-color: $buttonColor17;
    color: $white;
    min-width: 150px;
    width: fit-content;
    height: 30px;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.1em 0.3em;
    text-align: center;
    text-overflow: ellipsis;

    span {
      font-weight: 800;
    }
  }
}

.deparment-toggle {
  margin: 4px;
}

.cps-ud-table {
  .sortable-table {
    tbody {
      display: block;
      overflow-y: auto;
      max-height: 46vh;
    }
  }
  
  @media screen and (max-width:1650px) {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 45vh;
      }
    }
  }
  
  @media screen and (max-width:1500px) {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 45vh !important;
      }
    }
  }
  
  @media screen and (max-width:1280px) {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 46vh !important;
      }
    }
  }
}


.cps-ud-table-header {
  padding: 1em;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .broadcast_btn_div {
    margin-top:1rem;
    align-items: flex-end;
    button {
      background-color: #ff4d00;
      box-shadow: none;
      svg{
        g path:first-child{
          fill:white !important;
        }
        g path:nth-child(2){
          fill:#ff4d00 !important;
        }
        g path:nth-child(3){
          fill:#ff4d00 !important;
        }
      }
    }
    .select_talent_msg {
      text-transform: initial;
      color: #ff4d00;
      margin-top: 0.3em;
    }
  }

  .cps-ud-table-header-left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
    .account-manager-selector,
    .recruiter-selector {
      margin-right: 1em;
      width: 11.873983vw;
    }
    .company-status-selector{
      .d-inline-block {
        margin-right: 1em;
        min-width: 6.895833333333334vw;
      }
    }

    .select-user {
      margin-right: 1em;

      .user-form-control {
        min-width: 190px;
        width: fit-content;
      }
    }

    .deparment-toggle {
      margin-left: 1em;
    }

    .total-count {
      margin-bottom: 0.5em;
      margin-left: 1em;
      span {
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $defaultHighLightColor;

        .total-count-number {
          font-weight: bold;
        }
      }
    }
  }
}