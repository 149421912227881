@import '../../variables.scss';


.cd-cs-covv-section{
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    padding: 1em;

    .cd-cs-covv-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .cd-cs-covv-header-title-txt{
            span {
                color: $defaultHighLightColor;
                font-size: 0.833vw;
            }
        }
    }

    .cd-cs-covv-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .custom-chip{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0.5em 1em;
            border-radius: 20px;
            width: fit-content;
            margin-right: 1em;
            margin-top: 1em;
            background-color: #F7FBFD;

            border: 1px solid $defaultHighLightColor;
            .chip-title{
                margin-right: 1em;
                span{
                    font-size: 0.729vw;
                    color: $defaultHighLightColor;
                    font-weight: 800;
                }
            }

            .chip-action{
                width: 0.625vw;
                height: 0.625vw;
            }

        }
    }

}

