@import "../../../../variables.scss";

.scheduled-interview-ud {
  .access-btn {
    background-color: $buttonColor17;
    color: $white;
    min-width: 150px;
    width: fit-content;
    height: 30px;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.1em 0.3em;
    text-align: center;
    text-overflow: ellipsis;

    span {
      font-weight: 800;
    }
  }

  .total-count {
    margin-top: 20px;
    span {
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.86;
      letter-spacing: normal;
      color: $defaultHighLightColor;
      .total-count-number {
        font-weight: bold;
      }
    }
  }
  .apps-ud-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    .apps-ud-search{
      margin-right: 1em;
      display: flex;
      align-self: center;
      .sb-container{
        width:15vw;
      }
    }
  
    .btn-text span{
     white-space: pre;
    }
  }
}
.tab-icon {
    width: 120px;
    height: 120px;
    margin-right: 1em;
  
  }
.success-step {
    margin-top: 5%;
    margin-left: 2em;
    margin-bottom: 1em;

    .doc-saved-icon {
      width: 6.0078125vw;
      height: 6.3203125vw;
    }
  }

  .success-header-font {
    font-size: 2.041vw;
    font-weight: 600;
    color: $defaultHighLightColor;
  }
  
  .success-font {
    display: block;
    font-size:  1.941vw;
    font-weight: 600;
    color: $defaultHighLightColor;
  }
  .uf-content {
    overflow-x: hidden !important;
    .import-appl-sent-content {
      margin-left: 2rem;
      margin-bottom: 1em;

      span {
        font-size: 1.041vw;
        color: #0b3e78;
        text-transform: none;
      }
    }
  }
  .interview-ac-ud-table-search-bar {
    margin-right: 1em;
    display: inline-flex
}