@import "../../../../../variables.scss";

.new-hire-report-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .new-hire-report-header {
        display: flex;
        justify-content: space-between;

        .nh-r-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .dateRange {
                width: min-content;
                margin-top: 0.3em;
                margin-right: 1em;

                input {
                    width: 10vw !important;
                    min-width: 10.5vw !important;
                    color: $defaultHighLightColor !important;
                }
            }
            .error-holder {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 0;
            }
        }

        .nh-r-right {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            color: "#0B3E78";
            padding: 0 1em 0 1em;

            .total-count-action {
                margin-left: 1.5em;
                margin-right: 1em;

                span {
                    width: max-content;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.2;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;

                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }
            .download-btn {
                margin-right: 1em;

                svg {
                    .cls-1 {
                        fill: #fff !important;
                    }
                }
            }
        }
    }

    .table-container {
        border-radius: 0.2em;
        border: solid 1px #c0c2c4;
        margin-top: 2em;

        .nhr-table {
            max-height: 58vh;
            overflow: auto;
            position: relative;
    
            .sortable-table thead {
                z-index: 0;
                position: sticky;
            }
        }

        .header-container {
            margin-bottom: 1em;
            margin-top: 1em;
            display: flex;
            justify-content: space-between;
            color: "#0B3E78";
            padding: 0 1em 0 1em;

            .table-header {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-end;

                .table-header-title {
                    span {
                        font-size: 0.937vw;
                        color: $primaryColor;
                        font-weight: 800;
                        margin-bottom: 0.2em;
                    }

                }
            }
        }
    }
}