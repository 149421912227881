@import '../../../../variables.scss';
/// [sales calls]

.interviews-list-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    overflow: auto;

   
}


.recruite-chip {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    padding: 0.5em 1em;
    border-radius: 14px;
    margin: 0 1em 1em 1em;
}

.holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: -webkit-fill-available;

    .info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }

    .actions {

        .action-contact-icon {
            width: 0.625vw;
            height: 0.625vw;
        }
    }
}