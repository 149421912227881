@import '../../../../variables.scss';

.sys-config-email-config-container {
    width: -webkit-fill-available;
    margin: 0.3rem 1rem 1rem;

    .sys-config-email-config-content {
        padding: 1rem;
        width: -webkit-fill-available;
        border: 1px solid $tableBorderColor;

        .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .body-content{
            span {
                text-transform: none;
            }
        }

        .sc-ec-header-title {
            span {
                font-size: 0.937vw;
                font-weight: bold;
                color: rgb(11, 62, 121);
            }
        }

        .sc-ec-actions-holder {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 1rem;

            .error-holder {
                width: 38%;
            }

            .sc-ec-actions {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                .btn-cancel {
                    margin-right: 1em;
                }
            }
        }

        .MuiSvgIcon-root {
            width: 1.5vw;
            height: 1.5vw;
        }
    }
}