@import '../../../../../variables.scss';

.pcr-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .header-container {
        display: flex;
        justify-content: space-between;

        .table-header {
            display: flex;
            align-items: center;
            padding: 1.5em 0 0.2em 0;

            span {
                font-size: 0.937vw;
                color: $primaryColor;
                font-weight: 800;
                margin-bottom: 0.2em;
            }
        }
    }

    .pcr-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1rem;

        .pcr-h-left {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 90%;

            .fs-btn {
                margin-left: 2em;
            }

            .error-holder {
                width: 15vw;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-left: 1em;
                margin-bottom: 0;
            }

        }

        .pcr-h-right {

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            width: 90%;

            .total-count {
                margin-left: 2em;
                color: $primaryColor;
            }

            .search-bar {
                margin-left: 2em;
                width: 32%;
            }

            .download-btn {
                margin-left: 2em;
            }
        }
    }

    .na_div {
        width: 100%;
        text-align: center !important;
    }

}