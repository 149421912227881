@import "../../../../../variables.scss";

.payroll-journal-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .payroll-journal-header {
        display: flex;
        justify-content: space-between;

        .pj-r-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            .dateRange {
                width: min-content;
                margin-top: 0.3em;
                input {
                    width: 10vw !important;
                    min-width: 10.5vw !important;
                    color: $defaultHighLightColor !important;
                }
            }
            .fs-btn{
                margin-left: 1em;
            }
            .error-holder {
                width: 15vw;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-left: 1em;
                margin-bottom: 0;
            }
        }
        .pj-r-right {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            color: "#0B3E78";
            padding: 0 1em 0 1em;

            .total-count-action {
                margin-left: 1.5em;
                margin-right: 1em;

                span {
                    width: max-content;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.2;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;

                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }
            .download-btn{
                margin-right: 1em;
            }
        }
    }

    .table-container {
        border-radius: 0.2em;
        border: solid 1px #c0c2c4;
        margin-top: 2em;

        .pj-table {
            max-height: 50vh;
            overflow: auto;
            position: relative;
    
            .sortable-table thead {
                z-index: 0;
                position: sticky;
            }
        }

        .header-container {
            margin-bottom: 1em;
            margin-top: 1em;
            display: flex;
            justify-content: space-between;
            color: "#0B3E78";
            padding: 0 1em 0 1em;

            .table-header {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-end;

                .table-header-title {
                    span {
                        font-size: 0.937vw;
                        color: $primaryColor;
                        font-weight: 800;
                        margin-bottom: 0.2em;
                    }

                }
            }
        }
    }

    .total-counts-card {
        display: flex;
        justify-content: space-between;
        background: #f7fbfd 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        border-left: 4px solid $defaultHighLightColor;
        border-radius: 4px;
        opacity: 1;
        width: 100%;
        margin-top: 2em;
        padding: 1em;

        .total-title {
            color: #074783;
            font-size: 0.792vw;
            margin: 0 0.5em 0em 0.5em;
            font-weight: 800;
            flex-direction: row-reverse;
        }

        .total-count-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 49.4%;

            .total-count {
                width: 22%;
                color: #074783;
                font-size: 0.792vw;
                font-weight: 800;
                flex-direction: row-reverse;

                span {
                    text-transform: none;
                }
            }
        }
    }

}