@import '../../../variables.scss';


.ac-um-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;

    .ac-um-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 1em 2em;
        width: -webkit-fill-available;
        position: relative;
    }

    .ac-um-content {
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 2em;
        position: relative;

        .ac-um-table-holder {
            box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
            display: flex;
            flex-direction: column;
            .ac-um-table{
                thead {
                    top: -1.6vh !important;
                }
            }

            // .ac-um-table {
            //     .switch {
            //         .slider:after {
            //             content: "DISABLED" !important;
            //         }

            //         input:checked+.slider:after {
            //             content: "ENABLED" !important;
            //             margin-left: 0.8rem;
            //         }
            //     }

            //     .switch {
            //         width: 4.34375vw;

            //         input:checked+.slider:before {
            //             transform: translateX(3.25vw);
            //         }
            //     }
            // }
            .toggle-spinner{
                margin-left:1rem;
                .error-holder{
                    margin-top: 1em;
                }
            }

            .ac-um-table-header {
                padding: 1em;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .ac-um-table-search-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .total-count {
                        margin-left: 1em;

                        span {
                            font-weight: 300;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 2.86;
                            letter-spacing: normal;
                            color: $defaultHighLightColor;

                            .total-count-number {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .ac-um-actions {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

                .ac-um-add-user-btn {
                    width: fit-content;
                }

                .ac-um-add-icon {
                    color: $white;
                }
            }
        }
    }

}

.table-loading {
    width: 100%;
    height: 200px;
}