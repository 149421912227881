@import '../../../../../variables.scss';


.contribution-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;

    .contribution-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .section-header {
            // max-width: 100%;
            // width: 100%;
            font-size: 0.833vw;
            margin-bottom: 10px;
            color: #0b3e79;
            text-transform: uppercase;
        }
    }

    .contribution-content {
        margin-top: 1rem;
        .contribution-content-table {
            max-height: 8vw;
            overflow: auto;
            padding-bottom:2rem;
            .tick-icon svg{
                width: 1.3vw;
                height: 1.3vw;
                font-size: 1.3vw;
                vertical-align: text-top;
            }

        }

        // height:100%;
        .contribution-input-holder-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            margin-bottom: 0.6rem;

            .contribution-content-input-holder {
                width: 100%;
                margin-right: 2rem;
            }
        }

        .empty-data-msg {
            text-align: center;

            span {
                font-size: 1vw;
                color: $tableBorderColor;
            }
        }

        .toggle-row {
            margin-top: 1.5rem;
        }

        .tick-icon svg {
            width: 1.3vw;
            height: 1.3vw;
            font-size: 1.3vw;
            vertical-align: text-top;
        }
    }

    .contribution-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .contribution-actions-btn {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .btn-cancel {
            margin-right: 1rem;
        }
    }

    .contribution-history-label {
        margin-top: 1em;
        border-top: 1px solid $divDividerColor;
        span {
            font-size: 0.776vw;
            font-weight: 400;
            color: #074783;
            font-stretch: normal;
            font-style: normal;
            margin-left: 0.9em;
            text-decoration: underline;
            cursor: pointer;
            margin-top: 1em;
            text-transform: uppercase;
        }
    }
}