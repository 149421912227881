@import '../../../../variables.scss';

.tlnt-inh-in-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;
    // max-width: 50%;

    .tlnt-inh-in-f-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .tlnt-inh-in-f-content {
        padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .tlnt-inh-in-f-section-divider {

            .tlnt-inh-in-f-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start !important;
                align-items: stretch;
                justify-items: stretch;
                .tlnt-inh-in-f-input-holder {
                    min-width: 9.895833333333334vw;
                    margin-right: 1em;

                    .cmfs-margin-top {
                        margin-top: 1em !important;
                    }

                    .time-picker-container {
                        margin-top: 1.72em !important;
                        .MuiInputBase-root {
                            border: 0.3px solid $inputoutlineColor !important;
                            border-radius: 0.3em !important;

                            input {
                                min-width: 9vw;
                            }
                        }

                        .MuiInputBase-input {
                            padding: 0 0 0 0.5em !important;
                            border: none !important;
                            margin-left: 0 !important;
                            cursor: pointer;
                        }

                        .MuiSvgIcon-root {
                            margin-right: 0.3em;
                            right: 0;
                        }

                        .time-input-er-mes {
                            margin-top: 0 !important;
                        }
                    }
                }
        
            }
        }

        .tint-ihi-questions-section {
            display: flex;
            flex-direction: column;

            .tint-ihi-question-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2em;

                .tint-ihi-question-title {
                    // margin-bottom: 0.5em;

                    span {
                        font-size: 0.833vw;
                        color: $defaultHighLightColor;
                        font-weight: bold;
                        text-transform: none;
                    }
                }

                .tint-ihi-input-form {
                    display: flex;
                    flex-direction: column;
                    // margin-left: 1em;
                }
            }
        }

        .error-section {
            margin-bottom: 2em;
        }
    }

    .tlnt-inh-in-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top:1.5em;
        margin-left:3em;
        .btn-cancel {
            margin-right: 1em;
        }
    }

}

.tlnt-inh-in-f-link-icon {
    width: 0.833vw;
    height: 0.833vw;
}