@import '../..//../variables.scss';


.ac-um-usp-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: -webkit-fill-available;

    .ac-um-usp-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 1em 2em;
        width: -webkit-fill-available;
        position: relative;


    }

    .ac-um-usp-content {
        // padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 2em;
        position: relative;
        width: -webkit-fill-available;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .ac-um-usp-profile-container {
            flex: 1;
            box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
            padding: 1em;
            margin-right: 1em;
            max-width: 313px;
        }

        .ac-um-usp-settings-content {
            display: flex;
            flex-direction: column;
            flex: 3;
            position: relative;

            .settings-header {
                // height: 10px;
                // background-image: linear-gradient($primaryColor, $userProfileNameColor);
                width: -webkit-fill-available;
                // box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
                position: relative;

                .setting-header-tab {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    background-image: linear-gradient(#006eb9 70%, #0353a0 100%);
                    // background: radial-gradient(circle at 6% 0%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, #006eb9 0%, #0353a0 93%);;
                    padding: 0.5em 2em 0 2em;
                    width: fit-content;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 1em;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 74%;
                        top: 0;
                        right: -30%;
                        bottom: -1px;
                        background-image: url('../../../../assets/icons/curve.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100% 100%;
                    }

                    .tab-icon {
                        width: 1.145vw;
                        height: 1.145vw;
                        margin-right: 0.5em;
                    }

                    .tab-title {
                        span {
                            font-size: 0.729vw;
                            color: $white;
                            font-weight: 900;
                            font-family: $defaultFontFamily;
                        }
                    }
                }
            }

            .header-strap {
                width: -webkit-fill-available;
                height: 8px;
                border-top-right-radius: 3em;
                background: rgba($color: #0353a0, $alpha: 1.0);
            }

            .settings-container {
                width: -webkit-fill-available;
                // box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
                padding: 1em;
                position: relative;

                .ac-um-usp-edit-icon {
                    width: 0.989vw;
                    height: 0.989vw;
                }

                .ac-um-usp-toggle {
                    display: flex;
                }
                .ac-um-usp-ml{
                    margin-left: 2rem;
                }
            }

        }
    }

    .ac-um-usp-loading {
        width: 100%;
        height: 200px;
    }
}

.ac-um-add-icon {
    color: $white;
    margin-right: 0.5em;
}


.sp-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    padding: 1em;

    .sp-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1em;

        span {
            font-size: 0.833vw;
            color: $primaryColor;
        }
    }

    .sp-content {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;

        /// Role management table
        table {
            width: -webkit-fill-available;
            border: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);

            thead {
                background-color: $tableHeaderBackgroundColor;

                tr {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    .tb-header-cc {
                        display: flex;
                        flex: 1;
                        flex-shrink: 1;
                        flex-grow: 1;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        padding: 1em;
                        transition: 0.3s all ease-in-out;
                        cursor: pointer;
                        border-right: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);

                        &:hover {
                            background-color: rgba($color: $defaultHighLightColor, $alpha: 0.05);

                            .table-header-sort-icon {
                                display: block;
                            }
                        }

                        span {
                            text-overflow: ellipsis;
                            color: $primaryColor;
                            text-decoration: unset;
                        }

                        .table-header-sort-icon {
                            display: none;
                            font-size: 0.729vw;
                            transition: 0.3s all ease-in-out;
                            transform: rotate(0deg);
                            // color: $primaryColor;
                        }

                        .table-header-sort-icon-rotate {
                            transform: rotate(-180deg);
                            display: block;
                            transition: 0.3s all ease-in-out;
                        }

                    }
                }
            }

            tbody {
                tr {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    border-top: 1px solid rgba($color: $tableRowBorderColor, $alpha: 0.3);
                    cursor: unset;
                    transition: unset;

                    &:hover {
                        background-color: unset;
                    }

                    td {
                        flex: 1;
                        padding: 1em;
                        text-decoration: unset;
                        color: $tableRowTextColor;
                        border-right: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }
            }

        }
    }
}


/// Password reset 


.ac-um-usp-password-reset {
    background-color: rgba($color: $defaultActionColor, $alpha: 0.1);
    padding: 1em;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .ac-um-usp-password-reset-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;

        .header-txt {
            span {
                font-size: 0.833vw;
                font-weight: 800;
                color: $userProfileNameColor;
            }
        }

        .last-password-change-info {
            span {
                font-size: 0.729vw;
                color: $primaryColor;
            }
        }
    }

    .ac-um-usp-password-reset-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;


    }
}


/// Account password input
.ac-um-input-holder {
    display: flex;
    flex-direction: column;

    .ac-um-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 6px 1em 6px 1em;
        margin-bottom: 0.5em;

        input {
            position: relative;
            margin-right: 0.5em;
            background: transparent;
            width: 200px;

            &:focus-visible {
                outline: unset;
            }
        }

        input[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }

        .ac-um-s-icon {
            width: 0.937vw;
            height: 22px;
            // margin-bottom: 4px;
            // margin: 4px;
            padding: 2px 2px;
            cursor: pointer;
            transition: 0.3s all ease-in-out;
            border-radius: 22px;

            &:hover {
                background-color: rgba($color: $defaultActionColor, $alpha: 0.1);
            }
        }
    }

    .ac-um-input-txt {
        span {
            color: $passwordStrongColor;
        }
    }
}


/// 
.delete-icon {
    width: 0.833vw;
    height: 0.833vw;
    font-size: 0.833vw;
    background: none;
    border: none;
}