@import '../../../../variables.scss';

// .adh-container .adh-content-container{
//     border-radius: 0;
//     border:2px solid $primaryColor;
// }
.vc-container {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;

    .vc-header {
        display: flex;
        flex-direction: row;
        .title-icon{
            margin-right: 0em;
        }
    }

    .vc-content {
        display: flex;
        justify-content: center;
        height:90vh;
        // padding: 2rem 1rem;
        iframe{
            height:90vh;
            width:100%;
        }
        span {
            color: $primaryColor;
            font-size: 1vw;
            font-weight: 700;
            text-transform: none;
        }
        .webviewer{
            height:90vh !important;
        }
    }

    .vc-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .vc-actions-row {
            display: flex;
            flex-direction: row;
        }
    }
}