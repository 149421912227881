@import '../../../variables.scss';

.cmp-jo-main-container {
    border: 1px solid $tableBorderColor;
    color: $primaryColor;
    padding-bottom: 1em;

    .cmp-jo-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;

        .left-fields {
            display: flex;
            flex-direction: row;

            .total-count {
                display: flex;
                align-items: center;
                margin-left: 20px;
                font-size: 0.781vw;
            }

        }

    }
}
.cmfs-margin-top{
    margin-top: 0em;
}

.lock-icon {
    width: 15px;
    height: 17px;
    margin-right: 1em;
}

.work-claim-dialog-container {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    //height: 980px;
    color: $defaultHighLightColor;
    overflow: auto;

    .work-claim-dialog-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 10px 20px;

        img {
            color: black;
            cursor: pointer;
        }

    }

    .cmp-job-order-dialog-content {
        margin: 20px;
        padding-left: 3em;
        border-bottom: 1px solid $tableBorderColor;
        overflow: scroll;
        flex-direction: column;
        position: relative;

        .form-row {
            display: flex;
            margin-bottom: 2vw;
            flex-wrap: wrap;


            .form-column {
                display: flex;
                flex-direction: column;
                // margin-bottom: 5px;
                margin-right: 2em;
                width: 22%;

                p {
                    margin-bottom: 4px;
                }
                // span{
                //     //margin-bottom: 5px;
                // }

                // input {
                //    // width: 128px;
                // }
                .form-input-holder .form-input-tag{
                    min-width: 9.695833vw;
                    min-height: 2vw;
                }

                .input-form-control {
                    //margin-top: -5px;
                    width: 100%;
                }

                .select-form-control {
                    width: 100%;
                }

                #testing {
                    width: 160px;
                }
            }

            .form-column-spl {
                display: flex;
                flex-direction: column;
                margin-bottom: 5px;
                width: 33%;

                p {
                    margin-bottom: 4px;
                }
            }

            .form-column-un {
                display: flex;
                flex-direction: column;
                margin-bottom: 5px;
                width: 410px;

                p {
                    margin-bottom: 4px;
                }
            }
        }

        .small input {
            width: -webkit-fill-available;
        }

        .message {
            margin: 0;
            margin-top: 5px;
            font-size: 0.625vw;
            height: 0.781vw;
        }

        .dynamic-question {
            margin-bottom: 10px;

            input {
                width: -webkit-fill-available !important;
            }
        }

        .cmp-job-order-toggle {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            div {
                padding-right: 30px;
            }
        }

    }

    .cmp-job-order-footer {
        display: flex;
        justify-content: flex-end;
        padding: 0 20px 0 20px;
        float: right;

        .cmp-job-order-dialog-footer {
            float: right;
            display: flex;
            justify-content: space-between;

            div:first-child {
                margin-right: 10px;
            }

        }
    }

}

.tab-icon {
    width: 120px;
    height: 120px;
    margin-right: 1em;

}

.success-step {
    display: flex;
    flex-direction: row;
    margin-top: 5%;
}

.success-font {
    font-size: 1.041vw;
    font-weight: 600;
    display: block;
}

.job-label {
    font-weight: 600;
}

.add-role-action-holder {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .add-role-action {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 1em;

        .add-ac-btn {
            color: $white;
            padding: 0.5em 3em;
            border-radius: 5px;
            box-shadow: 0px 1px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 0.1);
        }

        .btn-yes {
            width: fit-content;

        }

        .btn-no {
            width: fit-content;
            margin-right: 1em;
        }
    }
}