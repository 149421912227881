@import '../../../../variables.scss';

.gpc-form-content {
    display: flex;
    justify-content: center;
    padding: 0 2rem;

    .gpc-form-section {
        .gpc-form-sub-title {
            font-size: 0.8999vw;
            margin-top: 1rem;
            font-weight: 900;
            color: $primaryColor;
        }

        .gpc-form-row {
            width: 50%;
            display: flex;
            justify-content: space-between;

            .gpc-form-item {
                margin-right: 1.5rem;

                .gpc-form-radio-btn-title {
                    font-size: 0.729vw;
                    margin-top: 0.7rem;
                    font-weight: 900;
                    color: $primaryColor;

                }

                .form-input-holder {
                    width: 8.715vw;

                    .form-input-tag {
                        min-width: 6.89583vw !important;
                    }
                }

                .cmfs-margin-top {
                    width: 8.824vw;

                    .css-t7ry41-control {
                        width: 8.794vw;
                    }
                }

                .MuiFormControl-root {
                    width: max-content;
                }

                .MuiFormGroup-root {
                    display: inline-block;
                }

                span {
                    text-transform: none;
                    font-size: 0.729vw;
                    color: $primaryColor;
                }

                .form-input {
                    min-width: 4.895833vw;
                    min-height: 0;
                }
            }
        }
    }

}

.gpc-form-actions {
    display: flex;
    justify-content: flex-end;
    border-top: solid 1px lightgray;
    margin-top: 2em;

    .btn-cancel {
        margin: 1rem 1em;
    }

    .btn-save {
        margin: 1rem 1rem 1rem 0;
    }
}