@import "../../../../../variables.scss";

.gp-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 2em;

    .header-container {
        display: flex;
        justify-content: space-between;

        .table-header {
            display: flex;
            align-items: center;
            padding: 1em 0 0.2em 0;

            span {
                font-size: 0.937vw;
                color: $primaryColor;
                font-weight: 800;
                margin-bottom: 0.2em;
                text-transform: none;
            }
        }
    }

    .gp-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        .gp-header-left{
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            .error-holder {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                margin-left: 1em;
                margin-bottom: 0;
            }
            .download-btn {
                margin-left: 1em;
            }
        }
    }

    .gp-content {
        .gp-div-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .gp-col {
                width: 31%;

                .gp-div {
                    width: 100%;
                    background-color: $tableHeaderBackgroundColor;
                    padding: 0.5rem 1.2rem;
                    border:1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                    border-left: 4.5px solid $primaryColor;
                    margin-bottom: 1.5rem;
                    .gp-div-title {
                        font-size: 1vw;
                        color: $primaryColor;
                        font-weight: 700;
                    }
                    // .fees-div{
                    //     table th
                    // }

                    table {
                        width: 100%;
                        margin: 1rem 0 0.5rem 0;
                        border-collapse: collapse;

                        thead th:last-child,
                        tbody td:last-child {
                            text-align: right;
                            padding-right: 0.8rem;
                        }
                        .second-col{
                            text-align: right;
                        }

                        th {
                            text-transform: uppercase;
                            color: $primaryColor;
                            font-weight: 500;
                        }

                        td {
                            color: $primaryColor;
                            padding: 0.2rem 0;
                            font-weight: 700;
                        }


                        .total-row {
                            border-top:1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                            // border-bottom:1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                            margin-top: 1rem;

                            td {
                                padding: 0.4rem 0;
                                font-size: 0.78vw;
                                text-transform: uppercase;
                                font-weight: 800;
                            }

                        }

                        tr:nth-last-child(2) {
                            td {
                                padding-bottom: 0.6rem;
                            }

                        }
                    }


                }
            }


        }
    }
}