@import '../variables.scss';

.sb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: #c0ccd3;
    position: relative;
    padding: 0.144583vw 0.6208333333vw;
    border: 0.3px solid rgb(154, 184, 209);
    border-radius: 4px;

    input {
        position: relative;
        margin-right: 0.5em;
        background: transparent;
        min-width: 10.416666666666666vw;
        width: -webkit-fill-available;
        color: $defaultHighLightColor;
        min-height: 1.5625vw;

        &:focus-visible {
            outline: unset;
        }
    }
    input[type="search"]::placeholder {
        color: $dasboardOptionTextColor;
    }

    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }

    .sb-s-icon {
        width: 0.833vw;
        height: 0.833vw;
        margin-bottom: 4px;
        color: #0b3e79;
        cursor: pointer;
    }
}