@import "../../variables.scss";


.avatar-holder {

    width: 5.729166666666667vw;
    height: 5.729166666666667vw;

    border-radius: 5.729166666666667vw;
    padding: 4px;
    border: 1px solid $primaryColor;
    position: relative;

    .avatar {
        background-image: url('../../../assets/icons/user.svg');
        border-radius: 100%;
    }
    .close-action {
        width: 1.35vw;
        height: 1.35vw;
        cursor: initial;
        position: absolute;
        right: 0px;
        top: 0px;
       // background-color: #ffc107;
        border-radius: 50%;
        border: 1px solid #BC624A;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
            color: $white;
        }
    }

}