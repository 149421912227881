@import '../../../../variables.scss';

.as-search-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient($white, $backgroundColor);
    box-sizing: border-box;
    overflow: hidden;

    .as-nav {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        position: relative;
    }

    .as-side-bar {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: -webkit-fill-available;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        background-color: white;
        padding: 1em;


        .as-side-menu {
            display: flex;
            background-color: $white;
            margin-top: 5px;
            height: -webkit-fill-available;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            padding: 2em 1em;
            box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
            border-right: 1px solid rgba($color: $tableBorderColor, $alpha: 1.0);
        }

        .as-content-bar {
            display: flex;
            flex-direction: column;
            position: relative;
            // height: 80vh;
            width: -webkit-fill-available;
            // overflow-y: auto;
            // padding: 2em 1em;
            box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
            border-right: 1px solid rgba($color: $tableBorderColor, $alpha: 1.0);
            margin-left: 1.5rem;
        }
    }

    .no-results-msg {
       
        padding:2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: -webkit-fill-available;
        span{
            font-size: 1.1vw;
            font-weight: 800;
            color: $defaultHighLightColor;
            min-width:20vw;
            width:20vw;
            text-transform: none;
        }
    }

}