@import "../../../../../variables.scss";

.jp-qstn-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;
  .jp-qstn-header {
    display: flex;
    flex-direction: column;
    color: #0b3e79;
    justify-content: center;
    align-items: center;
    // border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-bottom: 0.5rem;

    .header-title {
      font-size: 1.041vw;
      font-weight: 900;
    }
    .header-sub-title{
      font-weight: 600;
      font-size:0.8vw;
    }
  }

  .jp-qstn-content {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    padding:2em;

    span {
      color: $defaultHighLightColor !important;
      font-size: 0.837vw;
      text-transform: none;
    }
  }

  .jp-qstn-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0rem;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

    .btn-cancel {
      margin-left: 1em;
    }

    .btn-redirect {
      margin-left: 1em;
    }

    .msg-span {
      color: rgba($color: $defaultHighLightColor, $alpha: 1);
      font-weight: 550;
    }

    .copy-icon {
      .copy-btn {
        padding-top: 4px;
        padding-bottom: 4px;
        background-color: transparent;
        box-shadow: none;
      border: 1px solid rgba($color: $defaultHighLightColor, $alpha: 1);

        span {
          font-weight: 550 !important;
        color: $defaultHighLightColor !important;

        }
      }
    }
  }
}