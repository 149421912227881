@import '../../../../../variables.scss';



.tal-dd-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;

    .tal-dd-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .section-header {
            font-size: 0.833vw;
            margin-bottom: 10px;
            color: #0b3e79;
            text-transform: uppercase;
        }
    }

    .tal-dd-content {
        margin-top: 1rem;
        .tal-dd-table {
            // height: 15vw;
            max-height: 8vw;
            overflow: auto;

            .tick-icon svg{
                width: 1.3vw;
                height: 1.3vw;
                font-size: 1.3vw;
                vertical-align: text-top;
            }

            .sortable-table {
                .tb-header-cc {
                    span {
                        overflow: visible !important;
                        padding-right: 1.1em;
                    }
                }
            }

            .sortable-table thead tr .tb-header-cc {
                padding-right: 1.1em;
            }

        }
    }

    .tal-dd-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem 1rem 0rem 1rem;
        align-items: center;

    }

    .paycheck-history-label {
        margin-top: 1em;
        border-top: 1px solid $divDividerColor;
        span {
            font-size: 0.776vw;
            font-weight: 400;
            color: #074783;
            font-stretch: normal;
            font-style: normal;
            margin-left: 0.9em;
            text-decoration: underline;
            cursor: pointer;
            margin-top: 1em;
            text-transform: uppercase;
        }
    }
}