@import '../../../../../variables.scss';

.ac-sc-rm-dialog-header {
    padding: 1em 1em 0 1em;
    .dialog-header {
        span {
            font-size: 1.04vw;
            font-weight: bold;
            color: $defaultHighLightColor;
        }
    }

    .dialog-content {
        padding-top:0.5rem;
        margin-left: 2.9rem;
        span {
            text-transform: none;
        }
        .row-content{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .form-input-algnt {
                margin-right: 3em;
            }
        }
    }

    .dialog-footer {
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 1rem;

        .btn-cancel {
            margin-right: 1em;
        }
    }
}