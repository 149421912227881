@import '../../../../variables.scss';

.ocir-archd-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);



    .ocir-archd-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-bottom: 1em;
    }

    .ocir-archd-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .ocir-archd-add-user-btn {
            display: flex;
            width: fit-content;
            .apps-ud-search{
                margin-right: 1em;
            }
        }

        .ocir-archd-add-icon {
            color: $white;
        }
    }
}

.table-loading {
    width: 100%;
    height: 200px;
}

.ocir-archd-table-header-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;

    .recruiter-selector,
    .company-status-selector {
        margin-right: 1em;
        width: 11.873983vw;
    }

    .ocir-archd-table-search-bar {
        margin-right: 1em;
    }

    .select-user {
        margin-right: 1em;

        .user-form-control {
            min-width: 190px;
            width: fit-content;
        }
    }

    .deparment-toggle {
        margin-right: 1em;
    }

    .total-count {
        span {
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 3;
            letter-spacing: normal;
            color: $defaultHighLightColor;

            .total-count-number {
                font-weight: bold;
            }
        }
    }
}

.website-icon {
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
    text-decoration: none;

}

.open-orders-empty {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .ord-icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 1em;
    }

    .ord-text-empty {
        span {
            color: #b1b1b1;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }

    .ord-text {
        span {
            color: $openOrdersTextColor;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }
}

.status-icon {
    width: 1.25vw;
    height: 1.25vw;
    margin-right: 1em;
    flex-shrink: 0;
}

/// Name
// .ocir-archd-table-name {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     flex-wrap: nowrap;

//     .status-icon {
//         width: 1.25vw;
//         height: 1.25vw;
//         margin-right: 1em;
//     }

//     .name-txt {
//         span {
//             font-size: 0.729vw;
//             font-weight: bold;
//         }
//     }
// }

.pref-contact-icon {
    // width: 1.25vw;
    height: 1.25vw;
}

.ssn-holder {
    display: flex;
    flex-direction: row;
    // align-items: center;
    flex-wrap: nowrap;

    .ssn-number {
        margin-right: 0.5em;

        span {
            margin-top: 2px;
        }
    }

    .loc-open-icon {
        width: 14px;
        height: 14px;
    }
}