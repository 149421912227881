@import '../../../variables.scss';

.ob-cnt-inf-form-container {
    display: flex;
    flex-direction: column;
    padding: 1em 2em 2em 2em;
    width:100%;
    .ob-cnt-inf-form-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.ob-cnt-inf-f-input-holder {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 2em;
}

.ob-cnt-link-icon {
    width: 0.833vw;
    height: 0.833vw;
}