.errors-report-container {
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .er-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-col {
            display: flex;
            align-items: end;
            .toggle-switch{
                margin-left: 1em;
            }
        }

        .right-col {
            display: flex;
            align-items: center;

            .ml {
                margin-left: 1rem !important;
            }
        }

    }

    .er-content {
        .er-table {
            max-height: 55vh;
            overflow: auto;
            margin-top: 1rem;

            .er-resolve-btn {
                .custom-button {
                    padding: 0.2em 0.2em 0.2em 0.2em;

                    .btn-text {
                        span {
                            font-weight: 700 !important;
                        }
                    }
                }
            }
        }

        .na_div {
            width: 100%;
            font-size: 1vw;
        }
    }
}