@import "../../../../variables.scss";

.edc-db-container .rec-card .rec-card-icon .infoIcon circle {
  fill: $employeePortalEDCBgColor !important;
}

.edc-db-container {
  margin: 1rem;
  .onboarding_btn{
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
  }
  .edc-db-tbc {
    padding: 1em;
    color: #0b3e79;

    .title {
      font-size: 0.833vw;
    }
   
  }
  .obd-status-options {
    flex: 3;
    display: flex;
    flex-direction: row;
    padding:1em 0em;
    .option-column {
      display: flex;
      flex-direction: column;
      margin-right: 2em;
    }
  }

  .edc-db-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .edc-db-card {
    width: 45%;
    margin: 1em;
  }

  .ep-edac-dashboard {
    width: 1.45vw;
    height: 1.45vw;
    margin-right: 1.5rem;
  }


}
