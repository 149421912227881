@import '../../../variables.scss';

.lg-page-container {
    display: flex;
    flex-direction: column;

    .lg-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .lg-employee-portal-txt {
            span {
                font-size: 1.56vw;
                font-weight: 900;
                color: $primaryColor;
                font-family: $defaultFontFamily;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                
            }
        }
    }

    .lg-form{
        padding: 2.5em;
    }



}


.reset-password-dialog-container {
    padding: 10px;
    color: $primaryColor;
    padding:1.5rem;
    // height: 15vw;

    .reset-password-dialog-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title{
            font-size: 1.2vw;
            font-weight: 800;
        }

        img {
            float: right;
            cursor: pointer;
        }
    }

    .reset-password-dialog-content {
        margin-bottom: 10px;

        p {
            margin-bottom: 5px;
            font-size: 14px;
        }

        input {
            width: -webkit-fill-available;
            height: 30px;
        }

        .email-message {
            height: 20px;

            p {
                color: red;
                font-size: 10px;
                margin-left: 5px;
                margin-top: 5px;
            }
        }

    }

    .reset-password-dialog-footer {
        float: right;
    }

}