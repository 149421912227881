@import '../../../variables.scss';

.ac-ap-container {
    .ac-um-table-left {
        display: flex;
        justify-items: flex-start;
        align-items: flex-end;

        .total-count {
            margin-left: 1em;

            span {
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                color: $defaultHighLightColor;

                .total-count-number {
                    font-weight: bold;
                }
            }
        }
    }
}