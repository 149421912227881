@import '../../../../variables.scss';

/// Name
.risk-management-tlnt-container{
    .card-div{
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        margin-bottom: 1.2rem;
    }
    .risk-ud-table-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
    
        .status-icon {
            width: 1.25vw;
            height: 1.25vw;
            margin-right: 1em;
        }
    
        .name-txt {
            span {
                font-size: 0.729vw;
                font-weight: bold;
            }
        }
    }
    
    .claim-field-row {
        display: flex;
        flex-direction: row;
        // margin: 0.5rem 3rem 0 0.5rem;
        margin-right:3rem;
        // width: 100%;
        align-items: center;
    }
    
    .claim-field-text {
        color: #0b3e79;
        font-size: 0.729vw;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
    }
    
    .rsk-mng-ud-table-header {
        padding: 1rem 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    
        .header-left {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    
    .rsk-mng-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    
        .rsk-mng-ud-add-user-btn {
            width: fit-content;
            display: flex;
    
            .rsk-mng-ud-search-btn {
                margin-right: 1rem;
            }
        }
    
        .rsk-mng-ud-add-icon {
            color: $white;
        }
    }
    
    .rsk-mng-ud-container {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        min-height: 30vh;
        max-height: 30vh;
        overflow: scroll;
    
        .table-empty-container {
            min-height: 120px !important;
        }
    
    }
    
    .table-loading {
        width: 100%;
        height: 200px;
    }
    
    .rsk-mng-ud-table-header-options {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    
        .rsk-mng-ud-table-search-bar {
            margin-right: 1em;
        }
    
        .select-user {
            margin-right: 1em;
    
            .user-form-control {
                min-width: 190px;
                width: fit-content;
            }
        }
    
        .deparment-toggle {
            margin-right: 1em;
    
            span {
                text-transform: none;
            }
        }
    
        .total-count {
            margin-top: 0px;
    
            span {
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                //line-height: 2.86;
                letter-spacing: normal;
                color: $defaultHighLightColor;
    
                .total-count-number {
                    font-weight: bold;
                }
            }
        }
    }
    
    .website-icon {
        width: 1.25vw;
        height: 1.25vw;
        cursor: pointer;
        text-decoration: none;
    
    }
    
    .open-orders-empty {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    
        .ord-icon {
            width: 1.25vw;
            height: 1.25vw;
            margin-right: 1em;
        }
    
        .ord-text-empty {
            span {
                color: #b1b1b1;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
            }
        }
    
        .ord-text {
            span {
                color: $openOrdersTextColor;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
            }
        }
    }
}
