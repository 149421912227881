@import '../../../variables.scss';

/// Delete action
.del-role-container {
    display: flex;
    flex-direction: column;

    .del-role-header {
        display: flex;
        padding: 1em;
        justify-content: flex-start;
        align-items: center;

        .title-txt {
            span {
                font-size: 1.14vw;
                font-weight: bold;

            }
        }
    }

    .del-role-content {
        padding: 0 1em;
        display: flex;
        flex-direction: column;

        .error-section {
            margin-bottom: 1em;
            width: -webkit-fill-available;
        }

        .message-txt {
            margin-bottom: 1em;
        }
    }

    .del-role-action-holder {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .del-role-action {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 1em;

            .del-ac-btn {
                color: $white;
                padding: 0.5em 3em;
                border-radius: 5px;
                box-shadow: 0px 1px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 0.1);
            }

            .btn-yes {
                width: fit-content;

            }

            .btn-no {
                width: fit-content;
                margin-right: 0.5em;
            }
        }
    }
}


/// Add action
.add-role-container {
    display: flex;
    flex-direction: column;
    padding: 1em 1em 0 0.5em;

    .add-role-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;

        .title-icon {
            width: 1.666vw;
            height: 1.666vw;
            margin-right: 1em;
            color: $defaultHighLightColor;

            svg {
                width: 100%;
                height: 100%;

                path {

                    fill: $defaultHighLightColor;
                }
            }
        }

        .title-txt {
            span {
                font-size: 1.041vw;
                font-weight: 900;
                color: $defaultHighLightColor;
            }
        }
    }

    .add-role-content {
        padding: 0 0 1rem 2rem;

        .add-role-users {
            display: flex;
            flex-direction: column;
            width: -webkit-fill-available;
        }

        .error-section {
            margin-top: 1em;
            margin-bottom: 1em;
            width: -webkit-fill-available;
        }
    }

    .add-role-content-des {
        margin-top: 1em;
        margin-left: 1em;
    }

    .add-role-action-holder {
        display: flex;
        flex-direction: row;
        justify-content: flex-end !important;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

        .add-role-action {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0;

            .add-ac-btn {
                color: $white;
                padding: 0.5em 3em;
                border-radius: 5px;
                box-shadow: 0px 1px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 0.1);
            }

            .btn-yes {
                width: fit-content;
                margin-top: 1em;
            }

            .btn-no {
                width: fit-content;
                margin-right: 1em;
                margin-top: 1em;
            }
        }
    }

    .sys-config-add-category {
        margin-top: 1em;
        padding-left: 1rem !important;
    }
}