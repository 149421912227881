@import '../../../../../variables.scss';

.ac-sales-profile-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    border-radius: 3px;
    background-color: #f7fbfd;

    .ac-sales-profile-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-top: 1em;
        padding-left: 2em;
        padding-right: 2em;
        width: -webkit-fill-available;
        position: relative;

    }

    .job-sales-profile-content {
       padding:1rem 2rem 2rem;
        position: relative;
        width:100%;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;

    }
}

.ac-question{
    margin-bottom: 10px;
    width:48%;
    input {
      width: -webkit-fill-available !important;
    }
    span{
        text-transform: none;
    }
}