@import "../../../../variables.scss";
.competitor-form {
  .cmfs-label {
    margin-bottom: 5px;
    margin-top: 5px;

    span {
      font-size: 0.677vw;
    }
  }
  .uf-content {
    .cmfs-container {
      display: block;
    }
    overflow-x: hidden !important;
    padding-left: 3em;
  }
  .uf-actions {
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 3em;
    &:first-child {
      margin-left: auto;
    }
  }
  .selector-dropdown {
    width: 9.895833333333334vw;

    margin-right: 1.9em;

    .cmfs-margin-top {
      margin-top: 0.3em;
    }

    .cmfs-container {
      .cmfs-label {
         margin-top: 0;
      }
    }
  }
}


.col-md-3 {
  width: 20%;
}