@import "../../../../../variables.scss";

.payment-register-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .payment-register-header {
        display: flex;
        justify-content: space-between;

        .header-section-one {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .select-type {
                margin-left: 1em;
                margin-right: 1em;
            }

            .dateRange {
                width: min-content;
                margin-top: 0.3em;

                input {
                    width: 10vw !important;
                    min-width: 10.5vw !important;
                    color: $defaultHighLightColor !important;
                }
            }

            .error-holder {
                width: 15vw;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 0;
            }
        }

        .header-section-two {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .dwn-margin {
                margin-left: 1em;
                margin-right: 1em;
            }

            .total-count-action {
                margin-left: 1.5em;

                span {
                    width: max-content;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.2;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;

                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }
        }

    }

    .table-container {
        border-radius: 0.2em;
        border: solid 1px #c0c2c4;
        margin-top: 2em;

        .pr-table {
            max-height: 50vh;
            overflow: auto;
            position: relative;
    
            .sortable-table thead {
                z-index: 0;
                position: sticky;
            }
        }

        .table-header-title {
            span {
                font-size: 0.937vw;
                color: $primaryColor;
                font-weight: 800;
                margin-bottom: 0.2em;
                padding: 1em 1em 1em 1em;
            }

        }
    }

    .total-counts-card {
        display: flex;
        justify-content: space-between;
        background: #f7fbfd 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        border-left: 4px solid $defaultHighLightColor;
        border-radius: 4px;
        opacity: 1;
        width: 100%;
        margin-top: 2em;
        padding: 1em;

        .total-title {
            color: #074783;
            font-size: 0.792vw;
            margin: 0 0.5em 0em 0.5em;
            font-weight: 800;
            flex-direction: row-reverse;
        }

        .total-count {
            width: 36%;
            color: #074783;
            font-size: 0.792vw;
            font-weight: 800;
            flex-direction: row-reverse;

            span {
                text-transform: none;
            }
        }
    }

}