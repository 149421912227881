@import '../../../../variables.scss';

.as-cnt-imp-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .as-contact-image {
        width: 2.5vw;
        height: 2.5vw;
    }

    .as-cnt-imp-f-content {
        // padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

    }

    .company-row {
        display: flex;
        flex-direction: column;

        .icons-row {
            margin-top: 0.5rem;

            svg {
                width: 0.65vw;
                height: 0.65vw;
            }
        }
    }

    .company-div-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .comp-logo {
            width: 2vw;
            height: 1.8vw;
            margin-right: 0.5rem;
        }
    }

    .social-media-row {
        svg {
            width: 0.85vw;
            height: 0.85vw;
        }
    }

    .as-srch-table-holder {
        margin-top: 0.8rem;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 68vh;
        width: -webkit-fill-available;
        overflow-y: auto;
        .sortable-table thead {
            z-index: auto;
         }
    }
  

    .as-cnt-btn-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
            color: $defaultHighLightColor;
        }
        .credits-row {
            margin-right: 1rem;
            font-weight: 600;
            color: $defaultHighLightColor;

            span {
                font-size: 0.85vw;

            }
        }
        .creds-div-row{
            display: flex;
            flex-direction: row;
            justify-content:space-between            
        }
        .creds-row{
            display:flex;
            flex-direction: row;
            align-items: center;
            // justify-content:;
            .no-creds-div{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin:0 1rem;
            }
            .no-creds{
                color:$statusWarningColor;
                margin-right:1rem;
                font-size:0.7vw;
            }
            .add-creds-div{
                color: $defaultHighLightColor;
                font-weight: 800;
                text-decoration: underline;
                cursor:pointer;
            }
        }
        .selectd-count-div, .total-count{
            margin-right:1rem;
            span{
                font-size:0.78vw;
            }
        }
    }

    .circle-download-svg {
        svg {
            path {
                fill: white;
            }
        }
    }

    .icon-hover-div {
        position: relative;
        padding: 0.2rem;

        .mail-icon,
        .phone-icon {
            svg {
                display: block;
                width: 1.25vw;
                height: 1.25vw;
            }
        }

        .parent-position {
            height: 0;
            position: absolute;
            width: 0;
            z-index: 1000;
            top: 1.5vw;
            // left: 50%;
            opacity: 0;
            /* Initially hidden */
            pointer-events: none;
            /* Hide it from interactions */
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

            .child-position {
                display: flex;
                position: absolute;
                transform: translate(-46%, 0%);
                background-color: white;
                // margin-top: 0.5em;
                padding: 0.5em 1em;
                border-radius: 4px;
                border: 1px solid rgba($color: $tableRowBorderColor, $alpha: 1);
                .phone-details{
                    display: flex;
                    flex-direction: column;
                    .phone{
                        text-wrap: nowrap;
                    }
                    
                }
            }

            .child-position::after {
                content: '';
                height: 10px;
                width: 10px;
                position: absolute;
                background-color: white;
                top: -5px;
                left: calc(50% - 10px);
                border-top: rgba($color: $pageHeaderBoderColor, $alpha: 1.0) solid 1px;
                border-left: rgba($color: $pageHeaderBoderColor, $alpha: 1.0) solid 1px;
                transform: rotate(45deg);
            }




        }
    }

    .icon-hover-div:hover .parent-position {
        opacity: 1;
        /* Show the tooltip when hovered */
        pointer-events: auto;
        /* Enable interactions with the tooltip */
    }
}