@import '../../../../../variables.scss';


.markup-po-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0 0 0;
    border: 0.1px solid rgba(193, 205, 212, 0.2509803922) !important;

    .markup-container {
        padding: 0rem 1rem;
        height: 22vh;
        border-bottom: 0.1px solid rgba(193, 205, 212, 0.2509803922);

    }

    .po-container {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        padding: 0rem 1rem;
    }

    .markup-po-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .markup-po-header-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .sb-container {
                width: 11vw;
                margin-right: 0.5rem;
            }
        }

        .section-header {
            // max-width: 100%;
            // width: 100%;
            font-size: 0.833vw;
            margin-bottom: 10px;
            color: #0b3e79;
            text-transform: uppercase;
        }
    }

    .markup-po-content {
        margin: 0.5rem 0;
        overflow: auto;
        max-height: 15vh;
        height: 65%;

        .markup-po-input-holder-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            margin-bottom: 0.6rem;

            .markup-po-content-input-holder {
                width: 100%;
                margin-right: 2rem;
            }
        }

        .empty-data-msg {
            text-align: center;

            span {
                font-size: 1vw;
                color: $tableBorderColor;
            }
        }

        .toggle-row {
            margin-top: 1.5rem;
        }
    }

    .markup-po-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .markup-po-actions-btn {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .btn-cancel {
            margin-right: 1rem;
        }
    }
}