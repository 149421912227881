@import "../../../../../variables.scss";

.jb-f-stp-six {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

  padding-top: 2em;
  padding-bottom: 2em;
  margin-bottom: 2em;


  .jb-f-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2em;
  }

  .jb-f-input-holder {
    flex: 1;
    min-width: 30%;
    margin-bottom: 1em;
    // margin-right: 2em;
    justify-self: flex-start;
    width: 30%;
  }
}

