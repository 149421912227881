@import "../../variables.scss";

.teh-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  // background-color: #F7FBFD;

  //   padding: 20px;
  box-sizing: border-box;
}

.teh-panel1,
.teh-panel2,
.teh-panel3 {
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: calc(32.33333% - 10px);
  // border-right: 2px solid rgba($color: $divDividerColor, $alpha: 0.6);
    // padding: 0 1.5em;
  background: white;
  box-shadow: 0px 3px 5px #00000029;
  border-radius: 10px;

  .teh-panel-header {
    text-transform: uppercase;
    padding: 0.8em 2em;
    background-color: #dae2eb;
    font-size: 0.8vw;
    font-weight: 600;
    border-radius: 10px 10px 0px 0px;
    color: $userProfileNameColor;
  }
  .panel-info {
    padding: 0.8em 2em;
  }
}

.teh-info-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  // border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
//   margin-bottom: 1em;
  padding:1em 0.5em 1em 3em;
  border-bottom: 1px solid rgba($color: $divDividerColor, $alpha: 0.6);
  .teh-info-icon {
    width: 0.833vw;
    height: 0.885vw;
    margin-right: 1em;
  }

  .teh-info-content {
    display: flex;
    flex-direction: column;

    .teh-info-holder {
      display: flex;
      flex-direction: column;

      .teh-into-title {
        span {
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          font-size: 0.69vw;
        }
      }

      .teh-info-value {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        span {
          font-weight: 800;
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          padding-top: 0.7em;
          font-size: 0.69vw;
        }

        .teh-info-icon {
          width: 1.666vw;
          height: 1.666vw;
          cursor: pointer;
          padding: 0.5em;
          transition: 0.3s all ease-in-out;

          &:hover {
            background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
          }
        }
      }
      .sup_contact_div .teh-info-value{
        margin-bottom: 1em;;
      }
      .placed_person_div{
        margin-top: 1em;
      }

      .switch {
        .slider:after {
          content: 'NO' !important;
        }
    
        input:checked+.slider:after {
          content: 'YES' !important;
        }
      }
    }
  }
 
}

.teh-action-container {
  // border-top: 2px solid rgba($color: $divDividerColor, $alpha: 0.6);
  padding-top: 20px;
  .teh-action{
    width:100%;
    padding:1em 0.5em 1em 3em;
  }
}
