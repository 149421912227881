@import '../../../../variables.scss';


.as-side-nav-holder {
    display: flex;
    flex-direction: column;
    position: relative;
    height: -webkit-fill-available;
    width: -webkit-fill-available;

    .as-nav-exp-item-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding: 0 1em;

    }
}

.as-cnt-imp-f-section-divider {
    padding-right: 0.5rem;
}

.as-left-nav-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 70vh;
    width: -webkit-fill-available;
    overflow-y: auto;
    max-width:20vw;
    .as-nav-item-container {
        text-decoration: none;
        // background-color: $white;
        margin-bottom: 0.4em !important;
        transition: 0.3s all ease-in-out;
        border-radius: 4px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 0 0;
        padding: 0;
        list-style: none;
    }

    .as-section-title-txt {
        font-weight: 900;
        color: $defaultHighLightColor;

        span {
            font-size: 0.9vw;
        }


    }

    .as-cnt-credits-f-row {
       
        display: flex;
        flex-direction: column;
        width: 100%;
        .credits-row {
            margin-bottom: 0.7rem;
            font-weight: 600;
            color: $defaultHighLightColor;

            // span {
            //     font-size: 0.85vw;

            // }
        }
        .cred-btn-row{
            display: flex;
            justify-content: flex-end;
            margin-top:0.2rem;
            .add-credits-btn{
                padding:3px 4px !important;
                .btn-text span{
                    // font-size:0.8vw !important;
                    font-weight: 600 !important;
                }
            }
        }
        .no-creds-div{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .no-creds{
            color:$statusWarningColor;
            margin:0.2rem 0rem 0.4rem 0;
            font-size:0.7vw;
        }
        .add-creds-div{
            color: $defaultHighLightColor;
            font-weight: 800;
            text-decoration: underline;
            cursor:pointer;
            font-size:0.78vw;
        }
    }


    .as-cnt-title-f-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-bottom: 0.5rem;
        .filter-count {
            border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
            border-radius: 24px;
            padding: 0.1rem 0.5rem;

            span {
                font-size: 0.8vw;
                color: $defaultHighLightColor;
            }

            svg {
                width: 0.8vw;
                height: 0.8vw;
                margin-bottom: 0.2rem;
                cursor: pointer;
            }
        }


    }
}
.as-filters-container{
    padding-bottom: 0.5rem;
    .input-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        // width:;
        border-top:1px solid rgba(165, 165, 165, 0.4);
        margin-top:1rem;
        padding-top:0.5rem;

        .label-div{
            width:100%;
            label{
                font-size: 0.8vw;
                color:$defaultHighLightColor;
                font-weight: 800;
                margin-bottom: 0;
            }
        }
        .as-cnt-imp-f-input-holder{
            width:46%;
            min-width: 46%;
            .form-input{
                min-width:5vw !important;
            }
            .form-input-holder .form-input-tag{
                width:100%;
                min-width:5vw !important;
            }
        }
    }
}

.as-cnt-imp-f-input-holder {

    .label-text-field {

        font-size: 0.729vw;
        font-weight: 900;
        color: rgb(11, 62, 121);

    }

    .creatable_text {

        .css-13cymwt-control,
        .css-t3ipsp-control {
            color: #005A9A;
            border-radius: 3px;
            // padding: 0.5em;
            border: 0.3px solid #9AB8D1;
            font-size: 0.729vw;
            min-width: 9.8958333333vw;
            max-width: 14.8958333333vw;
            min-height: 1.6274853801vw;

            .css-qbdosj-Input {
                margin: 0px;

            }
        }

        .css-1jqq78o-placeholder {
            color: $defaultHighLightColor;
            opacity: 0.5;
            margin: 0px;
        }

        .css-qbdosj-Input {
            padding: 0px;
        }

        .css-3w2yfm-ValueContainer {
            padding: 2px 2px 2px 6px;
        }

        .css-12a83d4-MultiValueRemove {
            padding: 0
        }
    }

    .date-input-holder-div {
        width: 100%;
    }
}

.as-search-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    // margin-left: 3em;
    margin-top: 1em;

    .btn-cancel {
        margin-right: 1em;
    }
}