@import '../../../../variables.scss';


.backoffice-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .backoffice-card {
        flex: 1;
        position: relative;
        margin-bottom:1.5rem;
        min-width: 49%;
        max-width: 49%;
        width: 49%;
        border: 0.1px solid rgba(193, 205, 212, 0.2509803922) !important;

        .scrd-container {
            width:100%;
            height: 100% !important;
            border: none !important;
            min-height: auto !important;
        }
        .er-div{
            width: 100%;
            .error-section{
                margin-bottom: 0rem !important;
            }
        }

    }
}