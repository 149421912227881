
@import '../variables.scss';
.aut-page-container {
    display: flex;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.aut-page-container {
    background-color: #f4f4f6;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    box-sizing: border-box;

}

.aut-lotty-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    box-sizing: border-box;

    .aut-wrapper {
        margin-left: 7vw;
        width: 28.549vw;
        box-sizing: border-box;
        position: relative;
    }

    .aut-shape-wrapper {
        box-shadow: 0 0 5px 0 rgba(150, 149, 149, 0.2);
        height: 100%;
        box-sizing: border-box;
        position: relative;
        border-radius: 30px;
        background-color: white;

        // .aut-shape-pyramid {
        //     border-top: 0.78125vw solid transparent;
        //     border-right: 1.0416vw solid $defaultHighLightColor;
        //     border-bottom: 0.78125vw solid transparent;
        //     width: 0;
        //     height: 0;
        //     position: absolute;
        //     bottom: 35%;
        //     left: -1.0416vw;


        // }

        // .aut-shape-pyramid:before {
        //     content: "";
        //     position: absolute;
        //     border-top: 14px solid transparent;
        //     border-right: 19px solid #fff;
        //     border-bottom: 14px solid transparent;
        //     width: 0;
        //     height: 0;
        //     top: -14px;
        //     left: 1px;
        // }

        .aut-holder {
            padding: 0.3em;
            box-sizing: border-box;

        }
    }


}

.aut-lotty-wrapper{
    margin-right: 7vw;

    .aut-users-lotty {
        width: 27.175vw;
        height: 48.979vh;
        margin-right: 3em;
    
        .aut-users-svg {
            // background-image: url('../../assets/icons/kiniso.svg');
            background-size: contain;
        }
    }

}

