@import '../../variables.scss';


.ups-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.ups-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;

    .ups-avatar-holder {

        width: 110px;
        height: 110px;

        border-radius: 110px;
        padding: 4px;
        border: 1px solid $primaryColor;

        .ups-avatar {
            background-image: url('../../../assets/icons/user.svg');
            border-radius: 100%;
        }

    }

    .ups-user-name {
        margin-top: 0.5em;
        span {
            font-size: 1.25vw;
            font-family: $defaultFontFamily;
            color: $userProfileNameColor;
            font-weight: 900;
        }
    }

    .ups-user-tag {
        span {
            font-size: 0.729vw;
            font-family: $defaultFontFamily;
            color: $tableRowTextColor;
            font-weight: 900;
        }
    }
}

.ups-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em;

    .ups-info-icon {
        width: 0.833vw;
        height: 0.885vw;
        margin-right: 1em;
        color: $companiesOptionTextColor;
        
        .cls-1{
            fill: $companiesOptionTextColor;
        }
    };
    

    .ups-info-content {
        display: flex;
        flex-direction: column;

        .ups-info-holder {
            display: flex;
            flex-direction: column;

            .ups-into-title {
                span {
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    font-size: 0.833vw;
                }
            }

            .ups-info-value {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 0.5em;

                span {
                    font-weight: 800;
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                }

                .ups-info-icon {
                    width: 1.666vw;
                    height: 1.666vw;
                    cursor: pointer;
                    padding: 0.5em;
                    transition: 0.3s all ease-in-out;
                    &:hover{
                        background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }

            }

            .contact-table{
                width: 20vh;
                border: 0px;
                td{
                    border:none;
                    color:$defaultHighLightColor;
                    width:35%;
                    vertical-align: top;
                    padding:0.2em 0;
                }
                td:nth-child(2){
                    font-weight: 900;
                    width:70%;
        
                }
            }

        }
    }
}
// .cls-1{
//     fill: $companiesOptionTextColor !important;

// }