@import '../../../../../variables.scss';


.billing-invoices-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .billing-invoices-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .select-row {
            display: flex;
            flex-direction: row;

            .total-count {
                margin-top: 2.2rem;

                span {
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;

                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }

            .cmfs-container {
                margin-right: 1em;
            }

        }

        .bi-header-right {
            display: flex;
            flex-direction: row;

            .search-bar {
                margin-left: 1rem;
            }
        }
    }

    .billing-invoices-content {
        position: relative;

        .billing-invoices-table {
            margin-top: 2rem;
            overflow: auto;
            max-height: 55vh;  

            .view-invoice-btn,
            .menu-icon {
                padding: 0.2em 0.2em 0.2em 0.5m;
                border-radius: 2px;
                cursor: pointer;

                svg {
                    width: 0.9vw;
                    height: 0.9vw;
                }

                .btn-text {
                    display: none;
                }
            }

            .menu-icon {
                svg {
                    fill: $primaryColor;
                }

                margin-left: 1rem;

                .parent-position {
                    position: relative;
                    width: 0;
                    height: 0;
                    z-index: 1;

                    .child-position {
                        position: absolute;
                        top: 0em;
                        right: 0em !important;
                        background-color: white;
                        box-shadow: 0px 0px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 1);
                        min-width: 8vw;

                        .item-menu-title {
                            text-transform: capitalize;
                            font-size: 0.700vw;
                            color: $defaultHighLightColor;
                            padding: 1em;
                            width: 100%;
                            font-weight: 800;
                            cursor: pointer;

                            .rm-f-batch {
                                margin-bottom: 1em;
                            }
                        }

                        .item-menu-title:hover {
                            background-color: $backgroundHoverColor;
                        }
                    }
                }
            }
            .disable-div{
                pointer-events: none;
                svg {
                    fill: $statusDisabledColor;
                }
            }
            .table-data-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
            }
        }
    }

}