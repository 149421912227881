@import '../../../../variables.scss';


.add-rate-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .add-rate-header {
        .title-name span {
            text-transform: none;
        }
    }

    .add-rate-content {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        padding:2rem 1rem;
        .add-rate-input-holder-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0.5em;
            width: 100%;

            .add-rate-input-holder {
                width: 100%;
                margin-right: 2rem;
                span {
                    text-transform: none;
                }
            }
        }
    }

    .add-rate-actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 1rem;
        border-top: 1px solid hsla(0, 0%, 65%, .4);
        .btn-cancel {
            margin-right: 1rem;
        }
    }
}