@import '../../../../variables.scss';

.tlnt-clnt-in-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;
    // max-width: 50%;

    .tlnt-clnt-in-f-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .tlnt-clnt-in-f-content {
        padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .tlnt-clnt-in-f-section-divider {
            padding-bottom: 2em;

            .tlnt-clnt-in-f-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                justify-items: stretch;
                align-items: stretch;

                .tlnt-clnt-in-f-input-holder {
                    min-width: 11.2vw !important;
                    margin-right: 1em;

                    .time-picker-container {
                        margin-top: 1.72em !important;
                        .MuiInputBase-input {
                            margin-left: 0 !important;
                            min-width:9vw;
                        }
                    }
                }
                .tlnt-clnt-in-f-input-holder-first-row {
                    min-width: 15.25vw !important;
                    margin-right: 1em;
                    .MuiInputBase-input {
                        width: 12vw;
                    }
                }
            }
        }

        .tint-ihi-questions-section {
            display: flex;
            flex-direction: column;

            .tint-ihi-question-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 1em;

                .tint-ihi-question-title {
                    // margin-bottom: 0.5em;

                    span {
                        font-size: 0.833vw;
                        color: $defaultHighLightColor;
                        font-weight: bold;
                    }
                }

                .tint-ihi-input-form {
                    display: flex;
                    flex-direction: column;
                    // margin-left: 1em;
                }
            }
        }

        .error-section {
            margin-bottom: 2em;
        }
    }

    .tlnt-clnt-in-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top:1.5em;
        margin-left:3em;
        .btn-cancel {
            margin-right: 1em;
        }
    }

}

.tlnt-clnt-in-f-link-icon {
    width: 0.833vw;
    height: 0.833vw;
}

@media only screen and (max-width: 1440px) {
    .tlnt-clnt-in-f-container {
        .tlnt-clnt-in-f-content {
            .tlnt-clnt-in-f-section-divider {
                .tlnt-clnt-in-f-row {
                    .tlnt-clnt-in-f-input-holder {
                        .time-picker-container {
                            margin-top: 1.446em !important;
                        }
                    }
                }
            }
        }
    }
}