@import '../../variables.scss';

.error-handler-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    min-height: 18.301610541727673vw;

    .error-msg {
        margin-bottom: 1em;

        span {
            font-size: 1.25vw;
            color: $tableBorderColor;
        }
    }

    .error-action {
        width: fit-content;
    }
}


.err-h-add-icon{
    width: 1.25vw;
    height: 1.25vw;
    // font-size: 1.25vw;
    color: $white;
}

/// Empty list
/// 
.empty-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 18.301610541727673vw;
    padding: 1em;

    .empty-msg {
        margin-bottom: 1em;

        span {
            font-size: 1.25vw;
            color: $tableBorderColor;
        }
    }

    .refresh-action {
        width: fit-content;
    }
}

.loading-handler {
    width: 100%;
    height: 14.641288433382138vw;
}