@import '../../variables.scss';

.time-picker-container {
    .time-input-er-mes{
        font-size: 0.75rem;
        color: #f44336;
        margin-left: 1em !important;
        margin-top: 0.4em !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        margin: 0;
    }
    .MuiGrid-item {
        padding: 1px !important;
    }

    .MuiButton-label {
        font-size: small;
        font-weight: 900;
    }

    .MuiList-padding {
        padding-top: 0 !important;
    }

    &.required{
        .MuiInputBase-input {
            border-color: red !important;
        }
    }

    .MuiInputBase-input {
        padding: 0 0 0 0.5em !important;
        border: 0.3px solid $inputoutlineColor !important;
        border-radius: 0.3em !important;
        margin-left: 1em !important;
        cursor: pointer;
    }

    .MuiSvgIcon-root {
        fill: $primaryColor;
        position: relative;
        right: 2rem;
        cursor: pointer;
        height: 1vw;
        width: 1vw;
    }

    // .MuiInput-root {
    //     padding: 0 0 0 0.5em !important;
    //     border: 1px solid $tableBorderColor !important;
    //     border-radius: 0.3em !important;
    //     margin-left: 1em !important;
    //     cursor: pointer;
    // }

    .MuiFormLabel-root {
        max-width: 100%;
        box-sizing: border-box;
        word-break: unset;
        font-size: 0.729vw;
        font-family: Lato;
        margin-top: -0.4em;
        font-weight: 900;
        color: rgb(11, 62, 121);
    }

    .MuiInputLabel-formControl {
        transform: none;
    }

    .MuiInput-underline:before {
        border-bottom: none !important;
    }

    .MuiInput-underline:after {
        border-bottom: none !important;
    }
}