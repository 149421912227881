@import '../../../../variables.scss';


.ar-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .ar-header {
        display: flex;
        flex-direction: row;
    }

    .ar-content {
        display: flex;
        justify-content: center;
        padding: 2rem 1rem;

        span {
            color: $primaryColor;
            font-size: 1vw;
            font-weight: 700;
            text-transform: none;
        }
    }

    .ar-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .ar-actions-row {
            display: flex;
            flex-direction: row;
        }
    }
}