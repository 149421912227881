@import '../../../../../variables.scss';

.cash-receipts-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .cr-table {
        max-height: 40vh;
        overflow: auto;
        position: relative;

        .sortable-table thead {
            z-index: 0;
            position: sticky;
        }
    }

    .invoice-report-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .cr-h-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .select-type {
                margin-left: 1em;
            }
            .error-holder {
                width: 15vw;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-left: 1em;
                margin-bottom: 0;
            }
        }
        .cr-h-right {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .select-type {
                margin-left: 1em;
            }
            .total-count-action {
                margin-left: 1.5em;
    
                span {
                    width: max-content;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;
    
                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .total-counts-first-card {
        .total-count-main {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 63.6%;

            .total-count {
                width: 11.5%;
            }
        }
    }

    .total-counts-second-card {
        width: 25%;

        .mt-b {
            margin-top: 2rem;
        }

        .mt {
            margin-top: 0.5em;
        }
    }
}