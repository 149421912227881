@import '../../../../variables.scss';

.cmpny-op-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding-top: 1em;
    overflow: hidden;

    .cmpny-op-f-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .cmpny-op-f-content {
        padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        // overflow: auto;
        margin-bottom: 2em;
        // min-height: 140px;

        .cmpny-op-f-section-divider {
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

            .cmpny-op-f-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .cmpny-op-f-input-holder {
                    width: fit-content;
                    margin-right: 1em;
                }
            }
        }
        .error-section{
            margin-top: 1em;
            margin-bottom: 2em;
        }
    }

    .cmpny-op-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .btn-cancel {
            margin-right: 1em;
        }
    }

}

.cmpny-op-f-link-icon{
    width: 0.833vw;
    height: 0.833vw;
}