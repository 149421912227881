@import '../variables.scss';

.ub-container {
    width: $navbarUserIconSize;
    height: $navbarUserIconSize;
    border-radius: $navbarUserIconSize;
    padding: 2px;
    border: 1px solid $primaryColor;
    cursor: pointer;

    .ub-avatar{
        background-image: url('../../assets/icons/user.svg');
        border-radius: 100%;
    }
}

/// user menu

.ub-user-menu-list{
    display: block;
    position: absolute;
    z-index: 10000;
    // width: 200px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    right: 2em;
}

.ub-pyramid{
    border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 10px solid rgb(255, 255, 255);
    width: 0;
    height: 0;
    position: absolute;
    top: -7px;
    right: 1em;
}