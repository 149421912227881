@import "../../../../../variables.scss";

.ibl-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    box-sizing: border-box;

    .ibl-left-panel {
        flex: 1;
        min-width: 240px;
        display: flex;
        padding: 1em;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        margin-right: 2em;
        position: relative;
        box-sizing: border-box;
        min-height: -webkit-fill-available;
    }

    .ibl-right-panel {
        flex: 4;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        min-height: -webkit-fill-available;
    }
}