@import "../../../../variables.scss";

.new-edoc-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 2em;
    overflow: hidden;

    .new-edoc-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;

        .title-name span {
            text-transform: none;
        }
    }

    .new-edoc-content {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        margin-top: 0.5rem;

        .saved-doc-holder {
            padding: 2rem;

            .aknw-name, .acknw-div {
                font-size: 1.284375vw;
                font-weight: 800;
                color: #0b3e79;
                text-overflow: ellipsis;
            }
        }

        .edoc-form {
            display: flex;
            flex-direction: column;

            .edoc-input-holder-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 1rem;

                .edoc-ip-holder {
                    width: 48%;
                }
            }
        }
    }

    .new-edoc-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1rem;
        margin-top: 1.5rem;

        .btn-cancel {
            margin-right: 1em;
        }
    }
}