@import '../../../../../../variables.scss';

.invoice-list-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .invoice-list-header {
        font-size: 1.041vw;
        font-weight: 900;
        color: #0b3e79;
        margin-bottom: 1.5em;
    }

    .table-content{
        height: 20%;
        min-height: 20%;
    }

    .total-counts-card {
        display: flex;
        justify-content: space-between;
        background: #f7fbfd 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        border-left: 4px solid $defaultHighLightColor;
        border-radius: 4px;
        opacity: 1;
        width: 100%;
        margin-top: 2em;
        padding: 1em;

        .total-title {
            color: #074783;
            font-size: 0.792vw;
            margin: 0 0.5em 0em 0.5em;
            font-weight: 800;
        }

        .total-count-content {
            width: 23%;
            display: flex;
            justify-content: space-between;
            .total-count {
                color: #074783;
                font-size: 0.792vw;
                font-weight: 800;
                margin-right: 2.5em;
            }
        }
    }

    .invoice-list-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;
    }
}