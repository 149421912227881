@import "../../../../variables.scss";

.create-talent-emp-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;

  .create-talent-emp-header {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
  }

  .create-talent-emp-content {
    padding-left: 3em;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;

    .create-talent-emp-section-divider {
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

      .create-talent-emp-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0.5em;
        align-items: baseline;
        .create-talent-emp-input-holder {
          width: 9.8vw;
          margin-right: 2.5em;
          color: #0b3e79;

          .error {
            font-size: 0.75rem;
            text-align: left;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            letter-spacing: 0.03333em;
            margin: 0.4em 1em 0 1em;
            color: red;
          }
        }
      }
      .create-talent-emp-row.sec-3,
      .create-talent-emp-row.sec-4,
      .create-talent-emp-row.sec-6 {
        .create-talent-emp-input-holder {
          flex: 1 1 auto;
        }
      }

      .create-talent-emp-row.sec-4 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
      }
    }

    .create-talent-emp-section-divider:last-child {
      border: none;
      padding-bottom: 1em;
      margin-bottom: 1em;
    }

    .error-section {
      margin-bottom: 2em;
    }
  }

  .create-talent-emp-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 3em;

    .fl-right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    .btn-cancel {
      margin-right: 1em;
    }

    .btn-assign-to-call-deck {
      button {
        background-color: transparent !important;
        border: 2px solid rgb(11, 62, 121) !important;
      }

      span {
        color: rgb(11, 62, 121) !important;
      }
    }
  }

  .company_label {
    color: $primaryColor;
    font-weight: 800;
    margin-bottom: 5px;
  }

  .creatable_select {

    .css-1s2u09g-control,
    .css-3lqgde-control {
      border: 0.3px solid #9AB8D1;
      color: #0b3e79;
    }

    .css-1jqq78o-placeholder {
      color: #0b3e79;
      text-transform: lowercase;
      margin-bottom: 0.5em;
    }

    .css-166bipr-Input,
    .css-qbdosj-Input {
      color: #0b3e79;
      margin: 0;
      padding: 0;
    }

    .css-1u9des2-indicatorSeparator {
      border-color: #9AB8D1;
      height: 1.1274853801vw;
    }

    .css-1dimb5e-singleValue {
      color: #0b3e79;
    }

    svg {
      width: 1.02308vw;
      height: 0.92308vw;
      color: #0b3e79;
      opacity: 0.6;
    }
  }

  .switch {
    .slider:after {
      content: 'NO' !important;
    }

    input:checked+.slider:after {
      content: 'YES' !important;
    }
  }

  span {
    text-transform: initial !important;
  }
}