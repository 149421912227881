@import '../../variables.scss';
.field-data-container{
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        font-weight: 800;
        color: $userProfileNameColor;
        font-family: $defaultFontFamily;


        &:hover {
            text-decoration: underline;
            font-weight: 800;
        }
    }

    .field-data-btn{
        display: flex;
        flex-direction: row;
        color: $userProfileNameColor;
    }
}