@import '../../variables.scss';


.bo-card-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    background: $white;

    .bo-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1em;

        .bo-card-button{
            padding: 1em;
        }

        .bo-card-sub-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1em;
            cursor:pointer;
            .bo-card-path-name {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;

                .bo-card-path-txt {
                    font-size: 0.937vw;
                    font-family: $defaultFontFamily;
                    font-weight: 900;
                    text-transform: none;
                }
            }

            .bo-card-icon {
                svg {
                    width: 1.5vw;
                    height: 1.5vw;
                    margin-left: 0.5em;
                    color: $primaryColor;

                }
            }
        }
    }
}