@import "../../../../variables.scss";

.ac-sc-text-em-all-input{
  .generate-btn{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-left: 2em;
  }
}
.text-em-auth-dialog {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;

  .text-em-all-dialog-header {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
    .title-icon{
      display: none;
    }
  }
  .text-em-all-dialog-content {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
  }
  .text-em-all-dialog-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-top:1em;
    .btn-cancel {
      margin-right: 1em;
    }
  }

}
