@import '../../variables.scss';

.adc-option {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 0.2);
    border-radius: 2.8px;
    margin-right: 2em;
    margin-bottom: 2em;
    background-color: $cardColor;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    text-decoration: unset;

    .adc-option-icon-title {
        width: 12vw;
        min-height: 12vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:1rem 0;
    }

    &:hover {
        background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1);
    }

    .adc-option-icon {
        width: 4.6875vw;
        height: 4.6875vw;
        margin-bottom: 2em;

        svg path {
            fill: #153888 !important;
        }
    }

    .adc-option-icon-two {
        width: 1.6875vw;
        height: 1.6875vw;
        position: absolute;
        top: 0.7rem;
        right: 0;

        svg path {
            fill: #153888 !important;
        }
    }
    .adc-option-sup-text {
        width:fit-content;
        position: absolute;
        top: 0.7rem;
        right: 0;
        margin-right:1rem;
        span{
            color:$primaryColor;
            font-weight: 700;
        }
    }
    .adc-option-icon-tea {
        width: 4.6875vw;
        height: 4.6875vw;
        margin-bottom: 2em;

        svg {

            path:first-child,
            path:nth-child(2),
            path:nth-child(3) {
                stroke: #153888 !important;
                stroke-width: 1.5px;
                fill: white !important;
            }
        }
    }

    .adc-option-title {
        display: flex;
        justify-content: center;
        width: fit-content;

        div {
            font-size: 0.989vw;
            font-weight: 800;
            color: $defaultHighLightColor;
            text-overflow: ellipsis;
            text-align: center;
            padding:0 1rem;
        }
    }
}