@import '../../variables.scss';


.date-input {
  color: $inputBorderColor;
  border-radius: 3px;
  font-size: 0.729vw;
  min-width: 9.895833333333334vw;

  .MuiSvgIcon-root {
    width: 1.123vw !important;
    height: 1.123vw !important;
    margin-bottom: 0.2em;
  }
}



.MuiIconButton-label,
.MuiInputBase-input {
  color: #005a9a !important;
  font-size: 0.729vw !important;
}

.MuiSvgIcon-fontSizeInherit {
  font-size: inherit;
}

// .MuiSvgIcon-root {
//   font-size: 1.25vw !important;
// }

.MuiOutlinedInput-root {
  border-radius: 3px;
}

.MuiOutlinedInput-input {
  // padding: 0.9635416666666666vw 0.7291666666666666vw !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0.7291666666666666vw !important;
}

.MuiOutlinedInput-inputMarginDense {
  padding-top: 0.246875vw !important;
  padding-bottom: 0.346875vw !important;
}

.MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 0px;
}

.MuiInputAdornment-positionEnd {
  margin-left: 0px;
}

.date-input-holder-div{
  .Mui-error{
    margin-left: 0px !important;
    // margin:0 !important;
    // padding:5px !important;
    font-size:10px !important;
  }
}