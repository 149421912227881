@import '../../../../variables.scss';

.cps-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);

    .circle-download-svg{
        svg{
            path{
                fill:white;
            }
        }
    }
    .cps-ud-table{
        thead{
            top: -1.65vh !important;
        }
        .sortable-table {
            tbody {
              display: block;
              overflow-y: auto;
              max-height: 49vh;
            }
        }
        @media screen and (max-width:1650px) {
            .sortable-table {
                tbody {
                  display: block;
                  overflow-y: auto;
                  max-height: 45vh;
                }
            }
        }

        @media screen and (max-width:1500px) {
            .sortable-table {
                tbody {
                  display: block;
                  overflow-y: auto;
                  max-height: 45vh;
                }
            }
        }

        @media screen and (max-width:1280px) {
            .sortable-table {
                tbody {
                  display: block;
                  overflow-y: auto;
                  max-height: 49vh;
                }
            }
        }
    }

    .as-table-footer {
        position: relative;
    }

    .cps-ud-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0.5em;
        // margin-bottom: 1em;
    }

    .cps-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .upl-files{
            margin-right: 1em;
            svg {
                .cls-1 {
                    fill: #ffffff !important;
                }
            }
        }

        .get-comp{
            margin-right: 1em;
        }

        .cps-ud-add-user-btn {
            width: fit-content;
        }

        .cps-ud-add-icon {
            color: $white;
        }
    }




}

.table-loading {
    width: 100%;
    height: 200px;
}

.cps-ud-table-header-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;

    .account-manager-selector, .company-status-selector {
        margin-right: 1em;
        // width: 11.873983vw;
        width: 7.873983vw;
        .d-inline-block {
            min-width: 7.873983vw;
        }
    }


    .cps-ud-table-search-bar {
        margin-right: 1em;
    }

    .select-user {
        margin-right: 1em;

        .user-form-control {
            min-width: 190px;
            width: fit-content;
        }
    }

    .deparment-toggle {
        // margin-right: 1em;
        margin: 0 1em 0 0;
    }

    .total-count {
        span {
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            // line-height: 2.2;
            letter-spacing: normal;
            color: $defaultHighLightColor;
            // margin-bottom: 0.3em;

            .total-count-number {
                font-weight: bold;
            }
        }
    }
}

.website-icon {
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
    text-decoration: none;

}

.open-orders-empty {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .ord-icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 1em;
    }

    .ord-text-empty {
        span {
            color: #b1b1b1;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }

    .ord-text {
        span {
            color: $openOrdersTextColor;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
    }
}