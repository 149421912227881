@import '../../../../variables.scss';


.backoffice-payroll-container {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .backoffice-card {
        flex: 1;
        position: relative;
        margin: 0 0rem 1rem 0;
        min-width: 49%;
        max-width: 49%;
        width: 49%;
        border: 0.1px solid rgba(193, 205, 212, 0.2509803922) !important;
        .scrd-container {
            background: red !important;
            height: 100% !important;
            border: none !important;
            min-height: auto !important;
            .scrd-header{
                padding: 0.5em 1em;
            }
            .tax-info-content {
                margin-top: 0;
                .tax-info-table{
                    padding-bottom: 0;
                }
            }
        }

        .empty-data-msg {
            text-align: center;

            span {
                font-size: 1vw;
                color: $tableBorderColor;
            }
        }

        .error-data-msg {
            text-align: center;

            span {
                font-size: 1vw;
                color: $primaryColor;
                // text-transform:none;
            }
        }

    }

    .backoffice-card:nth-child(1) {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

}