@import "../../../../../variables.scss";

.sales-call-summary-report {
  width: -webkit-fill-available;
  padding: 2em;

  .sales-call-summary-report-header {
    display: flex;
    justify-content: space-between;

    .main-table-header {
      display: flex;
      justify-content: flex-start;

      .dateRange {
        width: min-content;
        margin-top: 0.3em;
        margin-left: 1em;

        input {
          width: 10vw !important;
          min-width: 10.5vw !important;
          color: $defaultHighLightColor !important;
        }
      }

      .error-holder {
        width: 15vw;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: 1em;
        margin-bottom: 0;
      }

    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-grow {
    flex-grow: 1;
  }

  .main-header {
    background: #f3f3f9;
    color: #0b3e79;
    border-bottom: 1px solid #0353a1;
    font-size: 0.937vw;
    font-weight: 900;
    margin-top: 1.5em;

    &:first-child {
      border-top-left-radius: 1em;
    }

    &:last-child {
      border-top-right-radius: 1em;
    }

    .selected {
      background-color: #0b3e79;
      color: white;
    }

    .main-header-item {
      padding: 1.25em;
    }
  }

  .table-container {
    border-radius: 0.2em;
    border: solid 1px #c0c2c4;
    border-top: none;

    .scsr-table {
      max-height: 52vh;
      overflow: auto;
      position: relative;
      .sortable-table thead {
          z-index: 0;
          position: sticky;
      }
  }

    .header-container {
      margin-bottom: 1.2em;
      display: flex;
      justify-content: space-between;
      color: "#0B3E78";
      padding: 0 1em 0 1em;

      .table-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;

        .table-header-title {
          span {
            font-size: 0.937vw;
            color: $primaryColor;
            font-weight: 800;
          }

        }
      }

      .total-count-action {
        margin: 0 1em 0.2em 1em;

        span {
          width: max-content;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $defaultHighLightColor;

          .total-count-number {
            font-weight: bold;
          }
        }
      }
      .rc-scsr-btns {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .cSCall-toggle{
          margin-bottom: 0.2em;
        }


        .rc-scsr-btn {
          margin-top: 1em;
          margin-right: 1em;
          svg {
            .cls-1{
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
}