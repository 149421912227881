@import '../../../../../variables.scss';


.tax-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;

    .tax-info-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .section-header {
            font-size: 0.833vw;
            margin-bottom: 10px;
            color: #0b3e79;
            text-transform: uppercase;
        }
    }

    .tax-info-content {
        margin-top: 1rem;
        
        .tax-info-table {
            // height: 15vw;
            max-height: 8vw;
            overflow: auto;
            padding-bottom:2rem;
            .tick-icon svg {
                width: 1.5vw;
                height: 1.3vw;
                font-size: 1.3vw;
                vertical-align: text-top;
            }

            .sortable-table {
                .tb-header-cc {
                    span {
                        overflow: visible !important;
                        padding-right: 1.1em;
                    }
                }
            }

            .sortable-table thead tr .tb-header-cc {
                padding-right: 1.1em;
            }

        }
    }

    .tax-info-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem 1rem 0rem 1rem;
        align-items: center;

        .tax-info-actions-btn {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .btn-cancel {
            margin-right: 1rem;
        }
    }
}