@import '../../../../../app/variables';

.talent-asmnt-main-container {
  border: 1px solid $tableBorderColor;
  color: $primaryColor;
  padding-bottom: 1em;

  .talent-asmnt-header {
    padding: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    .active-checkbox {
      display: flex;
      align-items: center;
      margin-left: 20px;

      span {
        margin-top: 3px;
        margin-left: 5px;
      }

    }

    .search-bar {
      margin-right: 1em;
    }

    .total-count {
      margin-left: 1em;
      span {
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $defaultHighLightColor;
          margin-top:2px;

          .total-count-number {
              font-weight: bold;
          }
      }
  }

  }
  .tap-table-footer {
    position: relative;
  }
  .tap-table {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 49vh;
      }
    }
  
    @media screen and (max-width:1650px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 45vh;
        }
      }
    }
  
    @media screen and (max-width:1500px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 45vh !important;
        }
      }
    }
  
    @media screen and (max-width:1280px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 49vh !important;
        }
      }
    }
  }
}
