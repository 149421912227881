@import '../../../../variables.scss';


.srch-talent-holder{
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    overflow: hidden;

    .srch-tlnt-ud-table-search-bar, .srch-table-holder{
        width: -webkit-fill-available;
    }
    .srch-tlnt-ud-table-search-bar{
        margin-bottom: 1rem;
    }
    .srch-table-holder{
        display: flex;
        flex-direction: column;
        height: 240px;
        overflow: auto;
    }
}

/// Name
.srch-tlnt-ud-table-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    .status-icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 1em;
    }

    .name-txt {
        span {
            font-size: 0.729vw;
            font-weight: bold;
        }
    }
}

.pref-contact-icon {
    // width: 1.25vw;
    height: 1.25vw;
}

.ssn-holder {
    display: flex;
    flex-direction: row;
    // align-items: center;
    flex-wrap: nowrap;

    .ssn-number {
        margin-right: 0.5em;
        span {
            margin-top: 2px;
        }
    }

    .loc-open-icon{
        width: 14px;
        height: 14px;
    }
}