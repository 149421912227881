@import '../../../../../variables.scss';

.job-contact-main-container {
    border: 1px solid $tableBorderColor;
    color: $primaryColor;
    padding-bottom: 1em;

    .job-contact-header {
        padding: 1em;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .job-contact-left-fields {
            display: flex;
            flex-direction: row;

            .active-checkbox {
                display: flex;
                align-items: center;
                margin-left: 20px;

                span {
                    margin-left: 5px;
                    font-weight: bold;
                }

            }

            .total-count {
                display: flex;
                align-items: center;
                margin-left: 20px;
                font-size: 0.781vw;

                span {
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.86;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;

                    .total-count-number {
                        font-weight: bold;
                    }
                }

            }

        }

    }

    .job-contact-content {
        .table-data-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start !important;
        }

        .contact-method-icon {
            svg {
                width: 1.25vw;
                height: 1.25vw;
            }

            .zip-recruiter svg {
                width: 70px;
            }

            .indeed svg {
                width: 40px;
            }
        }
        .sortable-table {
            tbody {
              display: block;
              overflow-y: auto;
              max-height: 49vh;
            }
          }
        
          @media screen and (max-width:1650px) {
            .sortable-table {
              tbody {
                display: block;
                overflow-y: auto;
                max-height: 45vh;
              }
            }
          }
        
          @media screen and (max-width:1500px) {
            .sortable-table {
              tbody {
                display: block;
                overflow-y: auto;
                max-height: 45vh !important;
              }
            }
          }
        
          @media screen and (max-width:1280px) {
            .sortable-table {
              tbody {
                display: block;
                overflow-y: auto;
                max-height: 49vh !important;
              }
            }
          }
    }

    .jcp-table-footer {
        position: relative;
    }   
}


.job-contact-jobs-dialog-container {
    width: 400px;
    height: 280px;
    margin: 10px 20px 30px 20px;
    color: $primaryColor;

    .dialog-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left-fields {
            display: flex;
            flex-direction: row;
            align-items: center;

            h4 {
                margin-left: 20px;
                margin-top: 10px;
            }
        }

        img {
            cursor: pointer;
        }
    }

    .job-contact-dialog-content {
        height: 160px;
        margin: 10px 30px 10px 30px;

        .form-row {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;

            margin: 0 10px 0 10px input {
                width: -webkit-fill-available !important;
            }
        }

    }

    .message {
        border-top: 1px solid $tableBorderColor;
        margin-left: 10px;
        padding-top: 10px;
        height: 1.302vw;
    }

    .job-contact-dialog-footer {
        margin-top: 15px;
        float: right;
    }

}