@import '../../variables.scss';


.job-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: -webkit-fill-available;
}

.job-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;

    .job-avatar-holder {

        width: 110px;
        height: 110px;

        border-radius: 110px;
        padding: 4px;
        border: 1px solid $primaryColor;

        .job-avatar {
            background-image: url('../../../assets/icons/fork-lift.svg');
            border-radius: 100%;
        }

    }

    .job-user-name {
        margin-top: 0.5em;

        span {
            font-size: 1.25vw;
            font-family: $defaultFontFamily;
            color: $userProfileNameColor;
            font-weight: 900;

        }
    }

    .job-title {
        margin-top: 0.5em;
       
        span {
            font-size: 0.937vw;
            font-family: $defaultFontFamily;
            color: $userProfileNameColor;
            font-weight: 300;
            
        }
    }

    .job-user-tag {
        span {
            font-size: 0.729vw;
            font-family: $defaultFontFamily;
            color: $tableRowTextColor;
            font-weight: 900;
        }
    }
}

.job-action-container {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    // justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;

    .job-action {
        width: -webkit-fill-available;
        margin-bottom: 1em;

        .job-action-icon {
            width: 1.25vw;
            height: 1.25vw;
            svg path{
                fill: $white;
            }
        }
    }
}

.job-branch-selector {
    display: flex;
    margin-bottom: 1em;
    padding-left: 3em;

    .job-branch-left,
    .job-branch-right {
        width: 50%;

        .job-status {
            margin-top: 1em;
            display: flex;
            flex-direction: column;

            span {
                color: $userProfileNameColor;
                font-family: $defaultFontFamily;
                font-size: 0.833vw;
            }
            
            .status-txt,
            .job-id-txt {
                font-weight: 800;
                font-family: $defaultFontFamily;
                font-size: 0.729vw;
            }
            .status-txt {
                color: $openOrdersTextColor;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .job-id-txt {
                color: $userProfileNameColor;
            }
        }
    }
}

.job-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em;

    .job-info-icon {
        width: 0.833vw;
        height: 0.885vw;
        margin-right: 1em;
        svg path{
            fill: $companiesOptionTextColor;
        }
    }

    .job-info-content {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;

        .job-info-holder {
            display: flex;
            flex-direction: column;

            .job-into-title {
                span {
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    font-size: 0.833vw;
                }
            }

            .job-info-value {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                // margin-bottom: 0.5em;

                span {
                    font-weight: 800;
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                }

                .job-info-icon {
                    width: 1.666vw;
                    height: 1.666vw;
                    cursor: pointer;
                    padding: 0.5em;
                    transition: 0.3s all ease-in-out;

                    &:hover {
                        background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }

            }

        }
    }
}
