@import "../../../../variables.scss";

.ep-pd-container {
    margin: 20px;

    .ep-pd-section {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
        margin-bottom: 2em;
    }

    .ep-db-pd-table-header {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        margin-bottom: 1em;

    }

    .ep-ud-table-header-options {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
}