@import '../../../../../variables.scss';

.ep-pd-ph-container {
    width: 100%;

    .ep-pd-ph-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1rem;

        .ep-pd-ph-header-left,
        .ep-pd-ph-header-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .search-bar {
                margin-right: 1.5rem;
            }

            .total-count {
                span {
                    color: $defaultHighLightColor;
                }
            }
            .check-bal {
                margin-left: 1.5rem;

                span {
                    color: $primaryColor;
                    font-size: 1vw;
                    font-weight: 700;
                }
            }

        }
        .ep-pd-ph-header-right{
            padding-right:1rem;
        }

        .ep-pd-ph-header-btns {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

    }

    .ep-pd-ph-table {
        max-height: 25vw;
        overflow: auto;
    }

    .ep-pd-ph-nra {
        td {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .view-check-btn {
        .custom-button {
            padding: 2px 6px !important;
            cursor: pointer;

            svg {
                width: 0.8vw !important;
                height: 0.8vw !important;
            }

            .btn-text {
                display: none;
            }
        }
    }
}