@import "../../variables.scss";

.send_message_to_emp {
  background-color: #ff4d00;
  box-shadow: none;
  svg {
    g path:first-child {
      fill: white !important;
    }
    g path:nth-child(2) {
      fill: #ff4d00 !important;
    }
    g path:nth-child(3) {
      fill: #ff4d00 !important;
    }
  }
}
.select_talent_msg {
  text-transform: initial;
  color: #ff4d00;
  margin-top: 0.3em;
}
