@import '../../../../variables.scss';

.ac-ptc-apt-popup-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .ac-ptc-apt-popup-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .ac-ptc-apt-popup-content {
        padding-left: 3em;
        padding-top: 0.5em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .ac-ptc-apt-popup-section-divider {
            margin-bottom: 0.6em;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .ac-ptc-apt-popup-input-holder {
                width: 46%;
                .cmfs-margin-top {
                    margin-top: 0.7em !important;
                }
            }
        }

    }


    .ac-ptc-apt-popup-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid $inputoutlineColor;
        margin-top: 1rem;

        .btn-cancel {
            margin-right: 1em;
            margin-top: 1em;
        }

        .btn-save {
            margin-top: 1em;
        }
    }

}