@import "../../../variables.scss";

.w4-container.talent-onboarding {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;

  .w4-header {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
  }

  .w4-content {
    padding: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    overflow: auto;

    .saved-doc-holder {
      margin: 2em 2em;
    }

    .w4-section-divider {
      padding-bottom: 2em;
      margin-bottom: 2em;
      border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

      .w4-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0.5em;

        .section-title-txt {
          span {
            color: $defaultHighLightColor;
          }
        }

        .w4-input-holder {
          width: 9.895833333333334vw;
          margin-right: 2.5em;
          .d-inline-block {
            width: 45%;
          }
          .cmfs-margin-top {
            margin-top: 0.6em;
          }
        }
        .css-c0tn0x-control {
          border: 1px solid $inputoutlineColor !important;
          .css-tlfecz-indicatorContainer {
            color: $inputBorderColor;
          }
        }
      }
      .flx-start {
        margin-bottom: 0;
      }
    }
    .w4-section-divider:last-child {
      border-bottom: none;
      padding-bottom: 1em;
      margin-bottom: 1em;
    }
    .error-section {
      margin-top: 2em;
      margin-bottom: 1em;
      width: -webkit-fill-available;
    }
  }

  .w4-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between !important;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    .btn-cancel {
      margin-right: 1em;
    }
   
    .errorMsg {
      display: flex;
      align-items: center;
    }

    .w4-actions-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between !important;
      align-items: center;
      .ssn-checkbox{
        display: flex;
        flex-direction: column;
      }
    }
  }
  .switch {
    .slider:after {
      content: "NO" !important;
    }
    input:checked + .slider:after {
      content: "YES" !important;
    }
  }
}

.w4-link-icon {
  width: 0.833vw;
  height: 0.833vw;
}

.info-txt {
  font-size: 1.0416666666666667vw !important;
}

.name {
  font-weight: normal !important;
  font-size: 1.0416666666666667vw !important;
}

.loading-spinner {
  height: 150px;
}
