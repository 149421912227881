@import "../../../../variables.scss";

.tab-navigation-container {
  padding: 10px;
}

.plan-next-step {
  color: $defaultHighLightColor;

  .header {
    font-size: 0.937vw;
    font-weight: bold;
    margin-bottom: 1em;
  }

  .next-steps {
    margin-bottom: 20px;
    display: flex;
  }

  .step-small {
    width: 160px;
    height: 150px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7fbfd;
    border: 1px solid #c0ccd3;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 3px;

    &.selected {
      border: 2px solid #00be4b;
      position: relative;

      .check-circle-outline {
        position: absolute;
        right: 10px;
        top: 10px;

        &.small {
          .MuiSvgIcon-root {
            height: 0.833vw;
            width: 0.833vw;
          }
        }
      }
    }

    &:hover {
      border: 2px solid #00be4b;
    }
  }

  .date-time-container {
    display: flex;
    margin-top: 10px;

    .time-picker-container {
      margin-top: 1.74em !important;

      .MuiInputBase-root input {
        min-width: 11vw;
      }

      .MuiInputBase-input {
        margin-left: 0 !important;
      }
    }
  }

  .social-media-options {
    svg {
      margin-right: 10px;
    }

    svg path {
      fill: $userProfileNameColor;
    }
  }

  .previous-step {
    color: #b5bac5;
    // margin-top: 20px;
    cursor: pointer;

    .MuiSvgIcon-root {
      margin-bottom: 5px;
    }

    .label {
      margin-left: 10px;
      font-size: 1.2vw;
      font-weight: bold;
      display: inline;
    }
  }

  .footer {
    display: flex;
    // margin-top: 20px;

    .btn-cancel {
      margin-left: auto;
      margin-right: 15px;
    }

    .btn-text {
      span {
        text-transform: none;
      }
    }
  }

  .footer-action {
    margin-top: 2.5em;
    padding-top: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
  }
}
.archive-appl-sent {
  .archive-appl-sent-content {
    margin-left: 3rem;
    margin-bottom: 1em;

    span {
      font-size: 1.041vw;
      color: #0b3e78;
      text-transform: none;
    }

    .doc-saved-holder .doc-saved-icon {
      width: 6.0078125vw;
      height: 6.3203125vw;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .plan-next-step .date-time-container .time-picker-container {
    margin-top: 1.5em !important;
  }
}
