.online-application-received-dashboard {
    .ad-actions {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      margin-left: auto;
    }
    .disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
  