@import '../../variables.scss';


.dg-wrapper-container {
    display: flex;
    flex-direction: column;
    padding: 1em;
    position: relative;
    width: -webkit-fill-available;
    overflow: hidden;
    z-index: 100;

    .close-action{
        width: 1.25vw;
        height: 1.25vw;
        cursor: pointer;
        position: absolute;
        top: 1em;
        right: 1em;
    }
}


.title-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;

    .title-icon{
        width: 1.666vw;
        height: 1.666vw;
        margin-right: 1em;
        color: $defaultHighLightColor;
        svg path{
            fill:$defaultHighLightColor;
        }
    }

    .title-holder{
        display: flex;
        flex-direction: column;
        .title-name{
            span {
                font-size: 1.041vw;
                font-weight: 900;
                color: $defaultHighLightColor;
                text-transform: none;
            }
        }
        .sub-title-name{
            span {
                font-size: 0.841vw;
                font-weight: 900;
                color: $defaultHighLightColor;
                text-transform: none;
            }
        }
    }

    .downld-btn {
        margin-left: 2.5rem;
    }

    
}