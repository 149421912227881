@import "../../../../../variables.scss";

.jobs-talent-pipeline-container {
    width: -webkit-fill-available;

    .talent-in-que-container,
    .talent-matches-container,
    .not-a-fit-container {
        width: -webkit-fill-available;
        margin-top:-1.5vw;
        // border-top:1px solid rgba(165, 165, 165, 0.4)
    }

    .accordion-container {
        .accordion-title {

            .title-holder {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: nowrap;

                .ac-title-icon {

                    margin-right: 1em;
                    color: $defaultHighLightColor;


                    svg {
                        width: 1.25vw;
                        height: 1.25vw;

                        path {
                            fill: $defaultHighLightColor;
                        }
                    }
                }
                .title-name{
                    display: flex;
                    align-items: center;
                    gap: 0.5em;
                    .accordion-subtitle{
                        text-transform: none;
                        font-size: 0.8vw;
                        font-weight: 600;
                    }
                }
            }

            span {
                font-weight: 900;
                font-size: 1vw;
                color: $primaryColor;
                text-transform: capitalize;
                margin-top:0 !important;
            }

        }

    }

    .talent-pipeline-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;


        .header-left-section {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .right-alignment {
                margin: 1em;
            }
            .multiple-btns{
                display: flex;
                flex-direction: row;
                align-items: center;
                .mul-que{
                    margin-right:1rem;
                }
                .mul-que,.mul-trash{
                    width:max-content;
                }
            }
        }

        .header-right-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            .total-count {
                span {
                    color: $defaultHighLightColor;
                }
            }

            .right-alignment {
                margin: 0em 1em;
            }

            .textemall {
                background-color: #FF4D00;
                box-shadow: none;

                svg {
                    g {
                        clip-path: none !important;
                    }
                    g path:first-child {
                        fill: white !important;
                    }

                    g path:nth-child(2) {
                        fill: #ff4d00 !important;
                    }

                    g path:nth-child(3) {
                        fill: #ff4d00 !important;
                    }
                }
            }

            .matches {
                svg {
                    g path:first-child {
                        fill: white !important;
                    }

                    g path:nth-child(2) {
                        fill: #ff4d00 !important;
                    }

                    g path:nth-child(3) {
                        fill: #ff4d00 !important;
                    }
                }
            }
        }
    }

    .sortable-table {
        tbody {
            display: block;
            overflow-y: auto;
            max-height: 35vh;
        }

        .nqd_div {
            text-align: center;
            width: 100%;
        }

        .pref-contact-icon {
            width: 1.2vw !important;
            height: 1.2vw !important;
        }
        .d-inline-block{
            max-width: 11vw !important;
        }
    }

    .icons-row {
        display: flex;
        flex-direction: row;
        padding: 0 1rem;

        .chaticon {
            margin-right: 1rem;
        }

        .chaticon,
        .delicon {
            cursor: pointer;
        }

        .chaticon:hover,
        .delicon:hover {
            transform: scale(1.2);
            /* Increase size by 20% */
        }
    }

    .tx-center {
        text-align: center;
        padding: 0 1em;
    }

    .talent-matches-table {
        .col-hover-container {
            span {
                display: inline;
            }
            .parent-position {
              .child-position {
                left: 102%;
                bottom: -1em !important;
                max-width: 22vw;
                padding: 1.2em;
              }
            }
          }
    }

}