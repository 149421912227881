@import '../../../../variables.scss';

.create-assignment-panel-1 {
  display: flex;
  padding: 15px;

  .time-picker-container .MuiSvgIcon-root {
    width: 0.7em;
    height: 0.7em;
  }

  .assignment_popup_container {
    width: -webkit-fill-available;
  }

  .assignment_popup_table {
    margin-left: 2.9rem;

    .tlnt-ud-table-name {
      display: flex;
      justify-content: flex-start;
    }
  }

  .title-holder .title-name span {
    text-transform: none;
  }

  .sub-label {
    font-size: 0.729vw;
    font-family: Lato;
    margin-bottom: 10px;
    margin-top: 5px;
    font-weight: 900;
    color: rgb(11, 62, 121);
  }

  h2,
  h4,
  .search-header {
    color: #0b3e78;
    font-weight: bold;
  }

  .search-header {
    margin-top: 15px;
  }

  .sb-container {
    margin-top: 5px;
    margin-bottom: 1rem;

    input {
      width: 100%;
    }
  }

  .sortable-table {

    th:nth-child(2),
    td:nth-child(2) {
      // flex: 2 1;
    }

    thead,
    tbody {
      display: block;

      th:first-child,
      td:first-child {
        max-width: 35px;
        min-width: 35px;
      }

      .list-grid-row {

        th:first-child,
        td:first-child {
          max-width: 35px;
          min-width: 35px;
        }
      }
    }

    tbody {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 300px;
    }
  }

  .uf-actions {
    display: flex;
    flex-flow: row-reverse;
    border-top: solid 1px lightgray !important;

    .btn-save {
      margin-top: 1em;
    }
  }

  .form-content {
    padding-left: 3em;

    .form-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5em;
      border-top: 1px solid rgba(165, 165, 165, 0.4);
      margin-right: 5.5em;

      .error-section{
        width: 25%;
        margin-bottom: 0 !important;
      }

      .act-btns {
        display: flex;
        //margin-right: 20px;
        margin-top: 0.8em;
          .btn-back {
            margin-left: auto;
            margin-right: 10px;
          }
        }
    }

    .form-row {
      display: flex;
      justify-content: space-between;

      .col1,.col2 {
        width: 50% !important;
        padding-top: 2em;      
        
        p {
            margin-bottom: 0.3rem !important;
            font-weight: 900;
        }
        .jod-section-holder{
            margin-bottom: 0.5rem;
            .jod-section-title {
              margin-bottom: 0rem;
            }
        }
        .job-ord-sp-field-row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 0.6rem;

            .ac-sc-fields-job {
                flex-wrap: nowrap;
                width: 30%;
                margin-right: 0.7em;

                .input-form-control {
                    width: 7vw !important;
                }
            }
        }

        .fee_structure {
            display: flex;
            flex-direction: column;
            .MuiFormGroup-root{
                flex-direction: row;
            }

            .MuiSvgIcon-root {
                height: 0.8vw;
                width: 0.8vw;
            }

            .MuiFormControlLabel-label {
                font-size: 0.629vw;
                color: #0b3e79;
                font-weight: 600;
                text-transform: uppercase;
            }

            .MuiRadio-root {
                padding: 3px !important;
                padding-left: 0px !important;
            }
            .radio-btn{
                margin-right:0.5rem;
                flex-grow: 1;
                .MuiFormControlLabel-root {
                  margin-left: 0px;
                }
            }

            .radio-i {
                display: flex;
                align-items: center;
                
                  .form-input-holder .form-input-tag {
                      //width: 8.495833333333334vw;
                      min-width: 0vw !important;
                    
                    .suffix-holder{
                      margin-left: auto;
                      //margin-left: 3px;
                    } 
                  
                }
                 
            }

            .radio-i > div:first-of-type {
              width: 9.495833333333334vw;
            }
            .MuiRadio-root.Mui-disabled {
                color: $statusDisabledColor;
                /* Change this to the color you want for disabled state */
                opacity: 0.3;
                /* Adjust the opacity for a faded look */
            }

            .MuiFormControlLabel-root.Mui-disabled {
                color: $statusDisabledColor;

                /* Change label color when disabled */
                .MuiFormControlLabel-label {
                    color: $statusDisabledColor;
                }
            }

            input {
              min-width: 0vw !important;
            }
        }
        
    }

    .col2 {
        .MuiRadio-root {
          display: none;
      }
        .time-picker-container .MuiInputBase-input  {
          margin-left: 0em !important;
        
            min-width: 9.1958333333vw !important;
          
        }

        .jod-section-holder{
          .jod-section-title{
            visibility: hidden;
          }
        }

        .jod-section-holder:nth-of-type(2) {
          margin-bottom: 0em;
          .jod-section-title{
            visibility: visible;
            margin-bottom: 0em;
          }
          .jod-section-content {
            padding-top: 0.7em;
          }
          
        }

        .jod-section-holder:first-of-type {
          margin-right: 4em;
          .radio-btn {
            flex-grow: 1;
            .radio-i >div:first-of-type {
              width: 100%;
              margin-right: 0.7em;
            }
          }
        }
    }

      .form-item {
        flex:1 1;
        margin-right: 10px;
        //width: 22%;

        .date-input-holder-div {
          width: 9.75vw;
          .MuiFormControl-root {
            .MuiInputBase-input{
              width: 9.4vw;
              // width: 8.9vw;
            }
          }
        }

        .css-t7ry41-control {
          width: 9.7vw;
        }

        .form-input {
          width: 9.495833333333334vw;
        }

      }

      
      .form-table-item {
        width: 48%;
        color: #0b3e78;

        table {
          width: 100%;
          max-width: 100%;
          margin-top: 2em;
          thead {
            background-color: transparent;

            th {
              max-width: 100%;
              box-sizing: border-box;
              word-break: unset;
              font-size: 0.729vw;
              font-family: Lato;
              margin-top: 10px;
              font-weight: 900;
              color: rgb(11, 62, 121);
            }
          }

          tbody,
          td {
            border: 1px solid #0b3e79;
            border-collapse: collapse;
          }

          tr {
            display: flex;

            td,
            th {
              flex: 1;
              padding: 0.5em;
              min-height: 35px;
            }
            td {
              min-height: 50px;
            }
          }
        }
      }
    }
  }

  .pay-rate {
    .dollar-sign {
      padding: 2px 5px;
    }

    .form-input-holder {
      border: none;
    }


    input {
      //width: 120px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .create-assignment-panel-1 {
    .assignment_popup_table {
      margin-left: 2.1rem;
    }

    .form-content {
      .form-row {
        .form-item {
          .date-input-holder-div {
            .MuiFormControl-root {
              .MuiInputBase-input {
                width: 6.2vw;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:1200px) {
  .create-assignment-panel-1{
    .assignment_popup_container{
      .form-content{
        .form-row{
          .col2{
            .jod-section-holder:first-of-type {
              margin-right: 3.85em;
            }
          }
        }
        
      }
    }
    
  }
  
}