@import '../../../../variables.scss';

.verify-batch-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .verify-batch-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .verify-batch-content {
        padding: 2em 0em 2em 2rem;
        display: flex;
        flex-direction: column;
        position: relative;

        span {
            color: $primaryColor;
            font-size: 1.284375vw;
            font-weight: 700;
            text-transform: none;
        }

        .open-msg-div {
            display: flex;
            flex-direction: column;

            div {
                margin-bottom: 1rem;
                color: $primaryColor;
                font-size: 1vw;
                font-weight: 600;
                text-transform: none;
                display: inline-block;
            }
        }

        .verify-batch-error-holder {
            .error-title {
                color: $statusInActiveColor;
                font-size: 1.2843vw;
                margin-bottom: 0.5rem;
            }

            .aknw-name {

                span {
                    color: orange;
                    font-weight: 500 !important;
                    font-size: 1vw;
                    margin-bottom: 0.5rem;
                }
            }

            .no-table {
                text-align: center;
            }

            .error-table-container {
                max-height: 30vh;
                overflow: auto;
                border: 1px solid hsla(220, 3%, 77%, .3);
                margin-bottom: 1em;

                .error-table {
                    width: 100%;
                    position: relative;

                    th {
                        color: $primaryColor;
                        font-size: 0.729vw;
                        font-weight: 700;
                        padding: 0.5rem;
                        position: sticky;
                        background-color: $backgroundColor;
                        top: 0;
                    }

                    tr {
                        border-top: 1px solid hsla(220, 3%, 77%, .3);
                    }

                    td {
                        padding: 0.5rem;
                        font-size: 0.729vw;
                        color: $tableRowTextColor;
                    }

                    td:last-child {
                        width: 40%;

                        span {
                            font-size: inherit !important;
                            font-family: inherit !important;
                            color: inherit !important;
                        }
                    }
                }

            }
        }

    }



    .verify-batch-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;
    }
}