@import '../../../../variables.scss';

.bo-info-text-popup-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .bo-info-text-popup-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;

        .title-name span {
            text-transform: none;
        }
    }

    .bo-info-text-popup-content {
        padding: 0 3em 0 3em;
        overflow: auto;

        .info-text {

            p,
            span,
            b,
            li {
                font-size: 0.8vw;
                color: $primaryColor;
                line-height: 30px;
                text-transform: none;
            }
            p{
                margin-bottom:0.5rem !important
            }

            b {
                font-weight: 900;
            }
        }
    }

    .bo-info-text-popup-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid $inputoutlineColor;
        margin-top: 1rem;

        .btn-save {
            margin-top: 1em;
        }
    }
}