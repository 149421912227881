@import '../../variables.scss';

.pnh-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    border-radius: 2.8px;
    background: $white;
    // border: 0.1px solid $pageBoderColor;
    box-shadow: 0px 1px 2px 0px rgba($color: $pageBoderColor, $alpha: 1.0);

    .pnh-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1em;
        background: $adminCenterOptionBackgroundColor;
        box-shadow: 0px 0.5px 1px 0px rgba($color: $pageHeaderBoderColor, $alpha: 1.0);
        // border: 0.1px solid $pageHeaderBoderColor;
        .pnh-icon {
            width: 1.25vw;
            height: 1.25vw;
            margin-right: 1em;
        }

        .pnh-path-txt-0{
            font-size: 0.937vw;
            font-family: $defaultFontFamily;
            font-weight: 900;
        }

        

        .pnh-path-name{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            text-transform: capitalize;
           

            .pnh-active{
                cursor: pointer;
                padding: 4px 8px;
                transition: 0.3s all ease-in-out;
                border-radius: 5px;
                text-decoration: none;

                &:hover {
                    background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1);
                }
            }
           
            .pnh-arrow-icon{
                margin-left: 1em;
                margin-right: 0.5em;
            }
            .pnh-path-txt{
                font-size: 0.937vw;
                font-family: $defaultFontFamily;
                text-transform: capitalize;
            }
        }
    }

    .pnh-content {
        display: flex;
        height: -webkit-fill-available;
        background-color: $white;
    }
}