@import '../../variables.scss';

.cdch-spinner-container{
    display: flex;
    height: 240px;
    width: -webkit-fill-available;
}

.cdch-error-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    min-height: 250px;

    .error-msg {
        margin-bottom: 1em;

        span {
            font-size: 1.25vw;
            color: $tableBorderColor;
        }
    }

    .error-action {
        width: fit-content;
    }

}

.cdch-add-icon{
    width: 1.25vw;
    height: 1.25vw;
    // font-size: 1.25vw;
    color: $white;
}