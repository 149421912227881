@import '../../../../../variables.scss';


.un-db-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    box-sizing: border-box;

    .un-db-left-panel {
        flex: 1;
        min-width: 240px;
       // max-width: 240px;
        display: flex;
        padding: 1em;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        margin-right: 2em;
        position: relative;
        box-sizing: border-box;
    }

    .un-db-right-panel {
        flex: 4;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        width: 90%;
    }
}

.un-db-edit-icon {
    width: 0.989vw;
    height: 0.989vw;
    margin-bottom: 0.5em;
}

.un-db-job-match,
.un-db-comp-match {
    margin-top: 1em;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    height: 230px;
    overflow: auto;
}


.un-db-match-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    .un-db-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1em;

        .un-db-header-title {
            span {
                font-size: 0.833vw;
                color: $defaultHighLightColor;
            }

        }

        .un-db-header-search{
            width: 14.416667vw;
        }
    }

    .un-db-content {
        display: flex;
    }
}