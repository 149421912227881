@import '../../../../../../variables.scss';

.markup-main-container {
    .markup-po-content-table {
        height: 15vw;
        max-height: 15vw;
        position: relative;

        .tick-icon {
            width: 1.3vw;
            height: 1.3vw;
            font-size: 1.3vw;
        }

        .sortable-table {
            position: sticky;
            top: 0;
            .tb-header-cc {
                span {
                    overflow: visible !important;
                    padding-right: 1.1em;
                }
            }
        }

        .sortable-table thead tr .tb-header-cc {
            padding-right: 1.1em;
        }

    }
}