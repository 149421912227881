@import '../../../../variables.scss';


.tlnt-emph-cmpny-cdk-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .tlnt-emph-cmpny-cdk-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .tlnt-emph-cmpny-cdk-content {
        padding: 2em 2em 2em 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .tlnt-emph-cmpny-cdk-ack-section {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .doc-saved-icon {
                width: 8.0078125vw;
                height: 8.3203125vw;
                margin-right: 2em;
            }

            .ack-text{
                span {
                    font-size: 1.484375vw;
                    font-weight: bold;
                    color: $defaultHighLightColor
                }
            }
        }

        .error-section {
            margin-bottom: 2em;
        }
    }

    .tlnt-emph-cmpny-cdk-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .btn-cancel {
            margin-right: 1em;
        }
    }

}

.tlnt-emph-cmpny-cdk-link-icon {
    width: 0.833vw;
    height: 0.833vw;
}