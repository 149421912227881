@import '../../../../variables.scss';

.dshb-mrk-tlnt-op-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;
    // max-width: 50%;

    .dshb-mrk-tlnt-op-f-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .dshb-mrk-tlnt-op-f-content {
        padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .dshb-mrk-tlnt-op-f-section-divider {
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

            .dshb-mrk-tlnt-op-f-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .dshb-mrk-tlnt-op-f-input-holder {
                    min-width: 9.895833333333334vw;
                    margin-right: 1em;
                }
            }
        }
        .tint-ihi-questions-section {
            display: flex;
            flex-direction: column;
        
            .tint-ihi-question-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2em;
        
                .tint-ihi-question-title {
                    // margin-bottom: 0.5em;
        
                    span {
                        font-size: 0.833vw;
                        color: $defaultHighLightColor;
                        font-weight: bold;
                    }
                }
        
                .tint-ihi-input-form {
                    display: flex;
                    flex-direction: column;
                    // margin-left: 1em;
                }
            }
        }

        .error-section{
            margin-bottom: 2em;
        }
    }

    .dshb-mrk-tlnt-op-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .btn-cancel {
            margin-right: 1em;
        }
    }

}

.dshb-mrk-tlnt-op-f-link-icon{
    width: 0.833vw;
    height: 0.833vw;
}




.options-list-holder{
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    padding-bottom: 2em;

    .option-holder{
        display: flex;
        margin-bottom: 1em;
    }
    .option-holder >div >div >div:first-child{
        height: 0.75824vw !important;
        width: 0.75824vw !important;
        border-radius: 0.75824vw !important;
    }
    .option-holder > div > div > div:first-child>div {
        height: 0.35824vw !important;
        width: 0.35824vw !important;
        border-radius: 0.35824vw !important;
    }
}

.date-range-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 4em;

    .divider{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100px;

        .line{
            width: 30%;
            height: 2px;
            background-color: $defaultHighLightColor;
        }
    }
}


.mark-success-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 11.458333333333334vw;

    .question-txt{
        span {
            font-size: 1.25vw;
            color: $defaultHighLightColor;
            font-weight: 800;
            text-transform: none !important;
        }
    }
}

.option-label-txt{
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        font-size: 14px;
        color: $defaultHighLightColor;
        font-weight: 500;
        margin-right: 0.5em;
    }

    .name-txt {
        font-weight: 900;
    }
}