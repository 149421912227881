@import '../../../../variables.scss';

.emp-ud-container{
  .tlnt-ud-table {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 52vh;
      }
    }
    @media screen and (max-width:1650px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 45vh;
        }
      }
    }
    
    @media screen and (max-width:1500px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 45vh !important;
        }
      }
    }
    
    @media screen and (max-width:1280px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 52vh !important;
        }
      }
    }
  }
  .emp-ud-header-container {
    display: flex;
    justify-content: space-between;
  
    .emp-ud-header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 1em;
      margin-bottom: 1em;
  
      .total-count {
          margin-left: 1em;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $defaultHighLightColor;
      
          .total-count-number {
            font-weight: bold;
          }
        }
  
      .broadcast_btn_div {
  
        button {
          background-color: #ff4d00;
          box-shadow: none;
  
          svg {
            g path:first-child {
              fill: white !important;
            }
  
            g path:nth-child(2) {
              fill: #ff4d00 !important;
            }
  
            g path:nth-child(3) {
              fill: #ff4d00 !important;
            }
          }
        }
  
        .select_talent_msg {
          text-transform: initial;
          color: #ff4d00;
          margin-top: 0.3em;
        }
      }
    }
  }
}