@import '../../../../variables.scss';


.cdp-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    box-sizing: border-box;

    .cdp-left-panel {
        flex: 1;
        min-width: 240px;
        display: flex;
        padding: 1em;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        margin-right: 2em;
        position: relative;
        box-sizing: border-box;
        min-height: -webkit-fill-available;
    }

    .cdp-right-panel {
        flex: 4;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        min-height: -webkit-fill-available;
        
        // padding: 1em;
        // box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    }
}

.cdp-edit-icon {
    width: 0.989vw;
    height: 0.989vw;
    margin-bottom: 0.5em;
}