@import "../../../../../variables.scss";

.open-job-orders-reports-container {
  width: -webkit-fill-available;
  padding: 2em;

  .open-job-orders-reports-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .cmp-dropdown {
        margin-right: 1em;
      }
      .error-holder {
        width: 15vw;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0;
      }
    }
  }

  .table-container {
    border-radius: 0.2em;
    border: solid 1px #c0c2c4;
    margin-top: 2em;

    .ojhr-table {
      max-height: 36vh;
      overflow: auto;
      position: relative;

      .sortable-table thead {
        z-index: 0;
        position: sticky;
      }
    }

    .header-container {
      margin-bottom: 1.2em;
      display: flex;
      justify-content: space-between;
      color: "#0B3E78";
      padding: 0 1em 0 1em;

      .rc-scsr-btns {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .total-count-action {
          margin-right: 1em;

          span {
            width: max-content;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $defaultHighLightColor;

            .total-count-number {
              font-weight: bold;
            }
          }
        }

        .rc-scsr-btn {
          margin-top: 1em;
          margin-right: 1em;
        }
      }

      .table-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;

        .table-header-title {
          span {
            font-size: 0.937vw;
            color: $primaryColor;
            font-weight: 800;
            margin-bottom: 0.2em;
          }

        }
      }
    }
  }

  .custom-tab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0.5em 3.5em 0.5em 1.5em;
    border-radius: 4px;
    width: 28%;
    margin-left: auto;
    margin-top: 1em;
    background-color: $tableHeaderBackgroundColor;
    border-left: 4px solid $defaultHighLightColor;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    align-items: center;

    .tab-title {

      span {
        font-size: 0.729vw;
        color: $defaultHighLightColor;
        font-weight: 800;
      }
    }

    .tab-requested-value,
    .tab-placed-value,
    .tab-open-value {
      display: flex;
      flex-direction: column;
      text-align: center;

      span {
        font-size: 0.729vw;
        font-weight: 800;
      }
    }

    .tab-requested-value,
    .tab-open-value {
      color: $red;
    }

    .tab-placed-value {
      color: $openOrdersTextColor;
      margin-right: 1em;
    }

  }

  .call-counts {
    display: flex;
    justify-content: space-between;
    background: #f7fbfd 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    border-left: 4px solid $defaultHighLightColor;
    border-radius: 4px;
    opacity: 1;
    width: 28%;
    margin-top: 2em;
    margin-left: auto;
    padding: 1em;

    .total-count {
      color: #074783;
      font-size: 0.729vw;
      margin: 0 0.5em 0em 0.5em;
      font-weight: 800;
      flex-direction: row-reverse;
    }
  }
}