@import '../../../variables.scss';

/// [Verification token]

.onboarding-verification-token-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

/// [Verification token failed]
.onboarding-verification-token-failed-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: -webkit-fill-available;
    height: -webkit-fill-available;

    .token-failed-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 50%;
        padding: 2em;
        border-radius: 5px;
        box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.3);

        .title-holder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;

            span {
                font-size: 1.4583333333333333vw;
                color: rgba($color: #A8A8A8, $alpha: 0.6);
                font-weight: bold;
                // text-transform: none;
            }
        }
    }
}

.ob-application-form-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    position: relative;
    overflow: hidden;

    .ob-holder {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        position: relative;
        overflow: hidden;

        .ob-tasks-list {
            display: flex;
            flex-direction: column;
            flex: 1;
            position: relative;
            overflow: hidden;

            .task-holder {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                padding: 1em;
                width: -webkit-fill-available;
                margin-right: 5em;
                border-bottom: 1px solid rgba($color: #A8A8A8, $alpha: 0.4);

                .task-icon {
                    width: 1.25vw;
                    height: 1.25vw;
                    margin-right: 1em;
                }

                .task-title {
                    span {
                        font-size: 1.0416666666666667vw;
                        font-weight: 800;
                        margin-bottom: 0.3em;
                    }
                }
            }
        }

        .ob-task-form {
            display: flex;
            flex-direction: column;
            flex: 2;

            .ob-task-form-header {
                padding-bottom: 0.5em;
                // border-bottom: 1px solid rgba($color: #A8A8A8, $alpha: 0.4);

                span {
                    font-size: 1.0416666666666667vw;
                    font-weight: 800;
                    color: $defaultHighLightColor;
                    text-transform: none;
                }
            }

            .ob-task-form-content {
                display: flex;
                flex-direction: column;
                overflow: auto;
                position: relative;
            }

            .no_video {
                margin:auto;
                padding-top:4rem;
                justify-content: center;
                font-weight: 700;
                color: $defaultHighLightColor;
                span{
                    font-size: 1.0416666666666667vw;
                }
            }

            .error-section {
                margin-top: 2em;
            }
        }
    }

    .ob-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: -webkit-fill-available;
        padding: 1em 0;
        border-top: 1px solid rgba($color: #A8A8A8, $alpha: 0.4);
    }
}


.ob-wrk-form {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    .ob-wrk-header-txt {
        margin-top: 0.5em;
        margin-bottom: 0.5em;

        span {
            font-size: 0.8454212454212454vw;
            color: $defaultHighLightColor,
        }
    }

    .ob-wrk-action {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 1em 0;

        .add-new-position {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;

            .add-icon {
                width: 1.465201465201465vw;
                height: 1.465201465201465vw;
                margin-right: 1em;
            }

            .add-title {
                span {
                    font-size: 0.729vw;
                    color: $defaultHighLightColor;
                    font-weight: 800;
                }
            }

        }
    }
}


.ob-edk-form {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;


    .ob-edk-header-txt {
        margin-top: 0.5em;
        margin-bottom: 0.5em;

        span {
            font-size: 0.8454212454212454vw;
            color: $defaultHighLightColor,
        }
    }

    .ob-edk-action {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 1em 0 2em 0;
        border-bottom: 1px solid rgba($color: #A8A8A8, $alpha: 0.4);

        .add-new-position {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;

            .add-icon {
                width: 1.465201465201465vw;
                height: 1.465201465201465vw;
                margin-right: 1em;
            }

            .add-title {
                span {
                    font-size: 0.729vw;
                    color: $defaultHighLightColor;
                    font-weight: 800;
                }
            }

        }
    }
}


.close-extra-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em 1em 0;
    width: -webkit-fill-available;
    position: relative;

    .option-info {
        span {
            font-size: 0.8454212454212454vw;
            color: $defaultHighLightColor,
        }
    }

    .option-action {
        .close-action {
            width: 1.25vw;
            height: 1.25vw;
            cursor: pointer;
            // position: absolute;
            // top: 1em;
            // right: 1em;
        }
    }
}


.terms-condtions-input {
    display: flex;
    flex-direction: column;
    padding: 1em 0;
}


/// [Submity form success messge]

.success-message-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4em;
    margin-top: 4em;

    .message-txt {
        span {
            font-size: 1.4583333333vw;
            color: $defaultHighLightColor;
            font-weight: 800;
            text-transform: unset;
        }
    }
}