@import "../../../../../variables.scss";

.invoice-report-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .invoice-report-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .select-type{
            margin-left: 1em;
        }

        .ir-h-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            .dateRange {
                width: min-content;
                margin-top: 0.3em;
                margin-right: 1em;
                input {
                    width: 10vw !important;
                    min-width: 10.5vw !important;
                    color: $defaultHighLightColor !important;
                }
            }

            .error-holder {
                width: 15vw;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 0;
            }
        }
        .ir-h-right {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: flex-end;
    
            .total-count {
                margin-left: 1.5em;
    
                span {
                    width: max-content;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    color: $defaultHighLightColor;
    
                    .total-count-number {
                        font-weight: bold;
                    }
                }
            }
    
            .dwn-btn {
                margin-left: 1em;
                margin-right: 1em;
            }
        }
    }

    .table-container {
        border-radius: 0.2em;
        border: solid 1px #c0c2c4;
        margin-top: 2em;

        .ir-table {
            max-height: 40vh;
            overflow: auto;
            position: relative;
    
            .sortable-table thead {
                z-index: 0;
                position: sticky;
            }
        }

        .header-container {
            margin-bottom: 1em;
            margin-top: 1em;
            display: flex;
            justify-content: space-between;
            color: "#0B3E78";
            padding: 0 1em 0 1em;

            .table-header {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-end;

                .table-header-title {
                    span {
                        font-size: 0.937vw;
                        color: $primaryColor;
                        font-weight: 800;
                        margin-bottom: 0.2em;
                    }

                }

                .total-count-action {
                    margin-left: 1.5em;

                    span {
                        width: max-content;
                        font-weight: 300;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 2.2;
                        letter-spacing: normal;
                        color: $defaultHighLightColor;

                        .total-count-number {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .total-counts-first-card {
        display: flex;
        justify-content: space-between;
        background: #f7fbfd 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        border-left: 4px solid $defaultHighLightColor;
        border-radius: 4px;
        opacity: 1;
        width: 100%;
        margin-top: 2em;
        padding: 1em;

        .total-title {
            color: #074783;
            font-size: 0.792vw;
            margin: 0 0.5em 0em 0.5em;
            font-weight: 800;
            flex-direction: row-reverse;
        }

        .total-count-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 56%;

            .total-count {
                width: 10%;
                color: #074783;
                font-size: 0.792vw;
                margin: 0 1.5em 0em 0.5em;
                font-weight: 800;
                flex-direction: row-reverse;

                span {
                    text-transform: none;
                }
            }
        }

        .total-count-main .total-count:nth-child(3) {
            width: 15.5%;
        }
    }

    .total-counts-second-card {
        background: #f7fbfd 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        border-left: 4px solid $defaultHighLightColor;
        border-radius: 4px;
        opacity: 1;
        width: 20%;
        margin-top: 2em;
        padding: 1em;

        .total-title {
            color: #074783;
            font-size: 0.792vw;
            margin: 0 0.5em 2em 0.5em;
            font-weight: 800;
        }

        .total-count {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            color: #074783;
            font-size: 0.729vw;
            line-height: 2;
            margin: 0 2em 0em 0.5em;
            font-weight: 600;

            span {
                text-transform: none;
            }
        }

        .mt-b {
            margin-top: 1em;
        }

        .pb {
            border-bottom: 1px solid $tableBorderColor;
            padding-bottom: 1em;
        }
    }
}