@import "../../../../variables.scss";


.w2-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .w2-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .w2-content {
        .webviewer {
            height: 30vw !important;
            width: 100%;
        }
        @media screen and (max-width:1500px) {
            .webviewer {
              height: 35vw !important;
              width: 100%;
            }
        }

        .resp-iframe {
            height: 80vh;
            width: 100%;
        }
    }

    .w2-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between !important;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;

        .w2-actions-row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end !important;
            align-items: center;
            .generate-span{
                .MuiCircularProgress-root{
                    height:15px !important;
                    width:15px !important;
                    margin-right:0.5rem;
                    color: green;
                }
            }

            .btn-cancel {
                margin: 0 1em 0  1em;
            }
        }

    }
}