@import '../../../variables.scss';

.ac-vr-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    border: 1px solid $tableBorderColor;
    padding:1rem 2rem;

    .ac-vr-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: -webkit-fill-available;
        position: relative;

        .ac-vr-input{
            width:fit-content;
            margin-right:2rem;
        }
    }
    .ac-vr-actions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top:2rem;
        .actions-row{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        .btn-cancel{
            margin-right:1em;
        }
    }
}