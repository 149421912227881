@import '../../../../variables.scss';


.add-cont-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 1em 1em 3em;
    overflow: hidden;

    .add-cont-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;

        .title-name span {
            text-transform: none;
        }
    }

    .add-cont-content {
        display: flex;
        flex-direction: column;

        // position: relative;
        // overflow: auto;
        .saved-doc-holder {
            padding: 2rem;
        }

        .add-cont-input-holder-row {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            margin-bottom: 0.5em;
            width: 100%;
            align-items: flex-end;

            .add-cont-input-holder {
                margin-right: 1.5rem;
                margin-bottom:0.5rem;
                span {
                    text-transform: none;
                }
            }

            .switch {
                .slider:after {
                    content: 'NO' !important;
                }

                input:checked+.slider:after {
                    content: 'YES' !important;
                }
            }

            .cont-input-row-holder {
                display: flex;
                flex-direction: column;
                margin-bottom:0.5rem;

                .input-label {
                    font-size: 0.729vw;
                    color: #0b3e79;
                    font-weight: 900;
                    margin-bottom: 0.4rem;
                }

                .cont-input-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .spacer {
                        margin: 0 1rem;
                        font-size: 0.729vw;
                        color: #0b3e79;
                        font-weight: 700;
                    }
                }
            }
        }

        .row1 .add-cont-input-holder {
            width: calc(100%/2);
        }

        .row2 .add-cont-input-holder {
            width: calc(100%/3);
        }

        .row3 .add-cont-input-holder {
            width: calc(100%/4);
        }
    }

    .add-cont-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-top: 1rem;

        .btn-cancel {
            margin-right: 1em;
        }
    }
}