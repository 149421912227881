@import '../../../../../variables.scss';


.jb-f-stp-one{
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    // border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 2em;
    padding-bottom: 2em;
    margin-bottom: 2em;
    // align-items:flex-end;

    .jb-f-row{
        display: flex;
        flex-direction: row;
    }

    .jb-f-input-holder{
        margin-bottom: 1em;
        .form-input
        {
            min-width: 21.495833vw;
        }
        .form-input-tag{
            min-width: 19.795833vw;
        }
    }
}