@import '../../../../variables.scss';

.as-cnt-imp-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .as-contact-image {
        width: 2.5vw;
        height: 2.5vw;
    }

    .as-cnt-imp-f-content {
        // padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

    }
    .creds-row{
        display:flex;
        flex-direction: row;
        align-items: center;
        // justify-content:;
        .no-creds-div{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin:0 1rem;
        }
        .no-creds{
            color:$statusWarningColor;
            margin-right:1rem;
            font-size:0.7vw;
        }
        .add-creds-div{
            color: $defaultHighLightColor;
            font-weight: 800;
            text-decoration: underline;
            cursor:pointer;
        }
    }
   
    .company-row {
        display: flex;
        flex-direction: column;
        .company-name-span{
            cursor: pointer;
            font-weight: 700;
        }
        .company-name-span:hover{
            text-decoration: underline;
        }
        .icons-row {
            margin-top: 0.5rem;

            svg {
                width: 0.65vw;
                height: 0.65vw;
            }
        }
    }
    .company-div-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .company-logo{
            width: 2vw;
            height: 2vw;
            margin-right:0.5rem;
        }
    }

    .social-media-row {
        svg {
            width: 0.85vw;
            height: 0.85vw;
        }
    }

    .as-srch-table-holder {
        margin-top: 0.8rem;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 68vh;
        width: -webkit-fill-available;
        overflow-y: auto;
    }

    .as-cnt-btn-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span{
            color:$defaultHighLightColor;
        }
    }
    .circle-download-svg{
        svg{
            path{
                fill:white;
            }
        }
    }
}