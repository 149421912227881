@import '../../../../../variables.scss';

.timecards-report-tab-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .timecards-report-tab-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;

        .col1 {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }

        .col1 {
            .cmfs-container:first-child {
                margin-right: 1rem;
            }
        }

        .col2 {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-top: 1.7rem;

            .search-bar {
                margin-left: 1em;
            }
        }
    }

    .timecards-report-tab-content {

        .timecards-report-header {
            border: 1px solid $tableBorderColor;
            border-bottom: none;

            .sortable-table thead tr .tb-header-cc:first-child {
                padding-left: 12.2vw !important;
                padding-right: 12.2vw !important;

            }

            .sortable-table thead tr .tb-header-cc:nth-child(2) {
                padding-left: 1.7vw !important;
                padding-right: 1.7vw !important;

            }

            .sortable-table thead tr .tb-header-cc:nth-child(3) {
                padding-left: 1.69vw !important;
                padding-right: 1.69vw !important;

            }

            .sortable-table thead tr .tb-header-cc:nth-child(4) {
                padding-left: 1.09vw !important;
                padding-right: 1.09vw !important;

            }

            .sortable-table thead tr .tb-header-cc:nth-child(5) {
                padding-left: 0.15vw !important;
                padding-right: 0.15vw !important;

            }
        }

        .timecards-report-table {
            max-height: 45vh;
            border: 1px solid $tableBorderColor;
            overflow: auto;

        }

        .units-div {
            display: flex;
            justify-content: flex-end;

            .custom-units-card1 {
                width: 21.1%;
            }

            .custom-units-card2 {
                width: 21.2%;

            }

            .custom-units-card3 {
                width: 13%;

            }

            .custom-units-card4 {
                width: 5.7%;

            }

            .custom-units-div {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                // width: 16.7%;
                margin-top: 1em;
                margin-left: 1.5em;
                background-color: $tableHeaderBackgroundColor;
                border-left: 4px solid $defaultHighLightColor;
                box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
                justify-content: flex-start;

                .units-title {
                    margin: 1em 0 0 1em;

                    span {
                        font-size: 0.829vw;
                        color: $defaultHighLightColor;
                        font-weight: 800;
                    }
                }

                .units-val-row {

                    .col1 {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    .units-val {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin: 0.5em;
                        text-align: start;
                        color: $primaryColor;

                        .units-val-sub-sec {
                            display: flex;
                            justify-content: space-between;
                        }

                        span {
                            font-size: 0.729vw;
                            margin: 0.5em;
                        }

                    }
                }
            }
        }

    }
}


@media only screen and (max-width: 1440px) {
    .timecards-report-tab-container {
        .timecards-report-tab-content {
            .timecards-report-header {
                .sortable-table thead tr .tb-header-cc:first-child {
                    padding-left: 11.95vw !important;
                    padding-right: 11.95vw !important;

                }

                .sortable-table thead tr .tb-header-cc:nth-child(2) {
                    padding-left: 1.5vw !important;
                    padding-right: 1.5vw !important;

                }

                .sortable-table thead tr .tb-header-cc:nth-child(3) {
                    padding-left: 1.55vw !important;
                    padding-right: 1.55vw !important;

                }

                .sortable-table thead tr .tb-header-cc:nth-child(4) {
                    padding-left: 1vw !important;
                    padding-right: 1vw !important;

                }

                .sortable-table thead tr .tb-header-cc:nth-child(5) {
                    padding-left: 0.19vw !important;
                    padding-right: 0.19vw !important;

                }
            }
        }
    }
}