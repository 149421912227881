@import "../../../variables.scss";

/// [Verification token]

.verification-token-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

/// [Verification token failed]
.verification-token-failed-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: -webkit-fill-available;
  height: -webkit-fill-available;

  .token-failed-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    padding: 2em;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.3);

    .title-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      span {
        font-size: 1.4583333333333333vw;
        color: rgba($color: #a8a8a8, $alpha: 0.6);
        font-weight: bold;
      }
    }
  }
}

.oa-application-form-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  position: relative;
  overflow: hidden;

  .oa-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    position: relative;
    overflow: hidden;

    .oa-tasks-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;
      overflow: hidden;

      .task-holder {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding: 1em;
        width: fit-content;
        border-bottom: 1px solid rgba($color: #a8a8a8, $alpha: 0.4);

        .task-icon {
          width: 1.25vw;
          height: 1.25vw;
          margin-right: 1em;
        }

        .task-title {
          span {
            font-size: 1.0416666666666667vw;
            font-weight: 800;
            margin-bottom: 0.3em;
          }
        }
      }
    }

    .oa-task-form {
      display: flex;
      flex-direction: column;
      flex: 2;

      .oa-task-form-header {
        padding-bottom: 0.5em;
        border-bottom: 1px solid rgba($color: #a8a8a8, $alpha: 0.4);

        span {
          font-size: 1.0416666666666667vw;
          font-weight: 800;
          color: $defaultHighLightColor;
          text-transform: unset;
        }
      }


      .oa-task-form-content,
      .oa-wrk-form,
      .oa-cnt-inf-form-container,
      .oa-edk-form {
        display: flex;
        flex-direction: column;
        overflow: auto;
        position: relative;
      }

      .error-section {
        margin-top: 2em;
      }
    }
  }

  .oa-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: -webkit-fill-available;
    padding: 1em 0;
    border-top: 1px solid rgba($color: #a8a8a8, $alpha: 0.4);
  }
}

.oa-wrk-form {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;

  .oa-wrk-header-txt {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.729vw;
    color: $defaultHighLightColor;
    font-weight: 800;

    span {
      font-size: 0.8454212454212454vw;
      color: $defaultHighLightColor;
    }
  }

  .oa-wrk-action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 1em 0;

    .add-new-position {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      .add-icon {
        width: 1.465201465201465vw;
        height: 1.465201465201465vw;
        margin-right: 1em;
      }

      .add-title {
        span {
          font-size: 0.729vw;
          color: $defaultHighLightColor;
          font-weight: 800;
        }
      }
    }
  }
}

.oa-edk-form {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;

  .oa-edk-header-txt {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.729vw;
    color: $defaultHighLightColor;
    font-weight: 800;

    span {
      font-size: 0.8454212454212454vw;
      color: $defaultHighLightColor;
    }
  }

  .oa-edk-action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 1em 0 2em 0;
    border-bottom: 1px solid rgba($color: #a8a8a8, $alpha: 0.4);

    .add-new-position {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      .add-icon {
        width: 1.465201465201465vw;
        height: 1.465201465201465vw;
        margin-right: 1em;
      }

      .add-title {
        span {
          font-size: 0.729vw;
          color: $defaultHighLightColor;
          font-weight: 800;
        }
      }
    }
  }
}

.close-extra-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em 1em 0;
  width: -webkit-fill-available;
  position: relative;

  .option-info {
    span {
      font-size: 0.8454212454212454vw;
      color: $defaultHighLightColor;
    }
  }

  .option-action {
    .close-action {
      width: 1.25vw;
      height: 1.25vw;
      cursor: pointer;
      // position: absolute;
      // top: 1em;
      // right: 1em;
    }
  }
}

.terms-condtions-input {
  display: flex;
  flex-direction: column;
  padding: 1em 0;

  .terms-conditions-font-color {
    color: $primaryColor;
  }
  input[type="checkbox"] {
    width: 10px;
  }

}

/// [Submity form success messge]

.success-message-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4em;
  margin-top: 4em;

  .message-txt {
    span {
      font-size: 1.4583333333vw;
      color: $defaultHighLightColor;
      font-weight: 800;
      text-transform: unset;
    }
  }
}

///Mobile View Styles
.oa-application-form-container-mobile {
  .oa-task-form-content {
    margin-top: 10px;
  }

  .success-message-holder .header .page-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    align-items: baseline !important;

    .sub-title span {
      font-size: 14 px !important;
      font-weight: 600 !important;
    }

    .icon-holder {
      width: 25px;
      height: 34px;
      margin-right: 20px;
    }
  }

  .ct-stp-holder .stp-container .cricle-icon {
    width: 16px;
    height: 16px;
  }

  .ct-stp-holder .ct-stp-btn {
    width: auto !important;
  }

  .ct-stp-holder .stp-container.active .cricle-icon {
    width: 24px;
    height: 24px;
  }

  .ct-stp-holder .stp-container::after {
    top: 8px;
    width: 42vw !important;
  }

  .ct-stp-holder .stp-container.active::after {
    top: 12px;
  }

  .ph-info-container .ph-info-input-holder {
    width: 54px;
    height: 54px;
  }

  .oa-holder .oa-task-form .oa-task-form-header {
    border-bottom: none;

    .title span,
    .oa-wrk-header-txt {
      font-size: 18px;
    }

    span {
      font-size: 13px;
    }
  }

  .oa-task-form-content {

    .oa-wrk-form,
    .oa-cnt-inf-form-container,
    .oa-edk-form {
      border: 1px solid $tableBorderColor;
      padding: 20px;
      border-radius: 10px;
    }

    .oa-wrk-form .oa-wrk-header-txt,
    .oa-edk-form .oa-edk-header-txt {
      span {
        font-size: 16px;
      }
    }

    .option-info {
      margin-top: 10px;

      span {
        font-size: 16px;
      }
    }
    .terms-conditions-font-color {
      font-size: 12px !important;
    }
  }

  .oa-cnt-inf-form-container .oa-cnt-inf-form-row,
  .oa-wrh-form-container .oa-wrh-form-row,
  .oa-wrh-form-container .oa-wrh-f-input-holder,
  .oa-edk-form-container .oa-edk-form-row,
  .oa-edk-form-container .oa-edk-f-input-holder
  {
    span {
      font-size: 14px !important;
    }

    input,
    .form-input {
      font-size: 13px;
    }

  }
  .oa-edk-form  .oa-edk-header-txt,
  .oa-wrk-form  .oa-wrk-header-txt{
    font-size: 14px !important;

  }

  .oa-wrh-form-container .oa-wrh-f-input-holder,
  .oa-edk-form-container .oa-edk-f-input-holder {
    .icon-btn {
      margin-top: 10px;
    }
  }

  .oa-cnt-inf-form-container .oa-cnt-inf-form-row,
  .oa-wrh-form-container .oa-wrh-form-row,
  .oa-edk-form-container .oa-edk-form-row {
    display: block;
  }

  .oa-actions {
    padding-top: 4em;
    border-top: none;
  }

  .oa-actions .btn-save .custom-btn .custom-button .btn-text span,
  .oa-actions .btn-cancel .custom-btn .custom-button .btn-text span {
    font-size: 14px !important;
  }

  .oa-actions .btn-cancel .custom-btn {
    margin-right: 10px;
  }

  .MuiInputBase-input {
    font-size: 13px !important;
    padding: 0.5em !important;
  }

  .date-input-holder-div {
    width: 100% !important;
  }

  .date-input .MuiSvgIcon-root,
  .oa-wrk-action .add-new-position .add-icon,
  .close-extra-form .option-action .close-action,
  .oa-edk-action .add-new-position .add-icon,
  .ph-info-container .ph-info-input-holder .camera-icon-holder .close-action {
    width: 16px !important;
    height: 16px !important;
  }

  .oa-wrk-action .add-new-position,
  .oa-edk-action .add-new-position {
    span {
      font-size: 14px !important;
    }

    input {
      font-size: 13px;
    }

    margin-top: 10px;

    .add-icon {
      margin-right: 10px;
    }
  }

  .terms-condtions-input {
    border: 1px solid $tableBorderColor;
    margin-top: 20px;
    padding: 20px;

    span {
      font-size: 14px !important;
    }
  }

  .oa-edk-form .oa-edk-action {
    border-bottom: none;
  }
  .drad-input-container .drad-input-holder .file-icon-info .file-icon {
    width: 18px;
    height: 18px;
    margin-bottom: 1em;
}
}