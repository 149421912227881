@import '../../../../../app/variables';

.cmp-contact-main-container {
  border: 1px solid $tableBorderColor;
  color: $primaryColor;
  padding-bottom: 1em;

  .cmp-contact-header {
    padding: 1em;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .cmp-contact-left-fields {
        display: flex;
        flex-direction: row;

        .active-checkbox {
            display: flex;
            align-items: center;
            margin-left: 20px;
      
            span {
              margin-left: 5px;
            }
      
          }
      
        .total-count {
            display: flex;
            align-items: center;
            margin-left: 20px;
            font-size: 0.781vw;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.86;
            letter-spacing: normal;
            color: $defaultHighLightColor;
            span{
                font-weight: bold;
            }
      
          }

    }

    .as-table-footer {
        position: relative;
    }

  }

  .cmp-contact-content {
    .table-data-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
      .contact-method-icon {
        svg{
            width: 1.25vw;
            height: 1.25vw;
        }
        .zip-recruiter svg {
            width: 70px;
        }
        .indeed svg {
            width: 40px;
        }
      }
  }
  .sortable-table {
      tbody {
          display: block;
          overflow-y: auto;
          max-height: 47vh;
      }
  }

  @media screen and (max-width:1650px) {
      .sortable-table {
          tbody {
              display: block;
              overflow-y: auto;
              max-height: 45vh;
          }
      }
  }

  @media screen and (max-width:1500px) {
      .sortable-table {
          tbody {
              display: block;
              overflow-y: auto;
              max-height: 45vh !important;
          }
      }
  }

  @media screen and (max-width:1280px) {
      .sortable-table {
          tbody {
              display: block;
              overflow-y: auto;
              max-height: 47vh !important;
          }
      }
  }
}


.cmp-contact-jobs-dialog-container {
    // width: 900px;
    height: 430px;
    margin: 10px 20px 30px 20px;
    color: $primaryColor;

    .dialog-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left-fields {
            display: flex;
            flex-direction: row;
            align-items: center;

            h4 {
                margin-left: 20px;
                margin-top: 10px;
            }
        }

        img {
            cursor: pointer;
        }
    }

    .cmp-contact-dialog-content {
        height: 300px;
        overflow-y: scroll;
        margin-bottom: 20px;

        .form-row {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            margin-right: 10px;

            .form-column {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
            }

            // .form-column-1 {
            //     width: 570px;
            // }

            // .form-column-2 {
            //     width: 425px;
            // }
        }

        .cmp-contacts-jobs-dropdown {
            margin-top: 5px;
            .testing {
                width: 280px;
            }
        }


    }

    .message {
        border-top: 1px solid $tableBorderColor;
        margin-left: 10px;
        padding-top: 10px;
        height: 1.302vw;
    }

    .cmp-contact-dialog-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .btn-cancel {
            margin-right: 1em;
        }
    }
    
}