@import '../variables.scss';

.mp-container{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient($white, $backgroundColor);
    box-sizing: border-box;
    overflow: hidden;

    .mp-nav{
        display: flex;
        width: 100%;
        box-sizing: border-box;
        position: relative;
    }

    .mp-swither{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: -webkit-fill-available;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        .mp-side-menu{
            display: flex;
            background-color: $white;
            margin-top: 5px;
            height: -webkit-fill-available;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
        }

        .mp-wrapper{
            padding: 1em;
            width: -webkit-fill-available;
            box-sizing: border-box;
            position: relative;
            overflow: auto;
        }
    }

}


.password-update-dialog-container {
    padding: 1vw;
    color: $primaryColor;

    .password-update-dialog-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .password-update-dialog-content {
        display: flex;
        flex-direction: column;

        .password-valid-message {
            margin-left: 5px;
            height: 2vh;
            color: red;
        }
        .form-input-holder .suffix-holder{
            cursor: pointer;
        }


    }

    .password-update-dialog-footer {
        margin-top: 4vh;

        .update-button {
            float: right;
        }

    }

    .password-update-success {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3vh 0 3vh 0;

        h6 {
            margin-left: 10px;
            margin-bottom: 0;
        }
    }

}