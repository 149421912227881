@import "../../../../variables.scss";

.tlnt-emp-history-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
    padding-top: 20px;

    .tlnt-emp-history-ud-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // margin-bottom: 1em;
    }

    .tlnt-emp-history-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .tlnt-emp-history-ud-add-user-btn {
            width: fit-content;
        }

        .tlnt-emp-history-ud-add-icon {
            color: $white;
        }
    }

    .child-div {
        flex-grow: 1;
        height: 80px;
        margin: 0 auto;
        width: 100%;
    }

    .details-container {
        padding: 30px;
    }
}

.text-center {
    text-align: center;
}

.timeline-parent-div {
    margin-top: 0px;
}

.timeline-div {
    width: 100% !important;
    height: 100px;
    // margin: 0 auto;
    margin-left: 30px;
    display: flex;
    justify-content: flex-start;
}

.tlnt-emp-history-ud-container-empty {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
    height: 24.15812591508053vw;

    .no-data-div {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            font-size: 1.25vw;
            font-weight: 900;
            color: grey;
        }

        .tlnt-emp-history-ud-actions {
            margin-top: 20px;
        }
    }
}




.company_span {
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    color: $primaryColor;
    border: 1px solid $primaryColor;
    margin: 5px 0px 20px;
    padding: 5px 10px;
    position: relative;
    border-radius: 2px;
    ;
}

.company_span:after {
    position: absolute;
    content: '';
    width: 0.833vw;
    height: 0.833vw;
    border-top: 0px solid $primaryColor;
    border-right: 1px solid $primaryColor;
    border-bottom: 1px solid $primaryColor;
    border-left: 0px solid $primaryColor;
    left: 50%;
    transform: rotate(45deg);
    background: white;
    transform-origin: 0 0;
    bottom: -0.4444em;
}

.active_item::after {
    background-color: $primaryColor  !important;
}

.active_item .company_span,
.active_item .company_span::after {
    background-color: $primaryColor;
    color: white;
    font-weight: 600;
}

.timeline-ul .year_span {
    font-size: 0.937vw;
    color: $primaryColor;
    font-weight: 300;
}

.timeline-div button {
    border: 0;
    color: red;
    font-size: 1.25vw;
    margin: 0 8px;
}
// history holder

.history-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;

    .scrollable-list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        flex: 3;

        .left-action,
        .right-action {
            padding: 1em;
            cursor: pointer !important;
        }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-end;
        }


        .timeline-ul {
            display: flex;
            position: relative;
            justify-content: space-between;
            padding: 0;
            overflow-x: scroll;
            scroll-behavior: smooth;
            box-sizing: border-box;

            &::before {
                content: "";
                height: 0.5px;
                background: $tableBorderColor;
                position: absolute;
                bottom: 15px;
                left: 0;
                right: 0;
                z-index: 0;
                // transform: translateX(-50%);
            }

            &::-webkit-scrollbar {
                background: transparent !important;
                /* make scrollbar transparent */
                -webkit-appearance: none !important;
                width: 0 !important;
                height: 0 !important;
            }
        }

        .timeline-ul li {
            text-align: center;
            list-style: none;
            margin: 0 20px;
            position: relative;
            width: fit-content !important;
        }

        .timeline-ul li:hover {
            box-shadow: none !important;
        }

        .timeline-ul li::after {
            content: " ";
            width: 0.781vw;
            height: 0.781vw;
            border: solid 2px $primaryColor;
            border-radius: 50%;
            display: block;
            margin: 0 auto 5px;
            background: white;
        }

        .timeline-ul li.active::after {
            content: " ";
            width: 0.833vw;
            height: 0.833vw;
            position: absolute;
            top: 4px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            display: block;
            background: $primaryColor;
        }


    }

    .history-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1;
    }
}