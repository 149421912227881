@import "../../../../variables.scss";


.cps-ud-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);



  .cps-ud-table-header {
      padding: 1em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // margin-bottom: 1em;
  }

  .cps-ud-actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end;

      .mcd-search{
        margin-right: 1rem;
      }

      .cps-ud-add-user-btn {
          width: fit-content;
      }

      .cps-ud-add-icon {
          color: $white;
      }
  }




}

.table-loading {
  width: 100%;
  height: 200px;
}

.cps-ud-table-header-option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;

  .cps-ud-table-search-bar{
      margin-right: 1em;
    //   width: 180px;
  }

  .select-user {
      margin-right: 1em;

      .user-form-control {
          min-width: 190px;
          width: fit-content;
      }
  }

  .deparment-toggle {
      margin-right: 1em;
  }

  .total-count {
    margin-top: 20px;
    margin-left: 20px;
      span {
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.86;
          letter-spacing: normal;
          color: $defaultHighLightColor;

          .total-count-number {
              font-weight: bold;
          }
      }
  }
}

.website-icon {
  width: 1.25vw;
  height: 1.25vw;
  cursor: pointer;
  text-decoration: none;

}

.open-orders-empty {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .ord-icon {
      width: 1.25vw;
      height: 1.25vw;
      margin-right: 1em;
  }

  .ord-text-empty{
      span {
          color: #b1b1b1;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
      }
  }

  .ord-text {
      span {
          color: $openOrdersTextColor;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
      }
  }
}