@import '../../../../../variables.scss';


.uwcc-tab-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: -webkit-fill-available;

    .wcc-tab-field-row {
        display: inline-flex;
        flex-direction: row;
        width: -webkit-fill-available;
        margin-top: 3em;
        margin-left: 2em;
        margin-right: 0.5em;
        justify-content: space-between;
    }

    .wcc-tab-field-column {
        width: 25%;
    }

    .wcc-tab-field-column1 {
        width: 48%;
    }

    .wcc-tab-field-column2 {
        width: 100%;
    }

    .wcc-claim-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .wcc-tab-claim-doc {
            border: 1px solid $tableRowTextColor;
            width: 32%;
            font-size: 10px;
            color: $tableRowTextColor;
            background-color: #f8fcfd;
            font-weight: 800;
            margin: 1em 1em 1em 0;
            padding: 0.6em 1em;
            display: flex;
            flex-direction: row;
            align-items: center;

            .icons-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-left: 1em;

                .custom-btn {
                    width: auto !important;
                }

                .wcc-tab-download-button {
                    margin-right: 0.5rem;
                }

                .custom-button {
                    background-color: #00A4DC;
                    width: fit-content;
                    padding: 3px;
                    display: flex;
                    justify-content: center !important;

                    svg {
                        width: 0.8vw;
                        height: 0.8vw;
                    }

                    .btn-text {
                        display: none;
                    }
                }
            }
        }
    }

    .wcc-tab-field-header {
        font-size: .833vw;
        color: $userProfileNameColor;
    }

    .wcc-tab-field-label {
        font-size: 0.72vw;
        color: $userProfileNameColor;
        font-weight: 800;
        margin-top: 2px;
    }

    .wcc-tab-border {
        padding-bottom: 1em;
        border-bottom: 1px solid rgba(3, 80, 144, 0.1);
    }


    .wcc-tab-search-button {
        background-color: $buttonColor2A;
        margin-right: 0px;
    }
}