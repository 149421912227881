@import '../../../../../../variables.scss';

.wc-code-container {
    .code-ro-content-table {
        height: 15vw;
        max-height: 15vw;
        .sortable-table thead {
            z-index: 100;
        }
    }
}