@import '../../../variables.scss';

.oa-cnt-inf-form-container{
    display: flex;
    flex-direction: column;

    .oa-cnt-inf-form-row{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.oa-cnt-inf-f-input-holder{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 1em;
}