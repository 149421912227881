@import '../variables.scss';

.userlist-main-div {
    // padding: 0.5rem 1.5rem 0.5rem 1rem;
    
    .username-view {
        padding: 0.8rem 6em 0.8rem 1.5rem;
        border-bottom: 1px solid $divDividerColor;
        .user-mail {
            color: $primaryColor;
            text-transform: none;
            font-size: 0.99vw;
        }
        .user-name-span {
            color: $primaryColor;
            text-transform: none;
            font-size: 1.16vw;
            font-weight: 800;
        }
    }
    ul{
    padding: 0.5rem;
    }
}

ul {
    list-style-type: none;
    padding: 0.2em 0;
    margin-bottom: 0;
}


.uml-item-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em 1em;

}

.uml-item-icon {
    grid-area: item-icon;

    svg {
        height: 1vw;
        width: 1vw;
        ;
    }
}

.uml-item-name {
    span {
        text-overflow: ellipsis;
        font-weight: bold;
        color: $defaultHighLightColor;
        margin-left: 0.5em;
    }
}

li {
    cursor: pointer;
    width: 100%;
    padding: 0.6em;
    transition: all 0.3s ease-in-out;
    span{
        font-size:0.9vw;
    }

}

.profile-ul {
    li:hover {
        border-radius: 2px;

        span {
            text-decoration: underline;
        }

        background-color: rgb(235, 241, 248);
 
    }
}