@import "../../../../../variables.scss";

.ac-sc-ob-content-container {
  border: 1px solid $tableBorderColor;
  margin-top: 0.5em;
  border-radius: 5px;

  .iframe-div,
  .video-actions {
    width: 50%;

    iframe {
      width: 100%;
    }
  }

  .video-actions,
  .paperwork-content {
    padding: 2em 0em;

  }

  .ac-sc-ob-total-count {
    padding: 1em;
  }

  .ac-sc-ob-company-content {
    padding: 0em 2em 3em 2em;

    .flex-input-holder {
      // margin-top: 1em;
      // display: flex;
      // justify-content: space-between;
      // width: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-top: 1em;
      width: -webkit-fill-available;

      .esc-card,
      .wotc-card {
        flex: 1;
      }

      .wotc-card {
        margin-right: 1em;
      }

      .input-holder {
        width: 48% !important;
        margin: 1rem
      }
    }

  }

    .table-data {
      .tc-span {
        color: #0b3e79;
        margin-bottom: 1em;
      }
  
      .sortable-table {
        .table-data-container {
          .custom-btn {
            width: auto !important;
            margin-right: 0.5em;
            .custom-button {
              padding: 0.2em !important;
            }
            .download-icon {
              .btn-text {
                display: none !important;
              }
            }
  
            .delete-icon {
              width: auto !important;
              height: 1.59vw !important;
              .btn-text {
                display: none !important;
              }
            }
          }
        }
      }
    }
}

.ac-sc-ob-content-header {
  background-color: #f7fbfd;
  padding: 0.5em 2em;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid $tableBorderColor;

  span {
    font-size: 0.837vw;
    font-weight: 600;
    color: #0b3e79;
  }
}

.ac-sc-ob-content {
  padding: 2em;

  textarea {
    min-height: 180px !important;
  }

  .drop-files-name {
    height: 70px !important;
  }

  .table-data {
    .sortable-table thead {
      z-index: 100;
    }
  }
}

.ac-sc-ob-content-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  padding: 2em 0em;
  align-items: center;

  .btn-del {
    margin-left: 1em;
    cursor: pointer;
  }

  span {
    color: #0b3e79;
  }

  .btn-cancel {
    margin-right: 2em;
  }
}

.ac-sc-ob-content-actions.video-actions {
  justify-content: space-between;
}

.ac-sc-ob-content-actions.company-actions {
  // border-top: 1px solid $tableBorderColor;
  margin: 0em 2em;
  padding: 1.5em 0em;
}

.ac-ob-btn-header {
  display: flex;
  justify-content: flex-end;
  padding: 2em 2em 0em 2em;
}

.ac-ob-edit-icon {
  width: 0.989vw;
  height: 0.989vw;
}

.hv-icon-btn:hover {
  width: 1.2vw;
  height: 1.2vw;
}