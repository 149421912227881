@import '../../../../../variables.scss';

.job-order-docs {
    
    .job-order-documents {
        border: 1px solid $tableBorderColor;
        max-height: 50% !important;

        .job-order-documents-header {
            padding: 15px;
            color: $primaryColor;
            display: flex;
            justify-content: space-between;

            .dwn-icon {
                svg {
                    .cls-1 {
                        fill: #ffffff;
                    }
                }
            }

            .title {
                display: flex;
                flex-direction: row;

                .doc-title {
                    font-size: 1vw;
                    font-family: $defaultFontFamily;
                    color: $userProfileNameColor;
                    font-weight: 700;
                    margin: 0.4em 0 0 0.5em;
                }
            }

        }

        .job-order-documents-content {
            padding-bottom: 1em;
            table .scrolling-table{
                max-height: 180px;
                overflow-y: scroll !important;
                overflow-x: hidden;
                svg {
                    .cls-1 {
                        fill: #ffffff !important;
                    }
                }
            }

            .sortable-table thead {
                z-index: 100;
            }
        }
    }

    .job-order-documents-videos {
        margin-top: 15px;
        padding: 10px;
        border: 1px solid $tableBorderColor;
        color: $primaryColor;
        height: 45vh;

        .data-found {
            height: 20vw !important;
            iframe {
                height: 100% !important;
                width: 100% !important;
            }
        }
        
        .job-order-documents-videos-header {
            display: flex;
            justify-content: space-between;

            .dwn-icon {
                svg {
                    .cls-1 {
                        fill: #ffffff;
                    }
                }
            }

            .job-order-documents-videos-header-left {
                display: flex;

                .vid-title{
                    font-size: 1vw;
                    font-family: $defaultFontFamily;
                    color: $userProfileNameColor;
                    font-weight: 700;
                    margin: 0.4em 0 0 0.5em;
                }

            }

        }

        .job-order-documents-videos-content {
            
            .job-order-documents-videos-content-body {
                margin-top: 15px;
                height: 100% !important;
    
                svg {
                    cursor: pointer;
                    width: 1vw;
                    height: 1vw;
                }
    
            }

            .data-found {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 15vw !important;
                padding: 0 1em;

                .job-order-docs-container {
                    height: 10vw;
                    width:25%;

                    .job-order-docs-container-header {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0.5em;
                        margin-bottom: 0.5em;
                        .mdv-font-style {
                            font-weight: bold;
                            font-size: 0.927vw;
                        }
                    }

                }

            }

            .empty-video-data {
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }

    }

}


.document-upload-container {
    padding: 2em;
    color: $primaryColor;
    img {
        width: 1.25vw;
        height: 1.25vw;
    }

    span {
        font-size: 1.041vw;
        font-weight: 900;
        color: #0b3e79;
    }
    
    .document-upload-container-dialog-header {
        display: flex;
        justify-content: space-between;
    }

    .document-upload-container-dialog-content {
        margin-top: 0;

        .upload-dpdw-title{
            margin-top: 1em;
        }

        .drop-file-container{
            max-height: 12vw;
            .drop-files-name{
                span{
                    font-size: 0.729vw !important;
                    font-weight: 700 !important;
                    margin-bottom: 0.3em !important;
                    color: #0b3e79;
                }
            }
        }

    }

    .document-upload-container-dialog-footer {
        width: -webkit-fill-available;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

}