.employee-assignment-report-container {
    .rc-scsr-btn {
        svg {
            .cls-1 {
                fill: #fff !important;
            }
        }
    }
    
    .ear-table {
        max-height: 58vh;
        overflow: auto;
        position: relative;

        .sortable-table thead {
            z-index: 0;
            position: sticky;
        }
    }
    
    .error-holder {
        width: 100vw;
        margin-bottom: 0;
        margin-top: 0.5em;
    }
}