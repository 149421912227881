@import '../../../../../variables.scss';

.acds-container{
    .short-desc{
        border-top: 1px solid #0350901a;
        color: #0b3e79;
        padding: 1em;
        span{
            display: inline;
        }
        .show-more-btn{
            color: #035090;
            padding:0.1em;
            font-weight: 800;
        }
    }
    .info-table-div{
        table{
            width: 100%;
            border-collapse: collapse;
            tbody {
                tr td{
                    padding:0.2rem 0.5rem 0.2rem 1rem;
                    color: #035090;
                    width:50%;
                }
                td:first-child{
                    font-weight: 700;
                    
                }
                // td:nth-child(2){
                //     width:60%;
                // }
            }
            
        }
    }
}
