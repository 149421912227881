@import '../../../../variables.scss';

.my-profile-dashboad-container {
    display: block;
    flex-direction: column;
    width: -webkit-fill-available;
    margin-left: 1rem;

    .my-profile-container {
        padding: 1rem 2rem;

        .my-team-members-section {
            display: flex;
            justify-content: space-between;

            .team-name-content {
                .keyboard-backspace {
                    cursor: pointer;
                    // margin-bottom: 0.5em;
                    color: $defaultHighLightColor;
                }

                span {
                    font-weight: 900;
                    font-size: 1.5em;
                    margin-left: 0.5em;
                    color: $defaultHighLightColor;
                }
            }
        }

        .total-count {
            margin: 0.8rem 0;

            span {
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.86;
                letter-spacing: normal;
                color: $defaultHighLightColor;

                .total-count-number {
                    font-weight: bold;
                }
            }
        }

        .my-team-members-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            .mtm-hover-state-parent {
                position: relative;

                .mtm-hover-state-child {
                    position: absolute;
                    top: 3rem;
                    right: 1.5rem;
                    border: 1px solid $defaultHighLightColor;
                    border-radius: 0.5em;
                    color: $defaultHighLightColor;
                    padding: 0.5em;
                    background-color: $white;
                    z-index: 1;
                }
            }
        }

        .my-teams-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;

            .mtm-hover-state-parent {
                position: relative;

                .mtm-hover-state-child {
                    position: absolute;
                    bottom: 1rem;
                    right: 1.5rem;
                    border: 1px solid $defaultHighLightColor;
                    border-radius: 0.5em;
                    color: $defaultHighLightColor;
                    padding: 0.5em;
                    background-color: $white;
                }
            }

        }

        .my-team-members-content,
        .my-teams-section {
            .mps-flex-card {
                flex: 1;
                border-radius: 0.5em;
                border: 1px solid $statusDisabledColor;
                background-color: $dasboardOptionBackgroundColor;
                position: relative;
                // padding: 1rem 0.5rem;
                margin: 0 1.5rem 1.5rem 0;
                max-width: calc(33.3333% - 1.5rem);
                min-width: calc(33.3333% - 1.5rem);
                width: 16vw;
            }
        }

        .my-teams-section {
            .mps-flex-card {
                max-width: calc(23% - 2.5rem);
                min-width: calc(23% - 2.5rem);
                width: 12vw;

            }
        }




        @media (max-width: 600px) {
            .my-team-members-content {
                .mps-flex-card {
                    min-width: 35%;
                }
            }
        }

        @media (min-width: 600px) {
            .my-team-members-content {
                .mps-flex-card {
                    min-width: 35%;
                }
            }
        }

        @media (max-width: 1200px) {
            .my-team-members-content {
                .mps-flex-card {
                    min-width: 60%;
                }
            }
        }
        @media (min-width: 1366px) {
            .my-team-members-content {
                .mps-flex-card {
                    min-width: calc(50% - 2.5rem);
                }
            }
        }




        @media (max-width: 600px) {
            .my-teams-section {
                .mps-flex-card {
                    min-width: 35%;
                }
            }
        }

        @media (min-width: 600px) {
            .my-teams-section {
                .mps-flex-card {
                    min-width: 35%;
                }
            }
        }
        @media (max-width: 1200px) {
            .my-teams-section {
                .mps-flex-card {
                    min-width: 50%;
                }
            }
        }
        @media (min-width: 1366px) {
            .my-teams-section {
                .mps-flex-card {
                    min-width: calc(23% - 2.5rem);
                }
            }
        }
    }
}