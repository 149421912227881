@import '../../../variables.scss';


.drdp-f-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title-txt{
      color: $defaultHighLightColor !important;
      font-weight: 600;
      margin-bottom: 1rem;
      font-size: 1.6rem;
  }
  
}

.direct-deposit-sortable-table {
    margin-bottom: 1rem;
    tbody {
      display: block;
      overflow-y: auto;
      max-height: 27vh;
      td{
        border-right:1px solid rgba(195, 196, 198, 0.3);
        .switch {
          .slider:after {
            content: "OFF" !important;
          }
      
          input:checked + .slider:after {
            content: "ON" !important;
          }
        }
      }
    }
  }
  .cursor-p{
    cursor: pointer;
  }