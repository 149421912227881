@import '../../../../../variables.scss';

.crime-docs-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    padding: 1.0416666666666667vw;

    .btn-row {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .crime-hist-data-scroll {
        max-height: 40vh;
        overflow: auto;
        padding:0em 1em;
        margin-top:2em;
        .crime-rec-row-actions{
            display: flex;
            justify-content: space-between;
            .crime-rec-row{
                display: flex;
                justify-content: center ;
                div{
                    display: flex;
                    align-items: center;
                    // margin-right:1em;
                }
                svg {
                    width: 1.6276041667vw;
                    height: 1.6276041667vw;  
                    margin-right: 1vw;
                }
            }
           
            .actions-icons{
                display: flex;
                flex-direction: row;
                svg {
                    width: 0.9vw;
                    height: 0.9vw;
                    margin-right: 1vw;
                    cursor:pointer;
                    path{
                        fill:#bdc2cc;
                    }
                }
            }
        }
       

    .crime-hist-data-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid rgba(165, 165, 165, 0.4);
        padding-bottom: 2em;
        margin-bottom: 2em;;
        margin-top:1em;

        .data-card {
            width: calc((100%/3) - 5%);
            color: rgb(11, 62, 121);

            label {
                font-size: 0.833vw;
                margin-bottom: 5px;
                margin-top: 10px;
                font-weight: 900;
            }

            div {
                font-size: 0.729vw;
                margin-bottom: 5px;
                // margin-top: 10px;
                font-weight: 600;
            }
        }
        
    }
    .pill-card-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pill-card {
        width: 48%;
    }
    .doc-pill-card-container {
        position: relative;
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding: 0.5vw;
        box-shadow: 0px 0.5px 1px 0px rgba($color: $pageHeaderBoderColor, $alpha: 1.0);
        border-radius: 5px;
        margin-top: 1.0416666666666667vw;
        background-color: #F7FBFD;
        border: 1px solid #C1CDD466;

        width: -webkit-fill-available;
        transition: 0.3s all ease-in-out;

        &:hover {
            background-color: #dff1fa;
        }

        .stat-icon {
            width: 1.6276041667vw;
            height: 1.6276041667vw;
            margin-right: 1.0416666667vw;
            flex:none !important;
        }
        .d-row{
            display: flex;
            flex-direction: row;
        }

        .doc-name {
            width: -webkit-fill-available;
            span {
                color: $defaultHighLightColor;
                font-size: 0.729vw;
                font-weight: bold;

            }
        }
        .doc-down-btn {
            padding: 2px 4px;
            margin-right: 1em;;

            .btn-text {
                display: none;
            }

            svg {
                width: 0.7vw;
                height: 0.7vw;
            }

        }
        .del-svg{
            width: 0.8vw;
                height: 0.8vw;
        }
        .stat-banner {
            position: absolute;
            top: 0.441667vw;
            right: 0.441667vw;
            padding: 0 0.441667vw;
            border-radius: 20px;

            span {
                color: #fff;
                font-size: 0.5208333333333334vw;
            }
        }
        
    }
}
.error-span{
    color: #0b3e79;
    font-size: 0.8vw;
    font-weight: 500;
}
}