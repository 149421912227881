@import '../../variables.scss';

.side-nav-holder{
    display: flex;
    flex-direction: column;
    position: relative;
    height: -webkit-fill-available;
    width:  -webkit-fill-available;


    .nav-exp-item-container{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding: 0 1em;

        .expand-tile{
            padding: 0.5em;
            border-radius: 3px;
            transition: 0.3s all ease-in-out;
            &:hover {
                background-color: $dasboardOptionBackgroundColor;
            }
            .expand-icon{
                font-size: 1.25vw;
                color: $defaultHighLightColor;
            }

            .rotate {
                transform: rotate(-180deg);
            }
        }
    }
}

.left-nav-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: -webkit-fill-available;
    width:  -webkit-fill-available;
    overflow-y: auto;

    .nav-item-container {
        text-decoration: none;
        // background-color: $white;
        margin-bottom: 0.4em !important;
        transition: 0.3s all ease-in-out;
        border-radius: 4px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 0 0;
        padding: 0;
        list-style: none;

        a {
            text-decoration: none;
            margin: 0;
            width: 100%;
            padding: 0.5rem 1rem;
            margin: 0;
            background-color: $white;
            border-left: solid 4px $white;
            transition: 0.3s all ease-in-out;

            &:hover {
                border-radius: 4px;
                font-weight: 900;
                width: 100%;
                padding: 0.5rem 1rem;
                margin: 0;
                transition: 0.3s all ease-in-out;
            }
        }

        .is-active {
            border-radius: 4px;
            font-weight: 800;
            width: 100%;
            padding: 0.5rem 1rem;
            margin: 0;
            transition: 0.2s all;
        }

    }


    .dashboard {
        a {
            &:hover {
                border-left: solid 6px $dasboardOptionTextColor  !important;
                background-color: $dasboardOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $dasboardOptionTextColor  !important;
            background-color: $dasboardOptionBackgroundColor;
        }
    }

    .sales-marketing {
        a {
            &:hover {
                border-left: solid 6px $stateMarketingOptionTextColor  !important;
                background-color: $stateMarketingOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $stateMarketingOptionTextColor  !important;
            background-color: $stateMarketingOptionBackgroundColor;
        }
    }

    .companies {
        a {
            &:hover {
                border-left: solid 6px $companiesOptionTextColor  !important;
                background-color: $companiesOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $companiesOptionTextColor  !important;
            background-color: $companiesOptionBackgroundColor;
        }
    }

    .contacts {
        a {
            &:hover {
                border-left: solid 6px $contactsOptionTextColor  !important;
                background-color: $contactsOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $contactsOptionTextColor  !important;
            background-color: $contactsOptionBackgroundColor;
        }
    }

    .talent {
        a {
            &:hover {
                border-left: solid 6px $talentOptionTextColor  !important;
                background-color: $talentOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $talentOptionTextColor  !important;
            background-color: $talentOptionBackgroundColor;
        }
    }

    .jobs {
        a {
            &:hover {
                border-left: solid 6px $jobsOptionTextColor  !important;
                background-color: $jobsOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $jobsOptionTextColor  !important;
            background-color: $jobsOptionBackgroundColor;
        }
    }

    .assignments {
        a {
            &:hover {
                border-left: solid 6px $assignmentsOptionTextColor  !important;
                background-color: $assignmentsOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $assignmentsOptionTextColor  !important;
            background-color: $assignmentsOptionBackgroundColor;
        }
    }

    .report-center {
        a {
            &:hover {
                border-left: solid 6px $reportCenterOptionTextColor  !important;
                background-color: $reportCenterOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $reportCenterOptionTextColor  !important;
            background-color: $reportCenterOptionBackgroundColor;
        }
    }

    .back-office {
        a {
            &:hover {
                border-left: solid 6px $backOfficeOptionTextColor  !important;
                background-color: $backOfficeOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $backOfficeOptionTextColor  !important;
            background-color: $backOfficeOptionBackgroundColor;
        }
    }

    .risk-management {
        a {
            &:hover {
                border-left: solid 6px $riskManagementOptionTextColor  !important;
                background-color: $riskManagementOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $riskManagementOptionTextColor  !important;
            background-color: $riskManagementOptionBackgroundColor;
        }
    }

    .employee-portal {
        a {
            &:hover {
                border-left: solid 6px $employeePortalOptionTextColor  !important;
                background-color: $employeePortalOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $employeePortalOptionTextColor  !important;
            background-color: $employeePortalOptionBackgroundColor;
        }
    }

    .client-portal {
        a {
            &:hover {
                border-left: solid 6px $clientPortalOptionTextColor  !important;
                background-color: $clientPortalOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $clientPortalOptionTextColor  !important;
            background-color: $clientPortalOptionBackgroundColor;
        }
    }

    .admin-center {
        a {
            &:hover {
                border-left: solid 6px $adminCenterOptionTextColor  !important;
                background-color: $adminCenterOptionBackgroundColor;
            }
        }

        .is-active {
            border-left: solid 6px $adminCenterOptionTextColor  !important;
            background-color: $adminCenterOptionBackgroundColor;
        }
    }

    /// Text colors

    .dashboard-txt {
        color: $dasboardOptionTextColor;
    }

    .sales-marketing-txt {
        color: $stateMarketingOptionTextColor;
    }

    .companies-txt {
        color: $companiesOptionTextColor;
    }

    .contacts-txt {
        color: $contactsOptionTextColor;
    }

    .talent-txt {
        color: $talentOptionTextColor;
    }

    .jobs-txt {
        color: $jobsOptionTextColor;
    }

    .assignments-txt {
        color: $assignmentsOptionTextColor;
    }

    .report-center-txt {
        color: $reportCenterOptionTextColor;
    }

    .back-office-txt {
        color: $backOfficeOptionTextColor;
    }

    .risk-management-txt {
        color: $riskManagementOptionTextColor;
    }

    .employee-portal-txt {
        color: $employeePortalOptionTextColor;
    }

    .client-portal-txt {
        color: $clientPortalOptionTextColor;
    }

    .admin-center-txt {
        color: $adminCenterOptionTextColor;
    }

}

.nav-item-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .nav-item-icon {
        width: 1.562vw;
        margin-right: 1em;

        .icon {
            width: 1.145vw;
            height: 1.145vw;
        }
    }

    .nav-item-title {
        .left-heading {
            font-size: 0.729vw;
            font-family: $defaultFontFamily;
        }
    }
}


