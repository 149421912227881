@import '../../variables.scss';

.card {
    display: -webkit-inline-box;
    border: 1px solid $inputBorderColor;
    border-radius: 5px;
    // width: -webkit-fill-available;
    padding: 0.8em 1em;
    // margin-right: 1em;

    .icon {
        border-radius: 50%;
        padding: 0.7em;

        // background-color: $backgroundHoverColor;
        svg {
            width: 1.7vw;
            height: 1.7vw;
        }
    }

    .text-btn {

        .text-line-one,
        .text-line-two,
        .text-line-three {
            margin-left: 0.85rem;
            font-size: 0.789vw;
            font-weight: 700;
            // color: $primaryColor;
            text-overflow: ellipsis;
        }

        .btn-yes-no {
            width: 60%;

            .yes {
                color: $statusActiveColor;
                background-color: $statusLightActiveColor;
                border-radius: 3px;
                padding: 0.2em 1.2rem;
                font-size: 0.689vw;
                font-weight: 800;
                margin: 0.5em 0 0 1rem;
                cursor: pointer;
            }

            .no {
                color: $statusInActiveColor;
                background-color: $statusLightInActiveColor;
                border-radius: 3px;
                padding: 0.2em 1.2rem;
                margin-left: 0.5em;
                font-size: 0.689vw;
                font-weight: 800;
            }
        }
    }
}