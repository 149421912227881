@import '../../../../variables.scss';

.bo-ob-table-body{
    .toggle-switch {
        margin-top: 1em;
        .switch {
            .slider:after {
                content: 'NO';
            }
            input:checked+.slider:after {
                content: 'YES';
            }
        }
    }
}