@import '../../../../variables.scss';


.backoffice-invoice-docs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .sortable-table thead {
        z-index: 100;
    }

    .backoffice-id-container-holder {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;

        .backoffice-id-card {
            flex: 1;
            min-width: 49%;
            max-width: 49%;
            width: 49%;
            margin-bottom: 2rem;
            height: 100%;
            position: relative;
            // border: 0.1px solid rgba(193, 205, 212, 0.2509803922) !important;
            min-height: -webkit-fill-available !important;

            
            .sortable-table thead {
                z-index: 99;
            }

            .scrd-container {
                background: red !important;
                height: 100% !important;
                border: none !important;
                min-height: auto !important;

                .scrd-header {
                    .scrd-path-name {
                        .scrd-path-txt {
                            text-transform: none !important;
                        }
                    }
                }
            }

        }
        .backoffice-right-side-card{
            flex: 1;
            min-width: 49%;
            max-width: 49%;
            width: 49%;
            height: 100%;
            position: relative;
            min-height: -webkit-fill-available !important;
        } 
    }
}