@import '../../variables.scss';

.step {
    width: 230px;
    // height: 180px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7fbfd;
    border: 1px solid #c0ccd3;
    cursor: pointer;
    margin-right: 20px;

    .step-label {
        span {
            font-size: 0.937vw;
            text-align: center;
            font-weight: bold;
        }

    }

    .st-icon {
        margin-bottom: 1em;

        svg path {
            fill: $userProfileNameColor;
        }
    }

}