@import "../../variables.scss";

.curve-tab-navigation-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: -webkit-fill-available;
  height: -webkit-fill-available;

  .tab-curve {
    position: absolute;
    transform: scale(1);
    height: 102%;

    &.right {
      left: 99%;
      z-index: 1;
    }
    &.left {
      right: 99%;
      z-index: 1;
    }
    &.inactive {
      left: 99%;
      z-index: 1;
      bottom: 0%
    }
  }

  .curve-tab-header {
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;

    .curve-tab-options-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      position: relative;

      .curve-tab-option {
        padding-left: 20px;
      }

      .curve-tab-option {
        background-color: #f3f3f9;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        text-decoration: none;
        cursor: pointer;
        position: relative;

        .tab-option {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 0.5em 1em 0.3em 1em;
          position: relative;

          .tab-icon {
            width: 1.25vw;
            height: 1.25vw;
            margin-right: 1em;
          }

          .tab-title {
            width: 100%;
            margin-top: 0.20833333333333334vw;
            span {
              font-size: 0.8vw;
              font-family: $defaultFontFamily;
              color: $defaultHighLightColor;
            }
          }
        }
      }

      .tab-active:not(:first-child) {
        margin-left: 30px;
        .tab-option {
          padding: 0.5em 0em 0.3em 0.3em;
        }
      }

      .tab-active:first-child {
        .tab-option {
          padding: 0.5em 0em 0.3em 1em;
        }
      }

      .tab-active {
        background-color: #0353a0 !important;
        padding-left: 0px !important;
        z-index: 2;

        .tab-option {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: relative;

          .tab-icon {
            width: 1.25vw;
            height: 1.25vw;
            margin-right: 1em;
            svg path{
              fill:white !important;
            }
          }

          .tab-title {
            span {
              font-size: 0.8vw;
              font-family: $defaultFontFamily;
              color: $white !important;
            }
          }
        }
      }
    }

    .curve-tab-close-action {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      text-decoration: unset;
      cursor: pointer;
      padding: 4px 8px;
      transition: 0.3s all ease-in-out;

      &:hover {
        background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1);
      }

      .curve-tab-close-icon {
        margin-right: 0.5em;
        color: $defaultActionColor;
      }

      span {
        font-size: 0.729vw;
        font-family: $defaultFontFamily;
        color: $defaultActionColor;
      }
    }
  }

  .curve-tab-content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    border-top: 10px solid #0353a0;
    border-top-right-radius: 10px;
  }
}
