@import '../../../../variables.scss';

.edocs-invoice-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 2em;
    overflow: hidden;

    .edocs-invoice-header {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $tableBorderColor;
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 0.5rem;

    }

    .edocs-invoice-content {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        margin-top: 0.5rem;
    }
}