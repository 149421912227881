@import '../../../../../variables.scss';


.obd-docs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 1vw 0 0 1vw;
    justify-content: space-between;
    

    .obd-status-options {
        flex: 3;
        display: flex;
        flex-direction: row;

        .option-column {
            display: flex;
            flex-direction: column;
            margin-right: 1.77em;
        }
    }

    .obd-actions {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;

        .send-link,
        .create-login {
            margin-bottom: 1.0416666666666667vw;
        }
    }
   .main-docs-row2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:max-content;
    // padding: 1.0416666666666667vw;
    justify-content: space-between;
    }

    .no-data-message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20vh;
        flex: 1 1;
        padding: 1em;
        color: #c3cfd6;
        flex-wrap: nowrap;
        span {
            text-transform: none;
            font-size: 1vw;
        }
    }
}


.doc-card-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.0416666666666667vw;
    box-shadow: 0px 0.5px 1px 0px rgba($color: $pageHeaderBoderColor, $alpha: 1.0);
    border-radius: 5px;
    margin-bottom: 1.0416666666666667vw;
    background-color: #F7FBFD;
    border: 1px solid #C1CDD466;
    min-width: 16.135416666666668vw;
    transition: 0.3s all ease-in-out;

    &:hover {
        background-color: #dff1fa;
    }

    .stat-icon {
        width: 1.6276041666666667vw;
        height: 1.6276041666666667vw;
        margin-right: 1.0416666666666667vw;
    }

    .doc-name {
        span {
            color: $defaultHighLightColor;
            font-size: 0.8463541666666666vw;
            font-weight: bold;

        }
    }

    .stat-banner {
        position: absolute;
        top: 0.2vw;
        right: 0.441667vw;
        padding: 0.1vw 0.441667vw ;
        border-radius: 20px;

        span {
            color: #fff;
            font-size: 0.5208333333333334vw;
        }
    }
    .wotc-banner{
        padding: 0.2vw 0.641667vw;
        border-radius: 5px;
        span{
            font-size: 0.8208333333333334vw;
        }
    }
}