@import '../../../../../variables.scss';

.deduct-dsh-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    box-sizing: border-box;

    .deduct-dsh-left-panel {
        flex: 1;
        min-width: 240px;
        display: flex;
        padding: 1em;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        margin-right: 2em;
        position: relative;
        box-sizing: border-box;
        min-height: -webkit-fill-available;
    }

    .deduct-dsh-right-panel {
        flex: 4;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        min-height: -webkit-fill-available;
        
        // padding: 1em;
        // box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    }
}

.deduct-dsh-edit-icon {
    width: 0.989vw;
    height: 0.989vw;
    margin-bottom: 0.5em;
}

.deduct-ds-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: -webkit-fill-available;
}

.deduct-ds-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;

    .deduct-ds-avatar-holder {

        width: 110px;
        height: 110px;

        border-radius: 110px;
        padding: 4px;
        border: 1px solid $primaryColor;

        .deduct-ds-avatar {
            background-image: url('../../../../../../assets/icons/user.svg');
            border-radius: 100%;
        }

    }

    .deduct-ds-user-name {
        margin-top: 0.5em;

        span {
            font-size: 1.25vw;
            font-family: $defaultFontFamily;
            color: $userProfileNameColor;
            font-weight: 900;

        }
    }

    .deduct-ds-user-tag {
        span {
            font-size: 0.729vw;
            font-family: $defaultFontFamily;
            color: $tableRowTextColor;
            font-weight: 900;
        }
    }
}

.deduct-ds-action-container {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    // justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;

    .deduct-ds-action {
        width: -webkit-fill-available;
        margin-bottom: 1em;

        .deduct-ds-action-icon {
            width: 1.25vw;
            height: 1.25vw;
        }
        .cls-briefcase{
            fill: $white !important;
        }
    }
}
.deduct-ds-status {
    margin-top: 1em;
    // margin-bottom: 1em;
    span {
        font-weight: 900;
        text-transform: capitalize;
        color: $defaultHighLightColor;
        .status-txt {
            color: $secondaryColor;
        }

    }
}
.deduct-ds-branch-selector {
    display: flex;
    flex-direction: column;
    margin-bottom:1rem;

    
    .refresh_contact{
        padding:3px !important;
        
        span{
            font-size:0.7vw !important;
        }
        .err-h-add-icon{
            width:1vw !important;
            height:1vw !important;
        }

    }
    .no-creds-div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .no-creds{
        color:$statusWarningColor;
        margin:0.2rem 0rem 0.4rem 0;
        font-size:0.7vw;
    }
    .add-creds-div{
        color: $defaultHighLightColor;
        font-weight: 800;
        text-decoration: underline;
        cursor:pointer;
    }
    
}

.deduct-ds-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em;

    .deduct-ds-info-icon {
        width: 0.833vw;
        height: 0.885vw;
        margin-right: 1em;
        color: $companiesOptionTextColor;
        flex-shrink: 0;
        svg path{ 
            fill:  $companiesOptionTextColor;
        }

    }

    .deduct-ds-info-content {
        display: flex;
        flex-direction: column;

        .deduct-ds-info-holder {
            display: flex;
            flex-direction: column;

            .deduct-ds-into-title {
               
                span {
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    font-size: 0.833vw;
                }
            }

            .deduct-ds-info-value {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                //align-items: center;
                color: $userProfileNameColor;
                font-weight: 800;
                //text-transform: capitalize;
                margin-bottom: 0.5em;
                line-break: anywhere;
                a{
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    font-weight: 800;
                }

                span {
                    font-weight: 800;
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    text-transform: none;
                }

                .deduct-ds-info-icon {
                    width: 1.666vw;
                    height: 1.666vw;
                    cursor: pointer;
                    padding: 0.5em;
                    transition: 0.3s all ease-in-out;

                    &:hover {
                        background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }

            }

        }
    }
}

.gnrl-info-container{
    padding:1rem;
    .gnrl-info-header{
        span{
            text-transform:uppercase;
            font-size:0.9vw;
            color:$primaryColor;
        }
    }
    .gnrl-info-content{
        padding:1rem 0;
        span{
            font-size:0.8vw;
            color:$primaryColor;

        }
    }
}