@import '../../../../../variables.scss';

.ac-sc-rm-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;

    .ac-sc-rm-header {
        margin: 1em 1.5em 0 1em;
    }

    .ac-sc-rm-dropdown {
        margin: 0em 2em 2em 2em;
    }

    .ac-sc-rm-dropdown-button {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: -webkit-fill-available;
    }

    .ac-sc-rm-table {
        margin: 0em 2em 2em 2em;

        .sortable-table thead {
            z-index: 100;
        }
    
        table {
          border: 1px solid $tableBorderColor;
          color: $tableRowTextColor;
        }
        img {
            cursor: pointer;
            height: 0.781vw;
            width: 0.781vw;
          }
    }
}