@import '../../variables.scss';

.ph-info-container {
    display: flex;
    flex-direction: column;
    width: fit-content;

    .ph-info-label {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;

        .label-txt {
            font-size: 0.729vw; //Helvatica
            margin-bottom: 5px;
            margin-top: 10px;
            font-weight: 900;
            color: $defaultHighLightColor;
        }
    }

    .ph-info-input-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 89px;
        height: 89px;
        border-radius: 100%;
        border: 1px solid $defaultHighLightColor;
        padding: 0.3em;
        box-sizing: border-box;
        position: relative;

        .camera-icon-holder {
            background-color: #eff2f7;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: -webkit-fill-available;
            height: -webkit-fill-available;
            border-radius: 100%;

            .img-preview {
                display: flex;
                flex-direction: column;
                position: relative;
                width: -webkit-fill-available;
                height: -webkit-fill-available;

                .close-action {
                    width: 1.25vw;
                    height: 1.25vw;
                    cursor: pointer;
                    position: absolute;
                    top: -18%;
                    right: -18%;
                }
            }

            .close-action {
                width: 1.25vw;
                height: 1.25vw;
                cursor: pointer;
                position: absolute;
                top: -18%;
                right: -18%;
            }

            .camera-icon {
                width: 26px;
                height: 22px;
            }
        }

    }
}