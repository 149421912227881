@import "../../../../../variables.scss";
.assignment-dashboard {
  .ad-actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.ads-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: -webkit-fill-available;
}

.ads-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;

  .ads-avatar-holder {
    width: 110px;
    height: 110px;

    border-radius: 110px;
    padding: 4px;
    border: 1px solid $primaryColor;

    .ads-avatar {
      background-image: url("../../../../../../assets/icons/user.svg");
      border-radius: 100%;
    }
  }

  .ads-user-name {
    margin-top: 0.5em;

    span {
      font-size: 1.25vw;
      font-family: $defaultFontFamily;
      color: $userProfileNameColor;
      font-weight: 900;
    }
  }

  .ads-user-tag {
    span {
      font-size: 0.729vw;
      font-family: $defaultFontFamily;
      color: $tableRowTextColor;
      font-weight: 900;
    }
  }
}

.ads-action-container {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // justify-content: flex-start;
  border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
  margin-bottom: 1em;
  padding-top: 1em;

  .ads-action {
    width: -webkit-fill-available;
    margin-bottom: 1em;

    .ads-action-icon {
      width: 1.25vw;
      height: 1.25vw;
    }
    .cls-briefcase {
      fill: $white !important;
    }
  }
}

.ads-branch-selector {
  display: flex;
  margin-bottom: 1em;
  padding-left: 3em;

  .ads-branch-left,
  .ads-branch-right {

      .ads-status {
          margin-top: 1em;
          display: flex;
          flex-direction: column;

          span {
              color: $userProfileNameColor;
              font-family: $defaultFontFamily;
              font-size: 0.833vw;
              text-transform: uppercase;
          }
          
          .status-txt,
          .ads-id-txt {
              font-weight: 800;
              font-family: $defaultFontFamily;
              font-size: 0.729vw;
          }
          .status-txt {
              color: $openOrdersTextColor;
              &:hover {
                  text-decoration: underline;
                  cursor: pointer;
              }
          }
          .job-id-txt {
              color: $userProfileNameColor;
          }
      }
  }
  .ads-branch-left{
    width:45%;
  }
  .ads-branch-right{
    width:55%;
  }
}
.ads-info-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
  margin-bottom: 1em;
  padding-top: 1em;
  padding-left: 1em;

  .ads-info-icon {
    width: 0.833vw;
    height: 0.885vw;
    margin-right: 1em;
    flex: none;
    color: $companiesOptionTextColor;
    .cls-1 {
      fill: $companiesOptionTextColor;
    }
  }

  .ads-info-content {
    display: flex;
    flex-direction: column;

    .ads-info-holder {
      display: flex;
      flex-direction: column;

      .ads-into-title {
        span {
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          font-size: 0.833vw;
        }
      }

      .ads-field-data {
        color: $userProfileNameColor;
        font-weight: 800;
        font-family: $defaultFontFamily;
      }

      .ads-info-value {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        color: $userProfileNameColor;
        font-weight: 800;
        line-break: anywhere;
        // margin-bottom: 0.5em;
        //text-transform: capitalize;
        a {
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          font-weight: 800;
          text-decoration: none;
          line-break: anywhere;
        }

        span {
          font-weight: 800;
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
        }

        .ads-info-icon {
          width: 1.666vw;
          height: 1.666vw;
          cursor: pointer;
          padding: 0.5em;
          transition: 0.3s all ease-in-out;

          &:hover {
            background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
          }
        }
      }
    }
  }
}
.send_message_to_talent {
  svg {
    g path:first-child {
      fill: white !important;
    }
    g path:nth-child(2) {
      fill: #ff4d00 !important;
    }
    g path:nth-child(3) {
      fill: #ff4d00 !important;
    }
  }
}
