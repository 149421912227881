@import "../../../../../variables.scss";

.reports-directory-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .rd-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: center;
    }

    .rd-content {
        .col-row{
            display: flex;
            flex-direction: column;
        }
        .um-btn{
            padding: 3px !important;
            margin-top:0.5rem;
        }
    }
}