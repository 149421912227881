@import '../../../../variables.scss';

/// [sales calls]

.sales-calls-list-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    overflow: auto;
    // height:80vh;
    // min-height:80vh;

    .holder {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        width: -webkit-fill-available;

        .info {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;

            .date-info{
                display: flex;
                flex-direction: column;
                padding-right: 1em;
                // min-width: 100px;
                min-width: fit-content;
            }

            .user-info{
                display: flex;
                flex-direction: column;
                padding-left: 1em;

                .talent-info{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: nowrap;

                    .talent-icon{
                        width: 0.625vw;
                        height: 1.093vw;
                        margin-right: 0.5em;
                    }

                    .talent-name{
                        span {
                            font-size: 0.572vw;
                        }
                    }
                }
            }
        }

        .actions {

            .action-contact-icon{
                width: 0.937vw;
                height: 0.937vw;
            }
            .gmeet-icon{
                width: 1.5vw !important;
                height: 1.5vw !important;
            }
            .cls-1{
                fill: #1196A9 !important
            }
            .gmeet{
        
                path{
                    fill: #1196A9 !important;

                }
            }
        }
    }
}