@import '../../variables.scss';

.resume-holder-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    width: -webkit-fill-available;

    .icon-holder {
        width: 20px;
        height: 22px;
        margin-right: 0.5em;
        flex-shrink: 0;
    }

    .file-name-txt {
        width: calc(100% - 30px);
        line-break: anywhere;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
            color: $defaultHighLightColor;
            font-weight: 800;
            overflow: hidden;
            white-space: nowrap;
            width: -webkit-fill-available;
        }
    }
}