@import "../../../../variables.scss";

.jp-container {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 0em 1.5em 2em;

  .jp-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-right: 1em;

    .btn-cancel {
      margin-right: 1em;
    }

    .save-now-post-later-btn {
      border: 1px solid rgba($color: $defaultHighLightColor, $alpha: 1);
      background: transparent;
      box-shadow: none;

      span {
        color: $defaultHighLightColor !important;
      }
    }
  }
}

.jp-step-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  box-sizing: border-box;

  .jp-step-left-panel {
    flex: 1.3;
    min-width: 280px;
    display: flex;
    padding: 1em;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
    margin-right: 2em;
    position: relative;
    box-sizing: border-box;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.937vw;
      font-weight: 950;
      color: #0b3e79;
      text-transform: none;
    }

    .left-row-panel {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem;
      // align-items: center;
    }

    .fill-ans {
      color: red;
      font-size: 0.68vw;
      font-weight: 500;
      word-break: break-all;
      word-wrap: break-word;
      float: right;
    }
  }

  .jp-step-right-panel {
    flex: 4;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    min-height: -webkit-fill-available;

    // padding: 1em;
    // box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
  }

  .jp-step-right-panel-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .left-row-panel {
      display: flex;
      justify-content: space-between;
      width: 100%;

      // margin-bottom: 1rem;
      span {
        font-size: 0.937vw;
        font-weight: 950;
        color: #0b3e79;
        text-transform: none;
      }

      
    }

    .jp-step-header {
      display: flex;
      flex-direction: row;
      width: -webkit-fill-available;
      position: relative;
      margin: 1em 0em;
      justify-content: space-between;

      // align-items: center;
      .jp-step-header-title {
        font-size: 0.841vw;
        font-weight: 900;
        color: #0b3e79;
        text-transform: none;
      }

      .jp-step-header-subtitle {
        span {
          color: #0b3e79;
          text-transform: initial;
          margin-top: 10px;
        }
      }
      .btn-err {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        // justify-content: flex-end;
        align-items: flex-end;
      }

      .fill-ans {
        color: red;
        font-size: 0.68vw;
        font-weight: 500;
        word-break: break-all;
        word-wrap: break-word;
        float: right;
        margin-top: 2px
      }

      .chatgpt-icon {
        width: 2.545vw;
        height: 2.545vw;
        margin-right: 1em;
        cursor:pointer;
        div {
          // margin-top: 2px;
          color: $defaultHighLightColor;
        }
      }
    }

    .textarea-div {
      textarea {
        width: 100%;
        height: 100%;
        min-height: 300px !important;
        max-height: 150px !important;
        border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.6);
      }
    }
  }
}

.jp-step2-container {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  
  .jp-step2-header {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
    justify-content: space-between;

    // align-items: center;
    .jp-step2-header-title {
      font-size: 0.841vw;
      font-weight: 900;
      color: #0b3e79;
      text-transform: none;
    }

    .jp-step2-header-subtitle {
      span {
        color: #0b3e79;
        text-transform: initial;
        margin-top: 10px;
      }
    }
  }

  .jp-step2-content {
    border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.6);
    display: flex;
    flex-direction: column;
    
    .desc-content {
      width: 100%;
      height: 100%;
      min-height: 350px !important;
      max-height: 180px !important;
      border: none;
      padding: 0em 2em 2em 2em;
      color: $defaultHighLightColor;
      overflow: auto;
      .title-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .label {
        font-size: 0.8vw;
        font-weight: 700;
        margin: 1.2rem 0 0.2rem 0;
        text-transform: uppercase;
      }
      .job-title{
        font-size:1.4vw;
        font-weight: 900;

      }
      .label-title{
        font-size:0.8vw;
        font-weight: 700;
        margin-right:1vw;
        display: flex;
        flex-direction: row;
        
        flex-wrap: wrap;
      
        align-items: flex-end;
        svg{
          width: 1vw;
          height: 1vw;
          margin-right: 0.3em;
        }
      }
      
      .comp-loc-div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-top:0.4rem;
        // justify-content: ;
      }

      .label-btn {
        // margin-top: 0.9rem;

        .custom-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }


    }

    .edit-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      padding: 1em;

      svg {
        width: 0.989vw;
        height: 0.989vw;
        margin-bottom: 0.5em;
        cursor: pointer;
        margin-left: 1em;
        path{
          fill: #bdc2cc;
        }

      }

      // .edit-icon {
      //   margin-right: 1em;
      // }
    }
  }
}

.jb-pg-dialog-container {
  display: flex;
  flex-direction: column;

  .jb-pg-dialog-header {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
    justify-content: space-between;
  }

  .jb-pg-dialog-content {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    padding: 3em 2em;

  }

  .jb-pg-dialog-footer {
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.6);

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 1em;
  }
}