@import "../../../../variables.scss";

.import-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .import-f-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .import-f-content {
        padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .doc-saved-holder .doc-saved-content .resp-msg {
            color: $statusWarningColor !important;
            font-weight: 700 !important;
            font-size: 0.9vw !important;
            text-transform: capitalize;
            margin-top: 0.5rem;
        }
        .doc-saved-holder .doc-saved-content .gotoName{
            color: $defaultHighLightColor !important;
            font-weight: 600 !important;
            font-size: 0.83vw !important;
            text-transform:none;
            margin-top: 0.3rem;
        }
        .doc-saved-holder .doc-saved-content .gotoName:hover{
            box-shadow: none;
            text-decoration: underline;
        }
        .saved-doc-holder .confirm-msg .li-name{
            color: $defaultHighLightColor !important;
            font-weight: 600 !important;
            font-size: 0.83vw !important;
            text-transform:none;
            margin-top: 0.3rem;
        }
        .saved-doc-holder .confirm-msg .li-name:hover{
            box-shadow: none;
            // text-decoration: underline;
        }
        .saved-doc-holder {
            margin: 2em 2em;
            .error-section {
                margin-bottom: 0;
            }
        }
        .saved-doc-holder .deducted-count{
            margin-bottom:0.5rem;
            span{
                font-size:0.85vw;
                color:$primaryColor;

            }
        }
        .imp-radio-label{
            font-size:0.9vw;
            font-weight:500;
            color:$secondaryColor;
        }
        .import-f-section-divider {
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

            .import-f-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-bottom: 0.5em;
            }

            .flx-start {
                margin-bottom: 0;
            }
        }
        .radio-group-div{
            label span{
                text-transform: none;
                font-size:0.8vw;
            }
        }

        .import-f-section-divider:last-child {
            border-bottom: none;
            padding-bottom: 1em;
            margin-bottom: 1em;
        }

        .error-section {
            margin-bottom: 2em;
        }
        .confirm-msg{
            span{
                color:$defaultHighLightColor;
                font-size:1vw;
                text-transform: none;
            }
        }
    }

    .import-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;

        .btn-cancel {
            margin-right: 1em;
        }
    }




}