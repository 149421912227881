@import '../../variables.scss';

.nvb-top-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $navbarHeight;
    box-shadow: 0 0 2.8px 0 rgba(0,0,0,0.2);
    padding: 4px 2em 4px 2em;

    .agency-logo{
        margin-left: 1em;
    }

    .nvb-nav-actions{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .nvb-search-bar{
        margin-right: 2em;
    }
}