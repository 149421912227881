@import '../../../../variables.scss';

.interview-dialog-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;


    .interview-dialog-header {
        display: flex;
        justify-content: space-between;

        .left-fields {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #0B3E78;

            h4 {
                margin-left: 20px;
                margin-top: 10px;
                font-weight: 900;
            }
        }

        img {
            color: black;
            cursor: pointer;
        }
    }

    .interview-dialog-content {
        padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 1em;
        margin-top: 10px;
        padding-bottom: 2em;
        border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

        .cmpny-op-f-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .cmpny-op-f-input-holder {
                width: 9.895833333333334vw;
                margin-right: 1em;
            }
        }

        .error-section {
            margin-top: 1em;
            margin-bottom: 2em;
        }
    }


}

.cmpny-op-f-link-icon {
    width: 16px;
    height: 16px;
}

.job-label {
    font-weight: 600;
}

.ac-eng-field-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.si-ci-field-row {
    margin-top: 0 !important;
}

.ac-eng-fields {
    flex-wrap: nowrap;
    margin-right: 1em;

    .time-picker-container {
        margin-top: 1.7em !important;

        .MuiInputBase-root input {
            min-width: 11vw;
        }
    }
}

.ac-eng-fields-sci-time {
    flex-wrap: nowrap;
    width: 30%;
    margin-right: 1em;

    .time-picker-container {
        margin-top: 1.7em !important;

        .MuiInputBase-root input {
            min-width: 11vw;
            margin-left: 0 !important;
        }
    }
}

.ac-sc-desc-fields {
    flex-wrap: nowrap;
    width: 100%;
    margin-right: 1em;
}

.ac-Field-text {
    font-size: 0.7291666666666666vw;
    margin-bottom: 5;
    margin-top: 10;
    font-weight: 900;
    color: #0b3e79;
}

.ac-header-text {
    font-size: 16px;
    font-weight: 900;
    color: #0b3e79;
}



.interview-dialog-footer {
    float: right;

    .cmpny-op-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .btn-cancel {
            margin-right: 1em;
        }
    }

}

@media only screen and (max-width: 1440px) {
    .ac-eng-fields-sci-time {
        .time-picker-container {
            margin-top: 1.45em !important;
        }
    }
}