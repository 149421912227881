@import '../../../../variables.scss';

.terminate-talent-container {
    padding: 0em 1em 0em 1em;

    .saved-doc-holder{
        padding: 2em;
        .doc-saved-icon {
            width: 6.007813vw;
            height: 6.3203125vw;
        }
    }

    .terminate-talent-header {
        padding: 0.5em 0 0;

        span {
            font-size: 1.041vw;
            font-weight: 900;
        }
    }

    .terminate-talent-content {
        padding: 1em 1em 2em 1em;
    }

    .terminate-talent-footer {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-top: 1em;
        border-top: 1px solid rgba(3, 80, 144, 0.1);

        .btn-cancel {
            margin-right: 1em;
        }
    }
}