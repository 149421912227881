@import '../../../../variables.scss';

.ad-dw-content {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #99979740;
}

.actions-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}