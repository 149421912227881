@import '../../../../../variables.scss';

.payroll-dashboard-container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .payroll-dashboard-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left-col{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .more-options {
                margin-left: 1em;
            }
        }
        .right-col{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .ml{
                margin-left: 1rem;
            }

        }
    }
    .payroll-dashboard-content {
        margin-top:1rem;
        height: 59vh;
        position:  relative;
        .payroll-dashboard-table{
            max-height: 36vh;
            padding-bottom: 2rem;
            overflow: scroll;

            .menu-icon {
                padding: 0.2em 0.2em 0.2em 0.5m;
                border-radius: 2px;
                cursor: pointer;
                margin-left: 1rem;

                svg {
                    width: 0.9vw;
                    height: 0.9vw;
                    fill: $primaryColor;
                }

                .btn-text {
                    display: none;
                }

                .parent-position {
                    position: relative;
                    width: 0;
                    height: 0;
                    z-index: 1;

                    .child-position {
                        position: absolute;
                        top: 0em;
                        right: 0em !important;
                        background-color: white;
                        box-shadow: 0px 0px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 1);
                        min-width: 8vw;

                        .item-menu-title {
                            text-transform: capitalize;
                            font-size: 0.700vw;
                            color: $defaultHighLightColor;
                            padding: 1em;
                            width: 100%;
                            font-weight: 800;
                            cursor: pointer;

                            .rm-f-batch {
                                margin-bottom: 1em;
                            }
                        }

                        .item-menu-title:hover {
                            background-color: $backgroundHoverColor;
                        }
                    }
                }
            }
        }
        .pay-card-note1 {
            margin-top: 3em;

            .card {
                .icon {
                    margin-top: 0.5em;

                    svg {
                        width: 0.9em;
                        height: 2em;
                        color: $backOfficeCardTextColor;
                    }
                }
            }
        }

        .pay-card-note2 {
            position: absolute;
            bottom: 0;
            right:0;
            display: flex;
            justify-content: flex-end;

            .card {
                .text-btn {

                    .text-line-one,
                    .text-line-two,
                    .text-line-three {
                        margin-top: 0.3em;
                    }
                }
            }
        }
    }
}