@import '../../../../variables.scss';

.report-center-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    padding:1.5rem;
    .title-flex-card {
        flex: 1;
        border-radius: 0.3em;
        background-color: white;
        position: relative;
        margin: 0 1.5rem 1.5rem 0;
        max-width: calc(25% - 1.5rem);
        min-width: calc(25% - 1.5rem);
        width: 10vw;
        padding:1rem;
        box-shadow: 0 0 3px 0 rgba(165, 165, 165, 0.3);
        border: solid 0.3px #bdc9d0;
        min-height: 20.408vh;
    }
}