@import '../../../../variables.scss';

.oc-we-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .oc-we-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .oc-we-content {
        padding: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        span {
            color: $primaryColor;
            font-size: 1vw;
            font-weight: 600;
            text-transform: none;
        }
        .open-msg-div{
            display: flex;
            flex-direction: column;
            div{
                margin-bottom:1rem;
                color: $primaryColor;
                font-size: 1vw;
                font-weight: 600;
                text-transform: none;
                display: inline-block;
            }
        }

        .oc-error-holder {
            margin-top: 2rem;

            span {
                color: red;
                font-weight: 500 !important;
                font-size: 0.927vw;
            }
        }

    }



    .oc-we-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;
    }
}