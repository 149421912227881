@import "./../../../../../../../app/variables.scss";

.assignment-summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 15px;

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .shift-details {
    // flex: 1;
    width: 32.932564%;

    // margin-right: 1em;
    .shift-form {
      // width: fit-content;
    }
  }

  .section {

    .section-header {
      font-size: 0.833vw;
      // margin-bottom: 10px;
      color: #0b3e79;
    }

    .sc-section-header {
      font-size: 0.833vw;
      margin-bottom: 1em;
      color: #0b3e79;
    }
  }

  .schdule-sections-holder {
    display: flex;
    flex-direction: row;
    // flex: 1;
    justify-content: flex-end;

    .work-schedule {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;

      .work-schedule-table {

        // width: 30vw;
        td {
          border: 1px solid $tableBorderColor;
          padding: 0.2rem;
          vertical-align: middle;

          .label {
            // margin-right: 1em;
            margin-top: 0.5em;
            color: $primaryColor;
          }

          svg {
            margin-right: 1em;
            margin-top: 1em;
          }

          .MuiFormControl-root {
            max-width: 5vw;
          }
        }

        .section-header {
          font-weight: normal;
          color: rgb(11, 62, 121);
          font-family: Lato;
          margin-bottom: 1em;
        }

        .switch-holder {
          padding: 0 0 0 1em;
        }

        .MuiInputBase-input {
          padding: 0 0 0 1em !important;
          border: none !important;
          margin-left: 0 !important;
          cursor: pointer;
        }
      }
    }

    .service-calls {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
    }

    .work-schedule-input,
    .service-calls-input {

      margin-bottom: 1em;
    }
  }

  .MuiInputBase-root {
    min-width: 7.8125vw;
  }

  .label-header {
    font-size: 0.729vw;
    font-family: Lato;
    margin-top: 10px;
    font-weight: 900;
    color: $defaultHighLightColor;
    margin-bottom: 0.5em;
  }

  .cmfs-container {
    margin-top: 10px;
  }
}

.datePickerStyle {
  margin-top: 0px;
  width: 100%;
}

.assnt-form-input {
  width: -webkit-fill-available;

  .form-input {
    min-width: 7.895833vw;
  }

  .time-picker-container {
    .MuiInputBase-root {
      border: 0.3px solid $inputoutlineColor !important;
      border-radius: 0.3em !important;

      input {
        min-width: 5vw;
      }
    }

    .MuiInputBase-input {
      padding: 0 0 0 0.5em !important;
      border: none !important;
      margin-left: 0 !important;
      cursor: pointer;
    }

    .MuiSvgIcon-root {
      margin-right: 0.3em;
      right: 0;
    }
  }
}

.as-db-dh-row {
  padding: 1.6em;
  box-shadow: 0px 0px 2px 0px #c3cfd6;

  .as-db-dh {
    color: #0b3e79;
    font-size: 0.833vw;
  }

  .as-db-f-dh-holder {
    margin-bottom: 0.5em;

    fieldset {
      width: 75%;
    }

    table {
      width: 100%;

      td {
        width: 10% !important;

        .label-span {
          font-size: 0.729vw;
          color: #0b3e79;
          font-weight: 900;
          text-transform: none;
        }

        .radio-i {
          display: flex;
          align-items: center;

          .form-input,
          .form-input-holder .form-input-tag {
            min-width: 5vw !important;
            width: 5vw !important;
          }
        }

        .MuiSvgIcon-root {
          height: 0.8vw;
          width: 0.8vw;
        }

        .MuiFormControlLabel-label {
          font-size: 0.629vw;
          color: #0b3e79;
          font-weight: 600;
          text-transform: uppercase;
        }

        .MuiRadio-root.Mui-disabled {
          color: $statusDisabledColor;
          /* Change this to the color you want for disabled state */
          opacity: 0.3;
          /* Adjust the opacity for a faded look */
        }

        .MuiFormControlLabel-root.Mui-disabled {
          color: $statusDisabledColor;

          /* Change label color when disabled */
          .MuiFormControlLabel-label {
            color: $statusDisabledColor;
          }
        }
      }
    }
  }

  .as-db-f-dh-holder:nth-child(2),
  .as-db-f-dh-holder:nth-child(3) {
    table tr {

        td:nth-child(2) {
          width: 8% !important;
        }
        td:nth-child(4) {
            width: 9% !important;
        }
    }
  }


  .as-db-f-dh-holder:nth-child(4) {
    width: 36%;

    td {

      .form-input,
      .form-input-holder .form-input-tag {
        min-width: 5vw !important;
        width: 5vw !important;
      }
    }
  }
}