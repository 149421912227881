@import "../../../../../variables.scss";

.cs-popup-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em 2em;
    overflow: hidden;

    .cs-popup-header {
        padding-bottom: 0.8rem;
        display: flex;
        width: -webkit-fill-available;
        position: relative;
    }

    .cs-poup-content {
        padding-bottom:1rem;
        .cs-input-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 0.8rem;

            .cs-input-holder {
                width: 31%;
                .date-input-holder-div {
                    width: 100%;
                }
            }

            .ct-l-h {
                width: 30%;
            }

            .sd {
                width: 42%;
            }
        }
    }

    .cs-p-actions-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid $tableBorderColor;
        padding-top: 1rem;
        margin-top: 0.5rem;

        .cs-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .btn-cancel {
                margin-right: 1rem;
            }
        }
    }
}