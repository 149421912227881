@import '../../../../variables.scss';

.create-ta-popup-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .create-ta-popup-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;

    }

    .create-ta-popup-content {
        padding: 0em 0em 2em 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        .create-ta-table{
            position: relative;
            overflow: auto;
            max-height:20vw;
        }

        .create-ta-search-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 1rem;
            align-items: flex-end;

            .search-header {
                color: $primaryColor;
                font-size: 0.789vw;
                font-weight: 750;
                margin-bottom: 0.3rem;
            }

            .srch-tlnt-ud-table-search-bar {
                padding-top: 0.5rem;
                margin-right: 2rem;
            }

            .total-count {
                margin-left: 1rem;
            }
        }

        .error-holder {
            margin-top: 2rem;

            span {
                color: red;
                font-weight: 500 !important;
                font-size: 0.927vw;
            }
        }

        .aknw-name {
            text-transform: none;
        }

        span {
            color: $primaryColor;
            text-transform: none;
        }

        .sub-span {
            margin-top: 1rem;
            font-weight: 400;
            font-size: 1vw;
        }

        .open-msg-div {
            display: flex;
            flex-direction: column;

            div {
                margin-bottom: 1rem;
                color: $primaryColor;
                font-size: 1vw;
                font-weight: 600;
                text-transform: none;
                display: inline-block;
            }
        }

        .oc-error-holder {
            margin-top: 2rem;

            span {
                color: red;
                font-weight: 500 !important;
                font-size: 0.927vw;
            }
        }

        .saved-doc-holder {
            padding: 2rem 0rem;
        }
        .exist-tab{
            padding:1rem;
            .et-title{
                color: rgb(255, 140, 0);
                font-size: 0.9vw;
                font-weight: 600;
                text-transform: none;
                margin-bottom: 1rem;
            }
            .table-content {
                width: 100%;
                overflow: auto;
                max-height: 20vw;

                table {
                    width: 100%;
                    position: relative;

                    thead {
                        background-color: $tableHeaderBackgroundColor;
                        position: sticky;
                        top: 0;
                    }

                    th,
                    td {
                        color: $primaryColor;
                        font-size: 0.729vw;
                        padding: 0.5em;
                        border-bottom: 1px solid rgba($color: $tableBorderColor, $alpha: 0.4);
                    }
                }
            }
        }

    }



    .create-ta-popup-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        margin-left: 3em;
    }
}