@import '../../../../../variables.scss';


.jb-f-stp-seven{
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    // border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 2em;
    padding-bottom: 2em;
    margin-bottom: 2em;
    // align-items:flex-end;

    .jb-f-row{
        display: flex;
        flex-direction: row;
    }

    .jb-f-input-holder{
        margin-bottom: 1em;
    }
}