@import "../../../variables.scss";

.doc-holder {
    margin-bottom: 1.5em;
}

.doc-holder-title {
    margin-bottom: 0.5em;
    color: $primaryColor;
    font-size: 0.729vw;
    font-weight: 900;
    ;
}

.review_btn {
    border: 1px solid $tableBorderColor;
    background-color: #F7FBFD !important;
    box-shadow: none;
    width: -webkit-fill-available;
    justify-content: flex-start;
    padding: 0.7em 1.5em;

    span {
        color: $primaryColor !important;
        font-weight: 500 !important;
    }

    .review_searchicon {
        width: 0.943vw;
        height: 0.943vw;
    }
}

.btn-name-container{
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-holder {
        margin-right: 1em;
    }

    .label-name{
        span{
            text-transform: initial !important;
        }
    }
}