.talent-drd-content {
    .drd-title-txt {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:center;
        // span {
        //     color: #0b3e79;
        //     margin-bottom: 1rem;
        //     font-size: 1.6rem;
        // }
    }
    .doc-saved-holder{
        justify-content: center;
    }
}