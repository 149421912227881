@import "../../../../../variables.scss";

.import-page-container {
    padding: 1rem;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    display: flex;
    flex-direction: column;

    .import-page-header {
        display: flex;
        justify-content: flex-start;

        span {
            font-size: 1.2vw;
            color: $primaryColor;
            font-weight: 800;
            text-transform: none;
        }
    }

    .import-page-content {
        display: flex;
        flex-direction: row;
        padding-top: 1.8rem;
        width: 100%;

        .input-div {
            display: flex;
            // justify-content: space-between;
            width: 100%;

            .ip-input-row {
                width: 30%;
                margin-right: 3rem;

                .ip-input {
                    margin-bottom: 2rem;

                    .drag-info-txt {
                        width: 100%;
                    }

                    .drad-input-holder {
                        border: 2px dashed $tableBorderColor;
                        border-radius: 5px;
                        background: $tableHeaderBackgroundColor;
                    }

                    textarea {
                        min-height: 120px;
                    }
                }

                .desc {
                    width: 100%;
                }

                .status {
                    width: fit-content;

                }

            }

            .ip-input-row:first-child {
                width: 25%;
            }

        }
    }

    .import-page-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top:2rem;
        padding-bottom:1rem;
        padding-right:3rem;
        .btn-cancel{
            margin-right:1rem;
        }
    }
}