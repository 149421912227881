@import '../../../../../variables.scss';

.db-inbound-leads {
    .inbound-leads-header{
        display: flex;
        justify-content: center;
    }
    .cps-ud-table {
        .business-ul{
            padding:0;
            li{
                padding:0
            }
        }
        .parent-position {
            position: relative;

            .child-position {
                position: absolute;
                left: 1em;
                bottom: -3rem;

                .action-list-title {
                    text-transform: capitalize;
                    font-size: 0.700vw;
                    color: $primaryColor;
                    padding: 0.5em;
                    border-radius: 0.5em;
                    border: 1px solid #1198AB;
                    max-width: 20vw;
                    width: max-content;
                    background-color: #ffffff;
                    font-weight: 600;
                }
            }
        }
    }
}

.import-leads {
    .import-leads-content{
        font-size: 1.041vw;
        color: #0B3E78;
        text-transform: none;
        margin-left: 1em;
        .import-leads-success-message{
            padding: 1rem;
            span:first-child{
                font-size: 1.2vw;
            }
            span:nth-child(2){
                font-size: 1vw;
                font-weight: normal;
            }
        }
        .radio-group-div {
            margin-left: 2rem;
            .MuiSvgIcon-root {
                height: 1vw;
                width: 1vw;
            }
            
            .MuiFormControlLabel-label{
                font-size: 0.9vw;
            }
            
        }
        .error-holder{
            margin-left: 1.7rem;
        }
    }    
}

.archive-leads {
    .archive-leads-content {
        margin-left: 3rem;
        margin-bottom: 1em;
        span{
            font-size: 1.041vw;
            color: #0B3E78;
            text-transform: none;
        }
        .doc-saved-holder .doc-saved-icon {
            width: 6.0078125vw;
            height: 6.3203125vw;
        }
    }
}