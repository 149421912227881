@import "../../../../variables.scss";

.marketing-activity {
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .youtube-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 1.2vw;
      width: 1.2vw;
      margin-top: 0em !important;
    }
  }
  .main-header {
    background: #f3f3f9;
    color: #0b3e79;
    height: 45px;
    font-size: 0.937vw;
    font-weight: 900;

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
    .main-header-item {
      padding: 10px;
      border-right: 1px solid #b8b8b8;
      border-bottom: 1px solid #b8b8b8;
      &.selected {
        svg path {
          fill: #ffffff;
        }
      }
    }
  }

  .tabs {
    color: #0b3e79;
    font-size: 0.833vw;
    margin: 15px 0px;

    .tab {
      margin-left: 20px;
      margin-right: 25px;
      cursor: pointer;

      &.selected {
        font-weight: 800;
        border-bottom: 2px solid #0b3e79;
      }
    }
  }
  .complete-call {
    background-color: $buttonColor17;
    color: $white;
    min-width: 110px;
    width: fit-content;
    height: 30px;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.1em 0.3em;
    text-align: center;
    text-overflow: ellipsis;

    span {
      font-weight: 800;
    }
  }

  .table-container {
    padding: 10px;
    border-radius: 2.8px;
    border: solid 0.25px #c0c2c4;
    .actions {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      color: "#0B3E78";
      .add-campaign {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .add-campaign-search {
          margin-top: 1.8rem;
          margin-right: 1rem;
        }
      }
      .table-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        .total-count-action {
          margin-left: 10px;
          span {
            width: max-content;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.2;
            letter-spacing: normal;
            color: $defaultHighLightColor;
            .total-count-number {
              font-weight: bold;
            }
          }
        }
      }
    }

    // .actions {
    //   margin-bottom: 10px;
    //   display: flex;
    //   color: "#0B3E78";
    //   justify-content: space-between;
    //   .table-header{
    //     display:flex;
    //     justify-content: space-between;
    //     flex-direction: row;
    //     width:auto
    //   }
    //   // .table-header {
    //   //   display: flex;
    //   //   flex-direction: row;
    //   //   flex-wrap: nowrap;
    //   //   align-items: flex-end;
    //   //   .total-count {
    //   //     color: $defaultHighLightColor;
    //   //     padding-left: 1em;
    //   //     span {
    //   //       font-weight: 300;
    //   //       font-stretch: normal;
    //   //       font-style: normal;
    //   //       line-height: 2.86;
    //   //       letter-spacing: normal;
    //   //       color: $defaultHighLightColor;
      
    //   //       .total-count-number {
    //   //           font-weight: bold;
    //   //       }
    //   //   }
    //   //   }
    //   // }
    //   // .add-campaign {
    //   //   display: flex;
    //   //   align-items: flex-end;
    //   //   margin-left: auto;
    //   //   .add-campaign-search{
    //   //     margin-right: 1rem;
    //   //   }
    //   //   .btn-text span{
    //   //     text-transform: none;
    //   //   }
    //   // }
    // }
  }
  .tb-header-cc span{
    text-transform: none;
  }
 


  .call-counts {
    background: #f7fbfd 0% 0% no-repeat padding-box;
    border: 0.25px solid #035090;
    border-left-width: 4px;
    opacity: 1;
    width: 580px;
    margin-top: 25px;
    margin-left: auto;
    padding: 15px;
  }

  .sales-count {
    font-size: 0.9vw;
    margin-top: 10px;
  }
  .dateRange {
    width: min-content;
    margin-top: 0.3em;
    margin-left: 1em;
    input {
        width: 10vw !important;
        min-width: 10.5vw !important;
        color: $defaultHighLightColor !important;
    }
}
}
