@import "../../../../../variables.scss";

.jdp-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  box-sizing: border-box;

  .jdp-left-panel {
    flex: 1;
    min-width: 240px;
    display: flex;
    padding: 1em;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
    margin-right: 2em;
    position: relative;
    box-sizing: border-box;
    min-height: -webkit-fill-available;
  }

  .jdp-right-panel {
    flex: 4;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    min-height: -webkit-fill-available;

    // padding: 1em;
    // box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
  }
}

.jdp-edit-icon {
  width: 0.989vw;
  height: 0.989vw;
  margin-bottom: 0.5em;
}
.jdp-job-match,
.jdp-comp-match {
  margin-top: 1em;
  box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
  height: 25vw;
  overflow: auto;
  z-index: 99;
}

.jdp-match-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  margin-top:1em;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em 1em 0em 1em;

    .header-title {
      span {
        font-size: 0.833vw;
        color: $defaultHighLightColor;
      }
    }
    .header-right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .broadcast_btn_div button {
        background-color: #ff4d00;
        box-shadow: none;
        margin-right: 1rem;
        width:max-content;
        svg{
          g path:first-child{
            fill:white !important;
          }
          g path:nth-child(2){
            fill:#ff4d00 !important;
          }
          g path:nth-child(3){
            fill:#ff4d00 !important;
          }
        }
        
      }
      .sb-container{
        width:15vw;
      }
      .select_talent_msg {
        text-transform: initial;
        color: #ff4d00;
        margin-top: 0.1em;
      }
    }
  }

  .content {
    display: flex;
  }
}

.img-icon {
  width: 1.302vw;
  height: 1.302vw;
}
