@import "../../../../variables.scss";

.drd-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;

  .drd-header {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
  }

  .drd-content {
    position: relative;
    overflow: auto;

    .saved-doc-holder {
      margin: 2em 2em;
    }

    .drd-section-divider {
      padding-bottom: 2em;
      margin-bottom: 2em;
      border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

      .drd-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0.5em;

        .section-title-txt {
          span {
            color: $defaultHighLightColor;
          }
        }

        .drd-input-holder {
          width: 9.895833333333334vw;
          margin-right: 2.5em;
          .d-inline-block {
            width: 45%;
          }
          .cmfs-margin-top {
            margin-top: 0.6em;
          }
        }
        .css-c0tn0x-control {
          border: 1px solid $inputoutlineColor !important;
          .css-tlfecz-indicatorContainer {
            color: $inputBorderColor;
          }
        }
      }
      .flx-start {
        margin-bottom: 0;
      }
    }
    .drd-section-divider:last-child {
      border-bottom: none;
      padding-bottom: 1em;
      margin-bottom: 1em;
    }
    .error-section {
      margin-top: 2em;
      margin-bottom: 1em;
      width: -webkit-fill-available;
    }
  }

  .drd-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 3em;
    .btn-cancel {
      margin-right: 1em;
    }
  }
  .switch {
    .slider:after {
      content: "NO" !important;
    }
    input:checked + .slider:after {
      content: "YES" !important;
    }
  }
}

.drd-link-icon {
  width: 0.833vw;
  height: 0.833vw;
}

.info-txt {
  font-size: 1.0416666666666667vw !important;
}

.name {
  font-weight: normal !important;
  font-size: 1.0416666666666667vw !important;
}

.loading-spinner {
  height: 150px;
}
.talent-direct-deposit-sortable-table {
  margin: 2rem 0rem;

  table {
    border: 1px solid #cfdbeb;
  }
  tbody {
    display: block;
    overflow-y: auto;
    max-height: 27vh;
    td {
      border-right: 1px solid rgba(195, 196, 198, 0.3);
      .switch {
        .slider:after {
          content: "OFF" !important;
        }
    
        input:checked + .slider:after {
          content: "ON" !important;
        }
        input[type=checkbox]{
          cursor:initial
        }
      }
    }
  }
  
}
