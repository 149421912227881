// @import '../../../../variables.scss';
@import '../../../variables.scss';



.edc-db-container .rec-card .rec-card-icon .infoIcon circle {
    fill: $employeePortalEDCBgColor !important;
}

.edc-db-container {
    margin: 1rem;

    .edc-db-tbc {
        padding: 1em;
        color: #0b3e79;

        .title {
            font-size: 0.833vw;
        }
    }

    .edc-db-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .edc-db-card {
        width: 45%;
        margin: 1em;
    }

    .ep-edac-dashboard {
        width: 1.45vw;
        height: 1.45vw;
        margin-right: 1.5rem;
    }

    .ep-ud-db-edc-section {
        border-top: 1px solid rgba(3, 80, 144, 0.1);
        padding: 1em;

        color: #0b3e79;

        .title {
            font-size: 0.833vw;
        }
    }

}