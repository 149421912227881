@import "../../../../variables.scss";

.i9-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;

  .i9-header {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
  }

  .talent-i9-content {
    // padding: 3em;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // justify-content: space-around;
    // position: relative;
    // overflow: auto;
    .ob-review-div {
      margin-bottom: 1rem;
    }

    .webviewer {
      height: 500px ;
      width: 100%;
    }
    @media screen and (max-width:1600px) { // for less than 1600px screen 
      .webviewer {
        height: 320px !important;
        width: 100%;
      }
    }
    @media screen and (max-width:1280px) {
    .webviewer {
        height: 280px !important;
        width: 100%;
      }
    }
   
   

    .saved-doc-holder {
      margin: 2em 2em;
    }

    .i9-section-divider {
      padding-bottom: 2em;
      margin-bottom: 2em;
      border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

      .i9-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0.5em;

        .section-title-txt {
          span {
            color: $defaultHighLightColor;
          }
        }

        .i9-input-holder {
          width: 9.895833333333334vw;
          margin-right: 2.5em;

          .d-inline-block {
            width: 45%;
          }

          .cmfs-margin-top {
            margin-top: 0.6em;
          }
        }

        .css-c0tn0x-control {
          border: 1px solid $inputoutlineColor !important;

          .css-tlfecz-indicatorContainer {
            color: $inputBorderColor;
          }
        }
      }

      .flx-start {
        margin-bottom: 0;
      }
    }

    .i9-section-divider:last-child {
      border-bottom: none;
      padding-bottom: 1em;
      margin-bottom: 1em;
    }

    .error-section {
      margin-top: 2em;
      margin-bottom: 1em;
      width: -webkit-fill-available;
    }
  }

  .i9-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between !important;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    .btn-cancel {
      margin-right: 1em;
    }
   
    .errorMsg {
      display: flex;
      align-items: center;
    }
    .i9-actions-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between !important;
      align-items: center;

    }
  }

  .switch {
    .slider:after {
      content: 'NO' !important;
    }

    input:checked+.slider:after {
      content: 'YES' !important;
    }
  }

}

.i9-link-icon {
  width: 0.833vw;
  height: 0.833vw;
}

.info-txt {
  font-size: 1.0416666666666667vw !important;
}

.name {
  font-weight: normal !important;
  font-size: 1.0416666666666667vw !important;
}


.loading-spinner {
  height: 150px;
}

#pdf-view {
  iframe {
    html body div .HeaderToolsContainer {
      display: none !important;
    }
  }
}

