.talent-availability-report-container {
    .rc-scsr-btn {
        svg {
            .cls-1 {
                fill: #fff !important;
            }
        }
    }

    .ar-table {
        max-height: 58vh;
        overflow: auto;
        position: relative;

        .sortable-table thead {
            z-index: 0;
            position: sticky;
        }
    }
}