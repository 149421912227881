$black: #000000;
$white: #ffffff;
$red: red;
$primaryColor: #074783; //twilight blue
$secondaryColor: hwb(207 0% 33%);
$secondaryColor1: #0079C1;
$secondaryColor2: #0992D7;
$secondaryColor3: #153888;
$secondaryColor4: #5A3F98;
$secondaryColor5: #6C207D;
$secondaryColor6: #9360A7;
$secondaryColor7: #BE81B7;
$secondaryColor8: #7D83B5;

$backgroundHoverColor: #eeeeee;
$backgroundColor: #ebf1f8;
$openOrdersTextColor: #00be4b;
$statusActiveColor: #00be4b;
$statusLightActiveColor: #aed6ad;
$statusInActiveColor: #ff0000;
$statusLightInActiveColor: #fca8a8;
// $statusWarningColor: #FF7600;
$statusWarningColor: #EEAC17;
$statusDisabledColor: #c0ccd3;
$backOfficeCardTextColor: #228B22;
$textWarningColor: #EEAC17;

/// Border
$pageBoderColor: #bbbbbb;
$tableBorderColor: #c3cfd6;
$tableHeaderBackgroundColor: #f7fbfd;
$tableRowBorderColor: #c3c4c6;
$tableRowTextColor: #00a4dc;
$pageHeaderBoderColor: #b5b5b5;
$inputBorderColor: #005A9A;
$defaultActionColor: #b5bac5;
$userProfileNameColor: #035090;
$passwordStrongColor: #39b14a;
$formDeviderColor: #a5a5a5;
$divDividerColor: #BFCCD4;
$inputoutlineColor: #9AB8D1;


/// Buttons
$buttonColor1: #074783;
$buttonColor2A: #9c90c1;
$buttonColor2B: #9282af;
$buttonColor3A: #6f9dd8;
$buttonColor3B: #648ac5;
$buttonColor4: #5dcc48;
$buttonColor5: #2eaee0;
$buttonColor6: #5aa6bf;
$buttonColor7: #5aa6bf;
$buttonColor8: #5aa6bf;
$buttonColor9: #5aa6bf;
$buttonColor10: #5aa6bf;
$buttonColor11: #8eacd7;
$buttonColor12: #009ad7;
$buttonColor13: #00c655;
$buttonColor14: #bdc2cc;
$buttonColor15: #1198ab;
$buttonColor16: #00a4dc;
$buttonColor17: #00be4b;

/// Cards
$cardColor: #f8fcfd;
$defaultHighLightColor: #0b3e79;
$transparent: transparent;

$bageColor1A: #2d66aa;
$bageColor1B: #003f8c;

/// Side nav option background color
$dasboardOptionBackgroundColor: #f2f6fa;
$stateMarketingOptionBackgroundColor: #F2F6FA;
$companiesOptionBackgroundColor: #F4F7FB;
$contactsOptionBackgroundColor: #F2F6FA;
$talentOptionBackgroundColor: #E6F1F9;
$jobsOptionBackgroundColor: #E6F1F9;
$assignmentsOptionBackgroundColor: #F3F4F8;
$reportCenterOptionBackgroundColor: #EEEBF4;
$backOfficeOptionBackgroundColor: #f4f4fa;
$riskManagementOptionBackgroundColor: #F9F6FA;
$employeePortalOptionBackgroundColor: #FBF8FB;
$clientPortalOptionBackgroundColor: #FBF8FB;
$adminCenterOptionBackgroundColor: #F3F3F9;

/// Side nav option active color
$dasboardOptionActiveColor: #f2f6fa;
$stateMarketingOptionActiveColor: #f2f6fa;
$companiesOptionActiveColor: #f4f7fb;
$contactsOptionActiveColor: #f2f6fa;
$talentOptionActiveColor: #e6f1f9;
$jobsOptionActiveColor: #e6f1f9;
$assignmentsOptionActiveColor: #f3f4f8;
$reportCenterOptionActiveColor: #f4f4fa;
$backOfficeOptionActiveColor: #f4f4fa;
$riskManagementOptionActiveColor: #f9f6fa;
$employeePortalOptionActiveColor: #fbf8fb;
$clientPortalOptionActiveColor: #b577ad;
$adminCenterOptionActiveColor: #7279ad;

/// Side nav option border-left color
$dasboardOptionTextColor: #074783;
$stateMarketingOptionTextColor: #074783;
$companiesOptionTextColor: #074783;
$contactsOptionTextColor: #074783;
$talentOptionTextColor: #006eb7;
$jobsOptionTextColor: #006eb7;
$assignmentsOptionTextColor: #15317e;
$reportCenterOptionTextColor: #50378b;
$backOfficeOptionTextColor: #611d72;
$riskManagementOptionTextColor: #86559b;
$employeePortalOptionTextColor: #b477ac;
$clientPortalOptionTextColor: #b577ad;
$adminCenterOptionTextColor: #7279ad;



$viewBgColor : #509db8;
$mailBgColor : #84a3d2;

//employee portal
$employeePortalEDCBgColor: #FF7600;
$employeePortalTitleColor: #035090;


/// Font
$defaultFontFamily: Lato;

///Navbar
$navbarHeight: 4.124450951683748vw;

$navbarUserIconSize: 2.5vw;

$minWindowWidth: 65.625vw;