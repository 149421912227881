@import "../../variables.scss";

.ct-stp-holder {
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
  .stp-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    &::after {
      position: absolute;
      content: "";
      border-bottom: 1px solid $defaultHighLightColor;
      width: 100%;
      top: 9px;
      left: 50%;
      z-index: 2;
      transition: width 1s;
     
    }
    .cricle-icon {
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      border: 1px solid $defaultHighLightColor;
      margin-bottom: 0.3em;
      background-color: #fff;
      position: relative;
    }
    .label-txt {
      span {
        font-weight: 800;
        color: $defaultHighLightColor;
      }
    }
  }
}
.stp-container.active {
  .cricle-icon {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    border: 1px solid #00be4b;
  }
  .label-txt {
    span {
      color: #00be4b;
    }
  }
  &::after {
    top: 13px;
    width:100%;
    transition-delay: 0.6s;
  }
}
.stp-container.completed::after {
  width: 100%;
  top: 13px;
  border-color: #00be4b;
  transition: width 0.6s;
}

.ct-stp-btn:first-child .stp-container::before {
  content: none;
}
.ct-stp-btn:last-child .stp-container::after {
  content: none;
}
// .ct-stp-btn:nth-last-child(2) .stp-container.completed::after {
//   width: 0%;
//   top: 13px;
//   border-color: #00be4b;
// }

// old styles ------------->
// .ct-stp-holder {
//   display: flex;
//   flex-direction: row;
//   width: -webkit-fill-available;
//   align-items: center;
//   justify-content: space-between;
//   padding: 2em;
//   // position: relative;
//   z-index: initial;
//   .stp-container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     position: relative;
//     width: 100%;
//     box-sizing: border-box;
//     .cricle-icon {
//       width: 18px;
//       height: 18px;
//       border-radius: 100%;
//       border: 1px solid $defaultHighLightColor;
//       margin-bottom: 1em;
//       background-color: #fff;
//       position: relative;
//     }
//     .label-txt {
//       span {
//         font-weight: 800;
//         color: $defaultHighLightColor;
//       }
//     }
//     &::before {
//       content: "asdfasdfadsfsd";
//       position: absolute;
//       min-width: 400%;
//       z-index: -1;
//       top: 15%;
//       left: 1.5em;
//       right: 3em;
//       // border: 1px solid $defaultHighLightColor;
//       background: $defaultHighLightColor;
//     }
//   }
//   // .active {
//   //   display: flex;
//   //   flex-direction: column;
//   //   align-items: center;

//   //   .cricle-icon {
//   //     width: 26px;
//   //     height: 26px;
//   //     border-radius: 100%;
//   //     border: 1px solid #00be4b;
//   //     // padding: 3px;margin-left:-20px;
//   //     svg {
//   //       height: 26px;
//   //       width: 26px;

//   //       margin-left: -1px;
//   //       margin-top: -1px;
//   //     }
//   //   }

//   //   &::before {
//   //     border: 1px solid #00be4b;
//   //     top: 20%;
//   //   }

//   //   .label-txt {
//   //     span {
//   //       font-weight: 800;
//   //       color: #00be4b;
//   //     }
//   //   }
//   // }
//   .ct-stp-btn:last-child {
//     .stp-container {
//       &::after {
//         min-width: 0px;
//       }
//     }
//   }

//   // &::after {
//   //     content: "";
//   //     position: absolute;
//   //     // width: 86.5%;
//   //     z-index: -1;
//   //     top: 35%;
//   //     left: 3em;
//   //     right: 3em;
//   //     border: 1px solid $defaultHighLightColor;
//   //     background: $defaultHighLightColor;
//   // }
// }

//   trial styles

// .ct-stp-holder {
//   text-align: center;
// }

// .ct-stp-holder {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   position: relative;
//   padding: 2em 2em 2em 3em;
// }

// .ct-stp-holder::before {
//   content: "";
//   width: 100%;
//   height: 1px;
//   border-bottom: 1px solid $defaultHighLightColor;
//   position: absolute;
//   z-index: -2;
//   top: 35px;
// }

// .progress-line {
//   content: "";
//   width: 0%;
//   height: 1px;
//   border: 1px solid #00be4b;
//   background-color: #00be4b;
//   position: absolute;
//   z-index: -1;
//   transition: width 1s;
//   top:35px;
// }

// .stp-container {
//   background-color: white;
//   transition: border-color color;
//   transition-duration: 0.7s;
//   .cricle-icon {
//     z-index: 5;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 18px;
//     height: 18px;
//     border-radius: 100%;
//     border: 1px solid $defaultHighLightColor;
//     margin-bottom: 1em;
//     background-color: #fff;
//     position: relative;
//   }
//   .label-txt {
//     span {
//       font-weight: 800;
//       color: $defaultHighLightColor;
//     }
//   }
// }

// .completed {
//   .cricle-icon {
//     width: 26px;
//     height: 26px;
//     border-radius: 100%;
//     border: 1px solid #00be4b;
//   }
//   .label-txt {
//     span {
//       color: #00be4b;
//     }
//   }
// }
// .stp-container.active {
//   .cricle-icon {
//     width: 26px;
//     height: 26px;
//     border-radius: 100%;
//     border: 1px solid #00be4b;
//   }
//   .label-txt {
//     span {
//       color: #00be4b;
//     }
//   }
// }
