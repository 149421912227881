@import '../../../../../variables.scss';


.bs-ai-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0rem;

    .bs-ai-header {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 0rem 1rem;
    }

    .bs-ai-content {
        flex: 1;

        .bs-ai-input-holder-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            padding: 1rem;

            .bs-ai-content-input-holder {
                width: 100%;
                margin-right: 2rem;
            }

            .section-header {
                max-width: 100%;
                width: 100%;
                font-size: 0.833vw;
                margin-bottom: 10px;
                color: #0b3e79;
                text-transform: uppercase;
            }
        }

        .billing-schedule {
            border-bottom: 1px solid rgba(3, 80, 144, 0.1);
            padding-top:0;
        }
    }

    .bs-ai-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem 1rem 0rem 1rem;
        align-items: center;

        .bs-ai-actions-btn {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .btn-cancel {
            margin-right: 1rem;
        }
    }
}