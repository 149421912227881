@import '../../../../../variables.scss';

.timecards-tab-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .timecards-tab-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-bottom: 0rem;

        .col1,
        .col2 {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

        }

        .col1 {
            .cmfs-container:first-child {
                margin-right: 0.5rem;
            }
        }

        .col2 {
            padding-top: 1.3em;
            padding-left: 0.8rem;

            .toggle-col2 {
                margin-left: 0.2rem;
            }

            .switch-holder {
                margin-bottom: 0.5rem
            }

        }

        .col3 {
            padding-top: 1.3em;

            .col3-row1 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

            }

            .search-bar {
                margin-top: 1.5rem;
                display: flex;
                justify-content: flex-end;

                .sb-container {
                    width: 70%;
                }
            }

            .custom-btn {
                margin-left: 0.5rem;

                .custom-button {
                    padding: 3px;
                }
            }
        }
    }

    .timecards-tab-content {
        // .cmfs-container {
        //     width: 50%;
        // }

        .total-count {
            span {
                color: $primaryColor;
            }
        }
        .row2-sel{
            display: flex;
            flex-direction: row;
            .pay-f-sel{
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                margin-left: 1rem;
                span {
                    color: #0b3e79;
                    font-size: .729vw;
                    font-weight: 900;
                    text-transform: none;
                    width: 6vw;
                }
                .cmfs-label{
                    width: max-content !important;
                    margin-bottom: 0 !important;
                }
            }
        }

        .timecards-table {
            max-height: 45vh;
            border: 1px solid $tableBorderColor;
            // height:30vh;
            overflow: auto;

            // overflow-x:scroll;
            .sortable-table {
                tbody tr.tr-odd {
                    background-color: #eeeeee;
                }
                thead th{
                    padding:1em 0.2em !important;

                }
                 tbody tr td{
                    padding:0.5em 0.2em !important;
                }
            }

            .del-tmecard {
                padding: 0.5em 1em 0.5em 0.5em;
                cursor: pointer;

                .delete-icon {
                    width: 0.95vw;
                    height: 0.95vw;

                }
            }

            .parent-position {
                position: relative;
                width: 0;
                height: 0;
                z-index: 1;

                .child-position {
                    position: absolute;
                    left: 1.5em;
                    top: -1em;

                    .item-menu-title {
                        text-transform: capitalize;
                        font-size: 0.700vw;
                        color: $defaultHighLightColor;
                        padding: 0.8em 1em;
                        background-color: white;
                        box-shadow: 0px 0px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 1);
                        min-width: 8vw;
                        width: max-content;
                        font-weight: 800;
                        cursor: pointer;

                        .rm-f-batch {
                            margin-bottom: 1em;
                        }
                    }
                }
            }

            .activeRow {
                background-color: $backOfficeOptionBackgroundColor;
            }

            .drad-input-container-small .drad-input-holder {
                padding: 0.3rem;
                border: 1px dashed $primaryColor;
                border-radius: 6px;
                background-color: aliceblue;

                .file-icon-info {
                    .file-icon {
                        margin-right: 0.5rem;
                        margin-bottom: 0.5rem;
                        width: 0.7895833333vw;
                        height: 0.7375vw;
                    }

                    .drag-info-txt {
                        width: fit-content;

                        span {
                            font-weight: 600;
                        }
                    }
                }
            }

            .attach_doc_btn {
                padding: 0.5em;
                border: 1px dashed $primaryColor;
                border-radius: 6px;
                background-color: #F2F6FA !important;

                span {
                    color: $primaryColor !important;
                    font-size: 0.629vw !important;
                    font-weight: 550 !important;
                }

                .btn-text {
                    display: none;
                }

                .btn-icon {
                    width: 0.6895833333vw;
                    height: 0.6895833333vw;
                    margin-right: 0rem;
                    // margin-bottom: 0.5rem;
                    color: $primaryColor;

                    svg {
                        vertical-align: text-top;
                    }
                }
            }
            .attached_doc{
                border: 1px dashed #F2F6FA;
                background-color: $secondaryColor2 !important;
                .btn-icon {
                    color:white !important;
                }
            }

            .rt-span {
                text-align: right;
                width: -webkit-fill-available;
            }

        }

        .tt-form-input .form-input-holder .prefix-holder {
            margin-right: 0.1em !important;
        }

        .form-input-holder {
            .form-input-tag {
                min-width: 1.5vw !important;
            }

            .form-input {
                min-width: 3vw !important;
            }
        }

        .total-table {
            width: 100%;
            margin-top: 1rem;

            .total-row {

                display: flex;

                td {
                    color: $primaryColor;
                    min-width: 40px;
                    padding: 1em 0.2em;
                    font-weight: 750;

                }

                td:nth-child(n+8) {
                    background-color: $tableHeaderBackgroundColor;
                    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                    border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                }

                td:nth-child(8) {
                    border-left: 4px solid $primaryColor;
                }

                td:last-child {
                    border-right: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);

                }


            }
        }

        .units-div {
            display: flex;
            justify-content: flex-end;

            .custom-units-div {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                padding: 0.5em 1em 0.5em 1.5em;
                border-radius: 4px;
                width: 43vw;
                margin-top: 1em;
                background-color: $tableHeaderBackgroundColor;
                border-left: 4px solid $defaultHighLightColor;
                box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
                justify-content: space-between;

                .units-title {
                    margin: 1em;

                    span {
                        font-size: 0.729vw;
                        color: $defaultHighLightColor;
                        font-weight: 800;
                    }
                }

                .units-val-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 70%;

                    .col1,
                    .col2 {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    .col1 {
                        width: 28%;
                    }

                    .units-val {
                        display: flex;
                        flex-direction: row;
                        margin: 1em;
                        text-align: center;
                        color: $primaryColor;

                        span {
                            font-size: 0.729vw;
                            font-weight: 800;
                        }
                    }

                    .item-bill {
                        margin-right: 0em;
                    }

                    // .units-val:last-child{
                    //     margin-left:4em;
                    // }
                }


            }
        }

    }
}