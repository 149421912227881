@import "../../../../variables.scss";

.plan-call {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px;

  .header {
    font-size: 1.302vw;
    color: #0b3e78;
    font-weight: 800;
  }

  .call-goal {
    span {
      text-transform: none;
    }

  }

  .form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 3.2em;

    .call-plan-assign {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin-bottom: 2rem;

      .css-t7ry41-control {
        min-height: 0;
      }

      .psc-assign-call {
        margin-left: 1em;
      }
    }

    .cmfs-container {
      margin-top: 25px;

      .cmfs-label span {
        font-size: 0.850vw;
        text-transform: none;
        font-weight: 700;
      }

      .form-select {
        height: 50px;
        width: 600px;
      }
    }

    .label {
      margin-top: 0.7em;
      font-size: 0.850vw;
      color: #0b3e78;
      font-weight: 700;

    }

    .sub-label {
      font-size: 0.833vw;
      font-weight: 500;
      margin-top: 0.5em;
      color: #0b3e78;
      margin-bottom: 10px;
    }

    .time-sub-label {
      .sub-label {
        margin-left: 0.8rem;
        margin-bottom: 0.25em;
      }
    }

    // .MuiInputBase-root{
    //   input {
    //     min-height: 1.8vw;
    //   }
    // }

    // .MuiSvgIcon-root {
    //   fill: $primaryColor;
    // }

    .calendar {
      margin-top: 10px;
      height: 45px;
      text-transform: none;
    }

    .schedule-container {
      display: flex;
      justify-content: flex-start;

      // justify-content: space-between;

      .date-time {
        // width: 48%;
      }
    }

    .contact-methods {
      margin-top: 1em;

      svg path {
        fill: $userProfileNameColor;
      }

      .social-media-options {
        svg {
          margin-right: 10px;
        }

        svg path {
          fill: $userProfileNameColor;
        }
      }

      .contact-icon {
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // height: 70px;
        height: 12vh;
        // width: 200px;
        width: 120px;
        margin-right: 1rem;
        cursor: pointer;
        position: relative;

        .google-meet-icon svg {
          width: 2.5vw;
          height: 2.5vw;
          margin-bottom: 1em;
        }

        svg {
          width: 1.5vw;
          height: 1.5vw;
          margin-bottom: 1em;
        }

        svg path {
          color: #035090;
        }

        .social-media-options svg {
          margin-bottom: 0.5em;
          width: 1em;
          height: 1em;
        }

        &.selected {
          box-shadow: 0px 0px 2px 0px rgba($color: $buttonColor17, $alpha: 1);
          position: relative;

          .check-circle-outline {
            position: absolute;
            left: 45px;
            top: 0px;

            &.small {
              .MuiSvgIcon-root {
                height: 0.833vw;
                width: 0.833vw;
              }
            }
          }
        }

        &.small {
          height: 55px;
          width: 65px;
        }

        &:hover {
          box-shadow: 0px 0px 2px 0px rgba($color: $buttonColor17, $alpha: 1);
        }

        .contact-label {
          font-size: 0.729vw;
          font-weight: bold;
          color: #0b3e78;
          margin-top: 0.5em;
          position: absolute;
          bottom: 0.5em;
        }
      }

      .contact-icon-small {
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 80px;
        width: 90px;
        margin-right: 25px;

        &:hover {
          box-shadow: 0px 0px 2px 0px rgba($color: $buttonColor17, $alpha: 1);
        }
      }
    }

    .flex-row {
      display: flex;
      margin-bottom: 1rem !important;
    }
  }

  .footer {
    display: flex;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 3em;

    .btn-cancel {
      margin-left: auto;
      margin-right: 15px;
    }

    .btn-text {
      span {
        text-transform: none;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .plan-call {
    .form-container {
      .time-sub-label {
        .sub-label {
          margin-left: 0.7rem;
          margin-bottom: 0.5em;
        }
      }
    }
  }
}