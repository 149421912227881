@import '../../../variables.scss';

.lo-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;
    .lo-logo-size{
        object-fit: contain;
        margin-bottom: 1em;
    }

    span {
        font-size: 1.041vw;
        font-family: $defaultFontFamily;
    }
}