@import '../../../../variables.scss';


.tdp-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    box-sizing: border-box;

    .tdp-left-panel {
        flex: 1;
        min-width: 240px;
        display: flex;
        padding: 1em;
        box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
        margin-right: 2em;
        position: relative;
        box-sizing: border-box;
        // min-height: -webkit-fill-available;
    }

    .tdp-right-panel {
        flex: 4;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        // min-height: -webkit-fill-available;

        // padding: 1em;
        // box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    }
}

.tdp-edit-icon {
    width: 0.989vw;
    height: 0.989vw;
    margin-bottom: 0.5em;
}

.tdp-job-match,
.tdp-comp-match {
    margin-top: 1em;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
    // height: 230px;
    overflow: auto;
    .sortable-table thead {
        z-index: 1;
    }
}


.tdp-match-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    .tdp-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1em 1em 0em 1em;
        

        .tdp-header-title {
            span {
                font-size: 0.833vw;
                color: $defaultHighLightColor;
            }

        }

        .tdp-header-search{
            width: 14.416667vw;
        }
    }

    .tdp-content {
        display: flex;
    }
}