@import '../../../../../variables.scss';


.timecards-dashboard-container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    box-shadow: 0px 0px 2px 0px #c3cfd6;
    padding: 1rem;

    .timecards-dashboard-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .select-row {
            display: flex;
            flex-direction: row;

            .cmfs-container {
                margin-right: 2rem;
            }

        }
    }

    .timecards-dashboard-content {
        height: 59vh;
        position:  relative;

        .timecards-dashboard-table {
            max-height: 30vh;
            overflow: scroll;
            margin-top: 2rem;
        }

        .time-card-note1 {
            margin-top: 3em;

            .card {
                .icon {
                    margin-top: 0.5em;

                    svg {
                        width: 0.9em;
                        height: 2em;
                        color: $backOfficeCardTextColor;
                    }
                }
            }
        }

        .time-card-note2 {
            position: absolute;
            bottom: 0;
            right:0;
            display: flex;
            justify-content: flex-end;

            .card {
                .text-btn {

                    .text-line-one,
                    .text-line-two,
                    .text-line-three {
                        margin-top: 0.3em;
                    }
                }
            }
        }

    }

}