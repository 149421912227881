@import '../../../../../variables.scss';

.bo-wa-dashboard-container {
    overflow: hidden;

    .bo-wad-header {
        display: flex;
        justify-content: flex-start;
        padding: 1rem 0rem;
        align-items: center;

        .title {
            font-size: 0.937vw;
            color: $primaryColor;
            font-weight: 800;
            margin-right:2rem;
        }
        
    }

    .bo-wad-content {

        position: relative;
        .err-msg{
            color:$statusInActiveColor;
            font-size: 1vw;
            margin-bottom: 1rem;
        }
        .toggle-switch{
            margin-bottom:1rem;
            .toggle-label-text{
                font-size:0.82vw;
            }
        }
        .bo-wad-content-table{
            height: 43vh;
            overflow: scroll;
        }

        .status-btn-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;

            span {
                text-transform: capitalize;
            }
        }

        .wa-card-note1 {
            margin-top: 3em;
            height: 18vh;
            .card {
                .icon {
                    margin-top: 0.2em;

                    svg {
                        width: 0.9em;
                        height: 2em;
                        color: $backOfficeCardTextColor;
                    }
                }

                .text-btn {

                    .text-line-one,
                    .text-line-two,
                    .text-line-three {
                        margin-top: 0.3em;
                    }
                }
            }
        }

        .wa-card-note2 {
            position: absolute;
            bottom: 0px;
            right: 0;
            display: flex;
            justify-content: flex-end;

            .card {
                .text-btn {
                    .text-line-one,
                    .text-line-two,
                    .text-line-three {
                        margin-top: 0.2em;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 1024px) {
    .bo-wa-dashboard-container {
        .bo-wad-content {
            .bo-wad-content-table{
                height: 37vh !important;
            }
           
        }
    }
  }