@import '../../variables.scss';

.eng-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    .MuiStepContent-root{
        margin-top: auto;
        margin-bottom: auto;
    }
    .MuiStepConnector-vertical{
        padding: 0px;
    }

    .eng-ud-table {
        svg path {
            fill: $buttonColor14 !important;
        }
        .desc{
            color:$buttonColor16;
            margin-top:0.5em;
        }
        .en-file-row{
            display: flex;
            flex-direction: column;
            width:100%;
            margin-top:1em;
            padding-bottom:1em;
            border-bottom: 1px solid rgba($color: $tableRowBorderColor, $alpha: 0.3);
            .en-file-doc{
                display: flex;
                flex-direction: row;
            
                margin-bottom: 0.5em;
                .filename{
                    color:$buttonColor16;
                    font-weight: 400;
                    margin-right: 1em;
                    overflow-wrap: anywhere;
                }
                .en-file-dwn-btn{
                    background-color: $buttonColor16;
                    box-shadow: none;
                    border:none;
                    padding:1px 6px;
                    margin-right:0.5em;
                    
                    svg {
                        width:0.7vw;
                        height:0.7vw;
                        path{
                        fill:$white !important;
                        }
                    }
                    .btn-text{
                        display: none;
                    }
                }
            }
        }
   
    }

    .eng-ud-table-header {
        padding-left: 1em;
        padding-top: 1em;
        padding-right: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-bottom: 1em;

    }

    .eng-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .eng-ud-add-user-btn {
            width: fit-content;
        }

        .eng-ud-add-icon {
            color: $white;
        }
        .tlnt-ud-add-user-btn{
            margin-top: 8px;
        }
    }
}

.eng-ud-table-headerText {
    font-family: Lato;
    font-size: 0.9vw;
    //font-weight: 900;
    margin-top: 14px;
    margin-left: 20px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b3e78;
    span{
        text-transform: none;
    }
}

.spinner-holder{
    padding: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
   
}
.action-btn{
    flex-direction: row;
    justify-content: center;
    display: inline-flex;
    .MuiTypography-h6{
        font-size: 15px;
        margin-top: 4px;
    }
}
.ac-um-add-icon{
    svg{
    width: 23px;
    height: 23px;
    //margin-left: 3px;
    }
    
}
.eng-edit-icon{
    width: "15px";
    height: "15px";
    color: #0b3e78;

}