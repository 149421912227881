@import '../../variables.scss';


.adh-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    min-width: $minWindowWidth;

    .adh-content-container {
       
        position: relative;
        background-color: $white;
        border-radius: 10px;
        min-width: 100px;
        display: flex;
        flex-direction: column;
        min-height: 100px;
        transition: 0.05s all ease-in-out;
        overflow: hidden;
    }

    .adh-touchable-holder {
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 10px;
        min-height: 100px;
        width: 100%;
        height: 100%;
        transition: 0.05s all ease-in-out;
    }

}