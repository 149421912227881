@import '../../variables.scss';

.cmpny-op-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;


    .dialog-header {
        display: flex;
        justify-content: space-between;
        padding: 15px;

        .left-fields {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #0B3E78;

            h4 {
                margin-left: 20px;
                margin-top: 10px;
                font-weight: 900;
            }
        }

        img {
            color: black;
            cursor: pointer;
        }
    }

    .cmpny-op-f-content {
        padding-left: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        // overflow: auto;
        margin-bottom: 0em !important;
        // min-height: 140px;
        .ac-header-text {
            font-size: 0.8333333333333334vw;
            font-weight: 900;
            color: #0b3e79;
            margin-bottom: 0px;
            margin-top: 1em;
        }
        

        .cmpny-op-f-section-divider {
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

            .cmpny-op-f-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .cmpny-op-f-input-holder {
                    width: 9.895833333333334vw;
                    margin-right: 1em;
                }
            }
        }

        .error-section {
            margin-top: 1em;
            margin-bottom: 2em;
        }
    }

    .cmpny-op-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .btn-cancel {
            margin-left: 1em;
        }
    }

}

.cmpny-op-f-link-icon {
    width: 16px;
    height: 16px;
}

.job-label {
    font-weight: 600;
}

.ac-eng-field-row {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    width: 100%;
}

.ac-eng-fields {
    flex-wrap: nowrap;
    margin-right: 1em;

    .time-picker-container {
        margin-top: 0 !important;

        .MuiInputBase-root input {
            min-width: 10vw !important;
        }

        .MuiInputBase-input {
            margin-left: 0 !important;
            margin-top: -1em;
        }

        .MuiSvgIcon-root {
            margin-top: -0.5em;
        }
    }

    .ac-Field-text-time {
        font-size: 0.7291666666666666vw;
        margin-bottom: 0.66rem !important;
        font-weight: 900;
        color: #0b3e79;
    }
}

.ac-eng-input-form-field {
    flex-wrap: nowrap;
    margin-right: 1em;

    span {
        margin-top: 6px !important;
    }
}

.ac-sc-desc-fields {
    flex-wrap: nowrap;
    width: 100%;
    margin-right: 1em;
}

.ac-Field-text {
    font-size: 0.7291666666666666vw;
    margin-bottom: 5;
    margin-top: 10;
    font-weight: 900;
    color: #0b3e79;
    span {
        color: black;
        font-weight: normal;
    }
}

.ac-header-text {
    font-size: 0.8333333333333334vw;
    font-weight: 900;
    color: #0b3e79;
}

.dialog-footer {
    padding-right: 15px;
    float: right;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    padding-bottom: 0px !important;

}

@media only screen and (max-width: 1440px) {
    .ac-eng-fields {
        //width: 100%;
        .ac-Field-text-time {
            margin-bottom: 0.69rem !important;
        }

        .time-picker-container {
            .MuiInputBase-input {
                margin-left: 0 !important;
                margin-top: -0.55em !important;
            }

            .MuiSvgIcon-root {
                margin-top: 0em !important;
            }
        }
    }
    
}