@import '../../../../../variables.scss';

.bo-rec-pay-container {
    .bo-rec-pay-post {
        padding: 1rem;
        background-color: $tableHeaderBackgroundColor;
        border: 1px solid $tableBorderColor;
        margin: 0.5rem 0rem 1rem 0;

        .rp-form {
            display: flex;
            flex-direction: column;

            .rp-form-row {
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-bottom: 0.5rem;

                .rp-form-ip-holder {
                    width: 22%;
                    margin-right: 2rem;
                    ;
                }

                .rp-form-ip-holder1 .cmfs-container {
                    width: max-content;
                }
                .date-holder{
                    margin-top: -0.5em;
                    .date-input{
                        background-color: white;
                    }
                }

            }

            .row3 {
                width: 100%;
                align-items: baseline;

                .rp-form-ip-holder {
                    width: 23%;
                    margin-right: 2rem;
                }

                .rp-form-ip-holder2 {
                    width: 40%;
                    // background-color: red;
                    margin-right: 0rem;

                    .drad-input-container {
                        .drad-input-holder {
                            background-color: white;
                            border-style: dashed;
                        }
                    }
                }

                .rp-form-ip-holder3 {
                    width: 50%;
                    display: flex;
                    justify-content: flex-end;

                    // background-color: green;
                    span {
                        font-size: 1vw !important;
                        text-transform: uppercase;
                        font-weight: 600 !important;
                    }

                    .form-input-holder {

                        .prefix-holder span,
                        .form-input-tag {
                            font-size: 1.5vw !important;
                            font-weight: 600 !important;
                        }

                        min-height:3.5vw !important;
                        min-width:20vw !important;
                        border:none;
                        border-left:5px solid $primaryColor !important;
                    }
                }
            }

            .rpf-actions-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 0.8rem;
                align-items: center;

                .rpf-actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                }
            }

        }

    }

    .bo-rec-pay-invoices {
        border: 1px solid $tableBorderColor;

        .open-invoices-table {
            overflow: auto;
            max-height: 28vh;
            .sortable-table tbody tr td:last-child{
                justify-content: center !important;
            }
        }

        .header {
            padding: 0.7rem 1.5rem;

            .open-i {
                font-size: 1vw;
                color: $primaryColor;
                font-weight: 700;
            }
        }


        .border-bottom {
            border-bottom: 1px solid $tableBorderColor;
        }

        .total-row {
            margin-top: 1rem;
            border-left: 4px solid $primaryColor;
            background-color: $tableHeaderBackgroundColor;
            position: sticky;
            bottom: -2px;

            span {
                font-weight: 700;
                font-size: 0.9vw;
                color: $primaryColor;
            }
        }

        .amount-input {
            min-width: 6.5vw !important;
            .form-input-holder .form-input-tag {
                min-width: 6.6vw !important;
            }
        }

        .view-icon {
            padding: 0.2rem 0.3rem;
            background-color: $viewBgColor;
            border-radius: 2px;
            cursor: pointer;

            svg {
                width: 0.9vw;
                height: 0.9vw;
            }
        }
    }

}