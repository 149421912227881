@import '../../../../variables.scss';


.void-rev-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .void-rev-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
    }

    .void-rev-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 2rem 0;
        width:100%;
        .cnfrm-msg {
            margin-top: 1em;

            span {
                color: $primaryColor;
            }
            .doc-saved-holder {
                justify-content: center;
            }
            .radio-div{
                text-align: center;
                margin-left:1rem;
                span{
                    font-size: 0.8vw;
                }
                .rc-msg {
                    font-size: 0.7vw;
                    text-transform: none;
                    color: orange;
                }
            }
        }

        .vr-w-text {
            display: flex;
            flex-direction: row;
            align-items: center;   
            justify-content:center;

            .vr-w-icon {
                margin-right: 0.5em;
                padding-bottom: 4px;

                svg{
                    width:1.3vw;
                    height:1.3vw;
                    path:last-child{
                        fill: white;
                    }
                }
            }

            span {
                color: orange;
                font-size: 0.88vw;
                font-weight: 500;
                text-transform: none;
            }
        }


        .void-rev-data-table {
            // width: 100%;

            table {
                width: 100%;
                // border: 1px solid rgba(195, 196, 198, 0.3);
                color: $tableRowTextColor;

                tbody tr {
                    td {
                        color: $primaryColor;
                        padding: 0.3em 1em;
                        font-size:0.72vw;
                        // border: 1px solid rgba(195, 196, 198, 0.3);
                    }

                    td:nth-child(even) {
                        font-weight: 800;
                    }
                }
            }

        }
        .radio-group-div{
            .MuiSvgIcon-root{
                height:1vw;
                width:1vw;
            }
        }
    }

    .void-rev-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;

        .void-rev-actions-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .btn-cancel {
            margin-right: 1rem;
        }
    }
}