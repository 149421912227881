@import "../../../../../variables.scss";

.rc-wc-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 0.5em 2em;

    .header-container {
        display: flex;
        justify-content: space-between;

        .table-header {
            display: flex;
            align-items: center;
            padding: 1em 0 0.2em 0;

            span {
                font-size: 0.937vw;
                color: $primaryColor;
                font-weight: 800;
                margin-bottom: 0.2em;
                text-transform: none;
            }
        }
    }

    .rc-wc-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1rem;

        .main-header {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .fs-btn {
                margin-left: 1em;
            }

            .error-holder {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                margin-left: 1em;
                margin-bottom: 0;
            }
        }
    }

    .rc-wc-content {
        .na_div {
            width: 100%;

            color: $primaryColor;
            padding: 2rem;
            text-align: center;

            span {
                font-size: 0.9vw;
            }
        }

        .grand-total-table {
            width: 100%;

            .total-row {
                border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);
                border-left: 4px solid $primaryColor;
                background-color: $tableHeaderBackgroundColor;
                display: flex;

                td {
                    color: $primaryColor;
                    min-width: 40px;
                    padding: 0.5rem;
                    font-weight: 750;

                    span {
                        font-size: 0.9vw;
                    }
                }


            }
        }

        .rc-wc-div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            border: 1px solid rgba($color: $formDeviderColor, $alpha: 0.2);

            .rc-wc-div-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0.5rem 1.2rem;

                table {

                    th {
                        text-transform: uppercase;
                        color: $primaryColor;
                        font-weight: 500;
                        padding: 0.5rem 2rem;
                    }

                    td {
                        color: $primaryColor;
                        padding: 0.1rem 2rem;
                        font-weight: 750;
                    }
                }
            }

            .rc-wc-div-table {
                max-height: 20vw;
                overflow: auto;
                position: relative;

                .total-row {
                    border-left: 4px solid $primaryColor;
                    background-color: $tableHeaderBackgroundColor;
                    position: sticky;
                    bottom: 0;

                    td {
                        color: $primaryColor;
                        font-weight: 700;
                    }
                }
            }

        }
    }
}