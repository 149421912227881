@import '../../../../../app/variables';

.cmp-jo-main-container {
  border: 1px solid $tableBorderColor;
  color: $primaryColor;
  padding-bottom: 1em;

  .cmp-jo-header {
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;

    .left-fields {
      display: flex;
      flex-direction: row;

      .total-count {
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-size: 0.781vw;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.86;
        letter-spacing: normal;
        color: $defaultHighLightColor;
        span{
            font-weight: bold;
        }
  
      }

    }

  }

}
.cmp-jo-table {
  .sortable-table {
    tbody {
      display: block;
      overflow-y: auto;
      max-height: 47vh;
    }
  }

  @media screen and (max-width:1650px) {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 43vh;
      }
    }
  }

  @media screen and (max-width:1500px) {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 43vh !important;
      }
    }
  }

  @media screen and (max-width:1280px) {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 47vh !important;
      }
    }
  }
}

.cjo-table-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 1em;
  padding-right: 3.2rem;
  padding-bottom: 2em;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;

  .cjp-table-footer {
    width: 100%;
    .tb-footer-common-pagination {
      position: relative;
      top: 0;
      margin-top: 0;
      width: 100%;
    }
  }
  .cmp-job-order-analysis {
    width: 35%;
    float: right;
    border: 1px solid $tableBorderColor;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    margin-left: 1em;
    border-radius: 4px;
    color: $primaryColor;
    background-color: #f7fbfd;
    border-left: 5px solid $primaryColor;
    font-weight: 700;
    font-size: 0.729vw;
  
    .heading {
      margin-right: 1.5rem !important;
    }
  
    span { 
      text-align: center;
    }
  
    .fields {
      display: flex;
      flex-direction: row;
      width: 61%;
      justify-content: space-between;
      .field {
        display: flex;
        flex-direction: column;
      }
  
    }
  
  }

}

.cmfs-container .cmfs-label {
  margin-bottom: 2px;
}


.cmpy-job-order-dialog-container {
  height: 450px;
  color: $defaultHighLightColor;

  .cmp-job-order-dialog-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 10px 20px;

    img {
      color: black;
      cursor: pointer;
    }

  }

  .cmp-job-order-dialog-main-content {
    margin: 20px;
    height: 280px;
    padding: 0 0 10px 5px;
    border-bottom: 1px solid $tableBorderColor;
    
    .cmp-job-order-dialog-content {
      height: 250px;
      overflow: scroll;
  
      .form-row {
        display: flex;
        margin-bottom: 10px;
        margin-left: 3px;
        flex-wrap: wrap;
      }
  
      .form-column {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        margin-right: 30px;

      }
  
      .small input {
        width: 135px;
      }
  
      .message {
        margin: 0;
        margin-top: 5px;
        font-size: 0.625vw;
        height: 0.781vw;
      }
  
      .dynamic-question {
        margin-left: 1px;
        margin-bottom: 10px;
        input {
          width: -webkit-fill-available !important;
        }
      }
  
      .cmp-job-order-toggle {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        div {
          padding-right: 30px;
        }
      }
  
    }
  }

  .cmp-job-order-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    .cmp-job-order-dialog-footer {
      float: right;
      display: flex;
      justify-content: space-between;

      div:first-child {
        margin-right: 10px;
      }

    }
  }

}

.success-message {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}

@media only screen and (min-width: 1440px) {
    .cmp-job-order-analysis {
      .heading {
        margin-right: 2.5rem !important;
      }
    }
}