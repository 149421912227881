@import '../variables.scss';

.aie-error {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .aie-error-txt {
        display: flex;
        flex-direction: row;
        align-items: center;

        .aie-err-icon {
            margin-right: 0.5em;
            padding-bottom: 4px;
        }

        span {
            color: $red;
            font-size: 0.677vw;
            text-transform: none;
        }
    }

    .aie-error-action {
        padding: 2px 4px 2px 4px;
        border-radius: 24px;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $backgroundHoverColor;
        }
    }
}