@import '../../variables.scss';

.clnt-cmpny-ilv-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .clnt-cmpny-ilv-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        border-bottom: 1px solid $defaultHighLightColor;
        margin-bottom: 1em;

        .header-txt {
            margin-bottom: 0.5em;

            span {
                font-size: 1.171303074670571vw;
                font-weight: 800;
                color: $defaultHighLightColor;
            }
        }

        .header-action {
            .header-action-icon {
                width: 1.145vw;
                height: 1.145vw;
            }
        }
    }

}

.interview-card {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    margin-bottom: 1em;

}


.clnt-cmpny-ilv-ic-container {
    display: flex;
    flex-direction: column;
    padding: 1em 1em;
    box-shadow: 0px 0px 2px 0px rgba($color: $defaultHighLightColor, $alpha: 1.0);
    position: relative;
    border-radius: 3px;
    width: -webkit-fill-available;

    transition: 0.3s all ease-in-out;

    &:hover {
        box-shadow: 1px 2px 10px 0px rgba($color: $defaultHighLightColor, $alpha: 1.0);
    }

    .clnt-cmpny-ilv-ic-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        .chat-icon {
            width: 1.145vw;
            height: 1.145vw;
            margin-right: 1em;
        }

        .chat-info {
            display: flex;
            flex-direction: column;

            .chat-title-txt {
                span {
                    color: $defaultHighLightColor;
                    font-size: 0.833vw;
                    font-weight: 800;

                }
            }

            .chat-by-txt {
                span {
                    color: $defaultHighLightColor;
                    font-size: 0.625vw;
                }
            }
        }

    }

    .clnt-cmpny-ilv-ic-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .clnt-cmpny-ilv-ic-date-txt {
            span {
                font-weight: 800;
                color: $defaultHighLightColor;
            }
        }
    }

    .clnt-cmpny-ilv-ic-shape-pyramid {
        border-top: 0.48125vw solid transparent;
        border-left: 0.6416vw solid #0b3e79;
        border-bottom: 0.48125vw solid transparent;
        width: 0;
        height: 0;
        position: absolute;
        top: 40%;
        right: -0.6416vw;


    }

}



.clnt-cmpny-ilv-icf-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1em;
    border-radius: 3px;
    background-color: $white;

    .clnt-cmpny-ilv-icf-title {
        margin-bottom: 1em;
        span {
            color: $defaultHighLightColor;
            font-weight: 800;
        }
    }

    .interview-content {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 1em;

        .feedback-holder{
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;

            .feedback-title{
                margin-bottom: 1em;
                span {
                    color: $defaultHighLightColor; 
                }
            }


            .feed-back-answ{
                span {
                    color: $defaultHighLightColor;    
                    font-weight: 800;
                }
            }
        }
    }

}