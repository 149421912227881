@import '../../../variables.scss';

.import-data-container {
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: auto;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .import-data-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .import-data-content {
        padding: 1em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .error-holder {

            .error-title {
                color: $statusInActiveColor;
                font-size: 1.2843vw;
                margin-bottom: 0.5rem;
            }

            span {
                color: $statusWarningColor;
                font-weight: 500 !important;
                font-size: 1vw;
                text-transform: capitalize;
            }
        }
        .upload-loader{
            margin-top:1.5rem;
            span{
                color:$primaryColor;
            }
        }
    }
}

.import-data-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;

}