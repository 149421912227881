@import '../../../../variables.scss';

.create-batch-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .create-batch-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .create-batch-content {
        padding: 1em 0em 1em 2em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .create-form-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .cform-input-holder{
                width:30%;
            }
        }

        .error-holder {

            .error-title{
                color:$statusInActiveColor;
                font-size:1.2843vw;
                margin-bottom:0.5rem;
            }
            span {
                color: $statusWarningColor;
                font-weight: 500 !important;
                font-size: 1vw;
                text-transform: capitalize;
            }
        }
    }
    .create-batch-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;
        .actions-row{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
        }
        // margin-left: 3em;
    }
}