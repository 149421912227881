@import '../../../../../variables.scss';

.dialog-container {
    width: -webkit-fill-available;
    color: $defaultHighLightColor;
  
    .dialog-header {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 1.041vw;
        font-weight: 900;
        color: #0b3e79;
      }
  
      img {
        color: black;
        cursor: pointer;
      }
  
    }
  
    .dialog-content {
      padding: 0 0 0 1rem;
      margin-left: 2em;
  
      input {
        width: -webkit-fill-available;
      }
  
      .message {
        margin: 0;
        margin-top: 5px;
        font-size: 0.625vw;
        height: 10px;
      }
  
    }
  
    .dialog-footer {
      padding-bottom: 0;
      margin-top: 0.5em;
      .custom-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .btn-cancel{
        margin-right: 1em;
      }
    }
  
  }