@import '../../../variables.scss';

.tb-footer-common-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 2em;
    width: 81.5%;

    .page-nav-content {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        word-spacing: 0.2em;

        .per-page-content {
            margin-right: 2rem;
            border: 0.3px solid $tableBorderColor;
            color: $inputBorderColor;
            border-radius: 5px;

            .custom-select {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                width: 100%;
                border-radius: 5px;
                cursor: pointer;
                padding-right: 0.8em;
                background-color: $white;
            }

            .custom-select-value {
                span {
                    font-weight: bold;
                }
            }

            .custom-arrow {
                font-size: 0.8rem;
                color: $inputBorderColor;
                transition: transform 0.2s ease-in-out;
            }

            .custom-arrow.open {
                transform: rotate(180deg);
            }

            .custom-options-list {
                position: absolute;
                bottom: 100%;
                left: 0;
                right: 0;
                border: 0.3px solid $tableBorderColor;
                border-radius: 5px;
                background-color: $white;
                margin-bottom: 3px;
                list-style: none;
                padding: 0;
                z-index: 10;

                li {
                    color: $inputBorderColor;
                    &:hover {
                        background-color: rgba($color: $defaultHighLightColor, $alpha: 0.1);
                     }
                }

            }

            .custom-option {
                padding: 0.6em;
                font-size: 1em;
                color: #333;
                cursor: pointer;
            }

            .custom-option:hover {
                background-color: $white;
            }

            .custom-option.selected {
                background-color: $white;
            }

            .custom-select-value,
            .custom-option {
                padding: 0.8em;
            }

        }

        .per-page-content.disabled {
            opacity: 0.7;
            color: $statusDisabledColor;
            .custom-arrow { 
                opacity: 0.7;
                color: $statusDisabledColor;
            }
        }

        .prev-next {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: max-content;
            border: 0.3px solid $tableBorderColor;
            color: $inputBorderColor;
            border-radius: 5px;
            padding: 0 0 0 0.8em;
            font-size: 0.729vw;
            background-color: #fff;

            button {
                display: flex;
                align-items: center;
                padding: 0.2rem;
                margin: 0.2em;
                text-decoration: underline;
                color: $defaultHighLightColor;
                font-weight: 800;
                border-radius: 50%;
                border: 1px solid $defaultHighLightColor;
                color: $tableRowTextColor;

                svg {
                    width: 0.5vw;
                    height: 0.5vw;
                    path {
                        fill: $defaultHighLightColor;
                    }
                }
            }

            button:disabled {
                border: 1px solid $statusDisabledColor;
                svg {
                    path {
                        fill: $statusDisabledColor;
                    }
                }
            }

            .back-next-btns button:hover {
                background-color: $defaultHighLightColor;
                svg {
                    path {
                        fill: $white;
                    }
                }
                transform: scale(1.1);
            }

            .cp-size {
                span:first-child {
                    font-weight: 600;
                }

                span:nth-child(2) {
                    text-transform: none;
                    margin-left: 0.5em;
                }
            }

            .back-next-btns {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 0.5em;

                select {
                    min-width: max-content;
                    margin: 0 0.5rem;
                    border: 0.3px solid #9AB8D1;
                    color: #005A9A;
                    border-radius: 3px;
                    padding: 0.2em;
                    border: 0.3px solid #9AB8D1;
                    font-size: 0.729vw;
                }

                .select-gray:disabled {
                    color: $statusDisabledColor;
                    border-color: $statusDisabledColor;
                }

                select:focus {
                    outline: none;
                }

                .disabled {
                    path {
                        fill: gray;
                    }
                }

                span:nth-child(2) {
                    margin-left: 0.5em;
                }

                .next-arrow {
                    margin-left: 0.5em;
                }
            }

            .divider {
                width: 1px;
                height: 2.5vh;
                background-color: $tableBorderColor;
                margin:0 1em;
            }
        }
    }
}