@import '../../../../../variables.scss';

.cmpny-invoice-list {
    padding: 0.5rem 0;

    .cmpny-invoice-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-span {
            text-transform: uppercase;
            color: $primaryColor;
            font-weight: 300;
            border-bottom: 0.5px solid $primaryColor;
            font-size: 0.8vw;
        }

        .total-count {
            margin-left:2rem;
            color: $primaryColor;
        }

        .cmpny-header-right {
            display: flex;
            flex-direction: row;

            .search-bar {
                margin-left: 1rem;
            }
        }
    }

    .invoice-list-table {
        max-height: 60vh;
        overflow: auto;
        margin: 1rem 0rem;
    }

    .view-icon {
        padding: 0.2rem 0.3rem;
        background-color: $viewBgColor;
        border-radius: 2px;
        cursor: pointer;

        svg {
            width: 0.9vw;
            height: 0.9vw;
        }
    }

    .download-icon {
        padding: 0.2rem 0.3rem;
        background-color: $viewBgColor;
        border-radius: 2px;
        cursor: pointer;

        svg {
            width: 0.9vw;
            height: 0.9vw;
        }
    }
}