@import "../../variables.scss";

.broadcast_talent_container {
  display: flex;
  flex-direction: column;
  // width: -webkit-fill-available;
  position: relative;
  padding-top: 0.3em;

  .bc_talent_header {
    display: flex;
    // width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
  }
  .bc_talent_content {
    // padding: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    overflow: auto;
    width: 100%;
    margin-bottom: 1rem;
    min-height: 600px;
    height: 650px;
    padding-top: 1em;
    .broadcast_iframe {
      min-height: 580px;
      height: 620px;
      width: 100%;
    }
    .error-span {
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
  @media screen and (min-width: 1000px) {
    .bc_talent_content {
      min-height: 550px;
      height: 550px;
      .broadcast_iframe {
        min-height: 530px;
        height: 530px;
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 1500px) {
    .bc_talent_content {
      min-height: 600px;
      height: 600px;
      .broadcast_iframe {
        min-height: 580px;
        height: 580px;
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 2000px) {
    .bc_talent_content {
      min-height: 750px;
      height: 700px;
      .broadcast_iframe {
        min-height: 730px;
        height: 700px;
        width: 100%;
      }
    }
  }
  @media screen and (max-height: 600px) {
    .bc_talent_content {
      min-height: 500px;
      height: 500px;
      .broadcast_iframe {
        min-height: 430px;
        height: 440px;
        width: 100%;
      }
    }
  }

  // @media screen and (max-width: 1300px) {
  //   .bc_talent_content {
  //     min-height: 550px;
  //     height: 550px;
  //     .broadcast_iframe {
  //       min-height: 550px;
  //       height: 460px;
  //       width: 100%;
  //     }
  //   }
  // }
}
.broadcast_talent_error_container {
  padding: 0.5em;
  .error_table {
    table-layout: fixed;
    width: -webkit-fill-available;
    tbody td {
      padding: 0.5em 1em;
      span{

      
      word-wrap: break-word;
      white-space: normal;
      }
    }
  }
  .bc_talent_content {
    margin: 2em 1em 2em;
    max-height: 500px;
    overflow: auto;
    span {
      color: #0b3e79;
    }
  }
  .bc_talent_header .title-holder .title-icon {
    width: 1.3vw;
    height: 1.3vw;
    margin-right: 1.0416666666666667vw;
  }
  .bc_talent_header .title-holder .title-holder .title-name span {
    color: #ff7600;
    font-size: 0.8741vw;
    font-weight: 600;
    // color:$red;
  }
  .bc_talent_actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 2em;
    // margin-right:1em;

    .btn-cancel {
      margin-right: 1em;
    }
  }
}
