@import '../../../../variables.scss';

.team-manage-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;

    .team-manage-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0.5em 2em;
        width: -webkit-fill-available;
        position: relative;
    }

    .team-manage-content {
        border: 1px solid white;
        box-shadow: 0 0 1px 0;
        margin: 0rem 1.5rem;
        padding: 1rem;

        .team-bt-container {
            display: flex;
            justify-content: space-between;

            .team-name-content {
                .keyboard-backspace {
                    cursor: pointer;
                    color: $defaultHighLightColor;
                }
    
                span {
                    font-weight: 900;
                    font-size: 1.5em;
                    margin-left: 0.5em;
                    color: $defaultHighLightColor;
                }
    
                .team-total-count {
                    .total-count {
                        span {
                            font-weight: 300;
                            font-size: 1em;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 2.86;
                            letter-spacing: normal;
                            color: $defaultHighLightColor;
            
                            .total-count-number {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .add-team-bt {
                display: none;
            }

            .team-management-update-bt-content {
                display: flex;
                justify-content: space-between;

                .team-management-update-bt {
                    margin: 0.8em 1.5em 0 0;
                }
            }

        }       

        .team-bt-content {
            display: flex;
            justify-content: flex-end;
        }

        .team-member-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;

            .ac-tm-team-member-loading {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 2rem;
            }

            .tms-hover-state-parent {
                position: relative;

                .tms-hover-state-child {
                    position: absolute;
                    top: 3rem;
                    right: 1.5rem;
                    border: 1px solid $defaultHighLightColor;
                    border-radius: 0.5em;
                    color: $defaultHighLightColor;
                    padding: 0.5em;
                    background-color: $white;
                    z-index: 1;
                }
            }
        }

        .team-manage-content,
        .team-member-section {
            .tms-flex-card {
                flex: 1;
                border-radius: 0.5em;
                border: 1px solid $statusDisabledColor;
                background-color: $dasboardOptionBackgroundColor;
                position: relative;
                padding: 1rem 0.5rem;
                margin: 0 1.5rem 1.5rem 0;
                max-width: calc(33.3333% - 1.5rem);
                min-width: calc(33.3333% - 1.5rem);
                width: 16vw;
            }
        }

        .team-manage-content {
            .tms-flex-card {
                max-width:  calc(23% - 2.5rem);
                min-width:  calc(23% - 2.5rem);
                width: 12vw;

            }
        }

        @media (max-width: 600px) {
            .team-member-section {
                .tms-flex-card {
                    min-width: 35%;
                }
            }
        }

        @media (min-width: 600px) {
            .team-member-section {
                .tms-flex-card {
                    min-width: 35%;
                }
            }
        }

        @media (max-width: 1200px) {
            .team-member-section {
                .tms-flex-card {
                    min-width: 60%;
                }
            }
        }
        @media (min-width: 1366px) {
            .team-member-section {
                .tms-flex-card {
                    min-width: calc(33% - 2.5rem);
                }
            }
        }
    }
}