@import '../../../../../variables.scss';

.rc-sm-ms-container {
    .call-counts {
        display: flex;
        justify-content: space-between;
        background: #f7fbfd 0% 0% no-repeat padding-box;
        border: 0.25px solid #035090;
        border-left-width: 4px;
        opacity: 1;
        width: 30%;
        margin-top: 2em;
        margin-left: auto;
        padding: 1em;

        .total-count {
            color: #074783;
            margin: 0 0.5em 1.5em 0.5em;
            font-weight: 700;
            font-size: 0.9vw;
            flex-direction: row-reverse;
        }
    }
}