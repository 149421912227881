@import '../../../../../../variables.scss';

.interview-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    border-radius: 5px;
    background-color: $white;

    .info-txt-holder {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .info-txt {
            display: flex;
            align-items: center;
            justify-content: start;
            color: $defaultHighLightColor;
            min-width:30%;
            margin-right:1rem;
            margin-bottom:0.2rem;
            a {
                color: $defaultHighLightColor;

                &:hover {
                    color: $defaultHighLightColor;
                }
            }

            .tx-str {
                font-weight: 800;
                margin-left: 0.5em;
            }

        }
        .info-txt:nth-child(3),
        .info-txt:nth-child(6){
            margin-right:0 !important;
        }

    }


    .edit-icon {
        width: 0.989vw;
        height: 0.989vw;
        margin-bottom: 0.5em;
        margin-left: 0.5rem;
    }
}

.close-icon {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: end;
    width: 0.989vw;
    height: 0.989vw;
    margin-bottom: 0.5em;
}

@media (max-width: 768px) {
    .info-txt-holder {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns on medium screens */
    }
}

@media (max-width: 480px) {
    .info-txt-holder {
        grid-template-columns: 1fr;
        /* 1 column on small screens */
    }
}