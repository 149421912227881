@import '../../../../variables.scss';

.tlnt-obd-container{
    display: flex;
    flex-direction: column;

    .tlnt-obd-docs-holder {
        margin-bottom: 1em;
        display: flex;
        width: -webkit-fill-available;
        .tlnt-obd-certs {
            height: 40vh;
        }
    }

    .tlnt-obd-other-docs-holder{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: -webkit-fill-available;

        .tlnt-obd-certs, .tlnt-obd-hrng {
            flex: 1;
        }
        
        .tlnt-obd-certs{
            margin-right: 1em ;
            min-height: 26.6vw;
        }
        .tlnt-obd-docs{
            flex: 1;
            margin-right: 1em;
            height: 20vw;
        }
        .tlnt-obd-profile{
            width: 100%;
            flex: 1;
            height: 20vw;
        }
    }
}

// .obd-card {
//     height: 40vh;
//     border: 1px solid $tableBorderColor;

//     .title-bgc {
//         background-color: $backgroundColor;
//         padding: 10px;
//         color: #0B3E78;
//         font-weight: bold;
//     }

//     .talent-obd-button {
//         padding: 1rem;

//         .custom-btn {
//             display: flex;
//             justify-content: flex-end;
//         }

//     }

//     .talent-obd-bt {
//         padding: 1rem;

//         .custom-btn {
//             display: flex;
//             justify-content: flex-end;
//         }

//     }

//     .talent-obdocs-bt {
//         padding: 1rem;

//         .custom-btn {
//             display: flex;
//             justify-content: flex-end;
//         }

//     }

//     .obd-ht {
//         display: none;
//         margin-right: 1rem;
//     }

//     .talent-obdocs-bt:hover+.obd-ht {
//         display: block;
//         color: $talentOptionTextColor;
//         display: flex;
//         justify-content: flex-end;
//     }
// }