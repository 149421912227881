@import '../../../variables.scss';

.ac-as-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;

  .ac-as-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0.5em 1em;
    width: -webkit-fill-available;
    position: relative;
    border: 0 !important;
  }

  .ac-as-content {
    padding: 0 2em 2em 2em;
    position: relative;
    color: $defaultHighLightColor;

    .ac-as-content-agency {
      border: 1px solid $tableBorderColor;
      padding: 1em;
      margin-bottom: 2em;

      .ac-as-header-container {
        .ac-as-btn-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-right: 1em;

          span {
            font-size: 0.937vw;
            font-weight: bold;
          }
        }

        .form-row {
          display: flex;
        }

        .remit-div {
          display: flex;
          flex-direction: row;

          .remit-span {
            margin: 0.8rem 3rem 0 0;
            font-size: 0.9vw;
            font-weight: 900;
          }

          .switch-column {
            margin-top: 0.8rem;
          }
        }


        .form-column {
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          margin-right: 30px;

          // p {
          //   margin-bottom: 4px;
          // }

          // input {
          //   width: 320px;
          // }
          input {
            flex-grow: 1;
          }
        }

        // .small input {
        //   width: 220px;
        // }

        .actions-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .actions {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .btn-save,
            .btn-cancel {
              margin: 1em;
            }
          }

          .as-success-message {
            color: green;
            font-size:0.8vw;
            font-weight: 700;
          }

          .as-error-message {
            color: red;
          }
        }
      }
    }

    .agency-info-content {
      padding: 1em;
      font-weight: bold;
      border: 1px solid $tableBorderColor;

      .agency-info-title {
        span {
          font-size: 1vw;
          margin-bottom: 1em;
        }
      }

      .agenct-logo-body {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .agency-logo-delete {
          margin-left: 1em;
          margin-bottom: 0.5em;
        }

        .agency-logo-info {
          width: 20%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 2px dashed #c3cfd6;
          border-radius: 5px;
          padding: 1em;
          margin-bottom: 0.5em;
          cursor: pointer;

          .file-icon {
            height: .9375vw;
            margin-right: 1em;
            margin-bottom: 0.4em;
            width: .9895833333vw;
          }

          .drag-info-txt {
            span {
              font-size: 0.729vw;
              font-weight: normal;
              color: $defaultHighLightColor;
            }
          }
        }
      }
    }

    .ac-as-content-branch {
      margin-top: 20px;
      border: 1px solid $tableBorderColor;

      .header {
        display: flex;
        margin: 1em;
        justify-content: space-between;

        span {
          font-size: 0.937vw;
          font-weight: bold;
        }
      }

      .agency-table {
        overflow: auto;
        max-height: 30vh;
        .sortable-table thead {
          z-index: 99;
        }
      }

      .sb-s-container {
        width: 15vw;
        margin: 1em;

        .sb-container input {
          margin-right: 2em;
        }
      }
    }

  }

}


.ac-as-dialog-container {
  // width: 910px;
  // height: 58.9vh;
  color: $defaultHighLightColor;
  padding: 20px;

  .ac-as-dialog-header {
    display: flex;
    justify-content: space-between;

    img {
      color: black;
      cursor: pointer;
    }

    span {
      color: #0b3e79;
      font-size: 1.041vw;
      font-weight: 900;
    }

  }

  .ac-as-dialog-content {
    margin: 0em 1em 1em 3.2em;
    border-bottom: 1px solid $tableBorderColor;


    .form-row {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }

    .third-row {
      margin-bottom: 1.5em;
    }

    .form-column {
      display: flex;
      flex-direction: column;
      margin-right: 1vw;

      .css-1cfjzs1-control {
        border: 0.3px solid red;
      }

      .error {
        max-width: 100%;
        box-sizing: border-box;
        word-break: unset;
        color: red;
        padding: 0.5em;
        font-size: 0.65rem;
        font-family: Lato;
      }
    }

    .message {
      margin: 0;
      margin-top: 5px;
      font-size: 0.625vw;
      height: 0.781vw;
    }

  }

    .ac-as-dialog-footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .btn-cancel{
        margin-right: 1em;
      }
    }

}