@import '../../../../variables.scss';

.ac-prtc-ud-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);

    .ac-prtc-ud-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title-holder {
            margin-right: 1em;
        }
    }

    .ac-prtc-ud-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .ac-prtc-ud-add-user-btn {
            width: fit-content;
        }
    }

    .ac-prtc-ud-table-search-bar {
        margin-right: 1em;
    }

    .ac-prtc-ud-table thead {
        top: -1.6vh !important;
        z-index: 99;
    }


    .total-count {
        span {
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.2;
            letter-spacing: normal;
            color: $defaultHighLightColor;
            margin-bottom: 0.3em;

            .total-count-number {
                font-weight: bold;
            }
        }
    }
}