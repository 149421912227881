@import "../../../../variables.scss";

.hnd-b-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;

  .hnd-b-header {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    margin-bottom: 1em;
  }

  .hnd-b-content {
    padding: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    overflow: auto;

    .saved-doc-holder {
      margin: 2em 2em;
    }

    .hnd-b-section-divider {
      padding-bottom: 2em;
      margin-bottom: 2em;
      border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

      .hnd-b-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0.5em;

        .section-title-txt {
          span {
            color: $defaultHighLightColor;
          }
        }

        .hnd-b-input-holder {
          width: 9.895833333333334vw;
          margin-right: 2.5em;

          .d-inline-block {
            width: 45%;
          }

          .cmfs-margin-top {
            margin-top: 0.6em;
          }
        }

        .css-c0tn0x-control {
          border: 1px solid $inputoutlineColor !important;

          .css-tlfecz-indicatorContainer {
            color: $inputBorderColor;
          }
        }
      }

      .flx-start {
        margin-bottom: 0;
      }
    }

    .hnd-b-section-divider:last-child {
      border-bottom: none;
      padding-bottom: 1em;
      margin-bottom: 1em;
    }

    .error-section {
      margin-top: 2em;
      margin-bottom: 1em;
      width: -webkit-fill-available;
    }
    .webviewer {
      height: 500px !important;
      width: 100%;
    }
    @media screen and (max-width:1600px) {
      .webviewer {
        height: 320px !important;
        width: 100%;
      }
    }
  }

  .hnd-b-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
    padding-top: 1em;
    margin-left: 3em;

    .btn-cancel {
      margin-right: 1em;
    }
  }

  .switch {
    .slider:after {
      content: 'NO' !important;
    }

    input:checked+.slider:after {
      content: 'YES' !important;
    }
  }
}

.hnd-b-link-icon {
  width: 0.833vw;
  height: 0.833vw;
}

.info-txt {
  font-size: 1.0416666666666667vw !important;
}

.name {
  font-weight: normal !important;
  font-size: 1.0416666666666667vw !important;
}

