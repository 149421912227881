@import '../../../../variables.scss';


.tlnt-avblt-add-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;
    // max-width: 50%;

    .tlnt-avblt-add-f-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }

    .tlnt-avblt-add-f-content {
        padding-left: 3em;
        padding-right: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        min-height: 30%;

        .tlnt-avblt-add-f-section-divider {
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

            .tlnt-avblt-add-f-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .tlnt-avblt-add-f-input-holder {
                    min-width: 9.895833333333334vw;
                    margin-right: 1em;
                }
            }
        }
        .tint-ihi-questions-section {
            display: flex;
            flex-direction: column;
        
            .tint-ihi-question-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2em;
        
                .tint-ihi-question-title {
                    // margin-bottom: 0.5em;
        
                    span {
                        font-size: 0.833vw;
                        color: $defaultHighLightColor;
                        font-weight: bold;
                    }
                }
        
                .tint-ihi-input-form {
                    display: flex;
                    flex-direction: column;
                    // margin-left: 1em;
                }
            }
        }

        .error-section{
            margin-bottom: 2em;
        }
    }

    .tlnt-avblt-add-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 0.5px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 1em;

        .btn-cancel {
            margin-right: 1em;
        }
    }

}

.tlnt-avblt-add-f-link-icon{
    width: 0.833vw;
    height: 0.833vw;
}


.question-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: center;
    //min-height: 11.458333333333334vw;

    .question-txt{
        margin-top: 1em;
        margin-bottom: 1em;
        span {
            font-size: 1.25vw;
            color: $defaultHighLightColor;
            font-weight: 500;
            text-transform: none !important;
        }
    }
}