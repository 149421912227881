@import "../../../../../variables.scss";

.tlnt-ac-ud-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1);

  .tlnt-ac-ud-table-header {
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    .tlnt-ud-brdcast-btn {
      display: flex;

      .tlnt-ud-search {
        margin-right: 1rem;
      }

      button {
        background-color: #ff4d00;
        box-shadow: none;
        margin-right: 1.5em;

        svg {
          g path:first-child {
            fill: white !important;
          }

          g path:nth-child(2) {
            fill: #ff4d00 !important;
          }

          g path:nth-child(3) {
            fill: #ff4d00 !important;
          }
        }
      }
    }

    .select_talent_msg {
      text-transform: initial;
      color: #ff4d00;
      margin-top: 0.5em;
    }
  }

  .tlnt-ac-ud-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .tlnt-ac-ud-add-user-btn {
      width: fit-content;
    }

    .tlnt-ac-ud-add-icon {
      color: $white;
    }
  }
}

.table-loading {
  width: 100%;
  height: 200px;
}

.tlnt-ac-ud-table-header-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;

  .tlnt-ac-ud-table-search-bar {
    margin-right: 1em;
    display: inline-flex;
  }

  .select-user {
    margin-right: 1em;

    .user-form-control {
      min-width: 190px;
      width: fit-content;
    }
  }

  .deparment-toggle {
    margin-right: 1em;
  }

  .total-count {
    span {
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.86;
      letter-spacing: normal;
      color: $defaultHighLightColor;
      margin-top: 10px;

      .total-count-number {
        font-weight: bold;
      }
    }
  }
}

.website-icon {
  width: 1.25vw;
  height: 1.25vw;
  cursor: pointer;
  text-decoration: none;
}

.open-orders-empty {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .ord-icon {
    width: 1.25vw;
    height: 1.25vw;
    margin-right: 1em;
  }

  .ord-text-empty {
    span {
      color: #b1b1b1;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
    }
  }

  .ord-text {
    span {
      color: $openOrdersTextColor;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
    }
  }
}

.status-icon {
  width: 1.25vw;
  height: 1.25vw;
  margin-right: 1em;
}

.table-access-icon {
  width: 100%; 
  height: 100%;
  background: url("../../../../../../assets/icons/access_enter.svg") no-repeat;
  background-size: contain;
  flex-shrink: 0;
}

.table-access-icon:hover {
  background: url("../../../../../../assets/icons/access_full_enter.svg") no-repeat;
  background-size: contain;
  flex-shrink: 0;
}

/// Name
// .tlnt-ac-ud-table-name {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     flex-wrap: nowrap;

//     .status-icon {
//         width: 1.25vw;
//         height: 1.25vw;
//         margin-right: 1em;
//     }

//     .name-txt {
//         span {
//             font-size: 0.729vw;
//             font-weight: bold;
//         }
//     }
// }

.pref-contact-icon {
  // width: 1.25vw;
  height: 1.25vw;
}

.ssn-holder {
  display: flex;
  flex-direction: row;
  // align-items: center;
  flex-wrap: nowrap;

  .ssn-number {
    margin-right: 0.5em;

    span {
      margin-top: 2px;
    }
  }

  .loc-open-icon {
    width: 14px;
    height: 14px;
  }
}