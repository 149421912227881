@import "../../variables.scss";

.cds-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: -webkit-fill-available;
}

.cds-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;

  .cds-avatar-holder {
    width: 110px;
    height: 110px;

    border-radius: 110px;
    padding: 4px;
    border: 1px solid $primaryColor;

    .cds-avatar {
      background-image: url("../../../assets/icons/user.svg");
      border-radius: 100%;
    }
  }

  .cds-user-name {
    margin-top: 0.5em;

    span {
      font-size: 1.25vw;
      font-family: $defaultFontFamily;
      color: $userProfileNameColor;
      font-weight: 900;
    }
  }

  .cds-user-tag {
    span {
      font-size: 0.729vw;
      font-family: $defaultFontFamily;
      color: $tableRowTextColor;
      font-weight: 900;
    }
  }
}

.cds-action-container {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // justify-content: flex-start;
  border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
  margin-bottom: 1em;
  padding-top: 1em;

  .cds-action {
    width: -webkit-fill-available;
    margin-bottom: 1em;

    .cds-action-icon {
      width: 1.25vw;
      height: 1.25vw;
    }
    .cls-briefcase {
      fill: $white !important;
    }
  }
}

.c-ds-branch-selector {
  display: flex;
  margin-bottom: 1em;
  padding-left: 3em;

  .cds-branch-left,
  .cds-branch-right {
    width: 50%;

    .cds-status {
      margin-top: 1em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        color: $userProfileNameColor;
        font-family: $defaultFontFamily;
        font-size: 0.833vw;
      }
      .status-txt,
      .cds-id-txt {
        font-weight: 800;
        font-family: $defaultFontFamily;
        font-size: 0.729vw;
      }

      .status-txt {
        color: $openOrdersTextColor;
      }
      .cds-id-txt {
        color: $userProfileNameColor;
      }
      /*
          &:hover {
              span:nth-child(2) {
                  text-decoration: underline;
                  cursor: pointer;
              }
          }*/
      .status-txt:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .refresh_company {
    padding: 3px !important;

    span {
      font-size: 0.7vw !important;
    }
    .err-h-add-icon {
      width: 1vw !important;
      height: 1vw !important;
    }
  }
  .no-creds-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .no-creds {
    color: $statusWarningColor;
    margin: 0.2rem 0rem 0.4rem 0;
    font-size: 0.7vw;
  }
  .add-creds-div {
    color: $defaultHighLightColor;
    font-weight: 800;
    text-decoration: underline;
    cursor: pointer;
  }
}

.cds-info-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
  margin-bottom: 1em;
  padding-top: 1em;
  padding-left: 1em;

  .cds-info-icon {
    width: 0.833vw;
    height: 0.885vw;
    margin-right: 1em;
    flex: none;
    color: $companiesOptionTextColor;
    .cls-1 {
      fill: $companiesOptionTextColor;
    }
  }

  .cds-info-content {
    display: flex;
    flex-direction: column;

    .cds-info-holder {
      display: flex;
      flex-direction: column;

      .cds-into-title {
        span {
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          font-size: 0.833vw;
        }
      }

      .cds-info-value {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        color: $userProfileNameColor;
        font-weight: 800;
        line-break: anywhere;
        // margin-bottom: 0.5em;
        //text-transform: capitalize;
        a {
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
          font-weight: 800;
          text-decoration: none;
          line-break: anywhere;
        }

        span {
          font-weight: 800;
          color: $userProfileNameColor;
          font-family: $defaultFontFamily;
        }

        .cds-info-icon {
          width: 1.666vw;
          height: 1.666vw;
          cursor: pointer;
          padding: 0.5em;
          transition: 0.3s all ease-in-out;

          &:hover {
            background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
          }
        }
      }
    }
  }
}

.cds-info-address-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1em;
  padding-left: 1em;

  .cds-info-icon {
    width: 0.833vw;
    height: 0.885vw;
    margin-right: 1em;
    flex: none;
    color: $companiesOptionTextColor;

    .cls-1 {
      fill: $companiesOptionTextColor;
    }
  }

  .cds-info-value {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    color: $userProfileNameColor;
    font-weight: 800;
    line-break: anywhere;
  }
}
