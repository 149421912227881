@import '../../variables.scss';

.social-media-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
    margin-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em;



    .social-media-info-icon {
        width: 0.885vw;
        height: 0.885vw;
        margin-right: 1em;
    }

    .social-media-info-content {
        display: flex;
        flex-direction: column;

        width: -webkit-fill-available;

        .social-media-info-holder {
            display: flex;
            flex-direction: column;

            .social-media-into-title {
                span {
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                    font-size: 0.833vw;
                }
            }

            .social-media-info-value {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;

                span {
                    font-weight: 800;
                    color: $userProfileNameColor;
                    font-family: $defaultFontFamily;
                }

                .social-media-info-icon {
                    width: 1.666vw;
                    height: 1.666vw;
                    cursor: pointer;
                    padding: 0.5em;
                    transition: 0.3s all ease-in-out;
                    .cls-1{
                        fill:$companiesOptionTextColor;
                    }

                    &:hover {
                        background-color: rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }

            }

        }
    }
}