@import "../../../../variables.scss";

.import-file-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;

    .import-file-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;

        .title-name span {
            text-transform: none;
        }
    }

    .import-file-content {
        padding: 2rem;
        display: flex;
        align-items: center;

        .import-text {
            font-size: 1.284375vw;
            font-weight: bold;
            color: #0b3e79;
            text-transform: none;
            width: fit-content;
        }


        .error-holder {
            span {
                color: #EEAC17 !important;
                font-weight: 500 !important;
                font-size: 0.927vw;
            }
        }

        .doc-saved-content {
            margin-left: 1rem;

            span {
                font-size: 1.184375vw;
                font-weight: 600;
                color: #0b3e79;
                text-transform: none;
            }
        }

        .warning-text {
            color: $statusWarningColor !important;
        }
    }

    .import-file-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        border-top: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top: 2em;
        padding-left: 3em;
        width: 100%;

        .btn-new {
            span {
                text-transform: none !important;
            }
        }

        .btn-cancel {
            margin-right: 1rem;
        }
    }
}