.tdc_textemall_container {
  display: flex;
  flex-direction: column;
  // width: -webkit-fill-available;
  position: relative;
  padding: 1em;
  overflow: hidden;
  min-height: 500px;
  height: 500px;
  width: 100%;
  .tdc_textemall_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    overflow: auto;

    .error-span {
      word-break: break-all;
      white-space: pre-wrap;
    }
    .broadcast_iframe {
      width: 100%;
      min-height: 440px;
      height: 450px;
    }
  }
}
