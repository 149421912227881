@import "../../../../../app/variables";

.cmp-asmnt-main-container {
  border: 1px solid $tableBorderColor;
  color: $primaryColor;
  padding-bottom: 1em;

  .cmp-asmnt-header {
    padding: 1em;
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .cmp-asmnt-header-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      .active-checkbox {
        display: flex;
        align-items: center;
        margin-left: 20px;

        span {
          margin-left: 5px;
        }
      }

      .search-bar {
        margin-right: 1em;
      }

      .total-count {
        display: flex;
        align-items: center;
        margin-left: 1em;
        font-size: 0.781vw;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $defaultHighLightColor;
        span {
          font-weight: bold;
        }
      }
    }
    button{
      background-color: #FF4D00;
      box-shadow: none;
      svg{
        g path:first-child{
          fill:white !important;
        }
        g path:nth-child(2){
          fill:#ff4d00 !important;
        }
        g path:nth-child(3){
          fill:#ff4d00 !important;
        }
      }
    }
  }
  .cap-table-footer {
    position: relative;
  }
  .cap-table {
    .sortable-table {
      tbody {
        display: block;
        overflow-y: auto;
        max-height: 48vh;
      }
    }
  
    @media screen and (max-width:1650px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 45vh;
        }
      }
    }
  
    @media screen and (max-width:1500px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 45vh !important;
        }
      }
    }
  
    @media screen and (max-width:1280px) {
      .sortable-table {
        tbody {
          display: block;
          overflow-y: auto;
          max-height: 48vh !important;
        }
      }
    }
  }
  .select_talent_msg{
    text-transform: initial;
    color:#ff4d00;
    margin-top:0.3em;
}
}
