@import '../../../../variables.scss';


.jt-cp-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding-top: 1em;
    overflow: hidden;

    .jt-cp-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
        align-items: center;
        .title-icon {
            margin-right: 0.5em;
            svg{
                width:1.3vw;
                height:1.3vw;
        
            }
        }
    }

    .jt-cp-content {
        padding:1em 1em 1.5em 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        .confirm-text{
            font-size:0.89vw;
            font-weight:600;
            color:$primaryColor;
            text-transform: none;
        }

    }

    .jt-cp-actions-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid $tableBorderColor;
        padding-top: 1rem;
        margin-left:3em;
        .jt-cp-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .btn-cancel {
                margin-right: 1rem;
            }
        }

    }

}