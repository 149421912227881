@import '../../../../../../variables.scss';

.mt-export-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $tableBorderColor;
    padding:5rem 0;
    .btn-div {
        display: flex;
        justify-content: center;
        width: fit-content;
    }

}