@import '../../../../../variables.scss';


.certi-docs-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    padding: 1.0416666666666667vw;

    .btn-row {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .certi-pill-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        // margin-top: 2em;

        .pill-card {
            width: 48%;
        }

        .doc-pill-card-container {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 1.0416666666666667vw;
            box-shadow: 0px 0.5px 1px 0px rgba($color: $pageHeaderBoderColor, $alpha: 1.0);
            border-radius: 5px;
            margin-bottom: 1.0416666666666667vw;
            background-color: #F7FBFD;
            border: 1px solid #C1CDD466;

            width: -webkit-fill-available;
            transition: 0.3s all ease-in-out;

            &:hover {
                background-color: #dff1fa;
            }

            .stat-icon {
                width: 1.6276041666666667vw;
                height: 1.6276041666666667vw;
                margin-right: 1.0416666666666667vw;
                flex:none;
            }

            .doc-name {
                span {
                    color: $defaultHighLightColor;
                    font-size: 0.8463541666666666vw;
                    font-weight: bold;

                }
            }

            .stat-banner {
                position: absolute;
                top: 0.441667vw;
                right: 0.441667vw;
                padding: 0 0.441667vw;
                border-radius: 20px;

                span {
                    color: #fff;
                    font-size: 0.5208333333333334vw;
                }
            }
        }

        .doc-pill-card {
            position: relative;

            .parent-position {
                height: 0;
                position: absolute;
                width: 0;
                z-index: 1000;
                top: 30px;
                left: 100%;
                /* Position it to the right of the .doc-pill-card */
                transform: translateX(-10px);
                /* Add some spacing between the card and tooltip */
                // padding: 10px;
                opacity: 0;
                /* Initially hidden */
                pointer-events: none;
                /* Hide it from interactions */
                transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

                .child-position {
                    display: flex;
                    position: absolute;
                    // left: 50%;
                    // transform: translateX(20%);
                    background-color: white;
                    // margin-top: 0.5em;
                    padding: 1em;
                    border-radius: 4px;
                    border: 1px solid rgba($color: $pageHeaderBoderColor, $alpha: 1.0);
                    // right: -3em;
                    // bottom: -1.7em;

                    .action-list-title {
                        color: $defaultHighLightColor;
                        font-size: 0.8463541666666666vw;
                        font-weight: 700;
                        min-width: 10vw;
                        text-transform: capitalize;
                    }

                    .uploaded {
                        color: $defaultHighLightColor;
                    }

                    .doc-icon {
                        width: 1.25vw;
                        height: 1.25vw;
                        margin-right: 1em;

                    }

                    .action-btn {
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                    }

                }

                .child-position::after {
                    content: '';
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    background-color: white;
                    top: 20%;
                    left: -6px;
                    border-top: rgba($color: $pageHeaderBoderColor, $alpha: 1.0) solid 1px;
                    border-left: rgba($color: $pageHeaderBoderColor, $alpha: 1.0) solid 1px;
                    transform: rotate(-45deg);
                }

                .doc-down-btn {
                    padding: 2px 4px;

                    .btn-text {
                        display: none;
                    }

                    svg {
                        width: 0.7vw;
                        height: 0.7vw;
                    }

                }


            }
        }
        .doc-pill-card:hover .parent-position {
            opacity: 1; /* Show the tooltip when hovered */
            pointer-events: auto; /* Enable interactions with the tooltip */
          }

    }

}