@import '../../../variables.scss';

.ac-sytm-cns-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;

    .ac-sytm-cns-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 1em 2em;
        width: -webkit-fill-available;
        position: relative;
    }

    .ac-sytm-cns-content {
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 2em;
        position: relative;

        .ac-sytm-cns-table-holder {
            box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
            display: flex;
            flex-direction: column;

            .ac-sytm-cns-table-header {
                padding: 1em;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            
        }
    }

}

.table-loading {
    width: 100%;
    height: 200px;
}


.label-text {
    font-size: 0.729vw;
    color: $primaryColor;
    font-style: italic;
    display: block;
    padding-bottom: 10px;
}

.ac-sc-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    float: right;
    vertical-align: bottom;
    margin-top: 20px;

    .ac-sc-add-icon {
        color: $white;
        margin-right: 0.5em;
    }
}

.ac-sc-fields {
    flex-wrap: nowrap;
    width: 25%;

}

.ac-sc-formControl {
    width: 95%;
}

.ac-sc-table-header {
    padding: 1em;
    display: flex;
    flex-direction: row;
}

.ac-sc-top-header {
    padding: 1em;
    display: flex;
    flex-direction: row;
    padding-left: 30px;
    margin-top: -30px;
    margin-bottom: 1em;
    
}

.select-list {
    padding: 0px;
    height: 1.562vw;
}

.delete-icon {
    width: 0.833vw;
    height: 0.833vw;
    font-size: 0.833vw;
    background: none;
    border: none;
}

.edit-icon {
    width: 0.833vw;
    height: 0.833vw;
    font-size: 0.833vw;
    background: none;
    border: none;
    margin-right: 10px;
}

.ac-sytm-cns-content {
    .ac-sytm-cns-table {
        table {
            width: -webkit-fill-available;
            border: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);

            thead {
                background-color: $tableHeaderBackgroundColor;

                tr {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    .tb-header-cc {
                        display: flex;
                        flex: 1;
                        flex-shrink: 1;
                        flex-grow: 1;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        padding: 1em;
                        transition: 0.3s all ease-in-out;
                        cursor: pointer;
                        border-right: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);

                        &:hover {
                            background-color: rgba($color: $defaultHighLightColor, $alpha: 0.05);

                            .table-header-sort-icon {
                                display: block;
                            }
                        }

                        span {
                            text-overflow: ellipsis;
                            color: $primaryColor;
                            text-decoration: unset;
                        }

                        .table-header-sort-icon {
                            display: none;
                            font-size: 0.729vw;
                            transition: 0.3s all ease-in-out;
                            transform: rotate(0deg);
                            // color: $primaryColor;
                        }

                        .table-header-sort-icon-rotate {
                            transform: rotate(-180deg);
                            display: block;
                            transition: 0.3s all ease-in-out;
                        }

                    }
                }
            }

            tbody {
                tr {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    border-top: 1px solid rgba($color: $tableRowBorderColor, $alpha: 0.3);
                    cursor: unset;
                    transition: unset;

                    &:hover {
                        background-color: unset;
                    }

                    td {
                        flex: 1;
                        padding: 1em;
                        text-decoration: unset;
                        color: $tableRowTextColor;
                        border-right: 1px solid rgba($color: $userProfileNameColor, $alpha: 0.1);
                    }
                }
            }

        }
    }
}