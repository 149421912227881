@import '../../../../variables.scss';

.dshb-soa-f-container {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    position: relative;
    padding: 1em;
    overflow: hidden;
    // max-width: 50%;

    .dshb-soa-f-header {
        display: flex;
        width: -webkit-fill-available;
        position: relative;
        margin-bottom: 1em;
    }
    // .info-txt 
    // {
    //     color: #0b3e79;
    //     font-size: 0.9375vw !important;
    //     font-weight: 700;
    //     margin-left: 2.5em;
    //     margin-bottom: 1em;
    //     text-transform: none;
    // }
    

    .dshb-soa-f-content {
        padding: 3em;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .dshb-soa-f-section-divider {
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: 1px solid rgba($color: $formDeviderColor, $alpha: 0.4);

            .dshb-soa-f-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .dshb-soa-f-input-holder {
                    min-width: 9.895833333333334vw;
                    margin-right: 1em;
                }
            }
        }

        .tint-ihi-questions-section {
            display: flex;
            flex-direction: column;

            .tint-ihi-question-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2em;

                .tint-ihi-question-title {
                    // margin-bottom: 0.5em;

                    span {
                        font-size: 0.833vw;
                        color: $defaultHighLightColor;
                        font-weight: bold;
                    }
                }

                .tint-ihi-input-form {
                    display: flex;
                    flex-direction: column;
                    // margin-left: 1em;
                }
            }
        }

        .error-section {
            margin-bottom: 2em;
        }
    }

    .dshb-soa-f-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        // margin-top: 2em;
        border-top:1px solid rgba($color: $formDeviderColor, $alpha: 0.4);
        padding-top:1em;

        .btn-cancel {
            margin-right: 1em;
        }
    }

}

.dshb-soa-f-link-icon {
    width: 0.833vw;
    height: 0.833vw;
}


.dshb-soa-form {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    .dshb-soa-f-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .dshb-soa-f-input-holder {
            width: -webkit-fill-available;
            margin-right: 1em;
        }
    }
}


.dshb-soa-f-mark-success-holder{
    display: flex;
    flex-direction: column;

    .info-txt{
        font-size: 1.3020833333333333vw !important;
        margin-bottom: 0.5em;

    }

    .name,.email,.phone-number{
        font-size: 0.9375vw !important;
        font-weight: 100;
        margin-bottom: 0.5em;
    }
}