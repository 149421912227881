@import '../../../variables.scss';

.ac-sc-container {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  position: relative;

  .ac-sc-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 20px 0 20px;
    width: -webkit-fill-available;
    position: relative;
    border: 0 !important;

    .ac-sc-header-close {
      display: flex;
      align-items: center;
    }

    p {
      color: $defaultActionColor;
      font-size: 0.781vw;
      cursor: pointer;
      margin: 0;
    }

  }

  .ac-sc-dropdown {
    margin: 0 20px 20px 20px;

    p {
      color: $defaultHighLightColor;
      font-size: 0.729vw;
      margin-bottom: 5px;
    }
  }

  .ac-sc-dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: -webkit-fill-available;

    #testing {
      width: 350px;
    }

  }

  .ac-sc-table {
    margin: 20px;

    table {
      border: 1px solid $tableBorderColor;
      // border-left: 0;
      color: $tableRowTextColor;
    }

    .sortable-table tbody tr {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // td {
    //   border-left: 1px solid $tableBorderColor !important;
    //   border-top: 1px solid $tableBorderColor !important;
    // }

    th {
      border-left: 1px solid $tableBorderColor;
    }

    img {
      cursor: pointer;
      height: 0.781vw;
      width: 0.781vw;
    }

    // tr td:last-child,
    // tr th:last-child {
    //   flex: 0.3;
    //   display: flex;
    //   justify-content: center;
    // }

    .empty-table {
      display: flex;
      justify-content: center;
      margin-top: 75px;

      p {
        color: $defaultHighLightColor;
        font-size: 1.875vw;
      }

    }

  }

  .ac-sc-tlem-table {

    td,
    th {
      flex: 1 !important;
    }
  }

  .ac-sc-jobs-table {

    // td:nth-child(2),
    // th:nth-child(2) {
    //   flex: 0.3 !important;
    // }
  }

  .ac-sc-content {
    padding-left: 2em;
    padding-right: 2em;
    position: relative;

    .ac-um-table-holder {
      box-shadow: 0px 0px 2px 0px rgba($color: $tableBorderColor, $alpha: 1.0);
      display: flex;
      flex-direction: column;

      .ac-um-table-header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .ac-um-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .ac-um-add-user-btn {
          width: 148px;
        }

        .ac-um-add-icon {
          color: $white;
        }
      }
    }
  }

}

.ac-sc-table-loading {
  width: 100%;
  height: 200px;
}

.ac-sc-add-icon {
  color: $white;
}

.dialog-container {
  width: -webkit-fill-available;
  // height: 280px;
  color: $defaultHighLightColor;
  padding: 1em 1em 0 0.5em;

  .dialog-header {
    display: flex;
    justify-content: space-between;
    // padding: 15px;


    img {
      color: black;
      cursor: pointer;
    }

  }

  .dialog-content {
    padding: 1rem;

    input {
      width: -webkit-fill-available;
    }

    .message {
      margin: 0;
      margin-top: 5px;
      font-size: 0.625vw;
      height: 10px;
    }

  }

  .dialog-footer {
    padding-right: 0;
    .custom-btn {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

}

.dialog-container-2 {
  // height: 360px !important;

  .dialog-content {
    // padding-bottom: 30px !important;

    input {
      margin-bottom: 10px;
    }

    textarea {
      resize: none;
      height: 70px;
    }

    .message {
      height: 10px;
      display: block;
    }

  }

}

.dialog-delete-container {
  width: 400px;
  height: 100% !important;
  color: $defaultHighLightColor;

  .dialog-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0 15px;
  }

  .dialog-content {

    padding: 1rem;

    p {
      font-size: 1vw !important;
    }

    span {
      font-size: 0.9vw
    }
  }

  .dialog-footer {
    .custom-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.update-title {
  font-size: 1.04vw;
  font-weight: 700;
}
